
import { Component, Prop, Watch } from 'vue-property-decorator'
import BasicComponent from '@/views/BasicComponent'
import ShipmentCatalogueSelectBox from '@/components/select-boxes/ShipmentCatalogueSelectBox.vue'
import { ICategory, Packaging } from '@/models/packaging'
import EnviayaNumberInput from '@/overrides/mdb-components/EnviayaNumberInput.vue'
import {
  CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE,
  CATALOGUES_PRODUCT_CATEGORY_DEFAULT_MAX_WEIGHT_VALUE,
  CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE, PM_PACKAGING_CATEGORY, PM_PRODUCT_CATEGORY
} from '@/models/shipment'

const MAX_WEIGHT = 31.5

@Component({
  components: {
    ShipmentCatalogueSelectBox,
    EnviayaNumberInput
  }
})
export default class PackagingCatalogueSelections extends BasicComponent {
  @Prop(Number) public countryId!: number | undefined
  @Prop({ default: false }) public submitted!: boolean
  @Prop({ default: true }) public showTooltip!: boolean
  @Prop() public package!: Packaging
  @Prop({ default: null }) public physicalWeight?: number | null

  public CATALOGUE_TYPES: { [key: string]: string } = {
    PACKAGING_CATEGORY : 'c_ClaveUnidadPeso',
    PRODUCT_CATEGORY : 'c_ClaveProdServCP',
    DANGEROUS_MATERIAL_PRODUCT_CATEGORY : 'c_MaterialPeligroso',
    DANGEROUS_MATERIAL_PACKAGING_CATEGORY : 'c_TipoEmbalaje'
  }

  public packagingCategory: ICategory = { type: 'mx_sat_packaging', key: PM_PACKAGING_CATEGORY, code: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.value, name: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.text }
  public productCategory: ICategory = { type: 'mx_sat_product', key: PM_PRODUCT_CATEGORY, code: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.value, name: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.text }

  public packagingCategoryDefaultValue: { id: string, text: string, selected: boolean }[] = []
  public productCategoryDefaultValue: { id: string, text: string, selected: boolean }[] = []

  public validation: any = {
    packagingCategory: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    },
    productCategory: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    },
    dangerousMaterialProductCategory: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    },
    dangerousMaterialPackagingCategory: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    }
  }

  public created () {
    this.packagingCategory = this.package.packagingMeta.categories.find((category: ICategory) => category.type === 'mx_sat_packaging' )
      ?? { type: 'mx_sat_packaging', key: PM_PACKAGING_CATEGORY, code: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.value, name: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.text }

    this.productCategory = this.package.packagingMeta.categories.find((category: ICategory) => category.type === 'mx_sat_product' )
      ?? { type: 'mx_sat_product', key: PM_PRODUCT_CATEGORY, code: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.value, name: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.text }

    this.packagingCategoryDefaultValue.push({ id: this.packagingCategory.code as string, text: `${this.packagingCategory.name} (${this.packagingCategory.code})`, selected: true })
    this.productCategoryDefaultValue.push({ id: this.productCategory.code as string, text: `${this.productCategory.name} (${this.productCategory.code})`, selected: true })
  }

  public validateCartaForte (): boolean {
    let isValidForm: boolean = true
    if (!this.package.hasDangerousMaterials) {
      if (this.packagingCategory.code) {
        this.validation['packagingCategory'].valid = true
        this.validation['packagingCategory'].invalidFeedback = ''
      } else {
        this.validation['packagingCategory'].valid = false
        this.validation['packagingCategory'].invalidFeedback = 'can\'t be blank'
        isValidForm = false
      }
      this.validation['packagingCategory'].validated = true

      if (this.productCategory.code) {
        this.validation['productCategory'].valid = true
        this.validation['productCategory'].invalidFeedback = ''
      } else {
        this.validation['productCategory'].valid = false
        this.validation['productCategory'].invalidFeedback = 'can\'t be blank'
        isValidForm = false
      }
      this.validation['productCategory'].validated = true
    }

    if (this.package.hasDangerousMaterials) {
      if (this.package.dangerousMaterialProductCategory) {
        this.validation['dangerousMaterialProductCategory'].valid = true
        this.validation['dangerousMaterialProductCategory'].invalidFeedback = ''
      } else {
        this.validation['dangerousMaterialProductCategory'].valid = false
        this.validation['dangerousMaterialProductCategory'].invalidFeedback = 'can\'t be blank'
        isValidForm = false
      }
      this.validation['dangerousMaterialProductCategory'].validated = true

      if (this.package.dangerousMaterialPackagingCategory) {
        this.validation['dangerousMaterialPackagingCategory'].valid = true
        this.validation['dangerousMaterialPackagingCategory'].invalidFeedback = ''
      } else {
        this.validation['dangerousMaterialPackagingCategory'].valid = false
        this.validation['dangerousMaterialPackagingCategory'].invalidFeedback = 'can\'t be blank'
        isValidForm = false
      }
      this.validation['dangerousMaterialPackagingCategory'].validated = true
    }
    return isValidForm
  }

  @Watch('physicalWeight')
  public physicalWeightChanged (newValue: number, oldValue: number) {
    if (this.productCategory.code === CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.value && newValue !== oldValue) {
      this.productCategoryDefaultValue = []
      if (this.physicalWeight) {
        if (this.physicalWeight > MAX_WEIGHT) {
          this.productCategoryDefaultValue.push({ id: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_MAX_WEIGHT_VALUE.value, text: `${CATALOGUES_PRODUCT_CATEGORY_DEFAULT_MAX_WEIGHT_VALUE.text} (${CATALOGUES_PRODUCT_CATEGORY_DEFAULT_MAX_WEIGHT_VALUE.value})`, selected: true })
          this.productCategory.code = CATALOGUES_PRODUCT_CATEGORY_DEFAULT_MAX_WEIGHT_VALUE.value
        } else {
          this.productCategoryDefaultValue.push({ id: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.value, text: `${CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.text} (${CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.value})`, selected: true })
          this.productCategory.code = CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.value
        }
      }
    }
  }

  @Watch('package.packagingCategory.select_box_text')
  public packagingCategoryChanged () {
    this.packagingCategoryDefaultValue = []
    this.packagingCategoryDefaultValue.push({ id: this.packagingCategory.code as string, text: `${this.packagingCategory.name} (${this.packagingCategory.code})`, selected: true })
  }

  @Watch('package.productCategory.select_box_text')
  public productCategoryChanged () {
    this.productCategoryDefaultValue = []
    this.productCategoryDefaultValue.push({ id: this.productCategory.code as string, text: `${this.productCategory.name} (${this.productCategory.code})`, selected: true })
  }
}

