<script>
  import { DirectionsApiService } from '@/common/api/directions.api.service'
  import { Direction } from '@/models/direction'
  import { plainToClass } from 'class-transformer'
  import BasicSelect from '@/components/select-boxes/BasicSelect'
  import $ from 'jquery'
  import { USER_STATUSES } from '@/common/user.settings'

  export default {
    name: 'DirectionSelectBox',
    extends: BasicSelect,
    watch: {
      async value (val) {
        let direction
        if (val && this.init) {
          let directionResponse = await DirectionsApiService.get(val)
          direction = directionResponse.data
          this.setOption([this.formatOption(direction)])
        }

        this.setValue(val)
      }
    },
    methods: {
      processResults (data, params) {
        const results = data.map((direction) => (
          this.formatOption(direction)
        ))

        return {
          results
        }
      },
      formatOption (direction) {
        let directionName = direction.full_name
        if (direction.company) {
          directionName += ` (${direction.company})`
        }
        return { id: direction.id, title: direction.full_name, text: directionName, link: false, direction }
      },
      formatResult (option) {
        return option.text
      },
      formatSelection (option) {
        return option.text
      }
    },
    async mounted () {
      if (this.value && this.init) {
        let directionResponse = await DirectionsApiService.get(this.value)
        let direction = directionResponse.data
        this.setOption([this.formatOption(direction)])
      }

      this.setValue(this.value)
    }
  }
</script>
