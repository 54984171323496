import { useQuery } from '@tanstack/vue-query'
import { Ref } from 'vue'
import { UsersApiService } from '@/common/api/users.api.service'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { plainToClass } from 'class-transformer'

export const useUsersList = (applyFilter: Ref<boolean>, queryString: Ref<string>) => {
  const { isFetching, data } = useQuery({
    queryKey: ['userList', queryString],
    queryFn: () => {
      return UsersApiService.search(queryString.value)
    },
    select: ({ data }) => {
      return data.users
        .map((user: any) => {
          const userModel: EnviayaUserTS = plainToClass(EnviayaUserTS, user)
          return { text: `${userModel.name} (${userModel.email})`, value: userModel.id, selected: false }
        })
    },
    staleTime: 1000 * 60 * 2, // Two minutes for caching results
    retry: 0,
    enabled: applyFilter
  })

  return { isLoading: isFetching, data }
}

export const useUser = (
  applyFilter: boolean,
  id: number | undefined | null
) => {
  const { isFetching, data } = useQuery({
    queryKey: ['user', id],
    queryFn: () => {
      return UsersApiService.get(id)
    },
    select: ({ data }: { data: any }) => {
      const model: EnviayaUserTS = plainToClass(EnviayaUserTS, data)
      return { text: `${model.firstName} ${model.lastName} (${model.email})`, value: model.id }
    },
    staleTime: 1000 * 60 * 2, // Two minutes for caching results
    retry: 0,
    enabled: applyFilter
  })

  return { isLoading: isFetching, data }
}
