import { Exclude, Expose, Transform } from 'class-transformer'
import { AbstractModelList } from '@/models/abstract-model-list'
import { CorporateAccountTs } from '@/models/corporate-account-ts'

@Exclude()
export class CorporateAccountList extends AbstractModelList<CorporateAccountTs> {
  @Expose({ name: 'all_accounts_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public allAccountsCount: number = 0

  @Expose({ name: 'reseller_accounts_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public resellerAccountsCount: number = 0

  @Expose({ name: 'enviaya_accounts_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public enviayaAccountsCount: number = 0

  @Expose({ name: 'search_results_key' })
  @Transform(value => value || '', { toClassOnly: true })
  public searchResultsKey: string = ''
}
