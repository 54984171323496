<i18n src='@/locales/select_boxes.json'></i18n>
<i18n src="@/locales/select2.json"></i18n>
<script>
  import BasicSelect from '@/components/select-boxes/BasicSelect'
  import $ from 'jquery'

  export default {
    name: 'CarrierAccountSelectBox',
    extends: BasicSelect,
    props: {
      includeNone: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      processResults (data, params) {
        let options = []
        if (this.includeNone) {
          options.push({ id: 'none', text: this.$i18n.t('options.none') })
        }
        options.push(...data.carrier_accounts.map((el) => { return { id: el.id, text: `${ el.select_box_text }` } }))
        return {
          results: options
        }
      },
      formatResult (option) {
        if (option.link) {
          return $.parseHTML('<span>' + option.text + '</span>')
        }
        return option.text
      }
    }
  }
</script>
