import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import moment from 'moment'
import Vue from 'vue'
import { WhiteLabel } from '@/models/white-label'
import { CorporateAccountTs } from '@/models/corporate-account-ts'
import { CustomerInfo } from '@/models/customer-info'
import { DiscountProfile } from '@/models/discount-profile'
import { IDateRange } from '@/mixins/rangePicker'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { Assignation } from '@/models/enviaya-account/assignation'
import { Fee } from './Fee'
import { QuotingFilter } from '@/models/quoting-filter'

export const ACCOUNT_TYPE_ENVIAYA = 1
export const ACCOUNT_TYPE_RESELLER = 2

export enum ACCOUNT_STATUSES {
  ACTIVE = 1,
  DISABLED = 2,
  DELETED = 3,
  DISABLED_SYSTEM = 5
}

export enum STANDARD_PACKAGING_APPLICATION {
  DO_NOT_APPLY = 1,
  ALWAYS_APPLY = 2
}

// @ts-ignore
export const BALANCE_SALDO_TYPE_ENVIAYA = 1
export const BALANCE_SALDO_TYPE_RESELLER = 2

export const DONT_INSURE_SHIPMENTS = 'dont_insure_shipments'
export const INSURE_A_FIXED_AMOUNT = 'insure_a_fixed_amount'
export const INSURE_SHOPPING_CART_VALUE = 'insure_shopping_cart_value'

export interface ISelectData {
  text: string,
  value: string | number | undefined,
  selected?: boolean
}

export const ACCOUNT_TYPES: ISelectData[] = [
  { value: ACCOUNT_TYPE_ENVIAYA, text: 'enviaya' },
  { value: ACCOUNT_TYPE_RESELLER, text: 'reseller' }
]

export const PAYMENT_TYPES_LIST: ISelectData[] = [
  { text: `prepaid`, value: 1, selected: false },
  { text: `credit`, value: 2, selected: false },
  { text: `corporate_prepaid`, value: 3, selected: false }
]

export const SCOPE_LIST: ISelectData[] = [
  { text: `new_customers`, value: 'new_customers', selected: false },
  { text: `existing_customers`, value: 'existing_customers', selected: false },
  { text: `lost_customers`, value: 'lost_customers', selected: false },
  { text: `recently_lost_customers`, value: 'recently_lost_customers', selected: false },
  { text: `reactivated_customers`, value: 'reactivated_customers', selected: false },
  { text: `non_recurring_customers`, value: 'non_recurring_customers', selected: false },
  { text: `recurring_customers`, value: 'recurring_customers', selected: false },
  { text: `recurring_small_customers`, value: 'recurring_small_customers', selected: false },
  { text: `recurring_medium_customers`, value: 'recurring_medium_customers', selected: false },
  { text: `recurring_big_customers`, value: 'recurring_big_customers', selected: false },
  { text: `recently_lost_small_customers`, value: 'recently_lost_small_customers', selected: false },
  { text: `recently_lost_medium_customers`, value: 'recently_lost_medium_customers', selected: false },
  { text: `recently_lost_big_customers`, value: 'recently_lost_big_customers', selected: false },
  { text: `reactivated_small_customers`, value: 'reactivated_small_customers', selected: false },
  { text: `reactivated_medium_customers`, value: 'reactivated_medium_customers', selected: false },
  { text: `reactivated_big_customers`, value: 'reactivated_big_customers', selected: false },
  { text: `google_adwords_users`, value: 'google_adwords_users', selected: false },
  { text: `facebook_users`, value: 'facebook_users', selected: false }
]

export const ROLE_LIST: ISelectData[] = [
  { text: `1`, value: '1', selected: false },
  { text: `2`, value: '2', selected: false },
  { text: `3`, value: '3', selected: false },
  { text: `4`, value: '4', selected: false },
  { text: `5`, value: '5', selected: false },
  { text: `6`, value: '6', selected: false },
  { text: `7`, value: '7', selected: false },
  { text: `90`, value: '90', selected: false },
  { text: `91`, value: '91', selected: false },
  { text: `92`, value: '92', selected: false },
  { text: `93`, value: '93', selected: false },
  { text: `95`, value: '95', selected: false },
  { text: `94`, value: '94', selected: false },
  { text: `96`, value: '96', selected: false },
  { text: `99`, value: '99', selected: false }
]

export const STORE_PICKUP_TYPES_LIST: ISelectData[] = [
  { text: `always_show_store_pickup`, value: 1, selected: false },
  { text: `define_locations_for_store_pickup`, value: 2, selected: false }
]

export const INVOICE_SCHEDULE_LIST: ISelectData[] = [
  { text: `custom`, value: 0, selected: false },
  { text: `every_1st_and_16th`, value: 1, selected: false },
  { text: `every_1st`, value: 2, selected: false }
]

@Exclude()
export class SaldoJob {
  @Expose({ name: 'saldo_type' })
  @Transform(value => value ?? null)
  public saldoType: number | null = null

  @Expose({ name: 'job_id' })
  @Transform(value => value ?? null)
  public jobId: string | null = null
}

@Exclude()
export class EnviayaAccountReference extends AbstractModel {
  @Expose({ name: 'reference' })
  public reference: string = ''

  @Expose({ name: 'is_editable' })
  @Transform((value) => (value) ? value : false)
  public isEditable: boolean = false

  @Expose({ name: 'is_hidden' })
  @Transform((value) => (value) ? value : false)
  public isHidden: boolean = false

  @Expose({ name: '_destroy' })
  @Transform((value) => (value) ? value : false)
  public destroy: boolean = false

  public validated: boolean = false
}

@Exclude()
export class EnviayaAccountTS extends AbstractModel {
  static get modelName () {
    return 'EnviayaAccount'
  }

  /* General information */

  @Expose({ name: 'account' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public account: string | undefined

  @Expose({ name: 'alias' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public alias: string | undefined | null = ''

  @Expose({ name: 'select_box_text', toClassOnly: true })
  public selectBoxText: string | undefined

  @Expose({ name: 'status' })
  public status: number | undefined

  @Expose({ name: 'account_type' })
  public accountType: number | undefined | null = null

  public get account_type () {
    return this.accountType
  }

  @Expose({ name: 'reseller_operation_mode' })
  public resellerOperationMode: number | undefined

  public get reseller_operation_mode () {
    return this.accountType
  }

  @Expose({ name: 'balance_saldo_type' })
  public balanceSaldoType: number | undefined | null = null

  public get balance_saldo_type () {
    return this.balanceSaldoType
  }

  @Expose({ name: 'corporate_account_id' })
  @Transform(value => { return (value === null) ? undefined : value }, { toClassOnly: true })
  public corporateAccountId: number | undefined | null = null

  @Expose({ name: 'white_label_id' })
  @Transform(value => { return (value === null) ? undefined : value }, { toClassOnly: true })
  public whiteLabelId: number | undefined | null = null

  @Expose({ name: 'users', toClassOnly: true })
  @Type(() => EnviayaUserTS)
  @Transform(value => value || [], { toClassOnly: true })
  public users: EnviayaUserTS[] = []

  @Expose({ name: 'user_ids' })
  @Transform(value => value || [], { toClassOnly: true })
  public userIds: string[] = []

  @Expose({ name: 'quoting_filter' })
  @Type(() => QuotingFilter)
  public quotingFilter: QuotingFilter | undefined

  /* General information */

  /* Pricing information */

  @Expose({ name: 'discount_profile_id' })
  public discountProfileId: number | undefined | null = null

  @Expose({ name: 'discount_profile', toClassOnly: true })
  @Type(() => DiscountProfile)
  public discountProfile: DiscountProfile | undefined | null = null

  @Expose({ name: 'xeditable_options', toClassOnly: true })
  @Type(() => DiscountProfile)
  public xeditableOptions: DiscountProfile[] = []

  @Expose({ name: 'custom_discounts_enabled' })
  public customDiscounts: boolean = false

  @Expose({ name: 'custom_surcharge_discounts_enabled' })
  public customSurchargeDiscountsEnabled: boolean = false

  @Expose({ name: 'fee_attributes' })
  @Transform((value) => {
    if (value) {
      return value
    }
    return new Fee()
  })
  @Type(() => Fee)
  public feeAttributes: Fee = new Fee()

  /* Pricing information */

  /* Invoicing information */

  @Expose({ name: 'invoice_direction_id' })
  public invoiceDirectionId: string | undefined

  @Expose({ name: 'invoice_direction', toClassOnly: true })
  @Transform((value) => {
    if (value) {
      return [{ id: value.id, text: `${value.name} (${value.tax_id})` }]
    }
    return []
  })
  public invoiceDirection: { id?: string, text?: string }[] = []

  @Expose({ name: 'invoicing_schedule' })
  @Transform(value => { return (value === null || value === 0) ? undefined : value }, { toClassOnly: true })
  @Transform(value => { return (value === undefined) ? null : value }, { toPlainOnly: true })
  public invoicingSchedule: number | undefined

  public dateRange: IDateRange = {
    startDate: undefined,
    endDate: undefined
  }

  @Expose({ name: 'next_invoicing_date' })
  public set dateFrom (value) {
    this.dateRange.endDate = value
    this.dateRange.startDate = (value) ? moment(value).format('YYYY-MM-DD') : undefined
  }
  public get dateFrom (): string | undefined {
    return (this.dateRange.startDate) ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : undefined
  }

  @Expose({ name: 'next_invoicing_date' })
  public set dateTo (value) {
    this.dateRange.endDate = (value) ? moment(value).format('YYYY-MM-DD') : undefined
  }
  public get dateTo (): string | undefined {
    return (this.dateRange.endDate) ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : undefined
  }

  @Expose({ name: 'invoice_shipments_for_blank_credit_line' })
  @Transform(value => { return (!value) ? false : value }, { toClassOnly: true })
  public invoiceShipmentsForBlankCreditLine: boolean = false

  @Expose({ name: 'credit_line' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  @Transform(value => { return !value ? null : value }, { toPlainOnly: true })
  public creditLine: string | null = null

  @Expose({ name: 'currency' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public currency: string = ''

  @Expose({ name: 'payment_period' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public paymentPeriod: string = ''

  @Expose({ name: 'grace_period' })
  public gracePeriod: number | undefined

  @Expose({ name: 'invoicing_period' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public invoicingPeriod: string = ''

  @Expose({ name: 'invoicing_emails' })
  @Transform(value => value ?? [], { toClassOnly: true })
  public invoicingEmails: string[] = []

  @Expose({ name: 'confirmed_invoicing_emails' })
  @Transform(value => value ?? [], { toClassOnly: true })
  public confirmedInvoicingEmails: string[] = []

  @Expose({ name: 'preferred_payment_type' })
  public preferredPaymentType: number | undefined

  @Expose({ name: 'recalculate_saldo_jobs', toClassOnly: true })
  @Type(() => SaldoJob)
  public recalculateSaldoJobs: SaldoJob[] = []

  /* Invoicing information */

  /* Configuration information */

  @Expose({ name: 'standard_packaging_id' })
  public standardPackagingId: number | undefined

  @Expose({ name: 'parcel_template_id' })
  public parcelTemplateId: number | undefined

  @Expose({ name: 'standard_packaging_application' })
  @Transform(value => value === 2, { toClassOnly: true })
  @Transform(value => value ? 2 : 1, { toPlainOnly: true })
  public standardPackagingApplication: boolean = false

  @Expose({ name: 'shipment_notifications_destination' })
  public shipmentNotificationsDestination: boolean = false

  @Expose({ name: 'shipment_notifications_origin' })
  public shipmentNotificationsOrigin: boolean = false

  public shipmentNotificationsUserEnabled: boolean = false

  @Expose({ name: 'shipment_notifications_user' })
  @Transform(value => { return (value === null) ? 0 : value }, { toClassOnly: true })
  public shipmentNotificationsUser: number = 0

  @Expose({ name: 'insurance' })
  @Transform(value => { return (value === null) ? 'dont_insure_shipments' : value }, { toClassOnly: true })
  public insurance: string | undefined

  @Expose({ name: 'insurance_amount' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public insuranceAmount: number | undefined | null = null

  @Expose({ name: 'insurance_currency' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public insuranceCurrency: string | undefined

  @Expose({ name: 'has_transactions' })
  public hasTransactions: boolean = false

  @Expose({ name: 'custom_service_definitions' })
  public customServiceDefinitions: boolean = false

  @Expose({ name: 'settings_locked' })
  public settingsLocked: number = 0

  @Expose({ name: 'default_references', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => EnviayaAccountReference)
  public defaultReferences: EnviayaAccountReference[] = []

  @Expose({ name: 'default_references_attributes', toPlainOnly: true })
  public defaultReferencesAttributes: any

  public addReferences (reference: EnviayaAccountReference) {
    this.defaultReferences.push(reference)
    let defaultReferencesAttributes: Object = {}
    if (this.defaultReferences.length) {
      this.defaultReferences.forEach((reference) => {
        Object.assign(defaultReferencesAttributes, { [(reference.id) ? reference.id.toString() : Math.floor(Math.random() * 10000)]: reference })
      })
    }
    this.defaultReferencesAttributes = defaultReferencesAttributes
  }

  public get references (): EnviayaAccountReference[] {
    let defaultReferencesAttributes: Object = {}
    if (this.defaultReferences.length) {
      this.defaultReferences.forEach((reference) => {
        Object.assign(defaultReferencesAttributes, { [(reference.id) ? reference.id.toString() : Math.floor(Math.random() * 10000)]: reference })
      })
    }
    this.defaultReferencesAttributes = defaultReferencesAttributes
    return this.defaultReferences.filter((reference) => !reference.destroy)
  }

  public useFixedShipmentContent: boolean = false

  @Expose({ name: 'fixed_shipment_content' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  @Transform(value => { return (value === undefined) ? null : value }, { toPlainOnly: true })
  public fixedShipmentContent: string | undefined | null

  /* Configuration information */

  /* Configuration API information */

  @Expose({ name: 'enable_store_pickup' })
  public enableStorePickup: boolean = false

  @Expose({ name: 'store_pickup_type' })
  public storePickupType: number | undefined

  @Expose({ name: 'enable_free_shipping' })
  public enableFreeShipping: boolean = false

  @Expose({ name: 'minimum_order_amount' })
  @Transform(value => { return (value === null) ? undefined : value }, { toClassOnly: true })
  public minimumOrderAmount: number | undefined

  @Expose({ name: 'minimum_order_amount_currency' })
  public minimumOrderAmountCurrency: string | undefined

  @Expose({ name: 'free_shipping_title' })
  @Transform(value => { return (value === null) ? undefined : value }, { toClassOnly: true })
  public freeShippingTitle: string | undefined

  @Expose({ name: 'replace_service_with_fee_shipping' })
  public replaceServiceWithFeeShipping: boolean = false

  @Expose({ name: 'show_free_shipping_only' })
  public showFreeShippingOnly: boolean = false

  @Expose({ name: 'send_all_items_in_one_parcel' })
  public sendAllItemsInOneParcel: boolean = false

  @Expose({ name: 'send_all_items_for_rating' })
  public sendAllItemsForRating: boolean = false

  @Expose({ name: 'send_all_items_for_booking' })
  public sendAllItemsForBooking: boolean = false

  @Expose({ name: 'store_pickup_cities' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public storePickupCities: string[] = []

  @Expose({ name: 'store_pickup_states' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public storePickupStates: string[] = []

  @Expose({ name: 'store_pickup_states_map', toClassOnly: true })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public storePickupStatesMap: { id: string, name: string }[] = []

  @Expose({ name: 'store_pickup_postal_codes' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public storePickupPostalCodes: string[] = []

  @Expose({ name: 'store_pickup_postal_codes_map', toClassOnly: true })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public storePickupPostalCodesMap: { id: string, name: string }[] = []

  /* Configuration API information */

  @Expose({ name: 'corporate_account', toClassOnly: true })
  @Type(() => CorporateAccountTs)
  public corporateAccount: CorporateAccountTs | undefined | null = null

  @Expose({ name: 'carrier_account_ids', toClassOnly: true })
  public carrierAccountIds: {id: number}[] = []

  @Expose({ name: 'credit_saldo', toClassOnly: true })
  public creditSaldo: string | undefined

  @Expose({ name: 'national_saldo', toClassOnly: true })
  public nationalSaldo: string | undefined

  @Expose({ name: 'international_saldo', toClassOnly: true })
  public internationalSaldo: string | undefined

  @Expose({ name: 'default_pickup_booking' })
  public defaultPickupBooking: boolean = false

  @Expose({ name: 'default_invoice_request' })
  public defaultInvoiceRequest: boolean = false

  @Expose({ name: 'country', toClassOnly: true })
  public country: string | undefined

  @Expose({ name: 'locale' })
  public locale: string | undefined

  @Expose({ name: 'time_zone' })
  public timezone: string | undefined

  @Expose({ name: 'formatted_credit_balance' })
  public formattedCreditBalance: string | undefined

  @Expose({ name: 'formated_credit_line' })
  public formattedCreditLine: string | undefined

  @Expose({ name: 'account_users_count' })
  public accountUsersCount: number | undefined

  @Expose({ name: 'white_label' })
  @Type(() => WhiteLabel)
  public whiteLabel: WhiteLabel | undefined

  @Expose({ name: 'permissions' })
  public permissions: any | undefined

  @Expose({ name: 'is_enviaya_account' })
  public isEnviayaAccount: boolean | undefined

  @Expose({ name: 'credit_balance' })
  public creditBalance: number | undefined

  @Expose({ name: 'customer_info', toClassOnly: true })
  @Type(() => CustomerInfo)
  public customerInfo: CustomerInfo | undefined

  @Expose({ name: 'created_at', toClassOnly: true })
  @Transform(value => {
    if (value) {
      return `${moment(Date.parse(value)).format(Vue.moment.localeData().longDateFormat('L'))} `
    } else {
      return ''
    }
  }, { toClassOnly: true })
  public createdAt: string | undefined

  @Expose({ name: 'sales_executive_assignations', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => Assignation)
  public salesExecutiveAssignations: Assignation[] = []

  @Expose({ name: 'any_negative_saldo', toClassOnly: true })
  public anyNegativeSaldo: number = 1

  public get isResellerBalanceSaldoType () {
    return this.balanceSaldoType === BALANCE_SALDO_TYPE_RESELLER
  }

  public get isReseller () {
    return this.accountType === ACCOUNT_TYPE_RESELLER
  }

  public get isEnviaya () {
    return this.accountType === ACCOUNT_TYPE_ENVIAYA
  }

  public get disabled () {
    return Number(this.status) === ACCOUNT_STATUSES.DISABLED
  }

  public get deleted () {
    return Number(this.status) === ACCOUNT_STATUSES.DELETED
  }

  // For abilities

  public get settings_locked () {
    return this.settingsLocked
  }

  public get white_label_id () {
    return this.whiteLabelId
  }

  public get corporate_account_id () {
    return this.corporateAccountId
  }

  constructor () {
    super()
  }
}
