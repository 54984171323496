import i18n from '@/i18n'
import { CARRIER_ACCOUNTING_STATUSES } from '@/constants/clearing-charge'

export function useCarrierAccountingStatuses(selectedValue: number[] | number | undefined | null) {
  return Object.values(CARRIER_ACCOUNTING_STATUSES)
    .filter((value) => Number.isInteger(value as number))
    .map((status) => {
      return {
        text: i18n.t(`carrier_accounting_status.carrier_accounting_status_${status}`) as string,
        value: status,
        selected:
          !!selectedValue &&
          ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toString()).includes(status.toString())) ||
            (!Array.isArray(selectedValue) && selectedValue.toString() === status.toString()))
      }
    })
}
