
  import { Component, Prop } from 'vue-property-decorator'
  import BasicComponent from '@/views/BasicComponent'

  @Component
  export default class CustomBasicSelect extends BasicComponent {
    @Prop(Boolean) public isValid!: boolean | false
    @Prop(String) public invalidFeedback!: string
    @Prop({ default: true }) public wasValidated!: boolean
  }
