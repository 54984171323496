import defaultsDeep from 'lodash/defaultsDeep'

const STEP_UPDATED = (state, step) => {
  state.step = step
}

const CUSTOMER_INFORMATION_UPDATED = (state, data) => {
  state.customerInformation = {}
  defaultsDeep(state.customerInformation, data)
}

const CONTACT_INFORMATION_UPDATED = (state, data) => {
  Object.assign(state.contactInformation, data)
}

export default {
  STEP_UPDATED,
  CUSTOMER_INFORMATION_UPDATED,
  CONTACT_INFORMATION_UPDATED
}
