import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import moment from 'moment'
import Vue from 'vue'
import { ORDER_PROVIDER } from '@/models/orders/order'
import { AbstractModel } from '@/models/abstract-model'
import { EnviayaAccountTS } from '@/models/EnviayaAccountTS'

export enum SHOP_STATUSES {
  ACTIVE = 1,
  DISABLED = 2,
  DELETED = 3,
  UNCONFIRMED = 4
}

export enum TRACKING_TYPES {
  ENVIAYA = 'enviaya',
  CARRIER = 'carrier'
}

export const PROVIDERS = {
  SHOPIFY: 'Shopify',
  WOOCOMMERCE: 'WooCommerce',
  MAGENTO: 'Magento 2',
  WIX: 'Wix',
  PRESTASHOP: 'Prestashop',
  OPENCART: 'OpenCart',
  ECWID: 'Ecwid',
  JUMPSELLER: 'Jumpseller',
  LIVERPOOL: 'Liverpool',
  TIENDANUBE: 'TiendaNube',
  VTEX: 'Vtex',
  BIGCOMMERCE: 'BigCommerce'
}

export class Feauture extends AbstractModel {
  @Expose({ name: 'name' })
  public name: string = ''

  @Expose({ name: 'enabled' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public enabled: boolean = false

  @Expose({ name: 'caption' })
  public caption: string = ''

  @Expose({ name: 'hint' })
  public hint: string = ''
}

export class Shop extends AbstractModel {
  static get modelName () {
    return 'Shop'
  }

  @Expose({ name: 'id' })
  public id: number | null = null

  @Expose({ name: 'provider' })
  public provider: string = ''

  @Expose({ name: 'shop_domain' })
  @Transform(value => { return (!value) ? '' : value })
  public shopDomain: string = ''

  public shopId: number | undefined | null = undefined

  @Expose({ name: 'consumer_key' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public consumerKey: string = ''

  @Expose({ name: 'consumer_secret' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public consumerSecret: string = ''

  @Expose({ name: 'client_id' })
  @Transform(value => { return (!value) ? '' : value })
  public clientId: string = ''

  @Expose({ name: 'shop_token' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public shopToken: string = ''

  @Expose({ name: 'user' })
  @Exclude({ toPlainOnly: true })
  @Type(() => EnviayaUserTS)
  public user: EnviayaUserTS | undefined

  @Expose({ name: 'user_id' })
  public userId: number | undefined

  @Expose({ name: 'orders_count' })
  @Transform(value => value || 0)
  public ordersCount: number | undefined

  public get user_id () {
    return this.userId
  }

  public get corporate_account_id () {
    return this.corporateAccountId
  }

  @Expose({ name: 'status' })
  @Transform(value => { return (value === null) ? 4 : value }, { toClassOnly: true })
  public status: number | undefined

  @Expose({ name: 'corporate_account_id' })
  public corporateAccountId: number | undefined

  @Expose({ name: 'enviaya_account_id' })
  @Transform(value => { return (value === null) ? undefined : value })
  public enviayaAccountId: number | null = null

  @Expose({ name: 'shipment_template_id' })
  @Transform(value => { return (value === null) ? undefined : value })
  public shipmentTemplateId: number | null = null

  @Expose({ name: 'features_list', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => Feauture)
  public featuresList: Feauture[] = []

  @Exclude({ toClassOnly: true })
  @Expose({ name: 'features', toPlainOnly: true })
  @Transform((_value, obj) => {
    let keys = {}
    if (obj.featuresList) {
      obj.featuresList.forEach((feature: Feauture) => Object.assign(keys, { [feature.name]: feature.enabled }))
    }
    return keys
  }, { toPlainOnly: true })
  public features: {} = {}

  public respectCheckoutShipping () {
    return this.featuresList ? this.featuresList.find((feature) => feature.name === 'respect_checkout_shipping') : null
  }

  public includeSameDayDeliveries () {
    return this.featuresList ? this.featuresList.find((feature) => feature.name === 'include_same_day_deliveries') : null
  }

  public backgroundOrdersSyncing () {
    return this.featuresList ? this.featuresList.find((feature) => feature.name === 'background_orders_syncing') : null
  }

  public checkoutRating () {
    return this.featuresList.find((feature) => feature.name === 'checkout_rating')
  }

  @Expose({ name: 'automatic_booking' })
  @Transform(value => { return (value === null || value === 0) ? false : true }, { toClassOnly: true })
  @Transform(value => { return (value === false) ? 0 : 1 }, { toPlainOnly: true })
  public automaticBooking: boolean = false

  @Expose({ name: 'enviaya_account' })
  @Exclude({ toPlainOnly: true })
  @Type(() => EnviayaAccountTS)
  public enviayaAccount: EnviayaAccountTS | undefined

  @Expose({ name: 'service_name_layout' })
  public serviceNameLayout: number | null = null

  @Expose({ name: 'tracking_type' })
  public trackingType: string = ''

  @Expose({ name: 'include_carrier_in_name' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public includeCarrierInName: boolean = false

  @Expose({ name: 'plan' })
  public plan: string | undefined| null = null

  @Expose({ name: 'carrier_position_in_name' })
  public carrierPositionInName: number | undefined

  @Expose({ name: 'include_est_date' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public includeEstDate: boolean = false

  @Expose({ name: 'submit_min_and_max_delivery_date' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public submitMinAndMaxDeliveryDate: boolean = false

  @Expose({ name: 'disable_create_shipment' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public disableCreateShipment: boolean = false

  @Expose({ name: 'phone_required' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public phoneRequired: boolean = false

  @Expose({ name: 'separate_parcel' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public separateParcel: boolean = false

  @Expose({ name: 'disable_sync' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public disableSync: boolean = false

  @Expose({ name: 'add_packaging_weight' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public addPackagingWeight: boolean = false

  @Expose({ name: 'description' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public description: string = ''

  @Expose({ name: 'last_sync_date' })
  @Exclude({ toPlainOnly: true })
  @Transform(value => {
    return (value === null) ? '' : `${moment(value * 1000).format(Vue.moment.localeData().longDateFormat('L'))} ${moment(value * 1000).format(Vue.moment.localeData().longDateFormat('LT'))}`
  }, { toClassOnly: true })
  public lastSyncDate: string | undefined

  @Expose({ name: 'time_zone', toClassOnly: true })
  @Transform((_value, obj) => {
    let date = new Date(obj.last_sync_date * 1000)
    let dateArr = date.toLocaleString('en-US', { timeZoneName: 'short' }).split(' ')
    return dateArr[3] || ''
  }, { toClassOnly: true })
  public timeZone: string = ''

  @Expose({ name: 'sync_in_progress' })
  @Exclude({ toPlainOnly: true })
  public syncJobId: string | undefined

  @Expose({ name: 'full_sync' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public fullSync: boolean = false

  @Expose({ name: 'store_admin_email' })
  @Exclude({ toPlainOnly: true })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public storeAdminEmail: string = ''

  @Expose({ name: 'store_admin_name' })
  @Exclude({ toPlainOnly: true })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public storeAdminName: string = ''

  @Expose({ name: 'enviaya_account' })
  @Exclude({ toPlainOnly: true })
  @Type(() => EnviayaAccountTS)
  public account: EnviayaAccountTS | undefined

  @Expose({ name: 'metadata' })
  public metadata: object | undefined

  @Expose({ name: 'enable_packaging_slips' })
  @Transform(value => { return (value === null) ? undefined : value })
  public enablePackagingSlips: number | null = null

  public isShopOwner (userId: number) {
    return userId === this.userId
  }

  public isWooShop () {
    return this.provider === ORDER_PROVIDER.WOOCOMMERCE
  }

  public isShopify () {
    return this.provider === ORDER_PROVIDER.SHOPIFY
  }

  public isTiendaNube () {
    return this.provider === ORDER_PROVIDER.TIENDANUBE
  }
}
