import ApiService from '@/common/api/api.service'

export class QuickSearchApiService {
  public static async query (query: string, entityType: string, useId: boolean = true): Promise<any> {
    try {
      const data = await ApiService.query('quick_search/search', {
        params: {
          query: query,
          entity_type: entityType,
          use_id: useId
        }
      })
      return data
    } catch (_e) {
      return null
    }
  }
}
