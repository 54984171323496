
import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { Metadata } from 'libphonenumber-js'

@Component
export default class PhoneInput extends Vue {
  @Prop(String) public readonly country!: string
  @Prop(String) public readonly value!: string
  @Prop(String) public readonly label!: string
  @Prop({ default: false }) public readonly customValidation!: boolean
  @Prop({ default: true }) public readonly isValid!: boolean
  @Prop(String) public readonly invalidFeedback!: string
  @Prop({ default: false }) public readonly disabled!: boolean

  public maxPhoneLength: number = 0
  public metadata = new Metadata()
  public countryData: any = {}
  public charsCounterSpan: HTMLElement | null = null
  public labelEl: HTMLElement | null = null
  public input: HTMLElement | null = null

  public mounted () {
    this.labelEl = document.createElement('label')

    this.labelEl.textContent = this.label
    this.labelEl.classList.add('phone_label')

    // @ts-ignore
    this.$el.querySelector('.vue-tel-input').appendChild(this.labelEl)

    this.input = this.$el.querySelector('.vti__input')

    this.charsCounterSpan = this.$el.querySelector('.character-counter')

    this.$nextTick(() => {
      if (this.value && this.value.length) {
        this.labelEl!.classList.add('phone_label_active')
      }

      this.labelEl!.addEventListener('click', () => {
        this.input!.focus()
      })

      this.input!.addEventListener('focusin', () => {
        this.labelEl!.classList.add('phone_label_focused')
        this.labelEl!.classList.remove('phone_label_active')
        this.charsCounterSpan!.style.display = 'block'
      })

      this.input!.addEventListener('focusout', () => {
        if ((!this.value || !this.value.length)) {
          this.labelEl!.classList.remove('phone_label_focused')
        } else {
          this.labelEl!.classList.add('phone_label_active')
        }
        this.charsCounterSpan!.style.display = 'none'
      })

      this.input!.addEventListener('keyup', (ev: KeyboardEvent) => {
        // @ts-ignore
        this.charsCounterSpan!.textContent = `(${(ev.target!.value!.match(/\d/g) || []).length}/${this.metadata.possibleLengths().join('-')})`
        // @ts-ignore
        this.$emit('change', ev.target.value)
      })

      this.input!.addEventListener('keydown', (ev: KeyboardEvent) => {
        let keyCode = (ev.key ? ev.key : ev.code)
        if ((this.value.match(/\d/g) || []).length >= this.maxPhoneLength && !['Backspace', 'ArrowLeft', 'ArrowRight'].includes(keyCode)) {
          ev.preventDefault()
        }
      })
    })
  }

  public onInput (phoneNumber: string, phoneData: Object) {
    this.$emit('input', phoneNumber, phoneData)
  }

  public countryChanged (countryData: any) {
    this.metadata.selectNumberingPlan(countryData.iso2)
    this.maxPhoneLength = Math.max(...this.metadata.possibleLengths())
    this.countryData = countryData
    this.labelEl!.classList.add('phone_label_active')

    // @ts-ignore
    this.charsCounterSpan!.textContent = `(${(this.value.match(/\d/g) || []).length}/${this.metadata.possibleLengths().join('-')})`

    this.$emit('country-changed', countryData.iso2)
  }

  @Watch('value')
  public valueChanged () {
    let label: HTMLElement | null = this.$el.querySelector('.phone_label')
    if (this.value && this.value.length) {
      label!.classList.add('phone_label_active')
    }
  }
}
