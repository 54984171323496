import Vue from 'vue'
import Router from 'vue-router'
import { CONFIGURATION_KEYS } from '@/common/config'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior (to) {
    if (!to.query['fix_position']) {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'welcome',
      component: () => import('@/views/Welcome.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/directions',
      name: 'directions_index',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        direction_type: route.query.direction_type,
        query: route.query.query,
        sort: route.query.sort,
        order: route.query.order
      }),
      component: () => import('@/views/directions/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/directions/new',
      name: 'directions_new',
      component: () => import('@/views/directions/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/directions/edit/:id?',
      name: 'directions_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/directions/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/directions/copy/:id?',
      name: 'directions_copy',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/directions/CopyView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/directions/show/:id?',
      name: 'directions_show',
      component: () => import('@/views/directions/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/invoice_directions',
      name: 'invoice_directions_index',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        direction_type: route.query.direction_type,
        query: route.query.query,
        sort: route.query.sort,
        order: route.query.order
      }),
      component: () => import('@/views/invoice-directions/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/invoice_directions/new',
      name: 'invoice_directions_new',
      component: () => import('@/views/invoice-directions/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/invoice_directions/edit/:id?',
      name: 'invoice_directions_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/invoice-directions/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipments',
      name: 'shipments_index',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        direction_type: route.query.direction_type,
        query: route.query.query,
        sort: route.query.sort,
        direction: route.query.direction
      }),
      component: () => import('@/views/shipments/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipments/new',
      name: 'shipments_new',
      component: () => import('@/views/shipments/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipments/edit/:id?',
      name: 'shipments_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/shipments/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipments/:id/download_label',
      name: 'shipments_download_label',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/shipments/DownloadLabelView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/data_import',
      name: 'DataImport',
      component: () => import('@/views/DataImport.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: () => import('@/views/Maintenance.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/sales_dashboard',
      name: 'SalesDashboard',
      component: () => import('@/views/dashboards/SalesDashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/sales_by_account_dashboard',
      name: 'SalesByAccountDashboard',
      component: () => import('@/views/dashboards/SalesByAccountDashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/claim_collection_dashboard',
      name: 'ClaimCollectionDashboard',
      component: () => import('@/views/dashboards/ClaimCollectionDashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/customer_service_dashboard',
      name: 'CustomerServiceDashboard',
      component: () => import('@/views/dashboards/CustomerServiceDashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/carrier_invoicing_dashboard',
      name: 'CarrierInvoicingDashboard',
      component: () => import('@/views/dashboards/CarrierInvoicingDashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/accounting_dashboard',
      name: 'AccountingDashboard',
      component: () => import('@/views/dashboards/AccountingDashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/administration_dashboard',
      name: 'AdministrationDashboard',
      component: () => import('@/views/dashboards/AdministrationDashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user_dashboard',
      name: 'UserDashboard',
      component: () => import('@/views/dashboards/UserDashboard.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/MyShipments',
      name: 'MyShipments',
      component: () => import('@/views/MyShipments.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/associates',
      name: 'Associates',
      component: () => import('@/views/Associates.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/api_keys',
      name: 'API',
      component: () => import('@/views/ApiKeys.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/mfa_code',
      name: 'MfaCode',
      component: () => import('@/views/MfaCode.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('@/views/Logout.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/forgotten_password',
      name: 'PasswordRecovery',
      component: () => import('@/views/PasswordRecovery.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/shop_connected',
      name: 'ShopConnected',
      component: () => import('@/views/shops/ShopConnected.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/reset_password',
      name: 'PasswordReset',
      component: () => import('@/views/PasswordReset.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/sign_up',
      name: 'Register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/Shipments',
      name: 'Shipments',
      component: () => import('@/views/Shipments.vue')
    },
    {
      path: '/rate_shipment',
      name: 'rate_shipment',
      component: () => import('@/views/StartPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/rate_shipment_extended',
      name: 'rate_shipment_extended',
      component: () => import('@/views/StartPageExtended.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipment/:id/rate',
      name: 'quote',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/QuotePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipment/:id/public_rate',
      name: 'public_quote',
      props: (route) => ({
        id: route.query.id,
        shipment_id: route.query.shipment_id
      }),
      component: () => import('@/views/QuotePage.vue'),
      meta: {
        layout: 'default'
      }
    },
    {
      path: '/shipment/:id/quote',
      name: 'old_quote',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/QuotePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipment/:id/public_quote',
      name: 'old_public_quote',
      props: (route) => ({
        id: route.query.id,
        shipment_id: route.query.shipment_id
      }),
      component: () => import('@/views/QuotePage.vue'),
      meta: {
        layout: 'public'
      }
    },
    {
      path: '/fuel_surcharges',
      name: 'fuel_surcharges_index',
      props: (route) => ({
        direction: route.query.direction,
        sort: route.query.sort,
        currentPage: Number(route.query.currentPage),
        perPage: Number(route.query.perPage)
      }),
      component: () => import('@/views/fuel_surcharges/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/discount-profiles',
      name: 'discount_profiles',
      component: () => import('@/views/discount-profiles/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/discount-profiles/adjust',
      name: 'discount-profiles_adjust',
      component: () => import('@/views/discount-profiles/AdjustView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/access_points',
      name: 'access_points_index',
      component: () => import('@/views/AccessPoints.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipment_templates',
      name: 'shipment_templates_index',
      props: (route) => ({
        direction: route.query.direction,
        sort: route.query.sort,
        currentPage: Number(route.query.currentPage),
        perPage: Number(route.query.perPage)
      }),
      component: () => import('@/views/shipment-templates/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipment_templates/new',
      name: 'shipment_templates_new',
      component: () => import('@/views/shipment-templates/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipment_templates/edit/:id?',
      name: 'shipment_templates_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/shipment-templates/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shipment_templates/show/:id?',
      name: 'shipment_templates_show',
      component: () => import('@/views/shipment-templates/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('@/views/services/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/services/edit/:id?',
      name: 'services_edit',
      component: () => import('@/views/services/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/packagings',
      name: 'packagings_index',
      props: (route) => ({
        direction: route.query.direction,
        sort: route.query.sort,
        currentPage: Number(route.query.currentPage),
        perPage: Number(route.query.perPage)
      }),
      component: () => import('@/views/packagings/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/packagings/new',
      name: 'packagings_new',
      component: () => import('@/views/packagings/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/packagings/edit/:id?',
      name: 'packagings_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/packagings/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/lists',
      name: 'lists_index',
      props: (route) => ({
        direction: route.query.direction,
        sort: route.query.sort,
        currentPage: Number(route.query.currentPage),
        perPage: Number(route.query.perPage)
      }),
      component: () => import('@/views/lists/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/lists/new',
      name: 'lists_new',
      component: () => import('@/views/lists/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/lists/edit/:id?',
      name: 'lists_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/lists/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/tags',
      name: 'tags_index',
      props: (route) => ({
        direction: route.query.direction,
        sort: route.query.sort,
        currentPage: Number(route.query.currentPage),
        perPage: Number(route.query.perPage)
      }),
      component: () => import('@/views/tags/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/tags/new',
      name: 'tags_new',
      component: () => import('@/views/tags/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/tags/edit/:id?',
      name: 'tags_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/tags/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/download_label',
      name: 'Download Label',
      component: () => import('@/views/DownloadLabel.vue')
    },
    {
      path: '/User',
      name: 'User',
      component: () => import('@/views/User.vue')
    },
    {
      path: '/email_confirmation/:id?',
      name: 'RegistrationComplete',
      component: () => import('@/views/RegistrationComplete.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/confirm_email_change',
      name: 'EmailChangeConfirmation',
      component: () => import('@/views/EmailChangeConfirmation.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/activate/:token?',
      name: 'ActivationComplete',
      props: (route) => ({ token: route.query.token }),
      component: () => import('@/views/ActivateAccount.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/shops/orders',
      name: 'orders_index',
      props: (route) => ({
        currentPage: Number(route.query.currentPage),
        perPage: route.query.perPage,
        shopId: route.query.shopId,
        ctn: route.query.ctn,
        shopCustomerId: route.query.shopCustomerId,
        shopOrderId: route.query.shopOrderId,
        enviayaUserId: route.query.enviayaUserId
      }),
      component: () => import('@/views/orders/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/users',
      name: 'users_index',
      props: (route) => ({
        direction: route.query.direction,
        sort: route.query.sort,
        referrer_id: route.query.referrerId,
        currentPage: Number(route.query.currentPage),
        perPage: Number(route.query.perPage)
      }),
      component: () => import('@/views/users/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/accounts',
      name: 'enviaya_accounts_index',
      props: (route) => ({
        direction: route.query.direction,
        sort: route.query.sort,
        currentPage: Number(route.query.currentPage),
        perPage: Number(route.query.perPage)
      }),
      component: () => import('@/views/enviaya-accounts/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/carriers/edit',
      name: 'create_edit_rating_filters',
      props: (route) => ({
        direction: route.query.direction,
        sort: route.query.sort,
        currentPage: Number(route.query.currentPage),
        perPage: Number(route.query.perPage)
      }),
      component: () => import('@/components/quoting_filters/carriers_configuration/CarrierConfiguration.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/accounts/new',
      name: 'enviaya_accounts_new',
      props: (route) => ({
        user_id: route.query.user_id
      }),
      component: () => import('@/views/enviaya-accounts/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/accounts/edit/:id?',
      name: 'enviaya_accounts_edit',
      component: () => import('@/views/enviaya-accounts/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/accounts/show/:id?',
      name: 'enviaya_accounts_show',
      component: () => import('@/views/enviaya-accounts/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/users/new',
      name: 'users_new',
      props: (route) => ({
        user_id: route.query.user_id
      }),
      component: () => import('@/views/users/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/users/edit/:id',
      name: 'users_edit',
      props: true,
      component: () => import('@/views/users/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/corporate_accounts',
      name: 'corporate_accounts',
      props: true,
      component: () => import('@/views/corporate-accounts/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/corporate_accounts/edit/:id',
      name: 'corporate_accounts_edit',
      props: true,
      component: () => import('@/views/corporate-accounts/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/corporate_accounts/new',
      name: 'new_corporate_accounts',
      props: true,
      component: () => import('@/views/corporate-accounts/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '*',
      name: 'not_found',
      component: () => import('@/views/Page404.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/resource_not_found',
      name: 'resource_not_found',
      component: () => import('@/views/Page404.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/access_denied',
      name: 'access_denied',
      component: () => import('@/views/Page403.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/maintenance_mode',
      name: 'maintenance_mode',
      component: () => import('@/views/Page503.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/internal_server_error',
      name: 'internal_server_error',
      component: () => import('@/views/Page505.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      path: '/shops',
      name: 'shops_index',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        direction_type: route.query.direction_type,
        query: route.query.query,
        sort: route.query.sort,
        order: route.query.order
      }),
      component: () => import('@/views/shops/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/carrier_accounts',
      name: 'carrier_accounts_index',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        direction_type: route.query.direction_type,
        query: route.query.query,
        sort: route.query.sort,
        order: route.query.order
      }),
      component: () => import('@/views/carrier-accounts/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/carrier_accounts/edit/:id?',
      name: 'carrier_accounts_edit',
      component: () => import('@/views/carrier-accounts/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/connect_shop',
      name: 'connect_shop',
      component: () => import('@/views/shops/ConnectView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shops/edit/:id?',
      name: 'shops_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/shops/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/shop_edit_redirect',
      beforeEnter (to) {
        window.location.href = `${Vue.$getConst(CONFIGURATION_KEYS.REDIRECT_URL)}shops/edit_store?shop=${to.query.shop_edit_domain}`
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/create_shipment_based_on_order',
      beforeEnter (to) {
        window.location.href = `${Vue.$getConst(CONFIGURATION_KEYS.REDIRECT_URL)}shops/new_shipment?shop=${to.query.shop_domain}&id=${to.query.order_id}`
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/view_orders',
      beforeEnter (to) {
        window.location.href = `${Vue.$getConst(CONFIGURATION_KEYS.REDIRECT_URL)}shops/view_orders?shop=${to.query.shop_domain}&id=${to.query.id}`
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/blocked_payment_options/edit/:id?',
      name: 'blocked_payment_options_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/blocked-payment-options/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/clearing-charges',
      name: 'clearing_charges_index',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        query: route.query.query,
        sort: route.query.sort,
        order: route.query.order,
        id: route.query.id
      }),
      component: () => import('@/views/clearing-charges/ClearingChargesIndex.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/quotes/edit/:id/:shipment_offer_id/addresses',
      name: 'choose_quote_public_address_edit_step',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/quotes/EditAddresses.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/quotes/edit/:id/:shipment_offer_id',
      name: 'choose_quote_public_edit_step',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/quotes/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/select_payment_method',
      name: 'select_payment_method',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/Payment.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/carrier_rates',
      name: 'carrier_rates_index',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        sort: route.query.sort,
        order: route.query.order
      }),
      component: () => import('@/views/carrier-rates/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/invoices/import',
      name: 'invoices_import',
      component: () => import('@/views/invoices/ImportView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/import_logs/:id',
      name: 'import_logs',
      component: () => import('@/views/import_logs/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/cogs_configuration',
      name: 'cogs_configuration_index',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        direction_type: route.query.direction_type,
        query: route.query.query,
        sort: route.query.sort,
        order: route.query.order
      }),
      component: () => import('@/views/cogs-calculation/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/location_restrictions',
      name: 'location_restrictions_index',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        direction_type: route.query.direction_type,
        query: route.query.query,
        sort: route.query.sort,
        order: route.query.order
      }),
      component: () => import('@/views/location-restrictions/ListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/location_restrictions/new',
      name: 'location_restrictions_new',
      component: () => import('@/views/location-restrictions/AddView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/location_restrictions/edit/:id?',
      name: 'location_restrictions_edit',
      props: (route) => ({ id: route.query.id }),
      component: () => import('@/views/location-restrictions/EditView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/customer_rates',
      name: 'customer_rates',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        direction_type: route.query.direction_type,
        query: route.query.query,
        sort: route.query.sort,
        order: route.query.order
      }),
      component: () => import('@/views/services/GroupedListView.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/Home.vue'),
      meta: {
        requiresAuth: true,
        layout: 'quasar'
      }
    },
    {
      path: '/cogs_configuration_new',
      name: 'cogs_configuration_index_new',
      props: (route) => ({
        page: route.query.page,
        per_page: route.query.per_page,
        direction_type: route.query.direction_type,
        query: route.query.query,
        sort: route.query.sort,
        order: route.query.order
      }),
      component: () => import('@/vue3-migration/pages/CogsPage.vue'),
      meta: {
        requiresAuth: true,
        layout: 'quasar'
      }
    }
  ]
})
