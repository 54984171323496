import GraphQLService from './graphql.service'

export default class CountriesDataApiService {

  public static countryDataStructGraphQL () {
    return `
      id
      countryCode
      locale
      vat
      internationalVat
    `
  }

  public static getCountriesData () {
    return GraphQLService.query(
      `query {
        countryDatums {
          ${this.countryDataStructGraphQL()}
        }
      }	`,
      {}
    )
  }

}
