import { AbstractModel } from '@/models/abstract-model'
import { Exclude, Expose, Transform } from 'class-transformer'

@Exclude()
export class CarriersQuotingFilter extends AbstractModel {
  static get modelName () {
    return 'CarriersQuotingFilter'
  }

  @Expose({ name: 'min_shop_order_amount' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public minShopOrderAmount: number | undefined

  @Expose({ name: 'max_shop_order_amount' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public maxShopOrderAmount: number | undefined

  @Expose({ name: 'shop_order_amount_currency' })
  @Transform(value => value || 'MXN', { toClassOnly: true })
  public shopOrderAmountCurrency: string | null = null

  @Expose({ name: 'carrier_id' })
  public carrierId: number | undefined

  @Expose({ name: 'filter_services' })
  public filterServices: boolean | undefined

  @Expose({ name: 'remote_handling' })
  @Transform(value => { return (value === null) ? 1 : value }, { toClassOnly: true })
  public remoteHandling: number = 1
}
