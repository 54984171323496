import i18n from '@/i18n'
import { CLEARING_CHARGES_SCOPE } from '@/constants/clearing-charge'

export function useClearingChargeScopes(selectedValue: string[] | string | undefined | null) {
  return Object.values(CLEARING_CHARGES_SCOPE).map((scope) => {
    return {
      text: i18n.t(`properties.clearing_charge_scope.${scope.toLowerCase()}`),
      value: scope,
      selected:
        !!selectedValue &&
        ((Array.isArray(selectedValue) &&
          selectedValue.map((val) => val.toLowerCase()).includes(scope.toLowerCase())) ||
          (!Array.isArray(selectedValue) && selectedValue.toLowerCase() === scope.toLowerCase()))
    }
  })
}
