import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import Vue from 'vue'
import get from 'lodash/get'
import { CONFIGURATION_KEYS } from '@/common/config'
import { CarrierService } from '@/models/carrier-service'
import { USER_COUNTRY_CODE } from './carrier-account'

type LogoImageUrls = {png_jpg: string, svg: string}
export const CARRIERS_LOGOS: Record<string, LogoImageUrls & { authorized?: LogoImageUrls }> = {
  ampm: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ampm/ampm.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ampm/ampm.svg'
  },
  borzo: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/borzo/borzo.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/borzo/borzo.svg'
  },
  ups: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ups/ups.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ups/ups.svg'
  },
  dhl: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/dhl/dhl.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/dhl/dhl.svg'
  },
  redpack: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/redpack/redpack.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/redpack/redpack.svg'
  },
  fedex: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/fedex/fedex.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/fedex/fedex.svg',
    authorized:  {
      png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/fedex/fedex-centro-autorizado-de-envio.png',
      svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/fedex/fedex-centro-autorizado-de-envio.svg'}
  },
  minutos: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/99minutos/99minutos.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/99minutos/99minutos.svg'
  },
  ivoy: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ivoy/ivoy.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ivoy/ivoy.svg'
  },
  trunk: {
    png_jpg: 'https://s3-us-west-1.amazonaws.com/enviaya-public/images/logo-trunk.svg',
    svg: 'https://s3-us-west-1.amazonaws.com/enviaya-public/images/logo-trunk.svg'
  },
  estafeta: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/estafeta/estafeta.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/estafeta/estafeta.svg'
  },
  deprisa: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/deprisa/deprisa.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/estafeta/deprisa.svg'
  },
  four72: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/4-72/4-72.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/4-72/4-72.svg'
  },
  paquetexpress: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/paquetexpress/paquetexpress.png',
    svg: 'https://enviaya-public.s3.us-west-1.amazonaws.com/carriers/paquetexpress/paquetexpress.svg'
  },
  pickit: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/pickit/pickit.png',
    svg: 'https://enviaya-public.s3.us-west-1.amazonaws.com/carriers/pickit/pickit.svg'
  },
  treggo: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/treggo/treggo.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/treggo/treggo.svg'
  },
  tresguerras: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/tresguerras/tresguerras.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/tresguerras/tresguerras.svg'
  },
  sendex: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/sendex/sendex.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/sendex/sendex.svg'
  },
  zubut: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/zubut/zubut.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/zubut/zubut.svg'
  }
}

export const CREDENTIAL_TYPES = {
  RATING: 'rating',
  SHIPMENT: 'shipment',
  PICKUP: 'pickup',
  TRACKING: 'tracking',
  COVERAGE: 'coverage'
}

export const PICKUP_SERVICE = {
  PICKUP_SERVICE_NO_SERVICE: 0,
  PICKUP_SERVICE_FREE: 1,
  PICKUP_SERVICE_EXTRA_CHARGE: 2,
  PICKUP_SERVICE_MANDATORY: 3,
  PICKUP_SERVICE_INCLUDED: 4
}

@Exclude()
export class Text {
  @Expose({ name: 'en' })
  @Transform(value => value || '', { toClassOnly: true })
  public en: string | null = null

  @Expose({ name: 'de' })
  @Transform(value => value || '', { toClassOnly: true })
  public de: string | null = null

  @Expose({ name: 'es' })
  @Transform(value => value || '', { toClassOnly: true })
  public es: string | null = null
}

@Exclude()
export class CacheLevel {
  @Expose( { name: 'user' } )
  @Transform(value => value ?? undefined, { toClassOnly: true })
  public user: number | null = null

  @Expose( { name: 'enviaya' } )
  @Transform(value => value ?? undefined, { toClassOnly: true })
  public enviaya: number | null = null
}

@Exclude()
export class AuthField {
  @Expose({ name: 'db_field_name' })
  @Transform(value => value || '', { toClassOnly: true })
  public dbFieldName: string = ''

  @Expose({ name: 'form_field_type' })
  @Transform(value => value || '', { toClassOnly: true })
  public formFieldType: string = ''

  @Expose({ name: 'title' })
  @Type(() => Text)
  public title: Text = new Text()

  @Expose({ name: 'tip' })
  @Type(() => Text)
  public tip: Text = new Text()

  @Expose({ name: 'default_value' })
  @Transform(value => value || '', { toClassOnly: true })
  public defaultValue: string = ''

  @Expose({ name: 'for_roles' })
  public forRoles: number[] | null = null
}

@Exclude()
export class Functions {
  @Expose({ name: 'rating' })
  @Type(() => AuthField)
  public rating: AuthField[] = []

  @Expose({ name: 'shipment' })
  @Type(() => AuthField)
  public shipment: AuthField[] = []

  @Expose({ name: 'pickup' })
  @Type(() => AuthField)
  public pickup: AuthField[] = []

  @Expose({ name: 'tracking' })
  @Type(() => AuthField)
  public tracking: AuthField[] = []

  @Expose({ name: 'coverage' })
  @Type(() => AuthField)
  public coverage: AuthField[] = []
}

@Exclude()
export class OptionalAvailabilityFields {
  @Expose( { name: 'domestic' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public domestic: boolean = false

  @Expose( { name: 'import' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public import: boolean = false

  @Expose( { name: 'export' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public export: boolean = false

  @Expose( { name: 'origin_countries' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public originCountries: boolean = false

  @Expose( { name: 'destination_countries' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public destinationCountries: boolean = false

  @Expose({ name: 'zones' })
  @Transform(value => value ?? [], { toClassOnly: true })
  public zones: number[] = []
}

@Exclude()
export class OptionalBillingFields {
  @Expose( { name: 'billing_country_code' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public billingCountryCode: boolean = false

  @Expose( { name: 'cancelable' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public cancelable: boolean = false
}

@Exclude()
export class OptionalPickupFields {
  @Expose( { name: 'drop_off_center_indication' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public dropOffCenterIndication: boolean = false
}

@Exclude()
export class OptionalRatingFields {
  @Expose( { name: 'default_currency' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public defaultCurrency: boolean = false
}

@Exclude()
export class OptionalOtherFields {
  @Expose( { name: 'requires_shipment_number_ranges' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public requiresShipmentNumberRanges: boolean = false

  @Expose( { name: 'charges_fuel_surcharge' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public chargesFuelSurcharge: boolean = false
}

@Exclude()
export class OptionalFields {
  @Expose({ name: 'availability' })
  @Type(() => OptionalAvailabilityFields)
  public availability: OptionalAvailabilityFields = new OptionalAvailabilityFields()

  @Expose({ name: 'billing' })
  @Type(() => OptionalBillingFields)
  public billing: OptionalBillingFields = new OptionalBillingFields()

  @Expose({ name: 'rating' })
  @Type(() => OptionalRatingFields)
  public rating: OptionalRatingFields = new OptionalRatingFields()

  @Expose({ name: 'pickup' })
  @Transform(value => value ?? new OptionalPickupFields(), { toClassOnly: true })
  @Type(() => OptionalPickupFields)
  public pickup: OptionalPickupFields = new OptionalPickupFields()

  @Expose({ name: 'other' })
  @Type(() => OptionalOtherFields)
  public other: OptionalOtherFields = new OptionalOtherFields()
}

@Exclude()
export class DefaultAvailabilityValues {
  @Expose( { name: 'domestic' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public domestic: boolean | null = null

  @Expose( { name: 'import' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public import: boolean | null = null

  @Expose( { name: 'export' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public export: boolean | null = null

  @Expose({ name: 'min_weight' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public minWeight: number | undefined

  @Expose({ name: 'max_weight' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public maxWeight: number | undefined

  @Expose({ name: 'weight_unit' })
  @Transform(value => value || '', { toClassOnly: true })
  public weightUnit: string | null = null

  @Expose({ name: 'origin_countries' })
  @Transform(value => value ?? [], { toClassOnly: true })
  public originCountries: string[] | string = []

  @Expose({ name: 'destination_countries' })
  @Transform(value => value ?? [], { toClassOnly: true })
  public destinationCountries: string[] | string = []
}

@Exclude()
export class DefaultBillingValues {
  @Expose({ name: 'billing_country_code' })
  @Transform(value => value || USER_COUNTRY_CODE, { toClassOnly: true })
  public billingCountryCode: string = USER_COUNTRY_CODE

  @Expose( { name: 'cancelable' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public cancelable: boolean | null = null
}

@Exclude()
export class DefaultPickupValues {
  @Expose({ name: 'drop_off_center_indication' })
  @Transform(value => value ?? undefined, { toClassOnly: true })
  public dropOffCenterIndication: number | undefined | null
}

@Exclude()
export class DefaultRatingValues {
  @Expose( { name: 'enable_public_discount' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public enablePublicDiscount: boolean = false

  @Expose( { name: 'rate_list_price' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public rateListPrice: boolean = false

  @Expose({ name: 'default_currency' })
  @Transform(value => value || USER_COUNTRY_CODE, { toClassOnly: true })
  public defaultCurrency: string = USER_COUNTRY_CODE

  @Expose( { name: 'apply_discount_on_fuel_surcharge' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public applyDiscountOnFuelSurcharge: boolean = false
}

@Exclude()
export class DefaultOtherValues {
  @Expose( { name: 'requires_shipment_number_ranges' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public requiresShipmentNumberRanges: boolean = false

  @Expose( { name: 'coordinates_handling' } )
  @Transform(value => value ?? undefined, { toClassOnly: true })
  public coordinatesHandling: number | null = null

  @Expose({ name: 'cache_level' })
  @Type(() => CacheLevel)
  public cacheLevel: CacheLevel = new CacheLevel()
}

@Exclude()
export class DefaultFunctionValues {
  @Expose( { name: 'rating' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public rating: boolean = false

  @Expose( { name: 'booking' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public booking: boolean = false

  @Expose( { name: 'pickup' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public pickup: boolean = false

  @Expose( { name: 'tracking' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public tracking: boolean = false

  @Expose( { name: 'coverage' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public coverage: boolean = false
}

@Exclude()
export class DefaultValues {
  @Expose({ name: 'availability' })
  @Type(() => DefaultAvailabilityValues)
  public availability: DefaultAvailabilityValues = new DefaultAvailabilityValues()

  @Expose({ name: 'billing' })
  @Type(() => DefaultBillingValues)
  public billing: DefaultBillingValues = new DefaultBillingValues()

  @Expose({ name: 'rating' })
  @Type(() => DefaultRatingValues)
  public rating: DefaultRatingValues = new DefaultRatingValues()

  @Expose({ name: 'pickup' })
  @Transform(value => value ?? new DefaultPickupValues(), { toClassOnly: true })
  @Type(() => DefaultPickupValues)
  public pickup: DefaultPickupValues = new DefaultPickupValues()

  @Expose({ name: 'other' })
  @Type(() => DefaultOtherValues)
  public other: DefaultOtherValues = new DefaultOtherValues()

  @Expose({ name: 'functions' })
  @Type(() => DefaultFunctionValues)
  public functions: DefaultFunctionValues = new DefaultFunctionValues()
}

@Exclude()
export class CarrierAccountOptions {
  @Expose({ name: 'optional_fields' })
  @Type(() => OptionalFields)
  public optionalFields: OptionalFields = new OptionalFields()

  @Expose({ name: 'defaults' })
  @Type(() => DefaultValues)
  public defaultsValues: DefaultValues = new DefaultValues()

  @Expose({ name: 'credentials_per_function' })
  @Type(() => Functions)
  public credentialsPerFunctionFields: Functions = new Functions()

  @Expose({ name: 'functions' })
  @Transform(value => value || [], { toClassOnly: true })
  public functions: string[] = []

  @Expose({ name: 'credentials_title' })
  @Type(() => Text)
  public credentialsTitle: Text | null = null

  @Expose({ name: 'credentials' })
  @Type(() => AuthField)
  public credentialsFields: AuthField[] = []

  @Expose({ name: 'prepaid_options' })
  @Type(() => Option)
  public prepaidOptions: Option[] = []

  @Expose({ name: 'third_party_providers' })
  @Type(() => ThirdPartyProviderOptions)
  public thirdPartyProviderOptions: ThirdPartyProviderOptions | undefined
}

@Exclude()
export class Option {
  @Expose({ name: 'value' })
  @Transform(value => value || '', { toClassOnly: true })
  public value: string = ''

  @Expose({ name: 'text' })
  @Type(() => Text)
  public text: Text = new Text()
}

@Exclude()
export class CarrierInformation {
  @Expose({ name: 'logo' })
  @Type(() => Logo)
  public logo: Logo = new Logo()
}

@Exclude()
export class Logo {
  @Expose({ name: 'png_jpg' })
  public pngJpg: string = ''

  @Expose({ name: 'authorized' })
  @Type(() => AuthorizedLogo)
  public authorized: AuthorizedLogo = new AuthorizedLogo()
}

@Exclude()
export class AuthorizedLogo {
  @Expose({ name: 'png_jpg' })
  public pngJpg: string = ''
}

@Exclude()
export class CarrierOtherOptions {
  @Expose( { name: 'carrier_prepaid_shipments' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public carrierPrepaidShipments: boolean = false

  @Expose( { name: 'requires_shipment_number_ranges' } )
  public requiresShipmentNumberRanges: boolean = false

  @Expose({ name: 'cash_on_delivery' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public cashOnDelivery: boolean = false

  @Expose( { name: 'is_allowed_for_taxes_and_centers' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public isAllowedForTaxesAndCenters: boolean = false
}

@Exclude()
export class CarrierRatingOptions {
  @Expose( { name: 'rate_list_price' } )
  @Transform(value => value ?? false, { toClassOnly: true })
  public rateListPrice: boolean = false
}

@Exclude()
export class CarrierOptions {
  @Expose({ name: 'carrier_information' })
  @Type(() => CarrierInformation)
  public carrierInformation: CarrierInformation = new CarrierInformation()

  @Expose({ name: 'other' })
  @Type(() => CarrierOtherOptions)
  public other: CarrierOtherOptions = new CarrierOtherOptions()

  @Expose({ name: 'rating' })
  @Type(() => CarrierRatingOptions)
  public rating: CarrierRatingOptions = new CarrierRatingOptions()
}

@Exclude()
export class ThirdPartyProviderOptions {
  @Expose({ name: 't1_envios' })
  @Transform(value => value?.functions || [], { toClassOnly: true })
  public t1EnviosOptions: string[] = []

  @Expose({ name: 'shippiz' })
  @Transform(value => value?.functions || [], { toClassOnly: true })
  public shippizOptions: string[] = []
}

@Exclude()
export class Carrier extends AbstractModel {
  @Expose({ name: 'name' })
  public name: string = ''

  public logoUrl (defaultLogo: boolean) {
    const carrierName =
      this.name.toLocaleLowerCase() === 'deprisa' && Vue.$getConst(CONFIGURATION_KEYS.IS_PRODUCTION) ?
        'ups' :
        this.name.toLocaleLowerCase()

    const carrier = CARRIERS_LOGOS[carrierName] ?? null

    if (!carrier) return ''
    if (defaultLogo) {
      return carrier['png_jpg']
    } else return get(carrier, 'authorized.png_jpg') ?? carrier['png_jpg']
  }

  @Expose( { name: 'live_tracking' } )
  public liveTracking: boolean = false


  @Expose({ name: 'carrier_options' })
  @Type(() => CarrierOptions)
  public carrierOptions: CarrierOptions | undefined

  @Expose({ name: 'carrier_account_options', toClassOnly: true })
  @Type(() => CarrierAccountOptions)
  public carrierAccountOptions: CarrierAccountOptions = new CarrierAccountOptions()

  @Expose({ name: 'pickup_service' })
  public pickupService: string = ''

  @Expose({ name: 'status' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public status: number | undefined

  @Expose({ name: 'dropoff_possible' })
  @Transform(value => { return (!value) ? false : value })
  public dropoffPossible: boolean = false

  @Expose({ name: 'vat_calculation' })
  @Transform(value => { return (!value) ? false : value })
  public vatCalculation: boolean = false

  @Expose({ name: 'returns_negotiated_rates' })
  @Transform(value => { return (!value) ? false : value })
  public returnsNegotiatedRates: boolean = false

  public carrierServices: CarrierService[] = []

  public servicesByCountry: Record<string, Record<string, CarrierService[]>> = {}
}
