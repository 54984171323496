import { render, staticRenderFns } from "./PackagingSelectBox.vue?vue&type=template&id=7f475c82&scoped=true"
import script from "./PackagingSelectBox.vue?vue&type=script&lang=js"
export * from "./PackagingSelectBox.vue?vue&type=script&lang=js"
import style0 from "./PackagingSelectBox.vue?vue&type=style&index=0&id=7f475c82&prod&lang=css"
import style1 from "./PackagingSelectBox.vue?vue&type=style&index=1&id=7f475c82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f475c82",
  null
  
)

/* custom blocks */
import block0 from "@/locales/select_box_data/packaging_select_box.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FPackagingSelectBox.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/packagings/packagings_form.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FPackagingSelectBox.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/locales/popup.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FPackagingSelectBox.vue&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/locales/page505.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FPackagingSelectBox.vue&external"
if (typeof block3 === 'function') block3(component)
import block4 from "@/locales/select2.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FPackagingSelectBox.vue&external"
if (typeof block4 === 'function') block4(component)

export default component.exports