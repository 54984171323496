export enum CorporateAccountActions {
  NEW = 'new',
  EDIT = 'edit',
  NO_MAIN_ACTION = 'no_main_action',
  NO_SECONDARY_ACTION = 'no_secondary_action',
  DELETE_ACCOUNT = 'delete_account',
  DOWNLOAD_AS_CSV = 'download',
  NEW_CUSTOMER_INFO = 'new_customer_info',
  EDIT_CUSTOMER_INFO = 'edit_customer_info',
  INVITE_USER = 'invite_user',
  EDIT_WHITE_LABEL = 'edit_wl',
  NEW_WHITE_LABEL = 'new_wl',
  SEE_SHIPMENTS = 'see_shipments',
  INVOICE_SHIPMENTS = 'invoice_shipments',
  EDIT_ACCOUNT = 'edit_account',
  NEW_ASSIGNATION = 'new_assignation',
  TRANSACTION_FEE_INVOICING = 'transaction_fee_invoicing',
  QUICK_UPDATE_STATUS = 'quick_update_status',
  DELETE_ACCOUNTS = 'delete_accounts'
}

export enum SelectScopeOptions {
  WITH_CREDIT_LINE = 'with_credit_line'
}

export enum CorporateAccountStatuses {
  ACTIVE = 1,
  DISABLED = 2,
  DELETED = 3,
  DISABLED_SYSTEM = 5
}

export const AccountType = {
  ENVIAYA: 1,
  RESELLER: 2
}

export enum PasswordSecurity {
  NORMAL = 0,
  HIGH = 1
}
