var render, staticRenderFns
import script from "./CarrierAccountSelectBox.vue?vue&type=script&lang=js"
export * from "./CarrierAccountSelectBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/locales/select_boxes.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FCarrierAccountSelectBox.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/select2.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FCarrierAccountSelectBox.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports