
  import { Component, Prop } from 'vue-property-decorator'
  import BasicComponent from '@/views/BasicComponent'
  import { CarrierSelectItem } from '@/models/select-boxes/CarrierSelectItem'
  import { plainToClass } from 'class-transformer'
  import { Carrier } from '@/models/carrier'
  import { CarriersApiService } from '@/common/api/carriers.api.service'
  import CustomBasicSelect from '@/components/select-boxes/CustomBasicSelect.vue'

  @Component({
    components: {
      CustomBasicSelect
    }
  })
  export default class CarriersSelectBox extends BasicComponent {
    @Prop(String) public reference!: string | 'select'
    @Prop(String) public label!: string | 'Default label'
    @Prop(String) public classes!: string | ''
    @Prop(String) public placeholder!: string | ''
    @Prop([Number, Array]) public result!: number | number[] | undefined
    @Prop(Boolean) public disabled!: boolean | false
    @Prop({ default: false }) public resetBtn!: boolean
    @Prop({ default: false }) public multiple!: boolean
    @Prop({ default: true }) public wasValidated!: boolean

    @Prop(Boolean) public customValidation!: boolean
    @Prop(Boolean) public isValid!: boolean
    @Prop(String) public invalidFeedback!: string

    public carrierList: Carrier[] = []
    public carriers: CarrierSelectItem[] = []

    public async mounted () {
      this.carrierList = await CarriersApiService.list()
      this.carriers.push(...plainToClass(CarrierSelectItem, this.carrierList))
      if (Array.isArray(this.result)) {
        // @ts-ignore
        this.carriers.forEach((carrier) => { carrier.selected = !!(this.result && (this.result.map(String).includes(carrier.value!.toString()))) })
      } else {
        this.carriers.forEach((carrier) => { carrier.selected = !!(this.result && (this.result.toString() === carrier.value!.toString())) })
      }
      this.$emit('selectResults', this.carrierList)
      this.unwatchAccessors.push(this.$watch('result', this.onModelChanged))
    }

    public validate () {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs['select'].validate()
      })
    }

    public updateValue (value: string) {
      const selectedCarrier = this.carrierList.find( carrier => carrier.id === parseInt(value, 10) )
      this.$emit('selectedCarrier', selectedCarrier)
      this.$emit('update:result', value)
    }

    public onModelChanged (value: string) {
      if (!value) {
        this.carriers.forEach((carrier) => {
          carrier.selected = false
        })
        this.carriers.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)
      }
    }
  }
