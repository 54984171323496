import ApiService from '@/common/api/api.service'
import { AxiosResponse } from 'axios'
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer'
import { EnviayaAccountTS } from '@/models/EnviayaAccountTS'
import { AccountList } from '@/models/account-list'
import GraphQLService from './graphql.service'
import QuotingFilterApiService from './quoting-filter.api.service'
import { CorporateAccountTs } from '@/models/corporate-account-ts'
import { WhiteLabel } from '@/models/white-label'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'

export type AccountableObject = CorporateAccountTs | EnviayaAccountTS | WhiteLabel

export default class AccountsApiService {
  public static requestDiscount (params: object) {
    return ApiService.update('request_discount', '', params)
  }

  public static search (query: string): Promise<AxiosResponse> {
    return ApiService.query('accounts/search', {
      params: { query }
    })
  }

  public static searchByUserId (query: string, userId: number): Promise<AxiosResponse> {
    return ApiService.query('accounts/search', {
      params: {
        query: query,
        user_id: userId
      }
    })
  }

  public static async searchActiveAccountsByUserId (userId: number): Promise<EnviayaAccountTS[]> {
    let { data } = await ApiService.query('accounts/search', {
      params: {
        user_id: userId,
        status: 1
      }
    })
    return data.accounts.map((account: any) => { return plainToClass(EnviayaAccountTS, account) } )
  }

  public static async createDownloadJob (params: Object) {
    return ApiService.post('accounts/actions?actions=download', {
      ...params
    }).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }

  public static async invoiceTransactionFees (accountId: number, date: string) {
    return ApiService.post(`accounts/${accountId}/create_transaction_fees_invoice`, {
      params: {
        next_transaction_fee_invoicing_date: date
      }
    })
  }

  public static invoiceShipments (accountId: number, date: string) {
    return ApiService.post(`accounts/${accountId}/create_invoice`, {
      params: {
        next_invoicing_date: date
      }
    })
  }

  public static disableAccount (id: number) {
    return ApiService.put(`accounts/${id}/disable`, {})
  }

  public static enableAccount (id: number) {
    return ApiService.put(`accounts/${id}/update_to_active_status`, {})
  }

  public static deleteAccount (id: number) {
    return ApiService.put(`accounts/${id}/delete`, {})
  }

  public static async get (slug: any): Promise<EnviayaAccountTS> {
    let { data } = await ApiService.get('accounts', slug)

    return plainToClass(EnviayaAccountTS, data)
  }

  public static basicInfo (slug: any) {
    return ApiService.get(`accounts/${slug}/basic_info`)
  }

  public static getStat (query: any) {
    return ApiService.query('accounts/calculations', { params: { ...query } })
  }

  public static async getUserAccounts (slug: string, enviayaAccountId: number | undefined, page: number | undefined, perPage: number | undefined): Promise<AccountList> {
    try {
      let { data } = await ApiService.query(`users/${slug}/accounts`, { params: { page: page || 1, per_page: perPage, enviaya_account_id: enviayaAccountId } })
      return plainToClassFromExist(new AccountList(EnviayaAccountTS), data)
    } catch (e) {
      throw e
    }
  }

  public static async getAccountsByIds (ids: number[]): Promise<EnviayaAccountTS[]> {
    let { data } = await ApiService.query(`accounts`, {
      params: { 'enviaya_account_ids': ids }
    })
    return data.models.map((account: any) => { return plainToClass(EnviayaAccountTS, account) } )
  }

  public static async getAccounts (params: Object): Promise<AccountList> {
    let { data } = await ApiService.query('accounts', { params: { ...params } })
    return plainToClassFromExist(new AccountList(EnviayaAccountTS), data)
  }

  public static async updateStatus (id: number, value: number): Promise<void> {
    await ApiService.put(`accounts/${id}/update_xeditable_attribute`, {
      name: 'status',
      value: value
    })
  }

  public static updateDiscountProfile (acc: EnviayaAccountTS, value: any, userId: number | null): Promise<any> {
    return ApiService.put(`accounts/${acc.id}/update_xeditable_attribute`, {
      name: 'discount_profile_id', value: value, user_id: userId
    })
  }

  public static canRequestDiscount (): Promise<AxiosResponse> {
    return ApiService.get('can_request_discount', '')
  }

  public static createAccount (data: EnviayaAccountTS) {
    return ApiService.post('accounts', {
      account: classToPlain(data)
    })
  }

  public static updateAccount (data: EnviayaAccountTS) {
    return ApiService.update('accounts', String(data.id), {
      account: classToPlain(data)
    })
  }

  public static updateGQL (account: EnviayaAccountTS) {
    const { id, status, discountProfileId } = account
    return GraphQLService.query(`
      mutation updateAccount(
        $id: ID!
        $status: Int
        $discountProfileId: ID
      ) {
        updateEnviayaAccount(
          input: {
            id: $id
            status: $status
            discountProfileId: $discountProfileId
          }
        ) {
          enviayaAccount {
            id
            status
            discountProfileId
          }
          errors
        }
      }
    `, {
      id,
      status,
      discountProfileId
    })
  }

  public static connectionStructGraphql (nodeStruct: Function) {
    return `
      pageInfo {
        hasNextPage
      }
      nodes {
        ${nodeStruct()}
      }`
  }

  public static accountStructGraphQL () {
    return `
        id
        selectBoxText`
  }

  public static getAccountListForFilter (searchData: Record<string, string> = {}) {
    const { enviayaSearch, corporateSearch, brandSearch } = searchData
    return GraphQLService.query(
      `query accounts($enviayaSearch: String, $corporateSearch: String, $brandSearch: String) {
        enviayaAccountsConnection(searchQuery: $enviayaSearch) {
          ${this.connectionStructGraphql(this.accountStructGraphQL)}
        }
        corporateAccountsConnection(searchQuery: $corporateSearch) {
          ${this.connectionStructGraphql(this.accountStructGraphQL)}
        }
        brandAccountsConnection:whiteLabelsConnection(searchQuery: $brandSearch) {
          ${this.connectionStructGraphql(this.accountStructGraphQL)}
        }
      }	`, {
        enviayaSearch,
        corporateSearch,
        brandSearch
      }
    )
  }

  public static getMyAccountListForFilter (nodeStruct: Function) {
    return GraphQLService.query(
      `query myAccounts{
        me {
          enviayaAccounts{
            ${nodeStruct()}
          }
          corporateAccounts: corporateAccount {
            ${nodeStruct()}
            enviayaAccounts{
              ${nodeStruct()}
            }
          }
          brandAccounts {
            ${nodeStruct()}
          }
        }
      }	`, {}
    )
  }

  public static searchEnviayaAccountGraphql (searchQuery: string) {
    return GraphQLService.query(
      `query searchEnviayaAccount($searchQuery: String) {
        enviayaAccountsConnection(searchQuery: $searchQuery) {
          ${this.connectionStructGraphql(this.accountStructGraphQL)}
        }
      }	`, {
        searchQuery
      }
    )
  }

  public static async getCarrierAccountsAndQuotingFilter (id: number | string) {
    return GraphQLService.query(
      `query enviayaAccount($id: ID!) {
        enviayaAccount(id: $id) {
          whiteLabel {
            id
            quotingFilter {
              ${QuotingFilterApiService.quotingFilterDataStrucGraphql()}
            }
          }
          quotingFilter {
            ${QuotingFilterApiService.quotingFilterDataStrucGraphql()}
          }
        }
      }`, {
        id
      }
    )
  }

  public static async getAccountsGQL (page: number, itemsPerPage: number, params: Record<string, any> = {}): Promise<AccountList> {
    try {
      const accountResponse =
        await GraphQLService.query(`
        query (
          $id: ID
          $itemsPerPage: Int
          $page: Int
          $corporateAccountId: ID
          $userId: ID
          $whiteLabelId: ID
          $status: ID
        ) {
          enviayaAccountsOffset (
            perPage: $itemsPerPage,
            page: $page,
            id: $id,
            corporateAccountId: $corporateAccountId
            userId: $userId,
            whiteLabelId: $whiteLabelId
            status: $status
          ) {
            hasNextPage
            pagesCount
            nodesCount
            nodes {
              id
              alias
              account
              currency
              formatedCreditLine
              formattedCreditBalance
              creditSaldo
              creditLine
              balanceSaldoType
              status
              accountType
              discountProfileId
              whiteLabelId
              whiteLabel {
                id
                brandName
                enableReseller
              }
              corporateAccount {
                discountProfile {
                  id
                  name
                  level
                }
              }
              corporateAccountId
              discountProfile {
                id
                name
                level
              }
              accountUsersCount
              xeditableOptions {
                id
                selectBoxText
              }
            }
          }
        }`, {
          ...params,
          itemsPerPage,
          page
        })

      const errors = get(accountResponse, 'data.errors')
      const accounts = get(accountResponse, 'data.data.enviaya_accounts_offset')
      const accountList = new AccountList(EnviayaAccountTS)
      if (!accounts && !errors ) return accountList
      accountList.models = accounts.nodes.map((account: any) => plainToClass(EnviayaAccountTS, account))
      accountList.allAccountsCount = accounts.nodes_count

      return accountList
    } catch (e) {
      throw e
    }
  }

  public static assignAccountGQL (
    accountId: number,
    assignObject: AccountableObject
  ) {
    return GraphQLService.query(`
      mutation assignAccount (
        $id: ID!
        $corporateAccountId: ID
        $userId: ID
        $whiteLabelId: ID
        $discountProfileId: ID
      ) {
        updateEnviayaAccount (
          input: {
            id: $id
            corporateAccountId: $corporateAccountId
            userId: $userId
            whiteLabelId: $whiteLabelId
            discountProfileId: $discountProfileId
          }
        ) {
          enviayaAccount {
            id
          }
          errors
        }
      }
    `, {
      id: accountId,
      // @ts-ignore
      [`${camelCase(assignObject.constructor.modelName)}Id`]: assignObject.id,
      discountProfileId: null
    })
  }

  public static async recalculateSaldo (id: number, saldoType: number) {
    return ApiService.put(`accounts/${id}/recalculate_saldo`, { saldo_type: saldoType })
  }

  public static sendInvoicingContactsConfirmationEmails (accountId: number): Promise<AxiosResponse> {
    return ApiService.get(`accounts/${accountId}/send_invoicing_contacts_confirmation_emails`)
  }
}
