
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CommodityItem from '@/components/packagings/CommodityItem.vue'
import { PackagingCommodity } from '@/models/packaging-commodity'

@Component({
  components: {
    CommodityItem
  }
})
export default class Commodity extends Vue {
  @Prop(Number) public index!: number
  @Prop(Object) public commodity!: PackagingCommodity
  @Prop({ default: false }) public isShipmentScope!: Boolean
  @Prop({ default: '' }) public weightUnit!: String

  public removeCommodityItem (index: number) {
    this.$emit('removeCommodityItem', index)
  }

  @Watch('commodity.name')
  @Watch('commodity.unitValue')
  @Watch('commodity.quantity')
  @Watch('commodity.unitWeight')
  public async commodityChanged () {
    if (this.commodity.wasValidated) {
      await this.commodity.validateModel()
    }
  }
}
