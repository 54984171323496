
import { Component, Prop } from 'vue-property-decorator'
import BasicComponent from '@/views/BasicComponent'
import { ISelectBoxModel } from '@/models/select-box-model'
import { REFERENCE_TYPE } from '../../models/shipment-reference'

@Component
export default class RefTypeSelectBox extends BasicComponent {
  @Prop(String) public reference!: string | 'select'
  @Prop(String) public label!: string | 'Default label'
  @Prop(String) public classes!: string | ''
  @Prop(Number) public result!: number | undefined

  @Prop(Boolean) public customValidation!: boolean
  @Prop(Boolean) public isValid!: boolean
  @Prop(String) public invalidFeedback!: string

  public options: ISelectBoxModel[] = []

  public mounted () {
    this.fillSelectBox(this.result)
    this.unwatchAccessors.push(this.$watch('result', this.onModelChanged))
  }

  public fillSelectBox (result?: number) {
    this.options = []
    this.options.push(
      { text: this.$t('ref_types.1').toString(), value: REFERENCE_TYPE.REF_TYPE_1, selected: result === REFERENCE_TYPE.REF_TYPE_1 },
      { text: this.$t('ref_types.2').toString(), value: REFERENCE_TYPE.REF_TYPE_2, selected: result === REFERENCE_TYPE.REF_TYPE_2 },
      { text: this.$t('ref_types.3').toString(), value: REFERENCE_TYPE.REF_TYPE_3, selected: result === REFERENCE_TYPE.REF_TYPE_3 },
      { text: this.$t('ref_types.4').toString(), value: REFERENCE_TYPE.REF_TYPE_4, selected: result === REFERENCE_TYPE.REF_TYPE_4 },
      { text: this.$t('ref_types.5').toString(), value: REFERENCE_TYPE.REF_TYPE_5, selected: result === REFERENCE_TYPE.REF_TYPE_5 },
      { text: this.$t('ref_types.6').toString(), value: REFERENCE_TYPE.REF_TYPE_6, selected: result === REFERENCE_TYPE.REF_TYPE_6 },
      { text: this.$t('ref_types.7').toString(), value: REFERENCE_TYPE.REF_TYPE_7, selected: result === REFERENCE_TYPE.REF_TYPE_7 }
    )
  }

  public onModelChanged () {
    this.fillSelectBox(this.result)
  }

  public validate () {
    this.$nextTick(() => {
      // @ts-ignore
      this.$refs['select'].validate()
    })
  }

  public change (e: any) {
    this.$emit('update:result', e)
  }
}
