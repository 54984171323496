import i18n from '@/i18n'
import { Exclude, Expose, Transform } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
// @ts-ignore
import localeData from '@/locales/packagings/commodity_form.json'
import { IsNotEmpty, IsNumber } from 'class-validator'
@Exclude()
export class ParcelCommodity extends AbstractModel {
  static get modelName () {
    return 'Commodity'
  }

  constructor () {
    super()
    i18n.mergeLocaleMessage('en', localeData.en)
    i18n.mergeLocaleMessage('de', localeData.de)
    i18n.mergeLocaleMessage('es', localeData.es)
  }

  @Expose({ name: 'parcel_id' })
  public parcelId: number | null = null

  @IsNotEmpty({ message: () => i18n.t('commodity_form.validations.blank').toString() })
  @Expose({ name: 'description' })
  @Transform(value => value || '', { toClassOnly: true })
  public description: string = ''

  @Expose({ name: 'country_of_manufacture' })
  @Transform(value => value || 'MX', { toClassOnly: true })
  public countryCode: string = ''

  @Expose({ name: 'unit_price' })
  @IsNumber({}, { message: () => i18n.t('commodity_form.validations.blank').toString() })
  @Transform(value => value ? parseFloat(value) : '', { toClassOnly: true })
  public unitPrice: number | null = null

  @Expose({ name: 'unit_price_currency' })
  public currency: string = ''

  @Expose({ name: 'quantity' })
  @IsNumber({}, { message: () => i18n.t('commodity_form.validations.blank').toString() })
  public quantity: number | null = null

  @Expose({ name: 'weight_per_piece' })
  @IsNumber({}, { message: () => i18n.t('commodity_form.validations.blank').toString() })
  @Transform(value => value ? parseFloat(value) : '', { toClassOnly: true })
  public weightPerPiece: number | null = null

  @Expose({ name: 'weight_unit' })
  @Transform(value => value || 'kg', { toClassOnly: true })
  public weightUnit: string = 'kg'

  @Expose({ name: '_destroy' })
  @Transform((value) => (value) ? value : false)
  public destroy: boolean = false

  public get totalValue () {
    return (this.unitPrice || 0) * (this.quantity || 0)
  }

  public get totalWeight () {
    return (this.weightPerPiece || 0) * (this.quantity || 0)
  }

  public wasValidated: boolean = false
}
