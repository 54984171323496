import i18n from '@/i18n'
import { Exclude, Expose, Transform } from 'class-transformer'
import { IsNotEmpty, ValidateIf } from 'class-validator'
import { AbstractModel } from './abstract-model'
// @ts-ignore
import localeData from '@/locales/corporate_accounts/corporate_account.json'

export const FEE_TYPES = {
  none: 0,
  fixed_fee: 1,
  percent_fee: 2,
  fixed_plus_percent: 3
}

@Exclude()
export class Fee extends AbstractModel {
  constructor () {
    super()
    i18n.mergeLocaleMessage('en', localeData.en)
    i18n.mergeLocaleMessage('de', localeData.de)
    i18n.mergeLocaleMessage('es', localeData.es)
  }

  public wasValidated: boolean = false

  @Expose({ name: 'transaction_fee_fixed_net_amount' })
  @ValidateIf(o => o.feeType === FEE_TYPES.fixed_fee || o.feeType === FEE_TYPES.fixed_plus_percent)
  @IsNotEmpty({ message: () => i18n.t('form.invalid_msg.invalid_transaction_fee_fixed_amount').toString() })
  @Transform((value, obj) => {
    return (obj.fee_type === FEE_TYPES.none) ? undefined : parseFloat(value)
  }, { toClassOnly: true })
  public transactionFeeFixedNetAmount: number | undefined

  @ValidateIf(o => o.feeType === FEE_TYPES.fixed_fee || o.feeType === FEE_TYPES.fixed_plus_percent)
  @IsNotEmpty({ message: () => i18n.t('form.invalid_msg.blank_transaction_fee_fixed_currency').toString() })
  @Expose({ name: 'transaction_fee_fixed_currency' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public transactionFeeFixedCurrency: string | undefined

  @Expose({ name: 'transaction_fee_percentage' })
  @ValidateIf(o => o.feeType === FEE_TYPES.percent_fee || o.feeType === FEE_TYPES.fixed_plus_percent)
  @IsNotEmpty({ message: () => i18n.t('form.invalid_msg.blank_transaction_fee_percentage').toString() })
  @Transform((value, obj) => {
    return (obj.fee_type === FEE_TYPES.none) ? undefined : value
  }, { toClassOnly: true })
  public transactionFeePercentage: number | undefined

  @Expose({ name: 'fee_type' })
  public feeType: number | undefined
}
