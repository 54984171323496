import i18n from '@/i18n'
import { ImportTaxesPayments } from '@/models/corporate-account-ts'

export function useImportTaxPaymentTypes(selectedValue: number[] | number | undefined | null) {
  return Object.values(ImportTaxesPayments)
    .filter((value) => Number.isInteger(value as number))
    .map((status) => {
      return {
        text: i18n.t(`import_taxes_payment.option_${status}`) as string,
        value: status,
        selected:
          !!selectedValue &&
          ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toString()).includes(status.toString())) ||
            (!Array.isArray(selectedValue) && selectedValue.toString() === status.toString()))
      }
    })
}
