
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ISelectItem } from '@/models/SelectBoxItem'
import EnviayaNumberInput from '@/overrides/mdb-components/EnviayaNumberInput.vue'
import { PackagingCommodity } from '@/models/packaging-commodity'

@Component({
  components: {
    EnviayaNumberInput
  }
})
export default class CommodityItem extends Vue {
  @Prop(Object) public commodity!: PackagingCommodity
  @Prop({ default: '' }) public weightUnit!: String

  public countriesList: ISelectItem[] = []

  public mounted () {
    this.initCountries()
  }

  public initCountries () {
    const countryList = require('country-list')
    this.countriesList = []
    Object.entries(countryList.getCodeList()).map((item: any) => {
      this.countriesList.push({ text: item[1], value: item[0].toUpperCase(), selected: this.commodity.countryCode === item[0].toUpperCase() })
    })
  }
}
