import { Mixin } from 'vue-mixin-decorator'
import BasicComponent from '@/views/BasicComponent'
import { WhiteLabel } from '@/models/white-label'
import Vue from 'vue'
import { CONFIGURATION_KEYS } from '@/common/config'

@Mixin
export class Styles extends BasicComponent {
  public get wlLogo () {
    if (this.$currentWhiteLabel) {
      if (this.$currentWhiteLabel.logo) {
        return `${this.$currentWhiteLabel.logo}`
      } else if (this.$currentWhiteLabel.logoSvg) {
        return `${this.$currentWhiteLabel.logoSvg}`
      } else {
        return ''
      }
    } else {
      return this.enviayaLogo
    }
  }

  public get footerLogo () {
    if (this.$currentWhiteLabel) {
      if (this.$currentWhiteLabel.footerLogo) {
        return `${this.$currentWhiteLabel.footerLogo}`
      } else {
        if (this.$currentWhiteLabel.logo) {
          return `${this.$currentWhiteLabel.logo}`
        } else if (this.$currentWhiteLabel.logoSvg) {
          return `${this.$currentWhiteLabel.logoSvg}`
        } else {
          return ''
        }
      }
    } else {
      return this.getLogo()
    }
  }

  public get wlIcon () {
    if (this.$currentWhiteLabel) {
      if (this.$currentWhiteLabel.logoMinimized) {
        return `${this.$currentWhiteLabel.logoMinimized}`
      } else {
        return ''
      }
    } else {
      return `${Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH)}${this.$getConst(require('@/common/config').CONFIGURATION_KEYS.SMALL_LOGO_PATH)}`
    }
  }

  public get enviayaLogo () {
    return this.getLogo()
  }

  public getLogo (logoType: string = 'regular') {
    const path: string = Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH)

    const availableMonths: number[] = [2 , 3, 4, 5, 6, 9, 10, 11, 12]
    const currentMonth = Vue.moment().month() + 1

    if (availableMonths.includes(currentMonth)) {
      return `${path}img/${currentMonth}_logo_${logoType}.svg`
    } else {
      return `${path}img/default_logo_${logoType}.svg`
    }
  }

  public get enviayaIcon () {
    return this.getLogo('mini')
  }

  public setFavicon (icon: string) {
    let nodeList = document.getElementsByTagName('link')
    for (let i = 0; i < nodeList.length; i++) {
      if((nodeList[i].getAttribute('rel') === 'icon') || (nodeList[i].getAttribute('rel') === 'shortcut icon')) {
        nodeList[i].href = icon
      }
    }
  }

  public applyLoginStyles () {
    if (this.$currentWhiteLabel) {
      this.applyLoginPageWLStyles()
    } else {
      this.applyLoginPageEnviayaStyles()
    }
  }

  public applyStyles () {
    if (this.$currentWhiteLabel) {
      this.applyWLStyles()
      this.setFavicon(this.$currentWhiteLabel.favicon)
    } else {
      this.applyEnviayaStyles()
      this.setFavicon(`${Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH)}img/icons/favicon-32x32.png`)
    }
  }

  public applyLoginPageEnviayaStyles () {
    let r = document.querySelector(':root')
    // @ts-ignore
    r.style.setProperty('--main-background-color', '#fbfafa')
    // @ts-ignore
    r.style.setProperty('--main-background-img', 'url(\'/img/login_back.jpg\')')
    // @ts-ignore
    r.style.setProperty('--main-background-size', 'cover')
    // @ts-ignore
    r.style.setProperty('--main-background-repeat', 'no-repeat')
    // @ts-ignore
    r.style.setProperty('--main-background-position', 'unset')
  }

  public applyLoginPageWLStyles () {
    let wlData: WhiteLabel = this.$currentWhiteLabel
    let r = document.querySelector(':root')
    // @ts-ignore
    r.style.setProperty('--main-background-color', wlData.loginPageBackgroundColor)
    // @ts-ignore
    r.style.setProperty('--main-background-img', `url('${wlData.loginPageBackgroundImage}')`)
    // @ts-ignore
    r.style.setProperty('--main-background-size', wlData.loginPageBackgroundSize)
    // @ts-ignore
    r.style.setProperty('--main-background-repeat', wlData.loginPageBackgroundRepeat)
    // @ts-ignore
    r.style.setProperty('--main-background-position', wlData.loginPageBackgroundPosition)
  }

  public applyWLStyles () {
    let wlData: WhiteLabel = this.$currentWhiteLabel
    let r = document.querySelector(':root')
    let buttonBackgroundColor: string = '#40A2F7'
    if (wlData.buttonBackgroundColor) {
      buttonBackgroundColor = wlData.buttonBackgroundColor
    }
    // @ts-ignore
    r.style.setProperty('--header-color', wlData.headerColor)
    // @ts-ignore
    r.style.setProperty('--header-font-color', wlData.userMenuFontColor)
    // @ts-ignore
    r.style.setProperty('--header-dropdown-text-color', 'white')
    // @ts-ignore
    r.style.setProperty('--side-nav-color', wlData.sidebarBackgroundColor)
    // @ts-ignore
    r.style.setProperty('--side-nav-text-color', wlData.sidebarFontColor)
    // @ts-ignore
    r.style.setProperty('--side-nav-active-category-color', wlData.sidebarBackgroundColor)
    // @ts-ignore
    r.style.setProperty('--side-nav-expanded-items-color', wlData.sidebarFontColor)
    // @ts-ignore
    r.style.setProperty('--side-nav-expanded-active-item-color', wlData.sidebarFontColor)
    // @ts-ignore
    r.style.setProperty('--side-nav-vertical-line-color', wlData.headerColor)
    // @ts-ignore
    r.style.setProperty('--active-color', wlData.headerColor)
    // @ts-ignore
    r.style.setProperty('--btn-primary-color-hex', buttonBackgroundColor)
    // @ts-ignore
    r.style.setProperty('--btn-primary-color', this.hexToHSL(buttonBackgroundColor).full)
    // @ts-ignore
    r.style.setProperty('--btn-primary-color-h', this.hexToHSL(buttonBackgroundColor).partH)
    // @ts-ignore
    r.style.setProperty('--btn-search-hover', wlData.buttonBackgroundHover)
    // @ts-ignore
    r.style.setProperty('--btn-primary-text-color', wlData.buttonTextColor)
    // @ts-ignore
    r.style.setProperty('--footer-bg-color', wlData.footerBackgroundColor)
    // @ts-ignore
    r.style.setProperty('--footer-font-color', wlData.footerFontColor)
    // @ts-ignore
    r.style.setProperty('--footer-link-color', wlData.footerLinkColor)
    // @ts-ignore
    r.style.setProperty('--footer-link-hover-color', wlData.footerLinkHoverColor)
  }

  public applyEnviayaStyles () {
    let r = document.querySelector(':root')

    // @ts-ignore
    r.style.setProperty('--header-color', '#212b33')
    // @ts-ignore
    r.style.setProperty('--header-font-color', 'white')
    // @ts-ignore
    r.style.setProperty('--header-dropdown-text-color', 'white')
    // @ts-ignore
    r.style.setProperty('--side-nav-color', '#fff')
    // @ts-ignore
    r.style.setProperty('--side-nav-text-color', '#3d4c59')
    // @ts-ignore
    r.style.setProperty('--side-nav-active-category-color', '#fff')
    // @ts-ignore
    r.style.setProperty('--side-nav-expanded-items-color', 'inherit')
    // @ts-ignore
    r.style.setProperty('--side-nav-expanded-active-item-color', '#3fa2f7')
    // @ts-ignore
    r.style.setProperty('--side-nav-vertical-line-color', '#3fa2f7')
    // @ts-ignore
    r.style.setProperty('--active-color', '')
    // @ts-ignore
    r.style.setProperty('--btn-primary-color-hex', '#3fa2f7')
    // @ts-ignore
    r.style.setProperty('--btn-primary-color', this.hexToHSL('#40A2F7').full)
    // @ts-ignore
    r.style.setProperty('--btn-primary-color-h', this.hexToHSL('#40A2F7').partH)
    // @ts-ignore
    r.style.setProperty('--btn-search-hover', '#fc7753')
    // @ts-ignore
    r.style.setProperty('--btn-primary-text-color', '#fff')
    // @ts-ignore
    r.style.setProperty('--footer-bg-color', '#fbfafa')
    // @ts-ignore
    r.style.setProperty('--footer-font-color', '#8c8c8c')
    // @ts-ignore
    r.style.setProperty('--footer-link-color', '#006ead')
    // @ts-ignore
    r.style.setProperty('--footer-link-hover-color', '#FF6600')
  }

  public hexToHSL (H: any) {
    // Convert hex to RGB first
    let r: any = 0
    let g: any = 0
    let b: any = 0
    if (H.length === 4) {
      r = '0x' + H[1] + H[1]
      g = '0x' + H[2] + H[2]
      b = '0x' + H[3] + H[3]
    } else if (H.length === 7) {
      r = '0x' + H[1] + H[2]
      g = '0x' + H[3] + H[4]
      b = '0x' + H[5] + H[6]
    }
    // Then to HSL
    r /= 255
    g /= 255
    b /= 255
    let cmin = Math.min(r, g, b)
    let cmax = Math.max(r, g, b)
    let delta = cmax - cmin
    let h = 0
    let s = 0
    let l = 0

    if (delta === 0) h = 0
    else if (cmax === r) h = ((g - b) / delta) % 6
    else if (cmax === g) h = (b - r) / delta + 2
    else h = (r - g) / delta + 4

    h = Math.round(h * 60)

    if (h < 0) h += 360

    l = (cmax + cmin) / 2
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    return { full: 'hsl(' + h + ',' + s + '%,' + l + '%)', partH: h }
  }
}
