import { Exclude, Expose } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import {
  IsNotEmpty
} from 'class-validator'
import i18n from '@/i18n'
// @ts-ignore
import localeData from '@/locales/services/form.json'

@Exclude()
export class WhiteLabelTemplate extends AbstractModel {
  constructor () {
    super()
    i18n.mergeLocaleMessage('en', localeData.en)
    i18n.mergeLocaleMessage('de', localeData.de)
    i18n.mergeLocaleMessage('es', localeData.es)
  }

  static get modelName () {
    return 'WhiteLabelTemplate'
  }

  public wasValidated: boolean = false

  @Expose({ name: 'id' })
  public id: number | null = null

  @IsNotEmpty({ message: () => i18n.t('form.messages.errors.require_message').toString() })
  @Expose({ name: 'name' })
  public name: string = ''

  @Expose({ name: 'content' })
  public content: string = ''

  @Expose({ name: 'white_label_id' })
  public whiteLabelId: number | null = null
}
