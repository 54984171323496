import { Exclude, Expose, Transform } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'

@Exclude()
export class SurchargeDiscount extends AbstractModel {
  @Expose({ name: 'id' })
  public id: number | null = null

  @Expose({ name: 'margin' })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public margin: number = 0

  @Expose({ name: 'discount' })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public discount: number = 0

  @Expose({ name: 'fixed_net_amount' })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public fixedNetAmount: number = 0

  @Expose({ name: 'fixed_currency' })
  public fixedCurrency: string = ''
}
