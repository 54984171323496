import { Mixin } from 'vue-mixin-decorator'
import BasicComponent from '@/views/BasicComponent'
// @ts-ignore
import brandLocales from '@/locales/enviaya_brand.json'
import { AppModule } from '@/store/app.module'
import { AVAILABLE_LANGUAGES } from '@/common/user.settings'
import { DomainService } from '@/common/domain.service'
import { CONFIGURATION_KEYS } from '@/common/config'

@Mixin
export class Brand extends BasicComponent {
  public get brandName (): string {
    let locale: string = 'es'
    if (AppModule.locale && AVAILABLE_LANGUAGES.includes(AppModule.locale.split('-')[0])) {
      locale = AppModule.locale.split('-')[0]
    }
    return (!this.$currentWhiteLabel) ? brandLocales[locale]['brand']['name'] : this.$currentWhiteLabel.brandName
  }

  public get termsLink (): string {
    return (this.$currentWhiteLabel && this.$currentWhiteLabel.termsAndConditionUrl && this.$currentWhiteLabel.termsAndConditionUrl.length) ? this.$currentWhiteLabel.termsAndConditionUrl : 'https://enviaya.com.mx/terminos_y_condiciones'
  }

  public get policyLink (): string {
    return (this.$currentWhiteLabel && this.$currentWhiteLabel.privacyPolicyUrl && this.$currentWhiteLabel.privacyPolicyUrl.length) ? this.$currentWhiteLabel.privacyPolicyUrl : 'https://enviaya.com.mx/aviso_de_privacidad'
  }

  public googleApiKey (): string | null {
    if (DomainService.isEnviayaDomain()) {
      return this.$getConst(CONFIGURATION_KEYS.APP_GOOGLE_MAP_KEY)
    } else if (DomainService.isWLSubdomainOfEnviaya()) {
      if (this.$currentWhiteLabel && this.$currentWhiteLabel.googleApiKey && this.$currentWhiteLabel.googleApiKey.length) {
        return this.$currentWhiteLabel.googleApiKey
      } else {
        return this.$getConst(CONFIGURATION_KEYS.APP_GOOGLE_MAP_KEY)
      }
    } else {
      if (this.$currentWhiteLabel && this.$currentWhiteLabel.googleApiKey && this.$currentWhiteLabel.googleApiKey.length) {
        return this.$currentWhiteLabel.googleApiKey
      } else {
        return null
      }
    }
  }
}
