import ApiService from '@/common/api/api.service'
import { plainToClass, classToPlain } from 'class-transformer'
import { DirectionList } from '@/models/direction-list'
import { AddressAttributes, Direction, DirectionCoordinates } from '@/models/direction'
import GraphQLService from './graphql.service'
import { Ref } from 'vue'
import { DirectionsQuery } from '@/models/search-filters/directions-query'
import { useQuery } from '@tanstack/vue-query'

export class DirectionsApiService {
  public static async query (params: Object): Promise<DirectionList> {
    let { data } = await ApiService.query('directions', {
      params: params
    })
    return plainToClass(DirectionList, data)
  }

  public static async create (params: Object) {
    return ApiService.post('directions', {
      direction: { ...params }
    })
  }

  public static async update (slug: string, params: Object) {
    return ApiService.update('directions', slug, {
      direction: { ...params }
    })
  }

  public static async updateCoordinates (directionData: { id: number, latitude: string, longitude: string }) {
    let coordinates: DirectionCoordinates = plainToClass(DirectionCoordinates, {})
    coordinates.latitude = directionData.latitude
    coordinates.longitude = directionData.longitude
    coordinates.coordinatesAutofetched = false
    return ApiService.update('directions', String(directionData.id), {
      direction: { ...classToPlain(coordinates) }
    })
  }

  public static async get (slug: string) {
    return ApiService.get('directions', slug)
  }

  public static async getGQL (id: number) {
    return GraphQLService.query(
      `query direction($id: ID!) {
        direction(id: $id) {
          ${this.directionGraphQLStruct()}
        }
      }`,
      {
        id: id
      }
    )
  }

  public static async getFromCorporateAccountGQL (corporateAccountId: number) {
    return GraphQLService.query(
      `query directionsForCorpAccount($id: ID!) {
        corporateAccount(id: $id) {
          directions: {
            ${this.directionGraphQLStruct()}
          }
        }
      }`,
      {
        id: corporateAccountId
      }
    )
  }

  public static directionGraphQLStruct () {
    return `
      id
      fullName
      phone
      company
      phone
      email
      direction1
      direction2
      neighborhood
      country
      city
      district
      state
    `
  }

  public static async destroy (slug: number) {
    return ApiService.delete(`directions/${slug}`)
  }

  public static async destroyByIds (params: Object) {
    return ApiService.deleteByIds(`directions/delete`, {
      ...params
    })
  }

  public static async createDownloadJob (params: Object) {
    return ApiService.post('directions/download', {
      ...params
    })
  }

  public static async createLabelJob (params: Object) {
    return ApiService.post('directions/create_labels', {
      ...params
    })
  }

  public static async createLabelsFromLists (ids: number[], templateId: number) {
    return ApiService.post(`/directions/create_labels_from_lists`, {
      lists_ids: ids,
      shipment_template_id: templateId
    })
  }

  public static async createLabelsFromTags (ids: number[], templateId: number) {
    return ApiService.post(`/directions/create_labels_from_tags`, {
      tags_ids: ids,
      shipment_template_id: templateId
    })
  }

  public static async jobStatus (slug: string) {
    return ApiService.get('job_status', slug)
  }

  public static downloadCsvTemplate (version: string) {
    return ApiService.query(`directions/download_csv_template`, { responseType: 'blob', params: { version: version } })
  }

  public static import (params: any) {
    return ApiService.post(`directions/import`, params, {
      'Content-Type': 'multipart/form-data'
    })
  }

  public static async checkDefaultOrigin (userId: number) {
    let { data } = await ApiService.get(`directions/check_single_default?direction_user_id=${userId}&default_origin=true`)
    return data
  }

  public static async checkDefaultPickup (direction: Direction) {
    let { data } = await ApiService.get(`directions/check_single_default?direction_user_id=${direction.userId}&default_pickup=true`)
    return data
  }

  public static async checkAddressAttributes (countryCode: string): Promise<AddressAttributes> {
    let { data } = await ApiService.query(`directions/country_has_attributes`, { params:  { country: countryCode } })
    return plainToClass(AddressAttributes, data)
  }

  public static async createShipmentBasedOnDirection (directionId: number, params: Object) {
    return ApiService.post(`directions/${directionId}/shipment`, {
      ...params
    })
  }
}

export const useDirectionsIndex = (
  applyOnLoad: Ref<boolean>,
  filter: Ref<DirectionsQuery>
) => {
  const { isLoading, data } = useQuery({
    queryKey: ['directionsIndex', filter],
    queryFn: () => directionsIndexFn(filter.value),
    select: ({ data }) => {
      return plainToClass(DirectionList, data)
    },
    staleTime: 1000 * 60 * 2, // Two minutes for caching results
    retry: 0,
    enabled: applyOnLoad
  });

  return { isLoading, data }
}

export const directionsIndexFn = async (filter: DirectionsQuery) => {
  return ApiService.query('directions', {
    params: classToPlain(filter)
  })
}
