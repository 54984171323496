export const UserRoles = {
  USER_ROLE_USER: 1,
  USER_ROLE_CORPORATE_ADMIN: 2,
  USER_ROLE_CORPORATE_SUPERVISOR: 3,
  USER_ROLE_WHITE_LABEL_ADMIN: 4,
  USER_ROLE_VENDOR_CUSTOMER_SERVICE: 5,
  USER_ROLE_VENDOR_ACCOUNTING: 6,
  USER_ROLE_VENDOR_SALES_AGENT: 7,
  USER_ROLE_CUSTOMER_SERVICE: 90,
  USER_ROLE_ACCOUNTING: 91,
  USER_ROLE_SALES_EXECUTIVE: 92,
  USER_ROLE_COUNTRY_ADMIN: 93,
  USER_ROLE_ACCOUNTING_SUPPORT: 94,
  USER_ROLE_SUPER_ADMIN: 99,
  USER_ROLE_SALES_DIRECTOR: 95,
  USER_ROLE_CUSTOMER_SERVICE_MANAGER: 96
}

export const Dashboards = {
  SALES_DASHBOARD: 'SALES_DASHBOARD',
  SALES_BY_ACCOUNT_DASHBOARD: 'SALES_BY_ACCOUNT_DASHBOARD',
  CLAIM_COLLECTION_DASHBOARD: 'CLAIM_COLLECTION_DASHBOARD',
  CUSTOMER_SERVICE_DASHBOARD: 'CUSTOMER_SERVICE_DASHBOARD',
  CARRIER_INVOICING_DASHBOARD: 'CARRIER_INVOICING_DASHBOARD',
  ACCOUNTING_DASHBOARD: 'ACCOUNTING_DASHBOARD',
  ADMINISTRATION_DASHBOARD: 'ADMINISTRATION_DASHBOARD',
  USER_DASHBOARD: 'USER_DASHBOARD'
}

export const DashboardAccess = {
  'SALES_DASHBOARD': [
    UserRoles.USER_ROLE_SUPER_ADMIN,
    UserRoles.USER_ROLE_COUNTRY_ADMIN,
    UserRoles.USER_ROLE_SALES_DIRECTOR,
    UserRoles.USER_ROLE_SALES_EXECUTIVE,
    UserRoles.USER_ROLE_ACCOUNTING
  ],
  'SALES_BY_ACCOUNT_DASHBOARD': [
    UserRoles.USER_ROLE_SUPER_ADMIN,
    UserRoles.USER_ROLE_COUNTRY_ADMIN,
    UserRoles.USER_ROLE_SALES_DIRECTOR,
    UserRoles.USER_ROLE_SALES_EXECUTIVE
  ],
  'CLAIM_COLLECTION_DASHBOARD': [
    UserRoles.USER_ROLE_SUPER_ADMIN,
    UserRoles.USER_ROLE_COUNTRY_ADMIN,
    UserRoles.USER_ROLE_ACCOUNTING,
    UserRoles.USER_ROLE_ACCOUNTING_SUPPORT,
    UserRoles.USER_ROLE_CUSTOMER_SERVICE
  ],
  'CUSTOMER_SERVICE_DASHBOARD': [
    UserRoles.USER_ROLE_SUPER_ADMIN,
    UserRoles.USER_ROLE_COUNTRY_ADMIN,
    UserRoles.USER_ROLE_CUSTOMER_SERVICE,
    UserRoles.USER_ROLE_CUSTOMER_SERVICE_MANAGER,
    UserRoles.USER_ROLE_ACCOUNTING,
    UserRoles.USER_ROLE_ACCOUNTING_SUPPORT,
    UserRoles.USER_ROLE_SALES_DIRECTOR,
    UserRoles.USER_ROLE_SALES_EXECUTIVE
  ],
  'CARRIER_INVOICING_DASHBOARD': [
    UserRoles.USER_ROLE_SUPER_ADMIN,
    UserRoles.USER_ROLE_COUNTRY_ADMIN,
    UserRoles.USER_ROLE_ACCOUNTING
  ],
  'ACCOUNTING_DASHBOARD': [
    UserRoles.USER_ROLE_SUPER_ADMIN,
    UserRoles.USER_ROLE_COUNTRY_ADMIN,
    UserRoles.USER_ROLE_ACCOUNTING
  ],
  'ADMINISTRATION_DASHBOARD': [
    UserRoles.USER_ROLE_SUPER_ADMIN,
    UserRoles.USER_ROLE_COUNTRY_ADMIN,
    UserRoles.USER_ROLE_ACCOUNTING
  ]
}

export const WHO = {
  isRegularUser: (user) => {
    return UserRoles.USER_ROLE_USER === user.role && !user.corporateAccountId
  },
  isAdmin: (userRole) => {
    return UserRoles.USER_ROLE_SUPER_ADMIN === userRole
  },
  isSalesDirector: (userRole) => {
    return UserRoles.USER_ROLE_SALES_DIRECTOR === userRole
  },
  isCorporateAdministrator: (user) => {
    return !!(user.corporateAccountId && user.role === UserRoles.USER_ROLE_CORPORATE_ADMIN)
  },
  isCorporateSupervisor: (user) => {
    return !!(user.corporateAccountId && user.role === UserRoles.USER_ROLE_CORPORATE_SUPERVISOR)
  },
  isCorporateUser: (user) => {
    return !!(user.corporateAccountId && user.role === UserRoles.USER_ROLE_USER)
  },
  isVendorUser: (user) => {
    return user.isReseller
  },
  isVendorAdmin: (user) => {
    return user.resellerId
  },
  isVendorStaff: (user) => {
    return [
      UserRoles.USER_ROLE_VENDOR_CUSTOMER_SERVICE,
      UserRoles.USER_ROLE_VENDOR_ACCOUNTING,
      UserRoles.USER_ROLE_VENDOR_SALES_AGENT
    ].includes(user.role)
  },
  isWhiteLabelAdmin: (user) => {
    return (user.whiteLabelIds && user.whiteLabelIds.length > 0) // && user.role === UserRoles.USER_ROLE_USER
  },
  belongsToWhiteLabel: (user) => {
    return (user.whiteLabelIds && user.whiteLabelIds.length > 0) || user.whiteLabelId
  },
  isSalesExecutive: (user) => {
    return user.role === UserRoles.USER_ROLE_SALES_EXECUTIVE
  },
  adminOrAccounting: (userRole) => {
    return [
      UserRoles.USER_ROLE_SUPER_ADMIN,
      UserRoles.USER_ROLE_ACCOUNTING
    ].findIndex((role) => { return role === userRole }) > -1
  },
  adminOrCustomerServ: (userRole) => {
    return [
      UserRoles.USER_ROLE_SUPER_ADMIN,
      UserRoles.USER_ROLE_CUSTOMER_SERVICE,
      UserRoles.USER_ROLE_SALES_DIRECTOR,
      UserRoles.USER_ROLE_SALES_EXECUTIVE
    ].includes(userRole)
  },
  adminCSorAccounting: (userRole) => {
    return [
      UserRoles.USER_ROLE_SUPER_ADMIN,
      UserRoles.USER_ROLE_CUSTOMER_SERVICE,
      UserRoles.USER_ROLE_ACCOUNTING,
      UserRoles.USER_ROLE_SALES_DIRECTOR,
      UserRoles.USER_ROLE_SALES_EXECUTIVE
    ].findIndex((role) => { return role === userRole }) > -1
  },
  isManagement: (userRole) => {
    return [
      UserRoles.USER_ROLE_SUPER_ADMIN,
      UserRoles.USER_ROLE_CUSTOMER_SERVICE,
      UserRoles.USER_ROLE_ACCOUNTING,
      UserRoles.USER_ROLE_SALES_EXECUTIVE,
      UserRoles.USER_ROLE_COUNTRY_ADMIN,
      UserRoles.USER_ROLE_ACCOUNTING_SUPPORT,
      UserRoles.USER_ROLE_SALES_DIRECTOR,
      UserRoles.USER_ROLE_CUSTOMER_SERVICE_MANAGER
    ].findIndex((role) => { return role === userRole }) > -1
  },
  isSuperAdmin: (userRole) => {
    return UserRoles.USER_ROLE_SUPER_ADMIN === userRole
  },
  isDashboardViewer: (userRole) => {
    return [
      UserRoles.USER_ROLE_SUPER_ADMIN,
      UserRoles.USER_ROLE_CUSTOMER_SERVICE,
      UserRoles.USER_ROLE_ACCOUNTING,
      UserRoles.USER_ROLE_SALES_DIRECTOR,
      UserRoles.USER_ROLE_SALES_EXECUTIVE
    ].findIndex((role) => { return role === userRole }) > -1
  },
  isVerifiedStatusViewer: (userRole) => {
    return [
      UserRoles.USER_ROLE_SUPER_ADMIN,
      UserRoles.USER_ROLE_ACCOUNTING,
      UserRoles.USER_ROLE_ACCOUNTING_SUPPORT,
      UserRoles.USER_ROLE_SALES_DIRECTOR
    ].findIndex((role) => { return role === userRole }) > -1
  },
  isResellerClient (user, whiteLabel) {
    if (!user.whiteLabel) {
      return false
    }
    if (user.whiteLabel.enableReseller) {
      if (user.isWhiteLabelAdmin()) {
        return false
      } else if (user.corporateAccountId === whiteLabel.corporateAccountId) {
        return false
      } else {
        return true
      }
    }
  },
  hasAccessToDashboard (dashboard, user) {
    return DashboardAccess[dashboard].findIndex((role) => { return role === user.role }) > -1
  }
}
