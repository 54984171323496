const customerInformation = state => state.customerInformation
const contactInformation = state => state.contactInformation
const customerInformationInitData = state => state.customerInformationInitData
const step = state => state.step

export default {
  customerInformation,
  contactInformation,
  customerInformationInitData,
  step
}
