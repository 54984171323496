import { Exclude, Expose, Type } from 'class-transformer'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'

@Exclude()
export class VipSupport {
  static get modelName () {
    return 'VipSupport'
  }

  @Expose({ name: 'id' })
  public id: number | undefined

  @Expose({ name: 'user_id' })
  public userId: number | undefined

  @Expose({ name: 'vip_support_agent_id' })
  public vipSupportAgentId: number | undefined

  @Expose({ name: 'vip_support_agent', toClassOnly: true })
  @Type(() => EnviayaUserTS)
  public vipSupportAgent: EnviayaUserTS | null = null
}
