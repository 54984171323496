import ApiService from '@/common/api/api.service'
import { plainToClass } from 'class-transformer'
import { Carrier } from '@/models/carrier'
import GraphQLService from './graphql.service'

export class CarriersApiService {
  public static async list (): Promise<Carrier[]> {
    let { data } = await ApiService.get('carriers')
    return data.carriers.map((carrier: any) => { return plainToClass(Carrier, carrier) } )
  }

  public static async listJSON (): Promise<any[]> {
    let { data } = await ApiService.get('carriers')
    return data.carriers
  }

  public static async get (slug: any): Promise<Carrier> {
    let { data } = await ApiService.get('carriers', slug)
    return plainToClass(Carrier, data)
  }

  public static carrierAndServicesGraphQLStruct () {
    return `
      id
      name
      domestic
      international
      carrierServices {
        id
        serviceType
        serviceTypeToString
        serviceCode
        fullServiceSelectBoxText
      }
    `
  }

  public static listByGraphQL () {
    return GraphQLService.query(
      `query carriersAndServicesList {
        carriers {
          ${this.carrierAndServicesGraphQLStruct()}
        }
      }`,
      {}
    )
  }

  public static searchByAccessPointBrand (brand: string | null = null) {
    return GraphQLService.query(
      `query searchByAccessPointBrand($brand:String) {
        carriers(brand:$brand) {
          id
          name
        }
      }`,
      { brand: brand }
    )
  }

  public static async carrierIndex () {
    return ApiService.query('carriers', {})
  }
}
