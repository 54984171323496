import i18n from '@/i18n'
import { CALCULATION_TYPE } from '@/models/cogs-configuration'

export function useCalculationTypes(selectedValue: string[] | string | undefined | null) {
  return Object.values(CALCULATION_TYPE).map((type) => {
    return {
      text: i18n.t(`calculation_types.${type.toLowerCase()}`),
      value: type,
      selected:
        !!selectedValue &&
        ((Array.isArray(selectedValue) &&
            selectedValue.map((val) => val.toLowerCase()).includes(type.toLowerCase())) ||
          (!Array.isArray(selectedValue) && selectedValue.toLowerCase() === type.toLowerCase()))
    }
  })
}
