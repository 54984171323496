import i18n from '@/i18n'
import { LABEL_SIZES } from '@/common/user.settings'

export function useLabelSizes(selectedValue: number[] | number | undefined | null) {
  return Object.values(LABEL_SIZES)
    .filter((value) => Number.isInteger(value as number))
    .map((value) => {
      return {
        text: i18n.t(`user_settings.settings.label_sizes.size_${value}`) as string,
        value: value,
        selected:
          !!selectedValue &&
          ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toString()).includes(value.toString())) ||
            (!Array.isArray(selectedValue) && selectedValue.toString() === value.toString()))
      }
    })
}
