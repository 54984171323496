import GraphQLService from './graphql.service'
import { NotFoundError } from '@/common/exceptions/not-found-error'

export default class QuotingFilterApiService {
  public static quotingFilterDataStrucGraphql () {
    return `
      id
      restrictCarriers,
      enviayaAccountId,
      enviayaAccount {
        id,
        settingsLocked,
        accountType,
        corporateAccountId
        users {
          id,
          corporateAccountId
        }
      },
      corporateAccountId,
      corporateAccount {
        id,
        accountType,
        whiteLabelId
      },
      whiteLabelId,
      whiteLabel {
        id,
        enableReseller
      },
      configureAdvancedFilters,
      defineSpecificCutOffTime,
      sameDayCutOffTime,
      nextDayCutOffTime,
      globalCutOffTime,
      sameDayAdditionalHours,
      nextDayAdditionalHours,
      globalAdditionalHours,
      specificCarrierCutoffTime,
      onlyCheapestFilter,
      cheapestFilter,
      fastestFilter,
      cheapestStandard,
      cutOffConfigurations {
        id
        enviayaAccountId
        carrierId
        sameDayCutOffTime
        nextDayCutOffTime
        globalCutOffTime
        sameDayAdditionalHours
        nextDayAdditionalHours
        globalAdditionalHours
      }
      carriersQuotingFilters {
        carrierId,
        filterServices,
        minShopOrderAmount,
        maxShopOrderAmount,
        shopOrderAmountCurrency,
        remoteHandling
      },
      carrierServicesQuotingFilters {
        carrierServiceId
      },
      disabledCarriersByHigherLevel {
        id
        higherLevel
      },
      disabledCarrierServicesByHigherLevel {
        carrierId
        disabledServices {
          id
        }
        disabledServicesByWhiteLabel
      }
      `
  }

  public static createOrUpdateQuotingFilterMutationStruct (mutationType: string = 'create') {
    if (!['create', 'update'].includes(mutationType)) throw new NotFoundError(`Invalid mutation type, unable to process this mutation type!`,[mutationType])
    const mutationName = mutationType === 'create' ? 'createQuotingFilter' : 'updateQuotingFilter'

    return `
      mutation (
        $enviayaAccountId: ID
        $corporateAccountId: ID
        $whiteLabelId: ID
        $restrictCarriers: Boolean
        $specificCarrierCutoffTime: Boolean
        $restrictCarriersParams: JSON
        $cutOffConfigurations: JSON
        ${mutationType === 'update' ? '$quotingFilterId: ID' : ''}
      ){
        ${mutationName} (
          input: {
            quotingFilter: {
              enviayaAccountId: $enviayaAccountId,
              corporateAccountId: $corporateAccountId,
              whiteLabelId: $whiteLabelId,
              restrictCarriers: $restrictCarriers,
              specificCarrierCutoffTime: $specificCarrierCutoffTime
            },
            restrictCarriers: $restrictCarriersParams,
            cutOffConfigurations: $cutOffConfigurations
            ${mutationType === 'update' ? 'quotingFilterId: $quotingFilterId' : ''}
          }
        ) {
            quotingFilter {
              ${this.quotingFilterDataStrucGraphql()}
            },
            errors
          }
      }`
  }

  public static async createQuotingFilter (quotingFilterDataParams: any) {
    const { quoting_filter: quotingFilterParams, restrict_carriers: restrictCarriersParams, cutOffConfigurations } = quotingFilterDataParams
    const {
      restrict_carriers: restrictCarriers,
      enviaya_account_id: enviayaAccountId,
      corporate_account_id: corporateAccountId,
      white_label_id: whiteLabelId,
      specificCarrierCutoffTime
    } = quotingFilterParams

    return GraphQLService.query(
      `${this.createOrUpdateQuotingFilterMutationStruct()}`, {
        enviayaAccountId,
        corporateAccountId,
        whiteLabelId,
        restrictCarriers,
        specificCarrierCutoffTime,
        restrictCarriersParams,
        cutOffConfigurations
      }
    )
  }

  public static async updateQuotingFilter (quotingFilterDataParams: any) {
    const { quoting_filter: quotingFilterParams, restrict_carriers: restrictCarriersParams, quotingFilterId, cutOffConfigurations } = quotingFilterDataParams
    const {
      restrict_carriers: restrictCarriers,
      enviaya_account_id: enviayaAccountId,
      corporate_account_id: corporateAccountId,
      white_label_id: whiteLabelId,
      specificCarrierCutoffTime
    } = quotingFilterParams

    return GraphQLService.query(
      `${this.createOrUpdateQuotingFilterMutationStruct('update')}`, {
        enviayaAccountId,
        corporateAccountId,
        whiteLabelId,
        restrictCarriers,
        specificCarrierCutoffTime,
        restrictCarriersParams,
        cutOffConfigurations,
        quotingFilterId
      }
    )
  }

}
