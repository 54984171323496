
import BasicComponent from '../../views/BasicComponent'
import { Component, Prop } from 'vue-property-decorator'
import CarrierAccountModalBody from '@/components/popup/CarrierAccountModalBody.vue'

@Component({
  components: {
    CarrierAccountModalBody
  }
})
export default class CarrierAccountModal extends BasicComponent {
  @Prop({ default: false }) public show!: boolean
  @Prop({ default: 'div' }) public tag!: string
  @Prop({ default: 'info' }) public type!: string
  @Prop({ default: '' }) public position!: string
  @Prop({ default: false }) public side!: boolean
  @Prop({ default: false }) public centered!: boolean
  @Prop({ default: false }) public fullHeight!: boolean
  @Prop({ default: false }) public frame!: boolean
  @Prop({ default: false }) public removeBackdrop!: boolean
  @Prop({ default: false }) public cascade!: boolean
  @Prop({ default: false }) public avatar!: boolean
  @Prop({ default: false }) public tabs!: boolean
  @Prop({ default: false }) public dark!: boolean
  @Prop({ default: null }) public bgSrc!: string
  @Prop({ default: false }) public scrollable!: boolean
  @Prop({ default: null }) public size!: string
  @Prop({ default: 'top' }) public direction!: string

  // header
  @Prop({ default: 'div' }) public headerTag!: string
  @Prop({ default: '' }) public headerBgColor!: string
  @Prop({ default: 'black' }) public headerTextColor!: string
  @Prop({ default: true }) public close!: boolean
  @Prop({ default: '' }) public headerText!: string

  // body
  @Prop({ default: 'div' }) public bodyTag!: string
  @Prop({ default: '' }) public bodyText!: string
  @Prop({ default: 'info-circle' }) public bodyIcon!: string
  @Prop({ default: '4x' }) public bodyIconSize!: string

  // footer
  @Prop({ default: 'div' }) public footerTag!: string
  @Prop({ default: false }) public start!: boolean
  @Prop({ default: false }) public end!: boolean
  @Prop({ default: 'Ok' }) public applyBtnText!: string
  @Prop({ default: '' }) public cancelBtnText!: string
  @Prop({ default: false }) public closeAriaLabel!: boolean
  @Prop({ default: false }) public titleClasses!: boolean
  @Prop({ default:  () => { return [] } }) public widthClass!: Array<String>

  @Prop(Function) public apply!: Function

  public isProcessing: boolean = false

  public get hasCancelListener () {
    return this.$listeners && this.$listeners.cancel
  }

  public async applyAction () {
    this.isProcessing = true
    // @ts-ignore
    await this.$listeners.apply()
    this.isProcessing = false
  }

  public cancelAction () {
    this.$emit('cancel')
  }

  public closePopup () {
    this.$emit('close', false)
  }
}
