export interface ICheckboxData {
  text: string,
  value: string
}

export interface ISelectData {
  text: string,
  value: string | number,
  selected?: boolean
}

export const SHIPMENTS_PER_MONTH_LIST: ISelectData[] = [
  { text: `1 shipments`, value: '1' },
  { text: `< 10 shipments`, value: '< 10' },
  { text: `10 - 30 shipments`, value: '10 - 30' },
  { text: `31 - 50 shipments`, value: '31 - 50' },
  { text: `51 - 100 shipments`, value: '51 - 100' },
  { text: `101 - 500 shipments`, value: '101 - 500' }
]

export const MONTHLY_SALES: ISelectData[] = [
  { text: '0', value: 0 },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 }
]

export const DESTINATION_TYPES_LIST: ICheckboxData[] = [
  { value: 'local', text: 'local' },
  { value: 'national', text: 'national' },
  { value: 'international_export', text: 'international_export' },
  { value: 'international_import', text: 'international_import' }
]

export const SHIPMENT_TYPES_LIST: ICheckboxData[] = [
  { value: 'document', text: 'documents' },
  { value: 'package', text: 'packages' },
  { value: 'heavy_parcel', text: 'heavy_packages_70' },
  { value: 'freight', text: 'freight' }
]

export const CARRIERS_LIST: ICheckboxData[] = [
  { value: 'FedEx', text: 'FedEx' },
  { value: 'iVoy', text: 'iVoy' },
  { value: 'Redpack', text: 'Redpack' },
  { value: 'Estafeta', text: 'Estafeta' },
  { value: 'Deprisa', text: 'Deprisa' },
  { value: 'Minutos', text: '99 Minutos' },
  { value: 'DHL', text: 'DHL' },
  { value: 'UPS', text: 'UPS' },
  { value: 'Sendex', text: 'Sendex' }
]

export const COMPETITION_LIST: ISelectData[] = [
  { value: 'SrEnvío', text: 'SrEnvío' },
  { value: 'Envíoclick', text: 'Envíoclick' },
  { value: 'Mi Envío', text: 'Mi Envío' },
  { value: 'Pakke', text: 'Pakke' },
  { value: 'Pakmail', text: 'Pakmail' },
  { value: 'Mailboxes', text: 'Mailboxes' },
  { value: 'Competencia (Otros)', text: 'Competencia (Otros)' }
]

export const PRODUCTS_OF_INTERESTS: ISelectData[] = [
  { value: 'API', text: 'API' },
  { value: 'BigCommerce', text: 'BigCommerce' },
  { value: 'Magento', text: 'Magento' },
  { value: 'OpenCart', text: 'OpenCart' },
  { value: 'PrestaShop', text: 'PrestaShop' },
  { value: 'Referral', text: 'Referral' },
  { value: 'Reseller', text: 'Reseller' },
  { value: 'Shopify', text: 'Shopify' },
  { value: 'Website', text: 'Website' },
  { value: 'WhiteLabel', text: 'WhiteLabel' },
  { value: 'WooCommerce', text: 'WooCommerce' }
]

export const FUNCTIONS_OF_INTEREST: ISelectData[] = [
  { value: 'Packagings', text: 'Packagings' },
  { value: 'Templates', text: 'Templates' },
  { value: 'Support', text: 'Support' },
  { value: 'Multiple carriers', text: 'Multiple carriers' },
  { value: 'Advanced settings', text: 'Advanced settings' },
  { value: 'Massive shipments', text: 'Massive shipments' },
  { value: 'Filters', text: 'Filters' },
  { value: 'Prepaid guides', text: 'Prepaid guides' },
  { value: 'Address book', text: 'Address book' },
  { value: 'Multiple users', text: 'Multiple users' },
  { value: 'Custom reports', text: 'Custom reports' }
]

export const INDUSTRIES: ISelectData[] = [
  { value: 'e_comerce', text: 'e_comerce' },
  { value: 'services', text: 'services' },
  { value: 'market_place', text: 'market_place' },
  { value: 'education', text: 'education' },
  { value: 'commerce', text: 'commerce' },
  { value: 'associations', text: 'associations' },
  { value: 'government', text: 'government' },
  { value: 'end_customer', text: 'end_customer' },
  { value: 'particular', text: 'particular' },
  { value: 'other', text: 'other' }
]

export const SOURCES: ISelectData[] = [
  { value: 'API', text: 'api' },
  { value: 'api_manual', text: 'api_manual' },
  { value: 'chat', text: 'chat' },
  { value: 'events', text: 'events' },
  { value: 'facebook', text: 'facebook' },
  { value: 'linkedin', text: 'linkedin' },
  { value: 'networking', text: 'networking' },
  { value: 'canvassing', text: 'canvassing' },
  { value: 'recommendation', text: 'recommendation' }
]

export const LEAD_CONTACT_STATUSES: ISelectData[] = [
  { value: 'Active', text: 'Active' },
  { value: 'Automation', text: 'Automation' },
  { value: 'Closed', text: 'Closed' },
  { value: 'Contacted', text: 'Contacted' },
  { value: 'Fraude', text: 'Fraude' },
  { value: 'Future contacto', text: 'Future contacto' },
  { value: 'Junk', text: 'Junk' },
  { value: 'Reactivation Potential', text: 'Reactivation Potential' },
  { value: 'Waiting for contact', text: 'Waiting for contact' }
]

export const LEAD_NOT_CONTACT_STATUSES: ISelectData[] = [
  { value: 'Active', text: 'Active' },
  { value: 'Automation', text: 'Automation' },
  { value: 'Fraude', text: 'Fraude' },
  { value: 'Future contacto', text: 'Future contacto' },
  { value: 'Junk', text: 'Junk' },
  { value: 'Waiting for contact', text: 'Waiting for contact' }
]

export const CATEGORY_STATUSES: ISelectData[] = [
  { value: 1, text: 'Prequalified' },
  { value: 2, text: 'Strategic (AAA)' },
  { value: 3, text: 'Strategic (AA)' },
  { value: 4, text: 'Strategic (A)' },
  { value: 5, text: 'Status customer' },
  { value: 6, text: 'Recurring (High)' },
  { value: 7, text: 'Recurring (Medium)' },
  { value: 8, text: 'Recurring (Low)' },
  { value: 9, text: 'Esporádico' },
  { value: 10, text: 'Posible esporádico' },
  { value: 11, text: 'Unknown' },
  { value: 12, text: 'SaaS' },
  { value: 13, text: 'Fraude' }
]

export const CORP_ACC_CONTACT_STATUSES: ISelectData[] = [
  { value: 'Active', text: 'Active' },
  { value: 'Fraude', text: 'Fraude' },
  { value: 'Junk', text: 'Junk' }
]

export const CLIENT_TYPES: ISelectData[] = [
  { value: 'Entrepreneur', text: 'Entrepreneur' },
  { value: 'Individual', text: 'Individual' },
  { value: 'SME', text: 'SME' },
  { value: 'Company', text: 'Company' }
]
