import ApiService from '@/common/api/api.service'
import { Packaging } from '@/models/packaging'
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer'
import { AbstractModelList } from '@/models/abstract-model-list'

export class PackagingsApiService {
  public static async search (params: Object): Promise<Packaging[]> {
    let { data } = await ApiService.query('packagings', { params: { ...params } })
    return data.models.map((packaging: any) => { return plainToClass(Packaging, packaging) } )
  }

  public static async get (slug: any): Promise<Packaging> {
    let { data } = await ApiService.get('packagings', slug)
    return plainToClass(Packaging, data.packaging)
  }

  public static async basicInfo (slug: any): Promise<Packaging> {
    let { data } = await ApiService.get(`packagings/${slug}/basic_info`)
    return plainToClass(Packaging, data)
  }

  public static async getAll (params: any): Promise<AbstractModelList<Packaging>> {
    if (params.sort === 'created_at') {
      params.sort = 'packagings.created_at'
    }
    let { data } = await ApiService.query('packagings', { params: { ...params } })
    return plainToClassFromExist(new AbstractModelList<Packaging>(Packaging), data)
  }

  public static async create (model: Packaging) {
    return ApiService.post('packagings', { 'packaging': classToPlain(model) })
  }

  public static async update (packaging: Packaging) {
    return ApiService.put(`packagings/${packaging.id}`, { 'packaging': classToPlain(packaging) })
  }

  public static async delete (model: Packaging) {
    return ApiService.delete(`packagings/${model.id}`)
  }

  public static async createDownloadJob (params: Object) {
    return ApiService.post('packagings/actions?actions=download', {
      ...params
    })
  }
}
