<i18n src="@/locales/select2.json"></i18n>
<template>
  <div :class="(submitted && !isValid) ? 'select2-multi-wrap test-dropdown is-invalid' : 'select2-multi-wrap test-dropdown'">
    <label ref="label" :class="{ disabled: disabled, active: isActive }">{{placeholder}}</label>
    <span class="caret" :class="{ disabled: disabled }">▼</span>
    <select class="form-control" :name="name" :disabled="disabled" :required="required"/>
    <div class="invalid-feedback">{{invalidFeedback}}</div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { mapGetters } from 'vuex'
  import Vue from 'vue'
  import { appConfig, getCharsLimit } from '@/constants/app-config'
  import { DomainService } from '@/common/domain.service'
  import { CONFIGURATION_KEYS } from '@/common/config'

  export default {
    name: 'BasicMultiSelect',
    data () {
      return {
        select2: null,
        newModel: null,
        ajaxOptions: {
          url: `${this.getDomain()}${this.url}`,
          xhrFields: { withCredentials: true },
          dataType: 'json',
          delay: appConfig.searchDelay,
          tags: true,
          data: (params) => {
            return {
              query: params.term,
              q: params.term,
              ...this.urlParams
            }
          },
          transport: ((params, success, failure) => {
            let $request = $.ajax(params)
            $request.then(success)
            $request.fail((failure) => {
              if (failure.status) {
                switch (failure.status) {
                  case 401:
                    // this.$router.push('/logout').catch(() => { /**/ })
                    break
                  default:
                    this.$modal.show({
                      type: 'danger',
                      centered: true,
                      headerText: 'Error',
                      bodyText: failure
                    })
                    break
                }
              }
            })
            return $request
          }),
          processResults: (data, params) => {
            return this.processResults(data, params)
          },
          cache: true
        }
      }
    },
    model: {
      event: 'change',
      prop: 'value'
    },
    props: {
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: () => []
      },
      allowClear: {
        type: Boolean,
        default: false
      },
      selectionAsString: {
        type: Boolean,
        default: false
      },
      valueAsText: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      settings: {
        type: Object,
        default: () => { /**/ }
      },
      urlParams: {
        type: Object,
        default: () => { /**/ }
      },
      value: null,
      url: {
        type: String,
        default: ''
      },
      invalidFeedback: {
        type: String,
        default: 'invalidFeedback'
      },
      submitted: {
        type: Boolean,
        default: false
      },
      isValid: {
        type: Boolean,
        default: true
      },
      validationClass: {
        type: String,
        default: ''
      },
      useLimit: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      isActive: function () {
        return !!this.value
      }
    },
    watch: {
      options (val) {
        this.setOption(val)
      },
      value (val) {
        this.setValue(val)
      }
    },
    methods: {
      getDomain () {
        let vueExpectedHost = DomainService.getHostFromURI(Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH))
        let vueActualHost = DomainService.getHostFromURI(window.location.href)
        let url = ''
        if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
          Vue.axios.defaults.withCredentials = true
          if (vueExpectedHost !== vueActualHost) {
            url = `https://${DomainService.getHostFromURI(window.location.href)}/api/v2/`
          } else {
            url = Vue.$getConst(CONFIGURATION_KEYS.API_URL)
          }
        } else {
          if (vueExpectedHost !== vueActualHost) {
            url = `https://${DomainService.getDomainWithoutPort(window.location.href)}:${Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN_PORT)}/api/v2/`
          } else {
            url = Vue.$getConst(CONFIGURATION_KEYS.API_URL)
          }
        }
        return url
      },
      setOption (val = []) {
        let settings = {
          ...this.settings
        }

        let popup = $(this.$el).parents('.modal')
        this.select2.empty()
        this.select2.select2({
          dropdownParent: (popup.length) ? popup : this.$parent.$el,
          ...settings,
          minimumInputLength: this.getMinInputLength(),
          placeholder: '',
          templateResult: this.formatResult,
          templateSelection: this.formatSelection,
          allowClear: this.allowClear,
          ajax: this.ajaxOptions,
          data: val,
          language: {
            noResults: () => {
              return this.$t('select2.no_results_found')
            },
            inputTooShort: () => {
              return this.$t('select2.input_too_short')
            },
            searching: () => {
              return this.$t('select2.searching')
            }
          }
        })
        this.setValue(this.value)
      },
      setValue (val) {
        if (val instanceof Array) {
          this.select2.val([...val])
        } else {
          this.select2.val([val])
        }
        this.$nextTick(() => {
          if (this.$refs.label) {
            if (this.select2.val() && this.select2.val().length > 0) {
              this.$refs.label?.classList.add(['active'])
            } else {
              if (!$('.select2-search__field').is(':focus')) {
                this.$refs.label?.classList.remove(['active'])
              }
            }
          }
        })
        this.select2.trigger('change')
      },
      getMinInputLength () {
        if (!this.useLimit) return false
        if (this.minimumInputLength) return this.minimumInputLength
        if (!this.$currentUser) return this.minimumInputLength
        return this.$who.isVendorAdmin(this.$currentUser) || this.$currentUser.role >= 90 ? getCharsLimit(this.$currentUser) : false
      }
    },
    mounted () {
      let self = this
      let settings = {
        ...this.settings,
        ajax: this.ajaxOptions
      }

      let popup = $(this.$el).parents('.modal')
      this.select2 = $(this.$el).find('select')
      this.$nextTick(() => {
        this.select2.select2({
          dropdownParent: (popup.length) ? popup : this.$parent.$el,
          ...settings,
          minimumInputLength: this.getMinInputLength(),
          placeholder: '',
          templateResult: this.formatResult,
          templateSelection: this.formatSelection,
          allowClear: this.allowClear,
          data: this.options,
          language: {
            noResults: (params) => {
              return this.$t('select2.no_results_found')
            },
            inputTooShort: () => {
              return this.$t('select2.input_too_short')
            },
            searching: () => {
              return this.$t('select2.searching')
            }
          }
        })
          .on('select2:select', async ev => {
            if (ev?.params?.data?.id) {
              this.$emit('addValue', ev.params.data.id)
            }
            this.$emit('change', this.select2.val())
          })
          .on('select2:unselect', ev => {
            if (ev?.params?.data?.id) {
              this.$emit('removeValue', ev.params.data.id)
            }
            this.$emit('change', this.select2.val())
          })
          .on('select2:open', e => {
            if (this.newModel) {
              $('.add-new-select-item').parent().remove()
              $('.select2-results').off('click', 'a')

              let button = $('.add-new-select-item')
              if (!button.length) {
                $('.select2-results').append(`<div style='width: 100%; padding-top: 5px; padding-bottom: 5px;'><a
                class='main-color add-new-select-item'
                >`+ self.newModel +`</a></div>`)

                $('.select2-results').on('click', 'a', function () {
                  self.createModel()
                })
              }
            }

            this.$refs.label?.classList.add(['active'])
          })
          .on('select2:close', e => {
            if (this.select2.val() && this.select2.val().length === 0) {
              this.$refs.label?.classList.remove(['active'])
            }
          })
          .on('select2:selecting', e => {
            if (e.params.args.data.link) {
              e.params.args.data.action()
              e.preventDefault()
            }
          })
        this.setValue(this.value)
      })
    },
    beforeDestroy () {
      this.$nextTick(() => {
        this.select2.select2('destroy')
      })
    }
  }
</script>

<style>

  .select-custom-dropdown .select2-results__option {
    color: #757575;
    font-family: 'Lato';
    font-size: 14.4px;
    transition: .15s;
  }

  .select-custom-dropdown .select2-results__option.select2-results__option--highlighted {
    box-shadow: none!important;
  }

  @media (min-width: 500px) {

    .select-custom-dropdown .select2-results__options {
      max-height: 350px!important;
    }
  }


  .select2-multi-wrap {
    margin-top: calc(1.75rem + 12px);
    margin-bottom: 0;
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  .select2-multi-wrap label {
    top: 6px;
    position: absolute;
    color: #757575;
  }

  .select2-multi-wrap label.active {
    margin-top: -15px;
    font-size: 0.8rem;
    z-index: 1;
  }

  .select-custom-dropdown .select2-results__option {
    color: #757575;
    font-family: 'Lato';
    font-size: 14.4px;
    transition: .15s;
  }

  .select-custom-dropdown .select2-results__option.select2-results__option--highlighted {
    box-shadow: none!important;
  }

  .select2-results .add-new-select-item {
    font-weight: bold;
    padding: 7px !important;
    font-size: 0.9rem;
  }

  @media (min-width: 500px) {

    .select-custom-dropdown .select2-results__options {
      max-height: 350px!important;
    }
  }

  >>> .select2-container--default.select2-container--disabled span {
    color: #757575;
  }

  .select2-multi-wrap label.disabled {
    color: #c3c3c3;
  }

  .caret.disabled  {
    color: #c3c3c3 !important;
  }

</style>

<style scoped>
  /*.select2-multi-wrap {*/
  /*  margin-top: calc(1.75rem + 12px);*/
  /*  margin-bottom: 0;*/
  /*  position: relative;*/
  /*  max-width: 100%;*/
  /*}*/

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-right: 4px;
  }

  .form-control + >>> .select2-container--default .select2-selection--multiple .select2-selection__arrow b {
    display: none;
  }

  >>> .select2-selection__rendered {
    margin-bottom: -6px!important;
  }

  .caret {
    position: absolute;
    bottom: 0.625rem;
    right: .5rem;
    font-size: .63rem!important;
    color: #757575;
    z-index: 1;
    cursor: pointer;
  }
  >>> .select2-selection__rendered {
    padding-left: 0!important;
  }

  .select2-multi-wrap.is-invalid .caret {
    position: absolute;
    bottom: 2rem;
    right: .5rem;
    font-size: .63rem!important;
    color: #757575;
    z-index: 1;
    cursor: pointer;
  }

  .select2-multi-wrap.is-invalid .invalid-feedback {
    display: inline-block;
  }

  .select2-multi-wrap.is-valid .invalid-feedback {
    display: none;
  }

  >>> .select2-container--default .select2-selection--multiple{
    outline: none!important;
    border-top:none!important;
    border-left:none!important;
    border-right:none!important;
    border-radius: 0!important;
    border-bottom: 1px solid #ced4da!important;
    padding-bottom: 4px!important;
    background: transparent !important;
    cursor: pointer;
  }

  >>> .select2-selection__choice__remove {
    right: 8px;
    color: red!important;
    position: absolute;
  }

  >>> .select2-selection__choice {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap !important;
    border: none !important;
    max-width: 90% !important;
    position: relative!important;
    background-color: #eceff1;
    border-radius: 16px !important;
    padding-right: 24px !important;
    padding-left: 10px !important;
    font-size: 13px !important;
    line-height: 28px;
    margin-top: 12px !important;
  }

  >>> .select2-results__option {
    color: #757575;
  }

  >>> .select2-container {
    width: 100% !important;
    z-index: 2;
  }

  >>> .select2-search__field {
    font-size: 0.9rem !important;
  }

  >>> .select2-selection__clear {
    margin-left: 2px !important;
    margin-top: 12px !important;
    margin-right: 16px!important;
    color: red!important;
  }
</style>
