<template>
  <a
          tabindex="0"
          href="#"
          :class="textClasses"
          @mouseover="active = true"
          @mouseout="active = false"
          @keydown="$listeners.keydown"
  >
    <slot name="suggestion" v-bind="{ data: data, htmlText: htmlText }">
      <span v-html="htmlText"></span>
    </slot>
  </a>
</template>

<script>
import VueBootstrapTypeaheadListItem from 'vue-bootstrap-typeahead/src/components/VueBootstrapTypeaheadListItem.vue'

export default {
  extends: VueBootstrapTypeaheadListItem
}
</script>
