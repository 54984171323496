import { Exclude, Expose, Transform } from 'class-transformer'

@Exclude()
export class CountryData {

  @Expose({ name: 'id' })
  public id: number | string | undefined

  @Expose({ name: 'country_code' })
  public countryCode: string = ''

  @Expose({ name: 'locale' })
  public locale: string = ''

  @Expose({ name: 'vat' })
  @Transform(value => value || 0.0, { toClassOnly: true })
  public vat: number = 0.0

  @Expose({ name: 'international_vat' })
  @Transform(value => value || 0.0, { toClassOnly: true })
  public internationalVat: number = 0.0

}
