<script>
import BasicSelect from '@/components/select-boxes/BasicSelect'

export default {
  name: 'ShipmentCatalogueSelectBox',
  extends: BasicSelect,
  methods: {
    processResults: (data, params) => {
      let options = []
      options.push(
        ...data.catalogues.map((el) => {
          return { id: el.key, text: `${el.value} (${el.key})`, model: el }
        })
      )

      return {
        results: options
      }
    },
    formatResult (option) {
      return option.text
    },
    formatSelection (option) {
      if (option.model) {
        this.$emit('getCurrentModel', option.model)
      }
      return option.text
    }
  }
}
</script>
