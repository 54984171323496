
  import { Component, Prop } from 'vue-property-decorator'
  import BasicComponent from '@/views/BasicComponent'
  import { AxiosResponse } from 'axios'
  import { plainToClass } from 'class-transformer'
  import { EnviayaAccountSelectItem } from '@/models/EnviayaAccountSelectItem'
  import { EnviayaAccountTS } from '@/models/EnviayaAccountTS'
  import AccountsApiService from '@/common/api/accounts.api.service'

  @Component
  export default class EnviayaAccountSearchBox extends BasicComponent {
    @Prop(Number) public result!: number | undefined
    @Prop(String) public placeholder!: string | ''
    @Prop(Number) public userId!: number | undefined
    @Prop(Boolean) public disabled!: boolean | undefined

    public enviayaAccountSearch: string = ''
    public enviayaAccounts: EnviayaAccountSelectItem[] = []

    public $refs!: {
      enviayaAccountSearch: HTMLFormElement
    }

    public async mounted () {
      let account: EnviayaAccountTS
      try {
        if (this.result) {
          account = await AccountsApiService.get(this.result)
          this.enviayaAccountSearch = `${(account.users.length > 0) ? account.users[0].firstName + ' ' + account.users[0].lastName : ''} (${account.account})`
        }
      } catch (e) {
        console.log({ message: `Can not get account with id: ${this.result}`, position: 'top right', timeOut: 5000 })
      } finally {
        this.unwatchAccessors.push(this.$watch('enviayaAccountSearch', this.onEnviayaAccountSearchChanged))
        this.unwatchAccessors.push(this.$watch('result', this.onResultChanged))
      }
    }

    // Searching Enviaya Accounts
    public getEnviayaAccounts (query: string) {
      this.$refs.enviayaAccountSearch.loading = true
      AccountsApiService.search(query).then((response: AxiosResponse) => {
        this.enviayaAccounts = []
        response.data.accounts.forEach((user: any) => {
          this.enviayaAccounts.push(plainToClass(EnviayaAccountSelectItem, user))
        })
        this.$refs.enviayaAccountSearch.loading = false
      }).catch((error) => {
        this.$modal.show({
          type: 'danger',
          centered: true,
          headerText: 'Error',
          bodyText: error
        })
      })
    }

    // Searching Enviaya Accounts by user
    public getEnviayaAccountsByUser (query: string) {
      this.$refs.enviayaAccountSearch.loading = true
      AccountsApiService.searchByUserId(query, this.userId!).then((response: AxiosResponse) => {
        this.enviayaAccounts = []
        response.data.accounts.forEach((user: any) => {
          this.enviayaAccounts.push(plainToClass(EnviayaAccountSelectItem, user))
        })
        this.$refs.enviayaAccountSearch.loading = false
      }).catch((error) => {
        this.$modal.show({
          type: 'danger',
          centered: true,
          headerText: 'Error',
          bodyText: error
        })
      })
    }

    public onEnviayaAccountSearchChanged (value: string) {
      if (!value.length) {
        this.enviayaAccounts = []
      }
      if (!value.length && this.result) {
        this.$emit('update', undefined)
      } else if (this.$refs.enviayaAccountSearch.isFocused && value.length > 3) {
        if (this.userId) {
          this.getEnviayaAccountsByUser(value)
        } else {
          this.getEnviayaAccounts(value)
        }
      }
    }

    public async onResultChanged (value: number) {
      if (!value) {
        this.enviayaAccountSearch = ''
      } else {
        let account = await AccountsApiService.get(this.result)
        this.enviayaAccountSearch = `${(account.users.length > 0) ? account.users[0].firstName + ' ' + account.users[0].lastName : ''} (${account.account})`
      }
    }
  }
