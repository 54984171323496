
import { Component, Prop } from 'vue-property-decorator'
import BasicComponent from '@/views/BasicComponent'

@Component
export default class EnviayaCheckbox extends BasicComponent {
  @Prop(String) public readonly id!: string
  @Prop({ default: '' }) public readonly label!: string
  @Prop({ default: '' }) public readonly tip: string | undefined
  @Prop({ default: false }) public readonly value!: boolean
  @Prop({ default: false }) public readonly invalidFeedback: string | boolean | undefined
  @Prop({ default: false }) public readonly customValidation: string | boolean | undefined
  @Prop({ default: () => { return { placement: 'top' } } }) public readonly tooltipOptions: object | undefined
  @Prop({ default: false }) public readonly disabled!: boolean

  public change (val: boolean) {
    if (!this.disabled) {
      this.$emit('input', val)
    }
  }
}
