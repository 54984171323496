import i18n from '@/i18n'
import { InsuranceOptions } from '@/models/corporate-account-ts'

export function useCorporateAccountInsuranceTypes(selectedValue: number[] | number | undefined | null) {
  return Object.values(InsuranceOptions)
    .map((value) => {
      return {
        text: i18n.t(`insurance.${value}`) as string,
        value: value,
        selected: isSelected(selectedValue, value)
      }
    })
}

export function useAccountInsuranceTypes(selectedValue: number[] | number | undefined | null) {
  return Object.values(InsuranceOptions)
    .filter(
      (value) => value !== InsuranceOptions.DONT_INSURE_SHIPMENTS)
    .map((status) => {
      return {
        text: i18n.t(`insurance.${status}`) as string,
        value: status,
        selected: isSelected(selectedValue, status)
      }
    })
}

function isSelected(selectedValue, currentValue) {
  return (
    !!selectedValue &&
    ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toString()).includes(currentValue.toString())) ||
      (!Array.isArray(selectedValue) && selectedValue.toString() === currentValue.toString()))
  )
}
