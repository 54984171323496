import { Vue } from 'vue-property-decorator'

export default class BasicComponent extends Vue {
  public unwatchAccessors: Function[] = []

  public beforeDestroy () {
    this.unwatchAccessors.forEach((unwatch) => {
      unwatch()
    })
  }
}
