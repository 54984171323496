import { Exclude, Expose, Transform } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'

@Exclude()
export class AccessPoint extends AbstractModel {
  @Expose({ name: 'brand' })
  @Transform(value => value || '', { toClassOnly: true })
  public brand: string = ''

  @Expose({ name: 'city' })
  @Transform(value => value || '', { toClassOnly: true })
  public city: string = ''

  @Expose({ name: 'country_code' })
  @Transform(value => value || '', { toClassOnly: true })
  public countryCode: string = ''

  @Expose({ name: 'direction_1' })
  @Transform(value => value || '', { toClassOnly: true })
  public direction1: string = ''

  @Expose({ name: 'direction_2' })
  @Transform(value => value || '', { toClassOnly: true })
  public direction2: string = ''

  @Expose({ name: 'district' })
  @Transform(value => value || '', { toClassOnly: true })
  public district: string = ''

  @Expose({ name: 'email' })
  @Transform(value => value || '', { toClassOnly: true })
  public email: string = ''

  @Expose({ name: 'latitude' })
  @Transform(value => value || 0, { toClassOnly: true })
  public latitude: number | null = 0

  @Expose({ name: 'longitude' })
  @Transform(value => value || 0, { toClassOnly: true })
  public longitude: number | null = 0

  @Expose({ name: 'neighborhood' })
  @Transform(value => value || '', { toClassOnly: true })
  public neighborhood: string = ''

  @Expose({ name: 'phone' })
  @Transform(value => value || '', { toClassOnly: true })
  public phone: string = ''

  @Expose({ name: 'postal_code' })
  @Transform(value => value || '', { toClassOnly: true })
  public postalCode: string = ''

  @Expose({ name: 'state_code' })
  @Transform(value => value || '', { toClassOnly: true })
  public stateCode: string = ''
}
