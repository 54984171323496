import Vue from 'vue'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import JwtService from '@/common/jwt.service'
import { NotFoundError } from '@/common/exceptions/not-found-error'
import { AppUser } from '@/models/AppUser'
import router from '@/router'
import { AVAILABLE_LANGUAGES } from '@/common/user.settings'
import { UnhandledError } from '@/common/exceptions/unhandled-error'
import { CONFIGURATION_KEYS } from '@/common/config'
import { AccessDeniedError } from '@/common/exceptions/access-denied-error'
// @ts-ignore
import localeData from '@/locales/popup'
import { DomainService } from '@/common/domain.service'
import { ValidationError } from '@/common/exceptions/validation-error'

export interface GraphQLResponse extends AxiosResponse<any> {
  data: {
    code: number,
    status: string,
    data?: Record<string, any>,
    errors?: {
      message: string
    }[]
  }
}

export default class GraphQLService {
  public static axios?: AxiosInstance

  public static headers?: Record<string, string> = {}

  public static init () {
    if (GraphQLService.axios) return

    let vueExpectedHost = DomainService.getHostFromURI(Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH))
    let vueActualHost = DomainService.getHostFromURI(window.location.href)
    let baseURL

    if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
      if (vueExpectedHost !== vueActualHost) {
        baseURL = `https://${DomainService.getHostFromURI(window.location.href)}/graphql`
      } else {
        baseURL = Vue.$getConst(CONFIGURATION_KEYS.GRAPHQL_API_URL)
      }
    } else {
      if (vueExpectedHost !== vueActualHost) {
        baseURL = `https://${DomainService.getDomainWithoutPort(window.location.href)}:${Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN_PORT)}/graphql`
      } else {
        baseURL = Vue.$getConst(CONFIGURATION_KEYS.GRAPHQL_API_URL)
      }
    }
    this.getHeaderConfig()
    GraphQLService.axios = axios.create({
      baseURL,
      headers: this.headers,
      withCredentials: true
    })
  }

  public static getHeaderConfig (): void {
    const headerConfig: Record<string, string> = {}
    headerConfig['Accept'] = `application/json`
    headerConfig['Content-Type'] = `application/json`
    const appUser: AppUser | null = JwtService.getUser()
    if (appUser && appUser.locale) {
      headerConfig['Accept-Language'] = `${appUser.locale}`
    } else {
      if (Vue.cookies.get('locale')) {
        headerConfig['Accept-Language'] = Vue.cookies.get('locale') as string
      } else if (window.navigator.language) {
        if (AVAILABLE_LANGUAGES.includes(window.navigator.language.split('-')[0])) {
          headerConfig['Accept-Language'] = window.navigator.language.split('-')[0]
        } else {
          headerConfig['Accept-Language'] = 'es'
        }
      }
    }
    this.headers = headerConfig
  }

  public static async query (queryStatement: string, variables: {}): Promise<GraphQLResponse | undefined> {
    try {
      if (!this.axios) {
        this.throwError('GraphQL axios instance has not initalized')
        return
      }

      return await this.axios.post('', {
        query: queryStatement,
        variables
      }, )
    } catch (error) {
      this.throwError(error)
    }
  }

  public static throwError (error: any) {
    if (!error || !error.response) {
      throw new UnhandledError(`[RWV] ApiService: Error. Server is not available during call ${error.config.baseURL}${error.config.url}`)
    }
    if (error.message === 'Network Error') {
      throw new UnhandledError(`[RWV] ApiService: Error. Server is not available during call ${error.config.baseURL}${error.config.url}`)
    }

    const appUser: AppUser | null = JwtService.getUser()
    const { id, email } = appUser!

    switch (error.response.status) {
      case 401:
        if (error.response.data?.errors?.error_code === 40101) {
          router.push('/mfa_code').catch(() => { /**/ })
        }
        // if (router.currentRoute.name !== 'Login' && router.currentRoute.name !== 'MfaCode') {
        //   router.push('/logout').catch(() => { /**/ })
        // }
        throw error
      case 422:
        if (error.response.data.errors && error.response.data.errors.detail) {
          let errors: string[] = []
          if (Array.isArray(error.response.data.errors.detail)) {
            errors.push(error.response.data.errors.detail)
          } else {
            errors.push(error.response.data)
          }
          throw new ValidationError(error.response, errors, error.response.data.errors.error_code, error.response.data)
        } else {
          throw error
        }
      case 404:
        let errors: string[] = []
        if (error.response.data.errors && error.response.data.errors.error_message) {
          errors.push(error.response.data.errors.error_message)
          throw new NotFoundError(`[RWV] ApiService ${error}`, errors)
        } else {
          throw error
        }
      case 403:
        const errMessage = `
          [RWV] ApiService ${error}
          currentUser(id: ${id}, email: ${email})
          route: ${router.currentRoute.fullPath}
        `
        throw new AccessDeniedError(errMessage)
      case 500:
        if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
          Vue.$rollbar.log(`Internal Server Error: ${error.config.baseURL}${error.config.url}`)
        }
        throw new UnhandledError(`[RWV] ApiService ${error}`)
      default:
        throw error
    }
  }
}
