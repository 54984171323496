import { AppUser } from '@/models/AppUser'
import { plainToClass } from 'class-transformer'
import { WhiteLabel } from '@/models/white-label'
// @ts-ignore
import Cookies from 'js-cookie'

const CURRENT_USER_KEY = 'current_user'
const CURRENT_WHITE_LABEL_KEY = 'white_label'

export default class JwtService {
  public static getUser (): AppUser | null {
    let userString: string | null = window.localStorage.getItem(CURRENT_USER_KEY)
    let userObject: Object | null = null
    try {
      userObject = JSON.parse(userString!)
      if (!Object.keys(userObject!).length) {
        return null
      } else {
        return plainToClass(AppUser, userObject)
      }
    } catch (e) {
      return null
    }
  }

  public static getWhiteLabel (): WhiteLabel | boolean {
    let wlString: string | null = window.localStorage.getItem(CURRENT_WHITE_LABEL_KEY)
    let wlObject: Object | null = null
    try {
      wlObject = JSON.parse(wlString!)
      if ((typeof wlObject) !== 'object' || !Object.keys(wlObject!).length) {
        return false
      } else {
        return plainToClass(WhiteLabel, wlObject)
      }
    } catch (e) {
      return false
    }
  }

  public static saveUser (user: object) {
    window.localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user))
  }

  public static saveWhiteLabel (whiteLabel: object | boolean) {
    window.localStorage.setItem(CURRENT_WHITE_LABEL_KEY, JSON.stringify(whiteLabel))
  }

  public static destroyUser () {
    window.localStorage.removeItem(CURRENT_USER_KEY)
  }

  public static destroyWhiteLabel () {
    window.localStorage.removeItem(CURRENT_WHITE_LABEL_KEY)
  }
}
