<script>
import BasicMultiSelectSelect from '@/components/select-boxes/BasicMultiSelect'
import $ from 'jquery'

export default {
  name: 'CategoriesSelectBox',
  extends: BasicMultiSelectSelect,

  props: {
    selectedCategories: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    processResults (data, params) {
      let options = []
      data.map((el) => {
        options.push(this.formatOption(el.name, el.name))
      })
      return {
        results: options
      }
    },
    formatOption (id, text) {
      return { id: id, text: `${text}` }
    },
    formatResult (option) {
      if (option.loading) {
        return option.text
      } else {
        return $.parseHTML(option.text)
      }
    },
    formatSelection (option) {
      if (option.loading) {
        return option.text
      } else {
        return $.parseHTML(option.text)
      }
    }
  },

  watch: {
    selectedCategories (models) {
      let options = []
      models.map((model) => {
        options.push(this.formatOption(models.name, model.name))
      })
      this.options.splice(0)
      this.options.push(...options)
      this.setValue(this.value)
    }
  },

  async mounted () {
    let options = []
    this.selectedCategories.map((model) => {
      options.push(this.formatOption(model.name, model.name))
    })

    this.$props.options.push(...options)
  }
}
</script>
