<script>
  import BasicMultiSelectSelect from '@/components/select-boxes/BasicMultiSelect'
  import $ from 'jquery'
  import WhiteLabelsApiService from '@/common/api/white-labels.api.service'
  import Vue from 'vue'

  export default {
    name: 'WhiteLabelMultiSelectBox',
    extends: BasicMultiSelectSelect,

    props: {
      selectedAccounts: {
        type: Array,
        default: () => []
      }
    },

    watch: {
      selectedAccounts (accounts) {
        this.options.splice(0)
        this.options.push(...accounts)
        this.setValue(this.value)
      }
    },

    methods: {
      processResults (data, params) {
        let options = []
        options.push(...data.map((el) => { return { id: el.id, text: `${ el.select_box_text }` } }))
        return {
          results: options
        }
      },
      formatResult (option) {
        if (option.loading) {
          return $.parseHTML('<span>' + option.text + '</span>')
        }
        return option.text
      }
    },

    async mounted () {
      let options = []
      try {
        if (this.value && this.value.length) {
          let { data } = await WhiteLabelsApiService.searchByIds(this.value)
          data.map(( account) => {
            options.push({ id: account.id, text: `${ account.select_box_text }` })
          })
        }
        this.options.splice(0)
        this.options.push(...options)

      } catch (e) {
        console.log(e)
      }
    }
  }
</script>
