export function setCookie (this: any, key: string, value: string | number, option: any = {}): any {
  if (!key) {
    throw new Error('cookie name is not find in first argument')
  } else if (/^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
    throw new Error('cookie key name illegality ,Cannot be set to [\'expires\',\'max-age\',\'path\',\'domain\',\'secure\']\tcurrent key name: ' + key)
  }
  // support json object
  if (value && value.constructor === Object) {
    value = JSON.stringify(value)
  }
  let _expires = '; max-age=86400' // temp value, default expire time for 1 day
  let expires = option.expires || this.DEFAULT_CONFIG.expires
  if (expires) {
    switch (expires.constructor) {
      case Number:
        if (expires === Infinity || expires === -1) {
          _expires = '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
        } else {
          _expires = '; max-age=' + expires
        }
        break
      case String:
        if (/^(?:\d{1,}(y|m|d|h|min|s))$/i.test(expires)) {
          // get capture number group
          let _expireTime = expires.replace(/^(\d{1,})(?:y|m|d|h|min|s)$/i, '$1')
          // get capture type group , to lower case
          switch (expires.replace(/^(?:\d{1,})(y|m|d|h|min|s)$/i, '$1').toLowerCase()) {
              // Frequency sorting
            case 'm':
              _expires = '; max-age=' + +_expireTime * 2592000
              break // 60 * 60 * 24 * 30
            case 'd':
              _expires = '; max-age=' + +_expireTime * 86400
              break // 60 * 60 * 24
            case 'h':
              _expires = '; max-age=' + +_expireTime * 3600
              break // 60 * 60
            case 'min':
              _expires = '; max-age=' + +_expireTime * 60
              break // 60
            case 's':
              _expires = '; max-age=' + _expireTime
              break
            case 'y':
              _expires = '; max-age=' + +_expireTime * 31104000
              break // 60 * 60 * 24 * 30 * 12
            default:
          }
        } else {
          _expires = '; expires=' + expires
        }
        break
      case Date:
        _expires = '; expires=' + expires.toUTCString()
        break
    }
  }
  document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value) + _expires + (option.domain ? '; SameSite=Lax; domain=' + option.domain : '') + (option.path ? '; path=' + option.path : this.DEFAULT_CONFIG.path) + (option.secure ? '; secure' : '')
  return this
}
