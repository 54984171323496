import { AbstractModel } from '@/models/abstract-model'
import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class CarrierServicesQuotingFilter extends AbstractModel {
  static get modelName () {
    return 'CarrierServicesQuotingFilter'
  }

  @Expose({ name: 'carrier_service_id' })
  public carrierServiceId: number | undefined

}
