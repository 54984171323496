
  import { Component, Prop } from 'vue-property-decorator'
  import BasicComponent from '@/views/BasicComponent'

  @Component
  export default class CurrenciesSelectBox extends BasicComponent {
    @Prop(String) public label!: string | 'Default label'
    @Prop(String) public classes!: string | ''
    @Prop(String) public result!: string | ''
    @Prop({ default: false }) public search!: boolean
    @Prop({ default: false }) public disabled!: boolean
    @Prop(String) public searchPlaceholder!: string | ''

    public currencies: any[] = []

    public async mounted () {
      this.fillSelectBox(this.result)
      this.unwatchAccessors.push(this.$watch('result', this.onModelChanged))
    }

    public fillSelectBox (value: string) {
      let currency = value

      if (!value) {
        if (!this.$currentUser) {
          currency = this.$currencyHelper.getCurrencyByTimeZone()
        } else {
          if (this.$currentUser.enviayaAccounts && this.$currentUser.enviayaAccounts.length === 1) {
            currency = this.$currentUser.enviayaAccounts[0].currency || this.$currentUser.currency
          } else {
            currency = this.$currentUser.currency
          }
        }
      }

      this.currencies = []
      this.$currencyHelper.currencyList().map((item: { currency: string, sign: string }, index: number) => {
        if(this.$currencyHelper.currencyList().indexOf(item) === index) {
          this.currencies.push({ text: `${item.currency} (${item.sign})`, value: item.currency, selected: (currency === item.currency) })
        }
      })
    }

    public updateValue (value: string) {
      this.$emit('update:result', value)
      this.$emit('changeCurrency', value)
    }

    public onModelChanged (value: string) {
      if (value) {
        this.fillSelectBox(value)
      }
    }
  }
