import i18n from '@/i18n'
import { PasswordSecurity } from '@/constants/corporate-account'

export function usePasswordSecurityOptions(selectedValue: number[] | number | undefined | null) {
  return Object.values(PasswordSecurity)
    .filter((value) => Number.isInteger(value as number))
    .map((value) => {
      return {
        text: i18n.t(`password_security_options.${value}`) as string,
        value: value,
        selected:
          ((Array.isArray(selectedValue) && selectedValue.map((val) => val).includes(Number(value))) ||
            (!Array.isArray(selectedValue) && !isNaN(selectedValue) && selectedValue === Number(value)))
      }
    })
}
