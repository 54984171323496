export enum SERVICE_TYPE {
  SERVICE_TYPE_DOMESTIC = 1,
  SERVICE_TYPE_INTERNATIONAL = 2
}

export enum SERVICE_ACTIONS {
  NEW_SERVICE = 'new',
  IMPORT_RATES = 'import_rates',
  DOWNLOAD_AS_CSV = 'download',
  EDIT = 'edit',
  COPY_SERVICE = 'copy',
  DELETE = 'delete'
}

export enum SERVICE_STATUSES {
  STATUS_ACTIVE = 1,
  STATUS_DISABLED = 2,
  STATUS_DELETED = 3
}

export enum SERVICE_FORM_TAB_HASH {
  GENERAL = 'general',
  ASSIGNATIONS = 'assignations',
  SETTING = 'setting'
}

export enum BOOKING_TYPES {
  BOOKING_TYPE_LABEL = 1,
  BOOKING_TYPE_REFERENCE_EY_ID_UNPAID = 2,
  BOOKING_TYPE_REFERENCE_UUID_UNPAID = 3,
  BOOKING_TYPE_REFERENCE_EY_ID_PAID = 4,
  BOOKING_TYPE_REFERENCE_UUID_PAID = 5,
  BOOKING_TYPE_QR_CODE_DIRECTIONS_DATA_UNPAID = 6,
  BOOKING_TYPE_QR_CODE_ALL_DATA_UNPAID = 7
}

export enum CONTROL_AMOUNT_CHANNELS {
  CONTROL_AMOUNT_CHANNEL_PERCENT = 1,
  CONTROL_AMOUNT_CHANNEL_FIXED = 2,
  CONTROL_AMOUNT_CHANNEL_API = 3
}
