
  import { Component, Prop } from 'vue-property-decorator'
  import BasicComponent from '@/views/BasicComponent'
  import {
    FILL_AND_OPEN_PAYMENT_PAGE, FILL_AND_PAY,
    FILL_AND_RATE,
    FILL_SHIPMENT_FORM_FIELDS,
    SHIPPING_ACTIONS
  } from '@/models/orders/shipment-template'

  @Component
  export default class QuickShippingActionsSelectBox extends BasicComponent {
    @Prop(String) public reference!: string | 'select'
    @Prop(String) public label!: string | 'Default label'
    @Prop(String) public classes!: string | ''
    @Prop(String) public result!: string

    @Prop(Boolean) public customValidation!: boolean
    @Prop(Boolean) public isValid!: boolean
    @Prop(Boolean) public disabled!: boolean
    @Prop(String) public invalidFeedback!: string

    public shippingActions: any = []

    public mounted () {
      this.fillSelectBox(this.result)
      this.unwatchAccessors.push(this.$watch('result', this.onModelChanged))
    }

    public updateValue (value: string) {
      this.$emit('update:result', value)
    }

    public fillSelectBox (selected: string) {
      this.shippingActions = [
        { text: this.$t(`shipment_template_form.quick_shipping_action_list.${SHIPPING_ACTIONS.get(FILL_SHIPMENT_FORM_FIELDS)}`), value: FILL_SHIPMENT_FORM_FIELDS, selected: selected === 'fill_shipment_form_fields' },
        { text: this.$t(`shipment_template_form.quick_shipping_action_list.${SHIPPING_ACTIONS.get(FILL_AND_RATE)}`), value: FILL_AND_RATE, selected: selected === 'fill_and_rate' },
        { text: this.$t(`shipment_template_form.quick_shipping_action_list.${SHIPPING_ACTIONS.get(FILL_AND_OPEN_PAYMENT_PAGE)}`), value: FILL_AND_OPEN_PAYMENT_PAGE, selected: selected === 'fill_and_open_payment_page' },
        { text: this.$t(`shipment_template_form.quick_shipping_action_list.${SHIPPING_ACTIONS.get(FILL_AND_PAY)}`), value: FILL_AND_PAY, selected: selected === 'fill_and_pay' }
      ]
    }

    public validate () {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs[this.reference].validate()
      })
    }

    public onModelChanged (value: string) {
      this.fillSelectBox(value)
    }
  }
