import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { CustomerInfo } from '@/models/customer-info'
import 'reflect-metadata'
import moment from 'moment'
import Vue from 'vue'
import { AbstractModel } from '@/models/abstract-model'
import { EnviayaAccountTS, ISelectData } from '@/models/EnviayaAccountTS'
import { CorporateAccountTs } from '@/models/corporate-account-ts'
import { WhiteLabel } from '@/models/white-label'
import { Group } from './Group'
import i18n from '@/i18n'
// @ts-ignore
import contactLocaleData from '@/locales/request_discount/contact_information_form.json'
// @ts-ignore
import regionLocaleData from '@/locales/user_settings/region_and_language.json'
// @ts-ignore
import securityLocaleData from '@/locales/user_settings/security.json'
import {
  isNotEmpty,
  IsNotEmpty, MaxLength, MinLength, Validate, ValidateIf,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator'
import { IsEmailValid } from '@/common/validators/email-validator'
import parsePhoneNumber from 'libphonenumber-js'

// TODO Rename this model after JS version of this model

export enum SERVICE_NAME_LAYOUTS {
  CARRIER = 1,
  DYNAMIC = 2,
  STANDARDIZED = 3
}

export const VERIFICATION_STATUSES = {
  UNVERIFIED: 1,
  VERIFIED: 2,
  FORCE_VERIFICATION: 3,
  TRUSTED: 4,
  FRAUD: 5,
  FRAUD_SUSPICION: 6,
  RELATED_TO_FRAUD_IP: 7,
  DEBTOR: 8
}

export const USER_SUBSCRIPTION_STATUSES: ISelectData[] = [
  { text: `subscribed`, value: 1, selected: false },
  { text: `unsubscribed`, value: 2, selected: false }
]

@ValidatorConstraint()
export class IsPhoneValid implements ValidatorConstraintInterface {
  public validate (value: string, args: ValidationArguments) {
    const countryCode = (args.object as EnviayaUserTS).country
    const phoneNumber = parsePhoneNumber(value, countryCode.toUpperCase())
    return value && phoneNumber && phoneNumber.isPossible()
  }
}

@ValidatorConstraint()
export class MatchPasswords implements ValidatorConstraintInterface {
  public validate (value: string, args: ValidationArguments) {
    return (args.object as EnviayaUserTS).password === value
  }
}

@Exclude()
export class EnviayaUserTS extends AbstractModel {
  constructor () {
    super()
    i18n.mergeLocaleMessage('en', contactLocaleData.en)
    i18n.mergeLocaleMessage('de', contactLocaleData.de)
    i18n.mergeLocaleMessage('es', contactLocaleData.es)

    i18n.mergeLocaleMessage('en', regionLocaleData.en)
    i18n.mergeLocaleMessage('de', regionLocaleData.de)
    i18n.mergeLocaleMessage('es', regionLocaleData.es)

    i18n.mergeLocaleMessage('en', securityLocaleData.en)
    i18n.mergeLocaleMessage('de', securityLocaleData.de)
    i18n.mergeLocaleMessage('es', securityLocaleData.es)
  }

  static get modelName () {
    return 'User'
  }

  public wasValidated: boolean = false

  /* Contact information */

  @IsNotEmpty({ message: () => i18n.t('errorMessages.blank_name').toString() })
  @MaxLength(30, { message: () => i18n.t('errorMessages.two_long', { field: i18n.t('fields.name'), length: 30 }).toString() })
  @Expose({ name: 'firstname' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public firstName: string = ''

  @IsNotEmpty({ message: () => i18n.t('errorMessages.blank_last_name').toString() })
  @MaxLength(30, { message: () => i18n.t('errorMessages.two_long', { field: i18n.t('fields.last_name'), length: 30 }).toString() })
  @Expose({ name: 'lastname' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public lastName: string = ''

  @Expose({ name: 'name' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public name: string = ''

  public get fullName () {
    return `${this.firstName} ${this.lastName}`
  }

  @IsNotEmpty({ message: () => i18n.t('errorMessages.blank_email').toString() })
  @Validate(IsEmailValid, {
    message: () => {
      return i18n.t('errorMessages.invalid_email').toString()
    }
  })
  @Expose({ name: 'email' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public email: string = ''

  @Expose({ name: 'company' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public company: string = ''

  @Validate(IsPhoneValid, {
    message: (args: ValidationArguments) => {
      const countryCode = (args.object as EnviayaUserTS).country
      const phoneLength: number = (countryCode === 'MX') ? 10 : 6
      return i18n.t('errorMessages.invalid_phone', { number: phoneLength }).toString()
    }
  })
  @Expose({ name: 'phone' })
  @Transform((value, obj) => {
    return (value) ? parsePhoneNumber(value, obj.phoneCountry || obj.countryCode).number : ''
  }, { toPlainOnly: true })
  @Transform((value, obj) => {
    let phone = parsePhoneNumber(value || '', obj.country_code || 'MX')
    return (!phone) ? '' : phone.nationalNumber
  }, { toClassOnly: true })
  public phone: string = ''

  public phoneCountry: string = ''

  @Expose({ name: 'vip_support_agent_id' })
  public vipSupportAgentId: number | undefined | null = null

  /* Contact information */

  /* Region information */

  @IsNotEmpty({ message: () => i18n.t('user_settings.region_and_language.validations.blank').toString() })
  @Expose({ name: 'country' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public country: string = ''

  @IsNotEmpty({ message: () => i18n.t('user_settings.region_and_language.validations.blank').toString() })
  @Expose({ name: 'locale' })
  @Transform(value => {
    return (!value || !value.length) ? 'en' : value.substring(0, 2)
  }, { toClassOnly: true })
  public locale: string | undefined | null = null

  @IsNotEmpty({ message: () => i18n.t('user_settings.region_and_language.validations.blank').toString() })
  @Expose({ name: 'time_zone' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public timezone: string = ''

  /* Region information */

  /* Settings information */

  @Expose({ name: 'label_format' })
  @Transform(value => { return (value === null) ? undefined : value }, { toClassOnly: true })
  public labelFormat: string = ''

  @Expose({ name: 'label_size' })
  @Transform(value => { return (value === null) ? undefined : value }, { toClassOnly: true })
  public labelSize: number | undefined

  @Expose({ name: 'file_format' })
  @Transform(value => { return (value === null) ? undefined : value }, { toClassOnly: true })
  public fileFormat: number | undefined

  @Expose({ name: 'archive_copy' })
  @Transform(value => { return (value && value === 1) ? true : false }, { toClassOnly: true })
  @Transform(value => { return (!!value) ? 1 : 0 }, { toPlainOnly: true })
  public archiveCopy: boolean | undefined

  @Expose({ name: 'status' })
  @Transform(value => { return (value === null) ? undefined : value }, { toClassOnly: true })
  public status: number | undefined

  @Expose({ name: 'service_name_layout' })
  public serviceNameLayout: number | undefined | null = null

  @Expose({ name: 'default_options', toClassOnly: true })
  @Transform(value => { return (!value) ? [] : value }, { toClassOnly: true })
  public defaultOptions: string[] = []

  public get defaultPickupBooking () {
    return (this.defaultOptions.includes('default_pickup_booking'))
  }

  public set defaultPickupBooking (value: boolean) {
    if (value) {
      this.defaultOptions.push('default_pickup_booking')
    } else {
      this.defaultOptions.splice(this.defaultOptions.indexOf('default_pickup_booking'), 1)
    }
  }

  public get defaultInvoiceRequest () {
    return (this.defaultOptions.includes('default_invoice_request'))
  }

  public set defaultInvoiceRequest (value: boolean) {
    if (value) {
      this.defaultOptions.push('default_invoice_request')
    } else {
      this.defaultOptions.splice(this.defaultOptions.indexOf('default_invoice_request'), 1)
    }
  }

  public get enableTestMode () {
    return (this.defaultOptions.includes('enable_test_mode'))
  }

  public set enableTestMode (value: boolean) {
    if (value) {
      this.defaultOptions.push('enable_test_mode')
    } else {
      this.defaultOptions.splice(this.defaultOptions.indexOf('enable_test_mode'), 1)
    }
  }

  @Expose({ name: 'default_pickup_booking' })
  public get _defaultPickupBooking () {
    return (this.defaultOptions.includes('default_pickup_booking')) ? 'default_pickup_booking' : ''
  }

  @Expose({ name: 'default_invoice_request' })
  public get _defaultInvoiceRequest () {
    return (this.defaultOptions.includes('default_invoice_request')) ? 'default_invoice_request' : ''
  }

  @Expose({ name: 'enable_test_mode' })
  public get _enableTestMode () {
    return (this.defaultOptions.includes('enable_test_mode')) ? 'enable_test_mode' : ''
  }

  /* Settings information */

  @Expose({ name: 'white_label_id' })
  public whiteLabelId: number | undefined | null = null

  @Expose({ name: 'white_label_admin_id' })
  public whiteLabelAdminId: number | undefined | null = null

  @Expose({ name: 'referrer_id' })
  public referrerId: number | undefined | null = null

  @Expose({ name: 'current_sales_executive_id' })
  public currentSalesExecutiveId: number | undefined

  @Expose({ name: 'white_label' })
  @Type(() => WhiteLabel)
  public whiteLabel: WhiteLabel | undefined

  @Expose({ name: 'not_confirmed_email' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public notConfirmedEmail: string = ''

  @Expose({ name: 'activation_token' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public activationToken: string = ''

  @Expose({ name: 'role' })
  public role: number | undefined | null = null

  @Expose({ name: 'true_role' })
  @Exclude({ toPlainOnly: true })
  public actualRole: number | undefined

  @Expose({ name: 'corporate_account_id' })
  public corporateAccountId: number | undefined | null = null

  @Expose({ name: 'corporate_account', toClassOnly: true })
  @Type(() => CorporateAccountTs)
  public corporateAccount: CorporateAccountTs | undefined

  @Expose({ name: 'referrer_ids' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public referrerIds: number[] = []

  @Expose({ name: 'zoho_contact_id' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public zohoContactId: string = ''

  @Expose({ name: 'vouchery_id' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public voucheryId: string = ''

  @Expose({ name: 'customer_info' })
  @Type(() => CustomerInfo)
  public customerInfo: CustomerInfo | undefined

  @Expose({ name: 'enable_third_party_providers' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public enableThirdPartyProviders: boolean = false

  @Expose({ name: 'favorite_carrier_id' })
  public favoriteCarrierId: number | undefined | null = null

  /* Security information */

  @Expose({ name: 'two_factor_authenticated' })
  public twoFactorAuthenticated: boolean = false

  @Expose({ name: 'two_factor_authentication' })
  public twoFactorAuthentication: boolean = false

  @Expose({ name: 'allow_become' })
  public allowBecome: boolean = false

  @Expose({ name: 'admin_user_id' })
  @Transform(value => Number(value) || null, { toClassOnly: true })
  public adminUserId: number | null = null

  @Expose({ name: 'custom_carriers_enabled' })
  public customCarriersEnabled: boolean = false

  @Expose({ name: 'custom_carrier_services_enabled' })
  public customCarrierServicesEnabled: boolean = false

  @ValidateIf(o => isNotEmpty(o.password))
  @MinLength(6, { message: () => i18n.t('user_settings.security.error_messages.password_too_short').toString() })
  public password: string = ''

  @ValidateIf(o => isNotEmpty(o.password))
  @IsNotEmpty({ message: () => i18n.t('user_settings.security.error_messages.blank_confirmation_password').toString() })
  @Validate(MatchPasswords, { message: () => i18n.t('user_settings.security.error_messages.invalid_confirmation_password').toString() })
  public confirmPassword: string = ''

  @Expose({ name: 'password_missing', toClassOnly: true })
  public passwordMissing: boolean = false

  @Expose({ name: 'permissions' })
  @Exclude({ toPlainOnly: true })
  public permissions: any | undefined

  /* Security information */

  @Expose({ name: 'created_at' })
  @Transform(value => {
    if (value) {
      return `${moment(value, 'YYYY-MM-DD HH:mm').format(Vue.moment.localeData().longDateFormat('L'))} ${moment(value, 'YYYY-MM-DD HH:mm').format(Vue.moment.localeData().longDateFormat('LT'))} `
    } else {
      return ''
    }
  }, { toClassOnly: true })
  public createdAt: string[] = []

  @Expose({ name: 'last_activity_at' })
  @Transform(value => {
    if (value) {
      return `${moment(value, 'YYYY-MM-DD HH:mm').format(Vue.moment.localeData().longDateFormat('L'))} ${moment(value, 'YYYY-MM-DD HH:mm').format(Vue.moment.localeData().longDateFormat('LT'))} `
    } else {
      return ''
    }
  }, { toClassOnly: true })
  public lastActivityAt: string[] = []

  @Expose({ name: 'last_activity_from_ip_address' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public lastActivityFromIpAddress: string = ''

  @Expose({ name: 'last_shipment_date' })
  @Transform(value => {
    if (value) {
      return `${moment(value, 'YYYY-MM-DD').format(Vue.moment.localeData().longDateFormat('L'))} `
    } else {
      return ''
    }
  }, { toClassOnly: true })
  public lastShipmentDate: string = ''

  @Expose({ name: 'zoho_lead_id' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public zohoLeadId: string = ''

  @Expose({ name: 'zoho_account_id' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public zohoAccountId: string = ''

  @Expose({ name: 'verification_status' })
  public verifiedUser: number | null = null

  @Expose({ name: 'whitelisted' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public whitelisted: boolean = true

  @Expose({ name: 'origin_excess' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public originExcess: boolean = true

  @Expose({ name: 'subscription_status' })
  public subscriptionStatus: number | undefined

  @Expose({ name: 'enviaya_accounts' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  @Type(() => EnviayaAccountTS)
  public enviayaAccounts: EnviayaAccountTS[] = []

  @Expose({ name: 'currency' })
  public currency: string = ''

  @Expose({ name: 'group' })
  @Type(() => Group)
  public group?: Group

  @Expose({ name: 'api_version' })
  @Transform(value => { return (value === '') ? null : value }, { toPlainOnly: true })
  public apiVersion: string | null = null

  @Expose({ name: 'signature' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public signature: string = ''

  @Expose({ name: 'carrier_account_types' })
  @Transform(value => value ?? 1, { toClassOnly: true })
  public carrierAccountTypes: number = 1

  public get allAccountNames () {
    return this.enviayaAccounts.map((account: EnviayaAccountTS) => account.account).join(', ')
  }

  // For abilities
  public get corporate_account_id () {
    return this.corporateAccountId
  }

  public get white_label () {
    return this.whiteLabel
  }

  public get white_label_id () {
    return this.whiteLabelId
  }

  public get isWhiteLabelUser () {
    return !!this.whiteLabelId
  }

  public get isWhiteLabelAdmin () {
    return !!this.corporateAccount && this.corporateAccount.resellerWhiteLabel && !!this.corporateAccount.resellerWhiteLabel.id
  }
}
