import { Expose, Transform, Type } from 'class-transformer'
import { RawRule } from '@casl/ability'
import { WhiteLabel } from '@/models/white-label'
// @ts-ignore
import { UserRoles } from '@/common/user.roles.js'
import { CombinedAddress } from '@/models/combined-address'
import { Saldo, SALDO_TYPES } from '@/models/saldo'
import { CorporateAccountTs } from '@/models/corporate-account-ts'
import { EnviayaAccountTS } from '@/models/EnviayaAccountTS'
import moment from 'moment'
import Vue from 'vue'
import { AuthModule } from '@/store/auth.module'
import { ScopeTypes } from '@/constants/carrier-account'

export class AppUser {
  static get modelName () {
    return 'User'
  }

  @Expose({ name: 'id' })
  public id: string = ''

  @Expose({ name: 'uuid' })
  @Transform(value => value || '', { toClassOnly: true })
  public uuid: string = ''

  @Expose({ name: 'firstname' })
  @Transform(value => value || '', { toClassOnly: true })
  public firstName: string = ''

  @Expose({ name: 'lastname' })
  @Transform(value => value || '', { toClassOnly: true })
  public lastName: string = ''

  @Expose({ name: 'email' })
  @Transform(value => value || '', { toClassOnly: true })
  public email: string = ''

  @Expose({ name: 'vip_support_email' })
  @Transform(value => value || '', { toClassOnly: true })
  public vipSupportEmail: string = ''

  @Expose({ name: 'currency' })
  @Transform(value => value || '', { toClassOnly: true })
  public currency: string = ''

  @Expose({ name: 'country_code' })
  @Transform(value => value || '', { toClassOnly: true })
  public countryCode: string = ''

  @Expose({ name: 'company' })
  @Transform(value => value || '', { toClassOnly: true })
  public company: string = ''

  @Expose({ name: 'white_label_id' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public whiteLabelId: number | undefined

  @Expose({ name: 'white_label_admin_id' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public whiteLabelAdminId: number | undefined

  @Expose({ name: 'locale' })
  @Transform(value => value || '', { toClassOnly: true })
  public locale: string = ''

  @Expose({ name: 'time_zone' })
  @Transform(value => value || '', { toClassOnly: true })
  public timezone: string = ''

  @Expose({ name: 'created_at' })
  public createdAt?: number

  @Expose({ name: 'phone' })
  @Transform(value => value || '', { toClassOnly: true })
  public phone: string = ''

  @Expose({ name: 'reseller_id' })
  public resellerId?: number

  @Expose({ name: 'is_reseller' })
  public isReseller: boolean = false

  @Expose({ name: 'enable_third_party_providers' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public enableThirdPartyProviders: boolean = false

  @Expose({ name: 'password_missing' })
  public passwordMissing: boolean = false

  @Expose({ name: 'corporate_account_id' })
  public corporateAccountId?: number

  @Expose({ name: 'corporate_account', toClassOnly: true })
  @Type(() => CorporateAccountTs)
  public corporateAccount: CorporateAccountTs | undefined | null = null

  @Expose({ name: 'role' })
  public role?: number

  @Expose({ name: 'allow_become' })
  public allowBecome: boolean = false

  @Expose({ name: 'admin_user_id' })
  @Transform(value => Number(value) || null, { toClassOnly: true })
  public adminUserId: number | null = null

  @Expose({ name: 'shops_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public shopsCount: number = 0

  @Expose({ name: 'white_label_ids' })
  public whiteLabelIds: number[] = []

  @Expose({ name: 'white_label', toClassOnly: true })
  @Type(() => WhiteLabel)
  public whiteLabel: WhiteLabel | undefined

  @Expose({ name: 'abilities' })
  public abilities: RawRule[] = []

  @Expose({ name: 'options' })
  @Transform(value => value || [], { toClassOnly: true })
  public options: string[] = []

  @Expose({ name: 'two_factor_authenticated' })
  @Transform(value => { return (value === null || value === undefined) ? true : value }, { toClassOnly: true })
  public twoFactorAuthenticated: boolean = false

  @Expose({ name: 'two_factor_authentication' })
  public twoFactorAuthentication: boolean = false

  @Expose({ name: 'default_accept_pickup_conditions' })
  public defaultAcceptPickupConditions: boolean = false

  @Expose({ name: 'discount_profile' })
  public discountProfile: { id: number, name: string, level: number, pickup_availability: number } | undefined

  @Expose({ name: 'default_origin' })
  @Type(() => CombinedAddress)
  public defaultOrigin: CombinedAddress | undefined

  @Expose({ name: 'help_desk_link' })
  @Transform(value => value || '', { toClassOnly: true })
  public helpDeskLink: string = ''

  @Expose({ name: 'shipments_per_month' })
  @Transform(value => value || '', { toClassOnly: true })
  public shipmentsPerMonth: string = ''

  @Expose({ name: 'est_monthly_sales_volume' })
  public estMonthlySalesVolume: number | null = null

  @Expose({ name: 'est_monthly_sales_volume_currency' })
  @Transform((value, obj) => {
    return value || obj.currency
  }, { toClassOnly: true })
  public estMonthlySalesVolumeCurrency: string = ''

  public isWhiteLabelAdmin () {
    return (this.whiteLabelIds && this.whiteLabelIds.length > 0) // && this.role === UserRoles.USER_ROLE_USER
  }

  @Expose({ name: 'saldo' })
  @Transform(value => value || [], { toClassOnly: true })
  @Type(() => Saldo)
  public saldo: Saldo[] = []

  @Expose({ name: 'enviaya_accounts' })
  @Type(() => EnviayaAccountTS)
  public enviayaAccounts: EnviayaAccountTS[] = []

  @Expose({ name: 'favorite_carrier_id' })
  public favoriteCarrierId: number | undefined | null = null

  // user registration month and year mmyy, for example 0222
  public get rmy () {
    return moment.utc(this.createdAt! * 1000).format('MMYY')
  }

  // days since registration
  public get dsr () {
    const currentDate = Vue.moment().toDate()
    const registeredDate = Vue.moment(AuthModule.currentUser!.createdAt! * 1000).toDate()
    const daysCount = Vue.moment(currentDate).diff(Vue.moment(registeredDate), 'days')
    if (daysCount <= 30) {
      return daysCount
    } else if (daysCount >= 31 && daysCount <= 45) {
      return 45
    } else if (daysCount >= 46 && daysCount <= 60) {
      return 60
    } else if (daysCount >= 61 && daysCount <= 90) {
      return 90
    } else if (daysCount >= 91 && daysCount <= 180) {
      return 180
    } else {
      return 365
    }
  }

  public get creditSaldo () {
    return this.saldo.find(item => item.orderType === SALDO_TYPES.CREDIT && item.amount !== 0)
  }

  public get nationalSaldo () {
    return this.saldo.find(item => item.orderType === SALDO_TYPES.NATIONAL && item.amount !== 0)
  }

  public get internationalSaldo () {
    return this.saldo.find(item => item.orderType === SALDO_TYPES.INTERNATIONAL && item.amount !== 0)
  }

  @Expose({ name: 'last_shipment_date' })
  @Transform(value => {
    if (value) {
      return `${moment(value, 'YYYY-MM-DD').format(moment.localeData().longDateFormat('L'))}`
    } else {
      return ''
    }
  }, { toClassOnly: true })
  public lastShipmentDate: string = ''

  @Expose({ name: 'adminable_white_labels' })
  @Type(() => WhiteLabel)
  public adminableWhitelabels: WhiteLabel[] = []

  @Expose({ name: 'carrier_account_types' })
  @Transform(value => value ?? ScopeTypes.HYBRID, { toClassOnly: true })
  public carrierAccountTypes: number = ScopeTypes.HYBRID

  @Expose({ name: 'has_own_carrier_accounts' })
  @Transform(value => value ?? false, { toClassOnly: true })
  public hasOwnCarrierAccounts: boolean = false

// For abilities
  public get corporate_account_id () {
    return this.corporateAccountId
  }

  public get white_label () {
    return this.whiteLabel
  }

  public get white_label_id () {
    return this.whiteLabelId
  }
}
