<script>
import BasicSelect from '@/components/select-boxes/BasicSelect'

export default {
  name: 'QuickSearchSelectBox',
  extends: BasicSelect,

  methods: {
    processResults: function (data, params) {
      let options = []
      options.push(...data.map((el) => { return { id: el.id, text: `${ el.select_box_text }` } }))
      return {
        results: options
      }
    },
    formatResult (option) {
      return option.text
    },
    formatSelection (option) {
      return option.text
    }
  }
}
</script>
