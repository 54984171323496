import ApiService from '@/common/api/api.service'
import { AxiosResponse } from 'axios'
import AccountsApiService from './accounts.api.service'
import GraphQLService from './graphql.service'
import QuotingFilterApiService from './quoting-filter.api.service'

export default class WhiteLabelsApiService {
  public static getClientWhiteLabel (): Promise<AxiosResponse> {
    return ApiService.get('client_white_label')
  }

  public static get (slug: number): Promise<AxiosResponse> {
    return ApiService.get('white_labels', String(slug))
  }

  public static searchByIds (ids: number): Promise<AxiosResponse> {
    return ApiService.query('white_labels/search', {
      params: {
        white_label_ids: ids
      }
    })
  }

  public static getCarrierAccountsAndQuotingFilter (id: number | string) {
    return GraphQLService.query(
      `query whiteLabel($id: ID!) {
        whiteLabel(id: $id) {
          quotingFilter {
            ${QuotingFilterApiService.quotingFilterDataStrucGraphql()}
          }
        }
      }`, {
        id
      }
    )
  }

  public static searchBrandAccountsGraphql (searchQuery: string) {
    return GraphQLService.query(
      `query searchBrandAccounts($searchQuery: String) {
        brandAccountsConnection:whiteLabelsConnection(searchQuery: $searchQuery) {
          ${AccountsApiService.connectionStructGraphql(AccountsApiService.accountStructGraphQL)}
        }
      }	`, {
        searchQuery
      }
    )
  }

}
