import { Expose, Exclude } from 'class-transformer'

export class CorporateAccountSelectItem {
  @Expose({ name: 'id' })
  public id: number | undefined

  @Expose({ name: 'select_box_text' })
  public selectBoxText: string = ''

  @Exclude()
  public status: number | undefined

  @Exclude()
  public accountType: number | undefined
}
