import i18n from '@/i18n'
import { CLEARING_CHARGE_CODES } from '@/constants/clearing-charge'

export function useClearingChargeCodes(selectedValue: string[] | string | undefined | null) {
  return Object.values(CLEARING_CHARGE_CODES).map((code) => {
    return {
      text: `${i18n.t(`properties.clearing_charge_code.${code.toLowerCase()}`)} (${code})`,
      value: code,
      selected:
        !!selectedValue &&
        ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toLowerCase()).includes(code.toLowerCase())) ||
          !Array.isArray(selectedValue) && selectedValue.toLowerCase() === code.toLowerCase())
    }
  })
}
