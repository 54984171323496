export class NotFoundError extends Error {
  public errors: string[] = []

  constructor (message: string, errors: string[]) {
    super(message)
    this.name = 'NotFoundError'
    this.errors = errors
    this.stack = (new Error()).stack
  }
}
