import { AbstractModel } from '@/models/abstract-model'
import { Exclude, Expose, Transform } from 'class-transformer'
import { numToTime, timeToNum } from '@/models/quoting-filter'

@Exclude()
export class CutOffConfiguration extends AbstractModel {
  static get modelName () {
    return 'CutOffConfiguration'
  }

  @Expose({ name: 'carrier_id' })
  public carrierId: number | undefined

  @Expose({ name: 'enviaya_account_id' })
  public enviayaAccountId: number | undefined

  @Expose({ name: 'same_day_cut_off_time' })
  @Transform(value => { return (!value) ? '' : value })
  public sameDayCutOffTime: string = ''

  @Expose({ name: 'next_day_cut_off_time' })
  @Transform(value => { return (!value) ? '' : value })
  public nextDayCutOffTime: string = ''

  @Expose({ name: 'global_cut_off_time' })
  @Transform(value => { return (!value) ? '' : value })
  public globalCutOffTime: string = ''

  @Expose({ name: 'same_day_additional_hours' })
  @Transform(value => {
    return (value === null) ? '24:00' : numToTime(value)
  }, { toClassOnly: true })
  @Transform(value => {
    return !value ? '24:00' : timeToNum(value)
  }, { toPlainOnly: true })
  public sameDayAdditionalHours: string = '24:00'

  @Expose({ name: 'next_day_additional_hours' })
  @Transform(value => {
    return (value === null) ? '24:00' : numToTime(value)
  }, { toClassOnly: true })
  @Transform(value => {
    return !value ? '24:00' : timeToNum(value)
  }, { toPlainOnly: true })
  public nextDayAdditionalHours: string = '24:00'

  @Expose({ name: 'global_additional_hours' })
  @Transform(value => {
    return (value === null) ? '24:00' : numToTime(value)
  }, { toClassOnly: true })
  @Transform(value => {
    return !value ? '24:00' : timeToNum(value)
  }, { toPlainOnly: true })
  public globalAdditionalHours: string = '24:00'
}
