import { Expose, Transform, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import { EnviayaUserTS } from './EnviayaUserTS'
import moment from 'moment'

export class Audit extends AbstractModel {
  @Expose({ name: 'remote_address' })
  public remoteAddress: string | undefined

  @Expose({ name: 'user' })
  @Type(() => EnviayaUserTS)
  public user: EnviayaUserTS | undefined

  @Expose({ name: 'audited_changes' })
  public auditedChanges: Record<string, any> | undefined

  @Expose({ name: 'humanized_audited_changes' })
  public humanizedAuditedChanges: Record<string, any> | undefined

  @Expose({ name: 'comment' })
  public comment: string | undefined

  @Expose({ name: 'associated_id' })
  public associatedId: string | undefined

  @Expose({ name: 'associated_type' })
  public associatedType: string | undefined

  @Expose({ name: 'created_at' })
  @Transform(value => {
    if (moment.utc(value).isValid()) {
      return moment.utc(value)
    } else return null
  })
  public createdAt: moment.Moment | undefined

  public auditedChangeMessages: Record<string, string> = {}
}
