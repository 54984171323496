import { AbstractModel } from '@/models/abstract-model'
import { Exclude, Expose, Transform } from 'class-transformer'
import moment from 'moment'

@Exclude()
export class ShipmentEvent extends AbstractModel {
  @Expose({ name: 'original_timestamp' })
  @Transform(value => {
    if (!value) return null

    return moment(value).isValid() ? moment(value) : null
  }, { toClassOnly: true })
  public originalTimestamp: moment.Moment | null = null
}
