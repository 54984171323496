import i18n from '@/i18n'
import { ACCOUNTING_STATUSES } from '@/constants/clearing-charge'

export function useAccountingStatuses(selectedValue: number[] | number | undefined | null) {
  return Object.values(ACCOUNTING_STATUSES)
    .filter((value) => Number.isInteger(value as number))
    .map((status) => {
      return {
        text: i18n.t(`accounting_status.status_${status}`) as string,
        value: status,
        selected:
          !!selectedValue &&
          ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toString()).includes(status.toString())) ||
            (!Array.isArray(selectedValue) && selectedValue.toString() === status.toString()))
      }
    })
    .sort((a, b) => a.text.localeCompare(b.text))
}
