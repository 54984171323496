import i18n from '@/i18n'
import { VERIFICATION_STATUSES } from '@/models/EnviayaUserTS'
import { UserRoles, WHO } from '@/common/user.roles'
import { AuthModule } from '@/store/auth.module'
import pickBy from 'lodash/pickBy'

export function useVerificationStatuses(selectedValue: number[] | number | undefined | null) {
  return Object.values(VERIFICATION_STATUSES)
    .filter((value) => Number.isInteger(value as number) && value !== VERIFICATION_STATUSES.DEBTOR)
    .map((status) => {
      return {
        text: i18n.t(`verification_statuses.${status}`) as string,
        value: status,
        selected: isSelected(selectedValue, status),
        disabled: isDisabledStatus(AuthModule.currentUser, status)
      }
    })
}

export function useCorporateVerificationStatuses(selectedValue: number[] | number | undefined | null) {
  const availableStatuses = WHO.isVerifiedStatusViewer(AuthModule.currentUser.role)
    ? VERIFICATION_STATUSES
    : pickBy(
        VERIFICATION_STATUSES,
        (value, key) =>
          ['FORCE_VERIFICATION', 'FRAUD', 'FRAUD_SUSPICION'].includes(key) ||
          (!!selectedValue &&
            ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toString()).includes(value.toString())) ||
              (!Array.isArray(selectedValue) && selectedValue.toString() === value.toString())))
      )
  return Object.values(availableStatuses)
    .map((value) => {
      return {
        text: i18n.t(`verification_statuses.${value}`) as string,
        value: value,
        selected: isSelected(selectedValue, value),
      }
    })
}

function isDisabledStatus(user, status) {
  if (
    [
      UserRoles.USER_ROLE_CUSTOMER_SERVICE,
      UserRoles.USER_ROLE_SALES_EXECUTIVE,
      UserRoles.USER_ROLE_CUSTOMER_SERVICE_MANAGER
    ].includes(user.role) &&
    ![VERIFICATION_STATUSES.FRAUD, VERIFICATION_STATUSES.FRAUD_SUSPICION].includes(Number(status))
  ) {
    return true
  } else if (WHO.isWhiteLabelAdmin(user) && ![VERIFICATION_STATUSES.FRAUD].includes(Number(status))) {
    return true
  }
  return false
}

function isSelected(selectedValue, currentValue) {
  return (
    !!selectedValue &&
    ((Array.isArray(selectedValue) &&
        selectedValue.map((val) => val.toString()).includes(currentValue.toString())) ||
      (!Array.isArray(selectedValue) &&
        selectedValue.toString() === currentValue.toString()))
  )
}
