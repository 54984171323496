import { AbstractModel } from '@/models/abstract-model'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { CarrierTS } from './CarrierTS'
import { CorporateAccountTs } from './corporate-account-ts'
import { CountryData } from './country-data'
import { DiscountProfile } from './discount-profile'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { ISelectItem } from '@/models/SelectBoxItem'
import { CarrierService } from '@/models/carrier-service'
import { Carrier } from './carrier'

export const CARRIER_ACCOUNT_OWNER = {
  EY: 'enviaya',
  OWN: 'user'
}

export const CARRIER_ACCOUNT_TYPE = {
  BOOKING: 'booking',
  RATING: 'quoting',
  TRACKING: 'tracking',
  PICKUP: 'pickup'
}

export const CARRIER_ACCOUNT_USAGE_TYPE = {
  TEST: 'test',
  LIVE: 'live'
}

export const BOOKING_METHODS = {
  WITH_PROVIDER_QUOTE: 0,
  WITHOUT_PROVIDER_QUOTE: 1
}

export const NO_PREPAID_CONFIGURATIONS = 0

export const USER_COUNTRY_CODE = 'user'

export const USER_CURRENCY = 'user'

@Exclude()
export class CarrierAccount extends AbstractModel {

  static get modelName () {
    return 'CarrierAccount'
  }

  @Expose({ name: 'alias_name' })
  public aliasName: string = ''

  @Expose({ name: 'formatted_carrier_name' })
  public formattedCarrierName: string = ''

  @Expose({ name: 'account_number' })
  public accountNumber: string = ''

  @Expose({ name: 'carrier_id' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public carrierId: number | undefined

  @Expose({ name: 'select_box_text' })
  public selectBoxText: string = ''

  @Expose({ name: 'carrier' })
  @Transform(value => value || undefined)
  public carrier: CarrierTS | undefined

  @Expose({ name: 'is_enviaya_account' })
  @Transform(value => { return (!value) ? false : value })
  public isEnviayaAccount: boolean = false

  @Expose({ name: 'user_id' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public userId: number | null = null

  @Expose({ name: 'user_ids' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public userIds: number[] = []

  @Expose({ name: 'corporate_account_id' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public corporateAccountId: number | null | undefined

  @Expose({ name: 'corporate_account', toClassOnly: true })
  @Type(() => CorporateAccountTs)
  public corporateAccount: CorporateAccountTs | undefined | null = null

  @Expose({ name: 'corporate_account_ids' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public corporateAccountIds: number[] = []

  @Expose({ name: 'discount_profile_ids' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public discountProfileIds: number[] = []

  @Expose({ name: 'corporate_accounts', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => CorporateAccountTs)
  public corporateAccounts: CorporateAccountTs[] = []

  @Expose({ name: 'users', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => EnviayaUserTS)
  public users: EnviayaUserTS[] = []

  @Expose({ name: 'discount_profiles', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => DiscountProfile)
  public discountProfiles: DiscountProfile[] = []

  @Expose({ name: 'status' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public status: number | undefined

  @Expose({ name: 'assign_to_speicific_account' })
  @Transform(value => { return (!value) ? false : value })
  public assignToSpeicificAccount: boolean = false

  @Expose({ name: 'service_codes' })
  @Transform(value => value || [], { toClassOnly: true })
  public serviceCodes: string[] = []

  @Expose({ name: 'carrier_service_ids' })
  @Transform(value => value || [], { toClassOnly: true })
  public carrierServiceIds: number[] = []

  @Expose({ name: 'coordinates_handling' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public coordinatesHandling: number | null = null

  @Expose({ name: 'cache_level' })
  @Transform(value => value ?? 0, { toClassOnly: true })
  public cacheLevel: number | null = null

  @Expose({ name: 'min_weight' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public minWeight: number | undefined

  @Expose({ name: 'pickup_minimum_discount_level' })
  public pickupMinimumDiscountLevel: number = 0

  @Expose({ name: 'pickup_minimum_net_shipping_amount' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public pickupMinimumNetShippingAmount: number | undefined

  @Expose({ name: 'pickup_minimum_net_shipping_amount_currency' })
  public pickupMinimumNetShippingAmountCurrency: string = ''

  @Expose({ name: 'max_weight' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public maxWeight: number | undefined

  @Expose({ name: 'weight_unit' })
  public weightUnit: string = 'kg'

  @Expose({ name: 'default_currency' })
  public defaultCurrency: string = ''

  @Expose({ name: 'terms_and_conditions_link' })
  public termsAndConditionsLink: string = ''

  @Expose({ name: 'domestic' })
  @Transform(value => { return (!value) ? false : value })
  public domestic: boolean = true

  @Expose({ name: 'import' })
  @Transform(value => { return (!value) ? false : value })
  public import: boolean = true

  @Expose({ name: 'export' })
  @Transform(value => { return (!value) ? false : value })
  public export: boolean = false

  @Expose({ name: 'requires_shipment_number_ranges' })
  @Transform(value => { return (!value) ? false : value })
  public requiresShipmentNumberRanges: boolean = false

  @Expose({ name: 'enable_public_discount' })
  @Transform(value => { return (!value) ? false : value })
  public enablePublicDiscount: boolean = false

  @Expose({ name: 'apply_discount_on_fuel_surcharge' })
  @Transform(value => { return (!value) ? false : value })
  public applyDiscountOnFuelSurcharge: boolean = true

  @Expose({ name: 'rating_price_configuration' })
  @Transform(value => value === 2, { toClassOnly: true } )
  @Transform(value => value ? 2 : 1, { toPlainOnly: true } )
  public ratingPriceConfiguration: number | boolean = false

  @Expose({ name: 'test_account' })
  @Transform(value => { return (!value) ? false : value })
  public testAccount: boolean = false

  @Expose({ name: 'booking' })
  @Transform(value => { return (!value) ? false : value }, { toClassOnly: true })
  @Transform((_value, obj) => { return !!(obj.actions.includes('booking')) }, { toPlainOnly: true })
  public booking: boolean = false

  @Expose({ name: 'is_prepaid' })
  public isPrepaid: boolean = false

  @Expose({ name: 'prepaid_configuration' })
  @Transform(value => value || 0, { toClassOnly: true })
  public prepaidConfiguration: number | null = 1

  @Expose({ name: 'tracking' })
  @Transform(value => { return (!value) ? false : value }, { toClassOnly: true })
  @Transform((_value, obj) => { return !!(obj.actions.includes('tracking')) }, { toPlainOnly: true })
  public tracking: boolean = false

  @Expose({ name: 'cancelable' })
  @Transform(value => { return (!value) ? false : value })
  public cancelable: boolean = false

  @Expose({ name: 'pickup' })
  @Transform(value => { return (!value) ? false : value }, { toClassOnly: true })
  @Transform((_value, obj) => { return !!(obj.actions.includes('pickup')) }, { toPlainOnly: true })
  public pickup: boolean = false

  @Expose({ name: 'coverage' })
  @Transform(value => { return (!value) ? false : value }, { toClassOnly: true })
  @Transform((_value, obj) => { return !!(obj.actions.includes('pickup')) }, { toPlainOnly: true })
  public coverage: boolean = false

  @Expose({ name: 'quoting' })
  @Transform(value => { return (!value) ? false : value }, { toClassOnly: true })
  @Transform((_value, obj) => { return !!(obj.actions.includes('quoting')) }, { toPlainOnly: true })
  public quoting: boolean = true

  @Expose({ name: 'enabled' })
  @Transform(value => { return (!value) ? false : value })
  public enabled: boolean = false

  @Expose({ name: 'reselling_permitted' })
  @Transform(value => { return (!value) ? false : value })
  public resellingPermitted: boolean = false

  @Expose({ name: 'origin_country_datum_ids' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public originCountryDatumIds: number[] = []

  @Expose({ name: 'destination_country_datum_ids' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public destinationCountryDatumIds: number[] = []

  @Expose({ name: 'origin_country_datums', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => CountryData)
  public originCountryDatums: CountryData[] = []

  @Expose({ name: 'destination_country_datums', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => CountryData)
  public destinationCountryDatums: CountryData[] = []

  @Expose({ name: 'reselling_permitted_exception_ids' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public resellingPermittedExceptionIds: number[] = []

  @Expose({ name: 'billing_country_code' })
  public billingCountryCode: string = ''

  @Expose({ name: 'drop_off_center_indication' })
  public dropOffCenterIndication: number | undefined | null

  @Expose({ name: 'import_taxes_payment' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public importTaxesPayment: number | undefined

  @Expose({ name: 'rate_list_price' })
  @Transform(value => { return (!value) ? false : value })
  public rateListPrice: boolean = false

  @Expose({ name: 'user_name' })
  public userName: string = ''

  @Expose({ name: 'password' })
  public password: string = ''

  @Expose({ name: 'api_key' })
  public apiKey: string = ''

  @Expose({ name: 'auth_token' })
  public authToken: string = ''

  @Expose({ name: 'parameter_1' })
  @Transform(value => value || '', { toClassOnly: true })
  public parameter1: string = ''

  @Expose({ name: 'parameter_2' })
  @Transform(value => value || '', { toClassOnly: true })
  public parameter2: string = ''

  @Expose({ name: 'parameter_3' })
  @Transform(value => value || '', { toClassOnly: true })
  public parameter3: string = ''

  @Expose({ name: 'parameter_4' })
  @Transform(value => value || '', { toClassOnly: true })
  public parameter4: string = ''

  @Expose({ name: 'client_id' })
  @Transform(value => value || '', { toClassOnly: true })
  public clientId: string = ''

  @Expose({ name: 'client_secret' })
  @Transform(value => value || '', { toClassOnly: true })
  public clientSecret: string = ''

  @Expose({ name: 'third_party_provider' })
  public thirdPartyProvider: string | null = ''

  @Expose({ name: 'shipment_booking_method' })
  public shipmentBookingMethod: number | undefined | null

  @Expose({ name: 'cash_on_delivery' })
  @Transform(value => { return (!value) ? false : value })
  public cashOnDelivery: boolean = false

  public t1Credentials: boolean = false

  @Expose({ name: 'cogs_configurations_count' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public cogsConfigurationsCount: number | undefined

  public get formattedName () {
    return [this.formattedCarrierName, this.aliasName].join(' ')
  }

  @Expose({ name: 'user', toClassOnly: true })
  @Type(() => EnviayaUserTS)
  public user: EnviayaUserTS | undefined

  @Expose({ name: 'zones' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  @Transform(value => { return (value === []) ? null : value }, { toPlainOnly: true })
  public zones: number[] = []

  @Expose({ name: 'is_default_carrier_account' })
  @Transform(value => { return (!value) ? false : value })
  public isDefaultCarrierAccount: boolean = false

  @Expose({ name: 'related_carrier_account_id' })
  @Transform(value => value || 'none', { toClassOnly: true })
  public relatedCarrierAccountId: number | undefined

  @Expose({ name: 'related_carrier_account' })
  @Transform(value => Number(value) > 0 ? value : null, { toPlainOnly: true })
  @Type(() => CarrierAccount)
  public relatedCarrierAccount: CarrierAccount | undefined

  public actions: string[] = []
  public availableActions: ISelectItem[] = []

  // For abilities
  public get corporate_account_id () {
    return this.corporateAccountId
  }

  public carrierServices: CarrierService[] = []

  public isActionAvailable (action: string, carrier: Carrier | undefined | null) {
    if (!carrier) return false

    if (this.t1Credentials) {
      if (this.thirdPartyProvider === 'T1Envíos') {
        return !carrier.carrierAccountOptions.thirdPartyProviderOptions
          || (carrier.carrierAccountOptions.thirdPartyProviderOptions && (!carrier.carrierAccountOptions.thirdPartyProviderOptions.t1EnviosOptions.length || carrier.carrierAccountOptions.thirdPartyProviderOptions.t1EnviosOptions.includes(action)))
      }
      if (this.thirdPartyProvider === 'Shippiz') {
        return !carrier.carrierAccountOptions.thirdPartyProviderOptions
          || (carrier.carrierAccountOptions.thirdPartyProviderOptions && (!carrier.carrierAccountOptions.thirdPartyProviderOptions.shippizOptions.length || carrier.carrierAccountOptions.thirdPartyProviderOptions.shippizOptions.includes(action)))
      }
      return false
    } else {
      return !carrier.carrierAccountOptions.functions.length || carrier.carrierAccountOptions.functions.includes(action)
    }
  }

  public availableZones: number[] = []
  public enableCache: boolean = false
  public isLoading: boolean = false
  public expanded: boolean = false

  public configurationByCountry: Record<string, Record<string, CarrierService[]>> = {}
}
