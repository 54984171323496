import { AbstractModel } from '@/models/abstract-model'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { ArrayNotEmpty, IsNotEmpty, ValidateIf } from 'class-validator'
import i18n from '@/i18n'
import { CountryData } from '@/models/country-data'
import { defaultCurrency } from '@/vue3-migration/helpers/default-currency'

export const CogsConfigurationAction = {
  create: 'create',
  edit: 'edit',
  delete: 'delete'
}

export const CALCULATION_TYPE = {
  PERCENT_ON_LIST_PRICE: 'percent_on_list_price',
  CARRIER_API_PRICE: 'carrier_api_price',
  FIXED_PRICE: 'fixed_price',
  LIST_PRICE: 'list_price'
}

@Exclude()
export class CogsConfiguration extends AbstractModel {
  constructor () {
    super()
  }

  static get modelName () {
    return 'CarrierServiceStandardCogsConfiguration'
  }

  @Exclude({ toPlainOnly: true })
  public wasValidated: boolean = false

  @Exclude({ toPlainOnly: true })
  public carrierId: number | null = null

  @Exclude({ toPlainOnly: true })
  @ValidateIf(o => !o.surchargeCode || (!!o.surchargeCode && !o.useGlobalConfigurationForSurcharges))
  @ArrayNotEmpty({ message: () => i18n.t('cogs_calculation_form.fields.validations.blank').toString() })
  public carrierServiceIds: number[] = []

  @Exclude({ toPlainOnly: true })
  public forAllCountries: boolean = true

  @Exclude({ toPlainOnly: true })
  public useGlobalConfigurationForSurcharges: boolean = false

  @Expose({ name: 'carrier_account_id' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public carrierAccountId: number | undefined

  @Expose({ name: 'carrier_service_id' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public carrierServiceId: number | undefined | null = undefined

  @Expose({ name: 'surcharge_code' })
  public surchargeCode: string | undefined | null = undefined

  @Expose({ name: 'weight_unit' })
  @Transform(value => value || 'kg', { toClassOnly: true })
  public weightUnit: string = 'kg'

  @Expose({ name: 'min_weight' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public minWeight: number | undefined | null = undefined

  @Expose({ name: 'max_weight' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public maxWeight: number | undefined | null = undefined

  @Expose({ name: 'calculation_type' })
  public calculationType: string | undefined

  @Expose({ name: 'discount' })
  @ValidateIf(o => o.calculationType === CALCULATION_TYPE.PERCENT_ON_LIST_PRICE)
  @IsNotEmpty({ message: () => i18n.t('cogs_calculation_form.fields.validations.blank').toString() })
  @Transform(value => value || undefined)
  public discount: number | undefined | null = undefined

  @Expose({ name: 'fixed_base_weight' })
  @ValidateIf(o => o.calculationType === CALCULATION_TYPE.FIXED_PRICE)
  @IsNotEmpty({ message: () => i18n.t('cogs_calculation_form.fields.validations.blank').toString() })
  @Transform(value => value || undefined)
  public fixedBaseWeight: number | undefined | null = undefined

  @Expose({ name: 'fixed_weight_increment' })
  @ValidateIf(o => o.calculationType === CALCULATION_TYPE.FIXED_PRICE)
  @IsNotEmpty({ message: () => i18n.t('cogs_calculation_form.fields.validations.blank').toString() })
  @Transform(value => value || undefined)
  public fixedWeightIncrement: number | undefined | null = undefined

  @Expose({ name: 'fixed_amount_base' })
  @ValidateIf(o => o.calculationType === CALCULATION_TYPE.FIXED_PRICE)
  @IsNotEmpty({ message: () => i18n.t('cogs_calculation_form.fields.validations.blank').toString() })
  @Transform(value => value || undefined)
  public fixedAmountBase: number | undefined | null = undefined

  @Expose({ name: 'fixed_amount_increment' })
  @ValidateIf(o => o.calculationType === CALCULATION_TYPE.FIXED_PRICE)
  @IsNotEmpty({ message: () => i18n.t('cogs_calculation_form.fields.validations.blank').toString() })
  @Transform(value => value || undefined)
  public fixedAmountIncrement: number | undefined | null = undefined

  @Expose({ name: 'fixed_amount_currency' })
  @Transform(value => value || defaultCurrency(), { toClassOnly: true })
  public fixedAmountCurrency: string = ''

  @Expose({ name: 'origin_country_datums', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => CountryData)
  public originCountryDatums: CountryData[] = []

  @Expose({ name: 'origin_country_id' })
  @Transform(value => value || null, { toClassOnly: true })
  public originCountryId: number | undefined | null = null

  @Expose({ name: 'zones' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  @Transform(value => { return (value === []) ? null : value }, { toPlainOnly: true })
  public zones: number[] = []
}
