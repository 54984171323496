
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import VueExtendLayouts from 'vue-extend-layout'
import { UtmParamsProcessor } from '@/mixins/utmParamsProcessor'
import { AppModule } from '@/store/app.module'
import PopupContainer from '@/components/popup/PopupContainer.vue'
import { CONFIGURATION_KEYS } from '@/common/config'
import { Styles } from '@/mixins/styles'
import { MetaInfo } from 'vue-meta'
import { Brand } from '@/mixins/brand'
// @ts-ignore
import * as VueGoogleMaps from 'vue2-google-maps'
import { AuthModule } from '@/store/auth.module'
import JwtService from '@/common/jwt.service'
Vue.use(VueGoogleMaps)

@Component({
  metaInfo (): MetaInfo {
    return {
      // @ts-ignore
      title: this.brandName,
      meta: [
        // @ts-ignore
        { vmid: 'description', name: 'description', content: this.brandName },
        // @ts-ignore
        { vmid: 'keywords', name: 'keywords', content: this.brandName }
      ]
    }
  },
  components: {
    VueExtendLayouts,
    PopupContainer
  },
  mixins: [
    UtmParamsProcessor,
    Styles,
    Brand
  ]
})

export default class App extends Mixins(Brand) {
  public isRefreshing: boolean = false

  public beforeMount () {
    this.loadGoogleMap()
  }

  public async mounted () {
    document.body.onfocus = () => {
      if (AuthModule.currentUser) {
        if (AuthModule.currentUser.id !== JwtService.getUser()?.id && !this.isRefreshing) {
          this.isRefreshing = true
          Vue.$modal.show({
            type: 'warning',
            centered: true,
            headerText: this.$t('popup.titles.warning_title'),
            bodyText: this.$t('update_available_popup.user_changed'),
            applyBtnText: this.$t(`popup.buttons.apply_btn`),
            applyAction: async () => {
              window.location.reload()
            }
          })
        }
      }
    }
    AppModule.setGMapStateValid(!!this.googleApiKey())
    await this.checkingAppVersion()
  }

  public loadGoogleMap () {
    VueGoogleMaps.loadGmapApi({
      key: this.googleApiKey(),
      libraries: 'places',
      installComponents: true
    })
  }

  public get google () {
    return VueGoogleMaps.gmapApi()
  }

  public async checkingAppVersion () {
    if (Vue.$getConst(CONFIGURATION_KEYS.IS_PRODUCTION)) {
      Vue.axios.create({ baseURL: '/' }).get('manifest.json')
        .then(response => {
          const lastModified = response.headers['last-modified']
          window.localStorage.setItem('last-modified', lastModified)
        }).catch(() => { /**/ })

      setInterval(() => {
        if (!this.isRefreshing) {
          Vue.axios.create({ baseURL: '/' }).get('manifest.json')
            .then(response => {
              const storedLastModified = window.localStorage.getItem('last-modified')
              const lastModified = response.headers['last-modified']
              if (storedLastModified !== lastModified) {
                this.isRefreshing = true
                this.showRefreshUI(lastModified)
              }
            }).catch(() => { /**/ })
        }
      }, 300 * 1000)
    }
    if (window.navigator && navigator.serviceWorker) {
      await navigator.serviceWorker.getRegistrations()
        .then(async function (registrations) {
          for (let registration of registrations) {
            await registration.unregister()
          }
        })
    }
  }

  public showRefreshUI (lastModified: string) {
    this.$modal.show({
      type: 'warning',
      centered: true,
      headerText: this.$t('popup.titles.warning_title'),
      bodyText: this.$t('update_available_popup.text'),
      applyBtnText: this.$t(`popup.buttons.apply_btn`),
      applyAction: async () => {
        window.localStorage.setItem('last-modified', lastModified)
        window.location.reload()
      }
    })
  }

  public get appError () {
    return AppModule.appError
  }

  @Watch('AppModule.currentLocale')
  public onLocaleChanged (value: string) {
    this.$moment.locale(value)
  }

  @Watch('appError')
  public onAppErrorChanged (value: string) {
    if (value && value.length > 0) {
      this.$modal.show({
        type: 'danger',
        centered: true,
        headerText: this.$t('popup.titles.temporary_error_title'),
        bodyText: value,
        applyAction: () => {
          AppModule.updateError('')
        }
      })
    }
  }
}
