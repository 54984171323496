<script>
  import BasicMultiSelectSelect from '@/components/select-boxes/BasicMultiSelect'
  import $ from 'jquery'
  import AccountsApiService from '@/common/api/accounts.api.service'
  import Vue from 'vue'
  import { ACCOUNT_STATUSES, ACCOUNT_TYPE_RESELLER } from '@/models/EnviayaAccountTS'
  import { getCharsLimit } from '@/constants/app-config'

  export default {
    name: 'EnviayaAccountMultiSelectBox',
    extends: BasicMultiSelectSelect,

    props: {
      selectedAccounts: {
        type: Array,
        default: () => []
      }
    },

    methods: {
      processResults (data, params) {
        let options = []
        data.accounts.map((el) => {
          options.push(this.formatOption(el.id, el.select_box_text, el))
        })
        return {
          results: options
        }
      },
      formatOption (id, text, model) {
        if (model.status === ACCOUNT_STATUSES.DELETED) {
          return { id: id, title: `${text}`, text: `<i class="fal fa-user-times red-text pr-2"></i><span class="red-text text-decoration-line-through">${text}</span>`, link: false }
        } else if (model.status === ACCOUNT_STATUSES.DISABLED) {
          return { id: id, title: `${text}`, text: `<i class="fal fa-user-slash red-text pr-2"></i><span class="red-text text-decoration-line-through">${text}</span>`, link: false }
        } else if (model.status === ACCOUNT_STATUSES.DISABLED_SYSTEM) {
          return { id: id, title: `${text}`, text: `<i class="fal fa-user-shield red-text pr-2"></i><span class="red-text text-decoration-line-through">${text}</span>`, link: false }
        } else {
          if (model.referrer_id) {
            return { id: id, title: `${text}`, text: `<i class="fal fa-users blue-text pr-2"></i><span class="blue-text">${text}</span>`, link: false }
          } else if (model.account_type === ACCOUNT_TYPE_RESELLER) {
            return { id: id, title: `${text}`, text: `<i class="fal fa-users green-text pr-2"></i><span class="green-text">${text}</span>`, link: false }
          } else {
            return { id: id, title: `${text}`, text: `<i class="fal fa-user blue-text pr-2"></i><span class="blue-text">${text}</span>`, link: false }
          }
        }
      },
      formatResult (option) {
        if (option.loading) {
          return option.text
        } else {
          return $.parseHTML(option.text)
        }
      },
      formatSelection (option) {
        if (option.loading) {
          return option.text
        } else {
          return $.parseHTML(option.text)
        }
      },
      createOption (enviayaAccount) {
        let text = ''
        if (enviayaAccount.selectBoxText && enviayaAccount.selectBoxText.length > 0) {
          text = enviayaAccount.selectBoxText
        } else if (enviayaAccount.users && enviayaAccount.users.length) {
          text = enviayaAccount.alias
        }
        return this.formatOption(enviayaAccount.id, `${text}`, enviayaAccount)
      }
    },

    watch: {
      selectedAccounts (accounts) {
        let options = []
        accounts.map((enviayaAccount) => {
          options.push(this.createOption(enviayaAccount))
        })
        this.options.splice(0)
        this.options.push(...options)
        this.setValue(this.value)
      }
    },

    async mounted () {
      this.minimumInputLength = getCharsLimit(this.$currentUser) - 1
      let options = []
      try {
        if (this.value && this.value.length) {
          let accounts = await AccountsApiService.getAccountsByIds(this.value)
          accounts.map((enviayaAccount) => {
            options.push(this.createOption(enviayaAccount))
          })
        }
        this.options.splice(0)
        this.options.push(...options)

      } catch (e) {
        Vue.$rollbar.log(e)
      }
    }
  }
</script>
