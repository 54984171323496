// TODO: remove this part after user.roles.js has its typescript file
// @ts-ignore
import { UserRoles } from '@/common/user.roles'

export const AVAILABLE_LANGUAGES: string[] = [
  'en',
  'de',
  'es'
]

export const LOCALE_BY_COUNTRY: any = {
  'us': 'en',
  'de': 'de',
  'mx': 'es'
}

export enum LABEL_FORMATS {
  LABEL_FORMAT_DIN_A4_INT = 1,
  LABEL_FORMAT_ZPL2_INT = 2,
  LABEL_FORMAT_EPL2_INT = 3
}

export enum LABEL_SIZES {
  LABEL_SIZE_LETTER = 1,
  LABEL_SIZE_THERMAL = 2
}

export enum FILE_FORMATS {
  FILE_FORMAT_PDF = 1,
  FILE_FORMAT_DPL = 2,
  FILE_FORMAT_IPL = 3,
  FILE_FORMAT_PNG = 4,
  FILE_FORMAT_JSON = 5,
  FILE_FORMAT_ZPL = 6,
  FILE_FORMAT_EPL = 7
}

export enum USER_STATUSES {
  USER_STATUS_ACTIVE = 1,
  USER_STATUS_DISABLED = 2,
  USER_STATUS_DELETED = 3,
  USER_STATUS_EMAIL_CONFIRMATION_PENDING = 4,
  USER_STATUS_DISABLED_SYSTEM = 5
}

enum BLOCK_PAYMENT_OPTIONS {
  BY_USER = 3,
  ADDRESS = 5,
  PHONE = 6,
  EMAIL = 7,
  IP = 4
}

export const BLOCK_PAYMENT_OPTION_TYPES: Array<{ title: string, code: number, defaultChecked: boolean, prefix?: string }> = [
  { title: '5', code: BLOCK_PAYMENT_OPTIONS.BY_USER, defaultChecked: true },
  { title: '7', code: BLOCK_PAYMENT_OPTIONS.EMAIL, defaultChecked: true },
  { title: '5_origin', code: BLOCK_PAYMENT_OPTIONS.ADDRESS, prefix: 'origin', defaultChecked: true },
  { title: '5_destination', code: BLOCK_PAYMENT_OPTIONS.ADDRESS, prefix: 'destination', defaultChecked: false },
  { title: '6_origin', code: BLOCK_PAYMENT_OPTIONS.PHONE, prefix: 'origin', defaultChecked: true },
  { title: '6_destination', code: BLOCK_PAYMENT_OPTIONS.PHONE, prefix: 'destination', defaultChecked: false },
  { title: '4', code: BLOCK_PAYMENT_OPTIONS.IP, defaultChecked: true },
  { title: '6', code: BLOCK_PAYMENT_OPTIONS.PHONE, defaultChecked: true }
]

export function labelFormat (format: number): string {
  let labelFormats = new Map<number, string>()
  labelFormats.set(LABEL_FORMATS.LABEL_FORMAT_DIN_A4_INT, LABEL_FORMATS.LABEL_FORMAT_DIN_A4_INT.toString())
  labelFormats.set(LABEL_FORMATS.LABEL_FORMAT_ZPL2_INT, 'ZPL2')
  labelFormats.set(LABEL_FORMATS.LABEL_FORMAT_EPL2_INT, 'EPL2')
  return labelFormats.get(format)!
}

export function userRole (format: number): string {
  let roles = new Map<number, string>()
  roles.set(UserRoles.USER_ROLE_USER, 'user')
  roles.set(UserRoles.USER_ROLE_CORPORATE_SUPERVISOR, 'corporate_supervisor')
  return roles.get(format)!
}
