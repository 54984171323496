import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { Direction } from '@/models/direction'

@Exclude()
export class DirectionList {
  @Expose({ name: 'count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public modelsCnt: number = 0

  @Expose({ name: 'all_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public allCount: number = 0

  @Expose({ name: 'origin_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public originCount: number = 0

  @Expose({ name: 'destination_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public destinationCount: number = 0

  @Expose({ name: 'search_results_key' })
  public searchResultsKey: string = ''

  @Expose({ name: 'directions' })
  @Type(() => Direction)
  @Transform(value => value || [], { toClassOnly: true })
  public models: Direction[] = []
}
