<template>
  <div class='select2-multi-wrap'>
    <label ref="label">{{placeholder}}</label>
    <span class="caret">▼</span>
    <select class="form-control" :name="name" :disabled="disabled" :required="required" :url="url" :urlParams="urlParams"/>
  </div>
</template>

<script>
  import $ from 'jquery'
  import 'select2/dist/js/select2.full.js'
  import 'select2/dist/css/select2.min.css'
  import { mapGetters } from 'vuex'
  import Vue from 'vue'
  import { DomainService } from '@/common/domain.service'
  import { CONFIGURATION_KEYS } from '@/common/config'

  export default {
    name: 'select2-material',
    data () {
      return {
        select2: null,
        ajaxOptions: {
          url: `${this.getDomain()}${this.url}`,
          xhrFields: { withCredentials: true },
          dataType: 'json',
          delay: 250,
          tags: true,
          data: (params) => {
            return {
              query: params.term, // search term
              countries: this.urlParams.countries,
              fields: this.urlParams.fields,
              page: params.page
            }
          },
          transport: ((params, success, failure) => {
            let $request = $.ajax(params)
            $request.then(success)
            $request.fail(failure => {
              if (failure.status) {
                switch (failure.status) {
                  case 401:
                    // this.$router.push('/logout').catch(() => { /**/ })
                    break
                  default:
                    this.$modal.show({
                      type: 'danger',
                      centered: true,
                      headerText: 'Error',
                      bodyText: failure
                    })
                    break
                }
              }
            })

            return $request
          }),
          processResults: function (data, params) {
            return {
              results: data.addresses.map((el) => { return { id: el.city, text: el.city } })
            }
          },
          cache: true
        }
      }
    },
    model: {
      event: 'change',
      prop: 'value'
    },
    props: {
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      settings: {
        type: Object,
        default: () => { /**/ }
      },
      urlParams: {
        type: Object,
        default: () => { /**/ }
      },
      value: null,
      url: {
        type: String,
        default: ''
      }
    },
    watch: {
      options (val) {
        this.setOption(val)
      },
      value (val) {
        this.setValue(val)
      }
    },
    methods: {
      getDomain () {
        let vueExpectedHost = DomainService.getHostFromURI(Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH))
        let vueActualHost = DomainService.getHostFromURI(window.location.href)
        let url = ''
        if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
          Vue.axios.defaults.withCredentials = true
          if (vueExpectedHost !== vueActualHost) {
            url = `https://${DomainService.getHostFromURI(window.location.href)}/api/v2/`
          } else {
            url = Vue.$getConst(CONFIGURATION_KEYS.API_URL)
          }
        } else {
          if (vueExpectedHost !== vueActualHost) {
            url = `https://${DomainService.getDomainWithoutPort(window.location.href)}:${Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN_PORT)}/api/v2/`
          } else {
            url = Vue.$getConst(CONFIGURATION_KEYS.API_URL)
          }
        }
        return url
      },
      setOption (val = []) {
        let settings = {
          ...this.settings
        }
        this.select2.empty()
        this.select2.select2({
          ...settings,
          minimumInputLength: 4,
          ajax: this.ajaxOptions,
          data: val
        })
        this.setValue(this.value)
      },
      setValue (val) {
        if (val instanceof Array) {
          this.select2.val([...val])
        } else {
          this.select2.val([val])
        }
        if (this.select2.val() && this.select2.val().length > 0) {
          this.$refs.label?.classList.add(['active'])
        }

        this.select2.trigger('change')
      }
    },
    mounted () {
      let settings = {
        ...this.settings,
        minimumInputLength: 4,
        ajax: this.ajaxOptions
      }
      this.select2 = $(this.$el)
        .find('select')
        .select2({
          ...settings,
          data: this.options
        })
        .on('select2:select', ev => {
          this.$emit('change', this.select2.val())
          this.$emit('select', ev['params']['data'])
        })
        .on('select2:unselect', ev => {
          this.$emit('change', this.select2.val())
          this.$emit('select', ev['params']['data'])
        })
        .on('change', e => {
          if (this.select2.val() && this.select2.val().length > 0) {
            this.$refs.label?.classList.add(['active'])
          } else {
            this.$refs.label?.classList.remove(['active'])
          }
        })
        .on('select2:open', e => {
          this.$refs.label?.classList.add(['active'])
        })
        .on('select2:close', e => {
          if (this.select2.val() && this.select2.val().length === 0) {
            this.$refs.label?.classList.remove(['active'])
          }
        })
      this.setValue(this.value)
    },
    beforeDestroy () {
      this.select2.select2('destroy')
    }
  }
</script>

<style>


  .select-custom-dropdown .select2-results__option {
    color: #757575;
    font-family: 'Lato';
    font-size: 14.4px;
    transition: .15s;
  }

  .select-custom-dropdown .select2-results__option.select2-results__option--highlighted {
    box-shadow: none!important;
  }

  @media (min-width: 500px) {

    .select-custom-dropdown .select2-results__options {
      max-height: 350px!important;
    }
  }

  .select-custom-dropdown .select2-results__option {
    color: #757575;
    font-family: 'Lato';
    font-size: 14.4px;
    transition: .15s;
  }

  .select-custom-dropdown .select2-results__option.select2-results__option--highlighted {
    box-shadow: none!important;
  }

  @media (min-width: 500px) {

    .select-custom-dropdown .select2-results__options {
      max-height: 350px!important;
    }
  }

</style>

<style>

  .select-custom-dropdown .select2-results__option {
    color: #757575;
    font-family: 'Lato';
    font-size: 14.4px;
    transition: .15s;
  }

  .select-custom-dropdown .select2-results__option.select2-results__option--highlighted {
    box-shadow: none!important;
  }

  @media (min-width: 500px) {

    .select-custom-dropdown .select2-results__options {
      max-height: 350px!important;
    }
  }

</style>

<style scoped>
  .select2-multi-wrap {
    margin-top: calc(1.75rem + 12px);
    margin-bottom: 0;
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  .select2-multi-wrap label {
    top: 6px;
    position: absolute;
    color: #757575;
  }

  .select2-multi-wrap label.active {
    margin-top: -15px;
    font-size: 0.8rem;
    z-index: 1;
  }

  /*.select2-multi-wrap {*/
  /*  margin-top: 1.5rem;*/
  /*  margin-bottom: 1.5rem;*/
  /*  position: relative;*/
  /*  max-width: 100%;*/
  /*}*/

  .form-control + >>> .select2-container--default .select2-selection--multiple .select2-selection__arrow b {
    display: none;
  }

  >>> .select2-selection__rendered {
    margin-bottom: -6px!important;
  }

  .caret {
    position: absolute;
    bottom: 12px;
    right: .5rem;
    font-size: .63rem!important;
    color: #757575;
    z-index: 1;
    cursor: pointer;
  }
  >>> .select2-selection__rendered {
    padding-left: 0!important;
  }

  >>> .select2-container--default .select2-selection--multiple{
    outline: none!important;
    border-top:none!important;
    border-left:none!important;
    border-right:none!important;
    border-radius: 0!important;
    border-bottom: 1px solid #ced4da!important;
    padding-bottom: 5px!important;
  }
  /*>>> .select2-container {*/
  /*  max-width: 350px;*/
  /*}*/

  >>> .select2-selection__choice__remove {
    right: 8px;
    color: red!important;
    position: absolute;
  }

  >>> .select2-selection__choice {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap !important;
    border: none !important;
    max-width: 90% !important;
    position: relative!important;
    background-color: #eceff1;
    border-radius: 16px !important;
    padding-right: 24px !important;
    padding-left: 10px !important;
    font-size: 13px !important;
    line-height: 28px;
    margin-top: 12px !important;
  }

  /*>>> .select2-selection__choice:after {*/
  /*  content: ',';*/
  /*}*/
  /*>>> .select2-selection__rendered :nth-last-child(2):after {*/
  /*  content: none;*/
  /*}*/

  >>> .select2-results__option {
    color: #757575;
  }

  >>> .select2-container {
    width: 100% !important;
    /*max-width: 350px;*/
  }

  >>> .select2-search__field {
    width: 100% !important;
    font-size: 0.9rem !important;
  }

  /*.select2-multi-wrap label {*/
  /*  top: 7px;*/
  /*  position: absolute;*/
  /*  color: #757575;*/
  /*}*/

  /*.select2-multi-wrap label.active {*/
  /*  margin-top: -15px;*/
  /*  font-size: 0.8rem;*/
  /*  z-index: 1;*/
  /*}*/

  >>> .select2-container--default .select2-selection--multiple {
    background: transparent !important;
  }

</style>
