<template>
  <div v-html="html">
  </div>
</template>

<script>
export default {
  props: ['text', 'val'],

  data () {
    if (!this.val || this.val === 'geo') {
      return { html: `<span>${this.text}</span>` }
    } else {
      return {
        html: `
        <img class="img-flag" src="${require('../../assets/img/flag/4x3/' + this.val.toLowerCase() + '.svg')}" width="20">
        <span>${this.text}</span>
    `
      }
    }
  }
}
</script>
