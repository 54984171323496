<script>
  import BasicSelect from '@/components/select-boxes/BasicSelect'
  import $ from 'jquery'
  import { plainToClass } from 'class-transformer'
  import { CorporateAccountsApiService } from '@/common/api/corporate_accounts.api.service'
  import { CorporateAccountTs } from '@/models/corporate-account-ts'
  import { getCharsLimit } from '@/constants/app-config'
  import get from 'lodash/get'

  export default {
    name: 'CorporateAccountSelectBox',
    extends: BasicSelect,
    watch: {
      async value (val) {
        if (val) {
          const corporateAccountResponse = await CorporateAccountsApiService.getGQL(this.value)
          const account = get(corporateAccountResponse, 'data.data.corporate_account')
          this.$emit('selectedModel', plainToClass(CorporateAccountTs, account))
          this.setOption([{ id: account.id, text: `${account.company} (${account.id})`, account }])
        }
        this.init = false
        this.setValue(val)
      }
    },
    methods: {
      processResults (data, params) {
        let options = []
        options.push(...data.accounts.map((el) => { return { id: el.id, text: `${ el.select_box_text }` } }))
        return {
          results: options
        }
      },
      formatResult (option) {
        if (option.link) {
          return $.parseHTML('<span>' + option.text + '</span>')
        }
        return option.text
      }
    },
    async mounted () {
      this.minimumInputLength = getCharsLimit(this.$currentUser) - 1
      if (this.value && this.init) {
        let userResponse = await CorporateAccountsApiService.get(this.value)
        let account = plainToClass(CorporateAccountTs, userResponse.data)
        this.setOption([{ id: account.id, text: `${account.company} (${account.id})`, account }])
        this.$emit('selectedModel', account)
      }
      this.setValue(this.value)
    }
  }
</script>
