import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments, Validate } from 'class-validator'
import i18n from '@/i18n'
import { AbstractModel } from './abstract-model'
import { CorporateAccountTs } from './corporate-account-ts'
import { EnviayaAccountTS } from './EnviayaAccountTS'
import { EnviayaUserTS } from './EnviayaUserTS'
// @ts-ignore
import localeData from '@/locales/webhooks/webhook_form.json'
import { WEBHOOK_ENDPOINT_REGEX } from '@/common/utility'

export enum PickupWebhookEvents {
  CONFIRMATION = 'pickups.confirmation',
  STATUS_UPDATE = 'pickups.status_update',
  CANCELLED = 'pickups.cancelled',
  COMPLETED = 'pickups.completed'
}

export enum ShipmentWebhookEvents {
  STATUS_UPDATE = 'shipments.status_update',
  LABEL_UPDATE = 'shipments.label_update',
  AMOUNTS_UPDATE = 'shipments.amounts_update',
  CARRIER_NUMBER_UPDATE = 'shipments.carrier_tracking_number_update',
  ORIGIN_UPDATE = 'shipments.origin_update',
  DESTINTAION_UPDATE = 'shipments.destination_update',
  EST_DEL_DATE_UPDATE = 'shipments.estimated_delivery_date_update'
}

export const ConfigurableShipmentFields = [
  'shipment_id',
  'status_code',
  'event_code',
  'event_description',
  'event',
  'sub_event_code',
  'sub_event',
  'sub_event_description',
  'enviaya_account',
  'user_first_name',
  'user_last_name',
  'user_email',
  'total_amount',
  'origin_email',
  'origin_phone',
  'origin_country_code',
  'origin_postal_code',
  'origin_neighborhood',
  'origin_district',
  'origin_city',
  'origin_state_code',
  'origin_direction_1',
  'origin_direction_2',
  'destination_email',
  'destination_phone',
  'destination_country_code',
  'destination_postal_code',
  'destination_neighborhood',
  'destination_district',
  'destination_city',
  'destination_state_code',
  'destination_direction_1',
  'destination_direction_2',
  'user_id',
  'content',
  'insured_amount',
  'created_at',
  'updated_at',
  'origin_company',
  'destination_company',
  'shipment_type',
  'invoiced_weight',
  'physical_weight',
  'volumetric_weight',
  'carrier_name',
  'service',
  'insured_amount_currency',
  'carrier_shipment_number',
  'shipment_number',
  'origin_direction_id',
  'destination_direction_id',
  'status',
  'currency',
  'vat_rate',
  'vat_amount',
  'carrier_shipment_date',
  'last_checkpoint',
  'last_checkpoint_date',
  'last_checkpoint_comments',
  'shipment_error_response',
  'net_shipping_amount',
  'uuid',
  'label',
  'service_code',
  'timezone',
  'booking_error',
  'net_surcharges_amount',
  'net_total_amount',
  'est_delivery_date',
  'delivery_date_in_words',
  'destination_full_name',
  'origin_full_name',
  'destination_direction_2',
  'origin_direction_2',
  'last_tracking_date',
  'public_price',
  'customer_savings',
  'inicial_insured_amount',
  'inicial_insured_currency',
  'label_format',
  'converted_insured_amount',
  'date',
  'weight_unit',
  'encrypted_carrier_account',
  'customer_carrier_account',
  'picked_up_at',
  'converted_insured_amount_currency',
  'order_type',
  'carrier_tracking_weight',
  'carrier_invoiced_weight_unit',
  'currency_conversion_exchange_rate',
  'booking_time',
  'recalculated_est_delivery_date',
  'reweight_net_amount',
  'reweight_vat_amount',
  'reweight_total_amount',
  'reweight_status',
  'dimension_unit',
  'test',
  'public_net_shipping_amount',
  'public_net_surcharges_amount',
  'public_net_total_amount',
  'public_vat_amount',
  'public_total_amount',
  'public_currency',
  'accounting_status',
  'local_service_code',
  'revision_status',
  'origin_latitude',
  'origin_longitude',
  'origin_gmaps_url',
  'destination_latitude',
  'destination_longitude',
  'destination_gmaps_url',
  'reweight_public_net_amount',
  'reweight_public_vat_amount',
  'reweight_public_total_amount',
  'reseller_margin_addition_percentage',
  'reseller_margin_percentage_amount',
  'reseller_margin_net_shipping_amount',
  'reseller_margin_net_surcharges_amount',
  'reseller_margin_net_total_amount',
  'reseller_margin_vat_amount',
  'reseller_margin_total_amount',
  'premium_pickup_service',
  'carrier_account_type',
  'exchange_rate',
  'carrier_account_currency_exchange_rate',
  'reweight_public_net_surcharges_amount',
  'reweight_net_surcharges_amount',
  'shop_order_provider',
  'shop_order_id',
  'shop_id',
  'locale',
  'subsidy_total_amount'
].sort()

export const ConfigurablePickupFields = [
  'id',
  'email',
  'company',
  'date',
  'carrier_name',
  'city',
  'district',
  'user_id',
  'created_at',
  'updated_at',
  'status',
  'neighborhood',
  'full_name',
  'booking_time',
  'schedule_from',
  'schedule_to',
  'confirmation_number',
  'phone',
  'direction_1',
  'direction_2',
  'postal_code',
  'state_code',
  'country_code',
  'enviaya_account_id',
  'error_message',
  'uuid',
  'enviaya_account',
  'carrier_id',
  'test',
  'latitude',
  'longitude',
  'gmaps_url',
  'locale'
].sort()

export const DefaultShipmentFields = [
  'shipment_id',
  'shipment_number',
  'carrier_shipment_number',
  'status',
  'status_code',
  'references',
  'shop_order_id'
]

export const DefaultPickupFields = [
  'id',
  'confirmation_number',
  'status'
]

export const ConfigurableFields = {
  shipment: ConfigurableShipmentFields,
  pickup: ConfigurablePickupFields
}

@ValidatorConstraint()
export class IsUrlValid implements ValidatorConstraintInterface {
  public validate (value: string, _args: ValidationArguments) {
    return !!value.match(WEBHOOK_ENDPOINT_REGEX)
  }
}

@Exclude()
export class WebhookConfiguration extends AbstractModel {
  static get modelName () {
    return 'WebhookConfiguration'
  }

  constructor () {
    super()
    i18n.mergeLocaleMessage('en', localeData.en)
    i18n.mergeLocaleMessage('de', localeData.de)
    i18n.mergeLocaleMessage('es', localeData.es)
  }

  public wasValidated: boolean = false

  @Expose({ name: 'object_class_name' })
  public objectClassName: string | undefined

  @Expose({ name: 'user' })
  @Type(() => EnviayaUserTS)
  public user: EnviayaUserTS | undefined

  @Expose({ name: 'enviaya_accounts', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => EnviayaAccountTS)
  public enviayaAccounts: EnviayaAccountTS[] = []

  @Expose({ name: 'corporate_account', toClassOnly: true })
  public corporateAccount: CorporateAccountTs | undefined

  @Validate(IsUrlValid, { message: () => {
    return i18n.t('form.valid_msg.url').toString()
  }})
  @Expose({ name: 'url' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public url: string | undefined

  @Expose({ name: 'verify_token' })
  public verifyToken: string | undefined

  @Expose({ name: 'event_types' })
  public eventTypes: string[] = []

  @Expose({ name: 'disabled' })
  public disabled: boolean | undefined

  @Expose({ name: 'configurable_fields' })
  public configurableFields: string[] = []

  @Expose({ name: 'custom' })
  public custom: boolean | undefined = false

  @Expose({ name: 'user_id' })
  public userId: number | undefined

  @Expose({ name: 'corporate_account_id' })
  public corporateAccountId: number | undefined
}
