var Rollbar = require('rollbar');
const config = require("@/common/config");
const Vue = require("vue");
const {CONFIGURATION_KEYS} = require("@/common/config");

module.exports = {
  install: function(Vue, options) {
    let config = require("@/common/config");
    const rollbarInstance = new Rollbar({
      accessToken: Vue.$getConst(CONFIGURATION_KEYS.ROLLBAR_ACCESS_TOKEN),
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: true,
      source_map_enabled: true,
      environment: Vue.$getConst(config.CONFIGURATION_KEYS.APP_ENVIRONMENT),
      ignoredMessages: [
        /Timeout/i, // From external plugin
        /reading \'_t\'/i,
        /ChunkLoadError/i,
        /Loading chunk/i,
        /UserGuiding/i, // From external plugin
        /Script error/i,
        /Server is not available during call/i,
        /Network error/i,
        /Error: Request aborted/i,
        /ValidationError/i,
        /ResizeObserver/i,
        /i18n/i,
        /Nothing to repeat/i,
        /Unexpected token/i,
        /SyntaxError: Unexpected token/i,
        /Not implemented on this platform/i, // From external plugin
        /Object Not Found Matching Id/i, // From external plugin
        /_reportEvent is not defined/i, // From external plugin,
        /You already started the loading of google maps/i,
        /Cannot read property _t/i, // Not critcal error which happens when vue do redirect, tranlations of prev page we came from is not fully done but i18n object already killed.
        /AccessDeniedError/i, //Because of NGINX can return 403 for unknown reasons.
        /401/i, //Because of NGINX can return 403 for unknown reasons.
        /404/i,
        /reCAPTCHA/i,
        /gCrWeb/i,
        /CSS chunk/i
      ],
      payload: {
        client: {
          javascript: {
            code_version: process.env.CODE_VERSION,
            source_map_enabled: true,
            guess_uncaught_frames: true
          }
        }
      }
    });

    let rollbar = {
      log (err) {
        if (['production', 'staging'].includes(Vue.$getConst(config.CONFIGURATION_KEYS.APP_ENVIRONMENT)) && err) {
          rollbarInstance.error(err)
        } else {
          console.error(err)
        }
      },
      debug (err, obj) {
        if (['production', 'staging'].includes(Vue.$getConst(config.CONFIGURATION_KEYS.APP_ENVIRONMENT)) && err) {
          rollbarInstance.debug(err, obj)
        } else {
          console.error(err)
        }
      }
    }

    Vue.$rollbar = rollbar;
    Vue.prototype.$rollbar = rollbar;
  }
};
