<template>
    <mdb-row>
      <mdb-col col="3">
        <mdb-icon :icon="this.bodyIcon" :size="options.bodyIconSize"/>
      </mdb-col>
      <mdb-col col="9">
        <p v-if="options.bodyAsHtml" v-html="options.bodyText" class="body-content-container"></p>
        <p v-else class="body-content-container">{{options.bodyText}}</p>
      </mdb-col>
    </mdb-row>
</template>

<script>

const defaultBodyIcons = {
  success: 'check-circle',
  info: 'info-circle',
  warning: 'exclamation-circle',
  danger: 'exclamation-circle'
}

export default {
  name: 'MdPopupBody',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      bodyIcon: this.options.bodyIcon || defaultBodyIcons[this.options.type] || 'info-circle'
    }
  }
}
</script>

<style scoped>
  .body-content-container >>> a {
    color: #3fa2f7;
  }
</style>
