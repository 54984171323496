import i18n from '@/i18n'
import { AbstractModel } from '@/models/abstract-model'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { EnviayaAccountTS } from '@/models/EnviayaAccountTS'
import { CorporateAccountTs } from '@/models/corporate-account-ts'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
// @ts-ignore
import localeData from '@/locales/invoice-directions/invoice_direction_form.json'
import {
  IsNotEmpty,
  isEmpty,
  Validate,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface, ValidateIf, isNotEmpty
} from 'class-validator'
// @ts-ignore
import validateRfc from 'validate-rfc'

@ValidatorConstraint()
export class IsTaxIdCorrect implements ValidatorConstraintInterface {
  public validate (value: string, args: ValidationArguments) {
    if ((args.object as InvoiceDirection).countryCode.toLowerCase() === 'mx') {
      // @ts-ignore
      const rfc: {
        isValid: boolean
        rfc: string
        type: string
      } = validateRfc(value, { omitVerificationDigit: true })
      return isNotEmpty(value) && rfc.isValid
    } else {
      return true
    }
  }
}

@Exclude()
export class InvoiceDirection extends AbstractModel {

  constructor () {
    super()
    i18n.mergeLocaleMessage('en', localeData.en)
    i18n.mergeLocaleMessage('de', localeData.de)
    i18n.mergeLocaleMessage('es', localeData.es)
  }

  static get modelName () {
    return 'InvoiceDirection'
  }

  @Validate(IsTaxIdCorrect, {
    message: (args: ValidationArguments) => {
      if (isEmpty(args.value)) {
        return i18n.t('form.contact_information_valid_msg.tax_id').toString()
      } else {
        return i18n.t('form.contact_information_valid_msg.tax_id_invalid_format').toString()
      }
    }
  })
  @Expose({ name: 'tax_id' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public taxId: string = ''

  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.name').toString() })
  @Expose({ name: 'name' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public name: string = ''

  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.name').toString() })
  @Expose({ name: 'city' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public city: string = ''

  @Expose({ name: 'country_code' })
  @Transform(value => { return (!value) ? 'MX' : value }, { toClassOnly: true })
  public countryCode: string = 'MX'

  @Expose({ name: 'state_code' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public stateCode: string = ''

  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.direction_1').toString() })
  @Expose({ name: 'direction_1' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public direction1: string = ''

  @Expose({ name: 'municipality' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public municipality: string = ''

  @Expose({ name: 'district' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public district: string = ''

  @ValidateIf(o => o.countryCode === 'MX')
  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.name').toString() })
  @Expose({ name: 'neighborhood' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public neighborhood: string = ''

  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.name').toString() })
  @Expose({ name: 'postal_code' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public postalCode: string = ''

  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.ext_number').toString() })
  @Expose({ name: 'ext_number' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public extNumber: string = ''

  @Expose({ name: 'int_number' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public intNumber: string = ''

  @Expose({ name: 'payment_method' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public paymentMethod: string = ''

  @Expose({ name: 'tax_regime' })
  public taxRegime: number | null = null

  @Expose({ name: 'bank_account_number' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public bankAccountNumber: string = ''

  @Expose({ name: 'customer_notes' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public customerNotes: string = ''

  @Expose({ name: 'enlace_payment_method' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public enlacePaymentMethod: string = ''

  @Expose({ name: 'uso_cfdi' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public usoCfdi: string = ''

  @ValidateIf(o => o.assignTo === 'user')
  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.user_id').toString() })
  @Expose({ name: 'user_id' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  public userId: number | undefined | null = null

  @Expose({ name: 'user' })
  @Type(() => EnviayaUserTS)
  public user: EnviayaUserTS | undefined

  @ValidateIf(o => o.assignTo === 'corporate_account')
  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.corporate_account_id').toString() })
  @Expose({ name: 'corporate_account_id' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  public corporateAccountId: number | undefined | null = null

  @Expose({ name: 'enviaya_account_ids' })
  @Transform(value => value, { toPlainOnly: true })
  @Transform(value => { return !value ? [] : value }, { toClassOnly: true })
  public enviayaAccountIds: number[] = []

  @Expose({ name: 'enviaya_accounts', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => EnviayaAccountTS)
  public enviayaAccounts: EnviayaAccountTS[] = []

  @Expose({ name: 'corporate_account_ids' })
  @Transform(value => { return !value ? [] : [value] }, { toPlainOnly: true })
  @Transform(value => { return !value ? null : value[0] }, { toClassOnly: true })
  public corporateAccountIds: number | undefined | null = null

  @Expose({ name: 'corporate_account' })
  public corporateAccount: Object | undefined

  @Expose({ name: 'corporate_accounts', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => CorporateAccountTs)
  public corporateAccounts: CorporateAccountTs[] = []

  public wasValidated: boolean = false
  public assignTo: string = 'user'
  public additionalAddressBlockHidden: boolean = true

  @ValidateIf(o => o.additionalAddressBlockHidden && isEmpty(o.postalCode) && isEmpty(o.neighborhood) && isEmpty(o.city))
  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.name').toString() })
  public addressSearch: string = ''

  public get showPostalCodeSearch () {
    return this.countryCode && this.countryCode.toLowerCase() !== 'ca' && this.additionalAddressBlockHidden
  }

  public get isAdditionalAddressLinkShow () {
    return this.countryCode && this.countryCode.toLowerCase() !== 'ca' && this.additionalAddressBlockHidden
  }

  public get isAdditionalAddressBlockShow () {
    return this.countryCode && this.countryCode.toLowerCase() === 'ca' || !this.additionalAddressBlockHidden
  }

  // For abilities
  public get user_id () {
    return this.userId
  }
}
