import { LOCALE_COUNTRIES_MAP, COUNTRY_LOCALES_MAP } from '@/common/common'

export const countrySelectHelper = {
  data: function () {
    return {
      countryList: [
        {
          id: 'AF',
          text: this.$t('countries_data.AF')
        },
        {
          id: 'AX',
          text: this.$t('countries_data.AX')
        },
        {
          id: 'AL',
          text: this.$t('countries_data.AL')
        },
        {
          id: 'DZ',
          text: this.$t('countries_data.DZ')
        },
        {
          id: 'AS',
          text: this.$t('countries_data.AS')
        },
        {
          id: 'AD',
          text: this.$t('countries_data.AD')
        },
        {
          id: 'AO',
          text: this.$t('countries_data.AO')
        },
        {
          id: 'AI',
          text: this.$t('countries_data.AI')
        },
        {
          id: 'AG',
          text: this.$t('countries_data.AG')
        },
        {
          id: 'AR',
          text: this.$t('countries_data.AR')
        },
        {
          id: 'AM',
          text: this.$t('countries_data.AM')
        },
        {
          id: 'AW',
          text: this.$t('countries_data.AW')
        },
        {
          id: 'AU',
          text: this.$t('countries_data.AU')
        },
        {
          id: 'AT',
          text: this.$t('countries_data.AT')
        },
        {
          id: 'AZ',
          text: this.$t('countries_data.AZ')
        },
        {
          id: 'BS',
          text: this.$t('countries_data.BS')
        },
        {
          id: 'BH',
          text: this.$t('countries_data.BH')
        },
        {
          id: 'BD',
          text: this.$t('countries_data.BD')
        },
        {
          id: 'BB',
          text: this.$t('countries_data.BB')
        },
        {
          id: 'BY',
          text: this.$t('countries_data.BY')
        },
        {
          id: 'BE',
          text: this.$t('countries_data.BE')
        },
        {
          id: 'BZ',
          text: this.$t('countries_data.BZ')
        },
        {
          id: 'BJ',
          text: this.$t('countries_data.BJ')
        },
        {
          id: 'BM',
          text: this.$t('countries_data.BM')
        },
        {
          id: 'BT',
          text: this.$t('countries_data.BT')
        },
        {
          id: 'BO',
          text: this.$t('countries_data.BO')
        },
        {
          id: 'BA',
          text: this.$t('countries_data.BA')
        },
        {
          id: 'BW',
          text: this.$t('countries_data.BW')
        },
        {
          id: 'BR',
          text: this.$t('countries_data.BR')
        },
        {
          id: 'BQ',
          text: this.$t('countries_data.BQ')
        },
        {
          id: 'IO',
          text: this.$t('countries_data.IO')
        },
        {
          id: 'BN',
          text: this.$t('countries_data.BN')
        },
        {
          id: 'BG',
          text: this.$t('countries_data.BG')
        },
        {
          id: 'BF',
          text: this.$t('countries_data.BF')
        },
        {
          id: 'BI',
          text: this.$t('countries_data.BI')
        },
        {
          id: 'KH',
          text: this.$t('countries_data.KH')
        },
        {
          id: 'CM',
          text: this.$t('countries_data.CM')
        },
        {
          id: 'CA',
          text: this.$t('countries_data.CA')
        },
        {
          id: 'CV',
          text: this.$t('countries_data.CV')
        },
        {
          id: 'KY',
          text: this.$t('countries_data.KY')
        },
        {
          id: 'CF',
          text: this.$t('countries_data.CF')
        },
        {
          id: 'TD',
          text: this.$t('countries_data.TD')
        },
        {
          id: 'CL',
          text: this.$t('countries_data.CL')
        },
        {
          id: 'CN',
          text: this.$t('countries_data.CN')
        },
        {
          id: 'CX',
          text: this.$t('countries_data.CX')
        },
        {
          id: 'CC',
          text: this.$t('countries_data.CC')
        },
        {
          id: 'CO',
          text: this.$t('countries_data.CO')
        },
        {
          id: 'KM',
          text: this.$t('countries_data.KM')
        },
        {
          id: 'CD',
          text: this.$t('countries_data.CD')
        },
        {
          id: 'CG',
          text: this.$t('countries_data.CG')
        },
        {
          id: 'CK',
          text: this.$t('countries_data.CK')
        },
        {
          id: 'CR',
          text: this.$t('countries_data.CR')
        },
        {
          id: 'CI',
          text: this.$t('countries_data.CI')
        },
        {
          id: 'HR',
          text: this.$t('countries_data.HR')
        },
        {
          id: 'CU',
          text: this.$t('countries_data.CU')
        },
        {
          id: 'CW',
          text: this.$t('countries_data.CW')
        },
        {
          id: 'CY',
          text: this.$t('countries_data.CY')
        },
        {
          id: 'CZ',
          text: this.$t('countries_data.CZ')
        },
        {
          id: 'DK',
          text: this.$t('countries_data.DK')
        },
        {
          id: 'DJ',
          text: this.$t('countries_data.DJ')
        },
        {
          id: 'DM',
          text: this.$t('countries_data.DM')
        },
        {
          id: 'DO',
          text: this.$t('countries_data.DO')
        },
        {
          id: 'EC',
          text: this.$t('countries_data.EC')
        },
        {
          id: 'EG',
          text: this.$t('countries_data.EG')
        },
        {
          id: 'SV',
          text: this.$t('countries_data.SV')
        },
        {
          id: 'GQ',
          text: this.$t('countries_data.GQ')
        },
        {
          id: 'ER',
          text: this.$t('countries_data.ER')
        },
        {
          id: 'EE',
          text: this.$t('countries_data.EE')
        },
        {
          id: 'ET',
          text: this.$t('countries_data.ET')
        },
        {
          id: 'FK',
          text: this.$t('countries_data.FK')
        },
        {
          id: 'FO',
          text: this.$t('countries_data.FO')
        },
        {
          id: 'FJ',
          text: this.$t('countries_data.FJ')
        },
        {
          id: 'FI',
          text: this.$t('countries_data.FI')
        },
        {
          id: 'FR',
          text: this.$t('countries_data.FR')
        },
        {
          id: 'GF',
          text: this.$t('countries_data.GF')
        },
        {
          id: 'PF',
          text: this.$t('countries_data.PF')
        },
        {
          id: 'GA',
          text: this.$t('countries_data.GA')
        },
        {
          id: 'GM',
          text: this.$t('countries_data.GM')
        },
        {
          id: 'GE',
          text: this.$t('countries_data.GE')
        },
        {
          id: 'DE',
          text: this.$t('countries_data.DE')
        },
        {
          id: 'GH',
          text: this.$t('countries_data.GH')
        },
        {
          id: 'GI',
          text: this.$t('countries_data.GI')
        },
        {
          id: 'GR',
          text: this.$t('countries_data.GR')
        },
        {
          id: 'GL',
          text: this.$t('countries_data.GL')
        },
        {
          id: 'GD',
          text: this.$t('countries_data.GD')
        },
        {
          id: 'GP',
          text: this.$t('countries_data.GP')
        },
        {
          id: 'GU',
          text: this.$t('countries_data.GU')
        },
        {
          id: 'GT',
          text: this.$t('countries_data.GT')
        },
        {
          id: 'GG',
          text: this.$t('countries_data.GG')
        },
        {
          id: 'GN',
          text: this.$t('countries_data.GN')
        },
        {
          id: 'GW',
          text: this.$t('countries_data.GW')
        },
        {
          id: 'GY',
          text: this.$t('countries_data.GY')
        },
        {
          id: 'HT',
          text: this.$t('countries_data.HT')
        },
        {
          id: 'VA',
          text: this.$t('countries_data.VA')
        },
        {
          id: 'HN',
          text: this.$t('countries_data.HN')
        },
        {
          id: 'HK',
          text: this.$t('countries_data.HK')
        },
        {
          id: 'HU',
          text: this.$t('countries_data.HU')
        },
        {
          id: 'IS',
          text: this.$t('countries_data.IS')
        },
        {
          id: 'IN',
          text: this.$t('countries_data.IN')
        },
        {
          id: 'ID',
          text: this.$t('countries_data.ID')
        },
        {
          id: 'IR',
          text: this.$t('countries_data.IR')
        },
        {
          id: 'IQ',
          text: this.$t('countries_data.IQ')
        },
        {
          id: 'IE',
          text: this.$t('countries_data.IE')
        },
        {
          id: 'IM',
          text: this.$t('countries_data.IM')
        },
        {
          id: 'IL',
          text: this.$t('countries_data.IL')
        },
        {
          id: 'IT',
          text: this.$t('countries_data.IT')
        },
        {
          id: 'JM',
          text: this.$t('countries_data.JM')
        },
        {
          id: 'JP',
          text: this.$t('countries_data.JP')
        },
        {
          id: 'JE',
          text: this.$t('countries_data.JE')
        },
        {
          id: 'JO',
          text: this.$t('countries_data.JO')
        },
        {
          id: 'KZ',
          text: this.$t('countries_data.KZ')
        },
        {
          id: 'KE',
          text: this.$t('countries_data.KE')
        },
        {
          id: 'KI',
          text: this.$t('countries_data.KI')
        },
        {
          id: 'KR',
          text: this.$t('countries_data.KR')
        },
        {
          id: 'KW',
          text: this.$t('countries_data.KW')
        },
        {
          id: 'KG',
          text: this.$t('countries_data.KG')
        },
        {
          id: 'LA',
          text: this.$t('countries_data.LA')
        },
        {
          id: 'LV',
          text: this.$t('countries_data.LV')
        },
        {
          id: 'LB',
          text: this.$t('countries_data.LB')
        },
        {
          id: 'LS',
          text: this.$t('countries_data.LS')
        },
        {
          id: 'LR',
          text: this.$t('countries_data.LR')
        },
        {
          id: 'LY',
          text: this.$t('countries_data.LY')
        },
        {
          id: 'LI',
          text: this.$t('countries_data.LI')
        },
        {
          id: 'LT',
          text: this.$t('countries_data.LT')
        },
        {
          id: 'LU',
          text: this.$t('countries_data.LU')
        },
        {
          id: 'MO',
          text: this.$t('countries_data.MO')
        },
        {
          id: 'MK',
          text: this.$t('countries_data.MK')
        },
        {
          id: 'MK',
          text: this.$t('countries_data.MK')
        },
        {
          id: 'MW',
          text: this.$t('countries_data.MW')
        },
        {
          id: 'MY',
          text: this.$t('countries_data.MY')
        },
        {
          id: 'MV',
          text: this.$t('countries_data.MV')
        },
        {
          id: 'ML',
          text: this.$t('countries_data.ML')
        },
        {
          id: 'MT',
          text: this.$t('countries_data.MT')
        },
        {
          id: 'MH',
          text: this.$t('countries_data.MH')
        },
        {
          id: 'MQ',
          text: this.$t('countries_data.MQ')
        },
        {
          id: 'MR',
          text: this.$t('countries_data.MR')
        },
        {
          id: 'MU',
          text: this.$t('countries_data.MU')
        },
        {
          id: 'YT',
          text: this.$t('countries_data.YT')
        },
        {
          id: 'MX',
          text: this.$t('countries_data.MX'),
          selected: 'selected'
        },
        {
          id: 'FM',
          text: this.$t('countries_data.FM')
        },
        {
          id: 'MD',
          text: this.$t('countries_data.MD')
        },
        {
          id: 'MC',
          text: this.$t('countries_data.MC')
        },
        {
          id: 'MN',
          text: this.$t('countries_data.MN')
        },
        {
          id: 'ME',
          text: this.$t('countries_data.ME')
        },
        {
          id: 'MS',
          text: this.$t('countries_data.MS')
        },
        {
          id: 'MA',
          text: this.$t('countries_data.MA')
        },
        {
          id: 'MZ',
          text: this.$t('countries_data.MZ')
        },
        {
          id: 'MM',
          text: this.$t('countries_data.MM')
        },
        {
          id: 'NA',
          text: this.$t('countries_data.NA')
        },
        {
          id: 'NR',
          text: this.$t('countries_data.NR')
        },
        {
          id: 'NP',
          text: this.$t('countries_data.NP')
        },
        {
          id: 'NL',
          text: this.$t('countries_data.NL')
        },
        {
          id: 'NC',
          text: this.$t('countries_data.NC')
        },
        {
          id: 'NZ',
          text: this.$t('countries_data.NZ')
        },
        {
          id: 'NI',
          text: this.$t('countries_data.NI')
        },
        {
          id: 'NE',
          text: this.$t('countries_data.NE')
        },
        {
          id: 'NG',
          text: this.$t('countries_data.NG')
        },
        {
          id: 'NU',
          text: this.$t('countries_data.NU')
        },
        {
          id: 'NF',
          text: this.$t('countries_data.NF')
        },
        {
          id: 'KP',
          text: this.$t('countries_data.KP')
        },
        {
          id: 'MP',
          text: this.$t('countries_data.MP')
        },
        {
          id: 'NO',
          text: this.$t('countries_data.NO')
        },
        {
          id: 'OM',
          text: this.$t('countries_data.OM')
        },
        {
          id: 'PK',
          text: this.$t('countries_data.PK')
        },
        {
          id: 'PW',
          text: this.$t('countries_data.PW')
        },
        {
          id: 'PS',
          text: this.$t('countries_data.PS')
        },
        {
          id: 'PA',
          text: this.$t('countries_data.PA')
        },
        {
          id: 'PG',
          text: this.$t('countries_data.PG')
        },
        {
          id: 'PY',
          text: this.$t('countries_data.PY')
        },
        {
          id: 'PE',
          text: this.$t('countries_data.PE')
        },
        {
          id: 'PH',
          text: this.$t('countries_data.PH')
        },
        {
          id: 'PL',
          text: this.$t('countries_data.PL')
        },
        {
          id: 'PT',
          text: this.$t('countries_data.PT')
        },
        {
          id: 'PR',
          text: this.$t('countries_data.PR')
        },
        {
          id: 'QA',
          text: this.$t('countries_data.QA')
        },
        {
          id: 'RE',
          text: this.$t('countries_data.RE')
        },
        {
          id: 'RO',
          text: this.$t('countries_data.RO')
        },
        {
          id: 'RU',
          text: this.$t('countries_data.RU')
        },
        {
          id: 'RW',
          text: this.$t('countries_data.RW')
        },
        {
          id: 'BL',
          text: this.$t('countries_data.BL')
        },
        {
          id: 'SH',
          text: this.$t('countries_data.SH')
        },
        {
          id: 'KN',
          text: this.$t('countries_data.KN')
        },
        {
          id: 'LC',
          text: this.$t('countries_data.LC')
        },
        {
          id: 'MF',
          text: this.$t('countries_data.MF')
        },
        {
          id: 'PM',
          text: this.$t('countries_data.PM')
        },
        {
          id: 'VC',
          text: this.$t('countries_data.VC')
        },
        {
          id: 'WS',
          text: this.$t('countries_data.WS')
        },
        {
          id: 'SM',
          text: this.$t('countries_data.SM')
        },
        {
          id: 'ST',
          text: this.$t('countries_data.ST')
        },
        {
          id: 'SA',
          text: this.$t('countries_data.SA')
        },
        {
          id: 'SN',
          text: this.$t('countries_data.SN')
        },
        {
          id: 'RS',
          text: this.$t('countries_data.RS')
        },
        {
          id: 'SC',
          text: this.$t('countries_data.SC')
        },
        {
          id: 'SL',
          text: this.$t('countries_data.SL')
        },
        {
          id: 'SG',
          text: this.$t('countries_data.SG')
        },
        {
          id: 'SK',
          text: this.$t('countries_data.SK')
        },
        {
          id: 'SI',
          text: this.$t('countries_data.SI')
        },
        {
          id: 'SB',
          text: this.$t('countries_data.SB')
        },
        {
          id: 'SO',
          text: this.$t('countries_data.SO')
        },
        {
          id: 'SS',
          text: this.$t('countries_data.SS')
        },
        {
          id: 'ZA',
          text: this.$t('countries_data.ZA')
        },
        {
          id: 'ES',
          text: this.$t('countries_data.ES')
        },
        {
          id: 'LK',
          text: this.$t('countries_data.LK')
        },
        {
          id: 'SD',
          text: this.$t('countries_data.SD')
        },
        {
          id: 'SR',
          text: this.$t('countries_data.SR')
        },
        {
          id: 'SJ',
          text: this.$t('countries_data.SJ')
        },
        {
          id: 'SZ',
          text: this.$t('countries_data.SZ')
        },
        {
          id: 'SE',
          text: this.$t('countries_data.SE')
        },
        {
          id: 'SX',
          text: this.$t('countries_data.SX')
        },
        {
          id: 'CH',
          text: this.$t('countries_data.CH')
        },
        {
          id: 'SY',
          text: this.$t('countries_data.SY')
        },
        {
          id: 'TW',
          text: this.$t('countries_data.TW')
        },
        {
          id: 'TJ',
          text: this.$t('countries_data.TJ')
        },
        {
          id: 'TZ',
          text: this.$t('countries_data.TZ')
        },
        {
          id: 'TH',
          text: this.$t('countries_data.TH')
        },
        {
          id: 'TL',
          text: this.$t('countries_data.TL')
        },
        {
          id: 'TG',
          text: this.$t('countries_data.TG')
        },
        {
          id: 'TK',
          text: this.$t('countries_data.TK')
        },
        {
          id: 'TO',
          text: this.$t('countries_data.TO')
        },
        {
          id: 'TT',
          text: this.$t('countries_data.TT')
        },
        {
          id: 'TN',
          text: this.$t('countries_data.TN')
        },
        {
          id: 'TR',
          text: this.$t('countries_data.TR')
        },
        {
          id: 'TM',
          text: this.$t('countries_data.TM')
        },
        {
          id: 'TC',
          text: this.$t('countries_data.TC')
        },
        {
          id: 'TV',
          text: this.$t('countries_data.TV')
        },
        {
          id: 'UG',
          text: this.$t('countries_data.UG')
        },
        {
          id: 'UA',
          text: this.$t('countries_data.UA')
        },
        {
          id: 'AE',
          text: this.$t('countries_data.AE')
        },
        {
          id: 'GB',
          text: this.$t('countries_data.GB')
        },
        {
          id: 'US',
          text: this.$t('countries_data.US')
        },
        {
          id: 'UY',
          text: this.$t('countries_data.UY')
        },
        {
          id: 'UZ',
          text: this.$t('countries_data.UZ')
        },
        {
          id: 'VU',
          text: this.$t('countries_data.VU')
        },
        {
          id: 'VE',
          text: this.$t('countries_data.VE')
        },
        {
          id: 'VN',
          text: this.$t('countries_data.VN')
        },
        {
          id: 'VG',
          text: this.$t('countries_data.VG')
        },
        {
          id: 'VI',
          text: this.$t('countries_data.VI')
        },
        {
          id: 'WF',
          text: this.$t('countries_data.WF')
        },
        {
          id: 'EH',
          text: this.$t('countries_data.EH')
        },
        {
          id: 'YE',
          text: this.$t('countries_data.YE')
        },
        {
          id: 'ZM',
          text: this.$t('countries_data.ZM')
        },
        {
          id: 'ZW',
          text: this.$t('countries_data.ZW')
        }
      ]
    }
  },
  methods: {
    getCountryByLocale (locale) {
      if (typeof locale === 'undefined') return ''
      let split = locale.toUpperCase().split(/-|_/)
      let lang = split.shift()
      let code = split.pop()
      if (!/^[A-Z]{2}$/.test(code)) {
        code = LOCALE_COUNTRIES_MAP[lang.toLowerCase()]
      }
      if (!code) {
        return ''
      }
      return code
    },
    getLocaleByCountry (country) {
      if (typeof country === 'undefined') return ''
      let locale = COUNTRY_LOCALES_MAP[country.toLowerCase()]
      if (!locale) {
        return ''
      }
      return locale.split(/,/)[0]
    }
  },
  computed: {
    preferredCountries: function () {
      let preferredCountries = []
      this.countryList.sort((a, b) => {
        if (a.text.localeCompare(b.text)) return 1
        if (a.text.localeCompare(b.text)) return -1
        return 0
      })
      this.countryList.forEach((c, index) => {
        if (c.id === 'MX' || c.id === 'CL' || c.id === 'US' || c.id === 'ES' || c.id === 'CO') {
          this.countryList.splice(index, 1)
          preferredCountries.push(c)
        }
      })
      preferredCountries.push({ id: '_', text: '', disabled: true })
      preferredCountries.reverse().forEach((p) => { this.countryList.unshift(p) })
      return this.countryList
    },
    countriesForAddressImport: function () {
      let countries = []
      countries = this.countryList.filter((item) => {
        return item.id === 'MX' || item.id === 'US' || item.id === 'CA' || item.id === 'CO' || item.id === 'EC' || item.id === 'KR'
      })
      countries.unshift({ id: 'geo', text: 'GEO Postcodes' })
      return countries
    }
  }
}
