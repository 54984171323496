import { Expose, Exclude } from 'class-transformer'

@Exclude()
export class Application {
  @Expose({ name: 'id' })
  public id: number | undefined

  @Expose({ name: 'name' })
  public name: string = ''
}
