import { AppUser } from '@/models/AppUser'
import Vue from 'vue'

export const appConfig: Record<string, any> = {
  searchDelay: 1000,
  postalCodeAddrMinCharsLimit: 2,
  strAddrMinCharsLimit: 4,
  userCharsLimit: 1,
  adminCharsLimit: 4
}

export function getCharsLimit (currentUser: AppUser): number {
  if (currentUser.role! >= 90 || Vue.$who.isVendorAdmin(currentUser)) {
    return appConfig.adminCharsLimit
  } else {
    return appConfig.userCharsLimit
  }
}
