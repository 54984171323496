import { Exclude, Expose, Transform } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'

@Exclude()
export class DiscountProfile extends AbstractModel {
  static get modelName () {
    return 'DiscountProfile'
  }

  @Expose({ name: 'name' })
  public name: string = ''

  @Expose({ name: 'level' })
  @Transform(value => value, { toClassOnly: true })
  public level: number | string | undefined

  @Expose({ name: 'status' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public status: number | undefined

  @Expose({ name: 'default_for_users' })
  @Transform(value => { return (!value) ? false : value })
  public defaultForUsers: boolean = false

  @Expose({ name: 'select_box_text' })
  public selectBoxText: string = ''

  @Expose({ name: 'description' })
  @Transform(value => { return (!value) ? '' : value })
  public description: string = ''

  @Expose({ name: 'white_label_id' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public whiteLabelId: number | null | undefined

  @Expose({ name: 'default' })
  @Transform(value => { return (!value) ? false : value })
  public default: boolean = false

  @Expose({ name: 'kw' })
  public kw: string = ''

  @Expose({ name: 'quick_onboarding' })
  @Transform(value => { return (!value) ? false : value })
  public quickOnboarding: boolean = false

  @Expose({ name: 'unsent_shipment_notifications' })
  @Transform(value => value === 1 ? true : false, { toClassOnly: true })
  public unsentShipmentNotifications: number | null = null

  @Expose({ name: 'discount_display' })
  public discountDisplay: number | null | undefined

  @Expose({ name: 'pickup_availability' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public pickupAvailability: number | null | undefined

  @Expose({ name: 'price_rounding' })
  public priceRounding: number | null | undefined

  @Expose({ name: 'override_existing_discount_profile' })
  @Transform(value => { return (!value) ? false : value })
  public overrideExistingDiscountProfile: boolean = false

  @Expose({ name: 'min_override_discount_profile_level' })
  public minOverrideDiscountProfileLevel: number | null | undefined

  @Expose({ name: 'max_override_discount_profile_level' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public maxOverrideDiscountProfileLevel: number | null | undefined

  @Expose({ name: 'override_minimum_last_shipment_period' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public overrideMinimumLastShipmentPeriod: number | null | undefined

}

@Exclude()
export class ProfilesCountByLevel {
  @Expose({ name: 'level' })
  public level: number | string | undefined

  @Expose({ name: 'discount_profile_count' })
  public discountProfileCount: number | string | undefined

}
