<template>
  <div>
    <div :class="(submitted && !isValid) ? 'select2-single-wrap is-invalid' : 'select2-single-wrap'">
      <label ref="label" :class="{ disabled: disabled }">{{placeholder}}</label>
      <span class="caret" :class="{ disabled: disabled }">▼</span>
      <select class="form-control" :name="name" :placeholder="placeholder" :disabled="disabled" :required="required" :onlyIcons="onlyIcons"/>
      <div class="invalid-feedback" v-if="invalidFeedback">{{invalidFeedback}}</div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import 'select2/dist/js/select2.full.js'
import 'select2/dist/css/select2.min.css'
import Vue from 'vue'
import SelectItem from '@/components/select/SelectItem'
import SelectSelectionIcon from '@/components/select/SelectSelectionIcon'
import SelectSelectionText from '@/components/select/SelectSelectionText'
import LangSelectSelectionIcon from '@/components/select/LangSelectSelectionIcon'
import i18n from '@/i18n'
import { countrySelectHelper } from '@/mixins/countrySelectHelper'

export default {
  name: 'Select2',
  mixins: [ countrySelectHelper ],
  data () {
    return {
      select2: null
    }
  },
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => { /**/ }
    },
    value: null,
    onlyIcons: {
      type: Boolean,
      default: false
    },
    isLanguages: {
      type: Boolean,
      default: false
    },
    invalidFeedback: {
      type: String,
      default: ''
    },
    submitted: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    options (val) {
      this.setOption(val)
    },
    value (val) {
      this.setValue(val)
    }
  },
  methods: {
    setOption (val = []) {
      let settings = {
        ...this.settings,
        'templateResult': (() => {
          if (this.isLanguages) {
            return this.langTemplateResult
          } else {
            return this.templateResult
          }
        })(),
        'templateSelection': (() => {
          if (this.isLanguages) {
            return this.onlyIcons ? this.langTemplateSelectionIcon : this.langTemplateSelectionText
          } else {
            return this.onlyIcons ? this.templateSelectionIcon : this.templateSelectionText
          }
        })()
      }
      this.select2.empty()
      let popup = $(this.$el).parents('.modal')
      this.select2.select2({
        dropdownParent: (popup.length) ? popup : this.$parent.$el,
        ...settings,
        data: val
      })
      this.setValue(this.value)
    },
    setValue (val) {
      if (val instanceof Array) {
        this.select2.val([...val])
      } else {
        this.select2.val([val])
      }
      if (this.select2.val() && this.$refs.label && this.$refs.label.classList) {
        this.$refs.label?.classList.add(['active'])
      }
      this.select2.trigger('change')
    },
    templateSelectionIcon (item) {
      if (!item.id) {
        return item.text
      }
      let ComponentClass = Vue.extend(SelectSelectionIcon)
      let instance = new ComponentClass({
        propsData: { text: item.text, val: item.id },
        i18n: i18n
      })
      instance.$mount()
      return instance.$el
    },
    templateSelectionText (item) {
      if (!item.id) {
        return item.text
      }
      let ComponentClass = Vue.extend(SelectSelectionText)
      let instance = new ComponentClass({
        propsData: { text: item.text, val: item.id },
        i18n: i18n
      })
      instance.$mount()
      return instance.$el
    },
    langTemplateSelectionIcon (item) {
      if (!item.id) {
        return item.text
      }
      let ComponentClass = Vue.extend(LangSelectSelectionIcon)
      let instance = new ComponentClass({
        propsData: { text: item.text, val: item.id },
        i18n: i18n
      })
      instance.$mount()
      return instance.$el
    },
    langTemplateSelectionText (item) {
      if (!item.id) {
        return item.text
      }
      let $result = $('<span></span>')
      $result.text(item.text)
      if (item.id) {
        $result.prepend(`<img class="img-flag mr-2" src="${require(`../../assets/img/flag/4x3/${this.getCountryByLocale(item.id).toLowerCase()}.svg`)}" width="20">`)
      }
      return $result
    },
    templateResult (item) {
      let ComponentClass = Vue.extend(SelectItem)
      let instance = new ComponentClass({
        propsData: { text: item.text, val: item.id }
      })
      instance.$mount()
      return instance.$el
    },
    langTemplateResult (item) {
      if (!item.id) {
        return item.text
      }
      let $result = $('<span></span>')
      $result.text(item.text)
      if (item.id) {
        $result.prepend(`<img class="img-flag mr-2" src="${require(`../../assets/img/flag/4x3/${this.getCountryByLocale(item.id).toLowerCase()}.svg`)}" width="20">`)
      }
      return $result
    }
  },
  mounted () {
    let settings = {
      ...this.settings
    }

    if (this.isLanguages) {
      settings.templateResult = this.langTemplateResult
      if (this.onlyIcons) {
        settings.templateSelection = this.langTemplateSelectionIcon
      } else {
        settings.templateSelection = this.langTemplateSelectionText
      }
    } else {
      settings.templateResult = this.templateResult
      if (this.onlyIcons) {
        settings.templateSelection = this.templateSelectionIcon
      } else {
        settings.templateSelection = this.templateSelectionText
      }
    }

    this.select2 = $(this.$el).find('select')
    this.$nextTick(() => {
      let popup = $(this.$el).parents('.modal')
      this.select2.select2({
        dropdownParent: (popup.length) ? popup : this.$parent.$el,
        ...settings,
        data: this.options
      })
        .on('select2:select select2:unselect', ev => {
          this.$emit('change', this.select2.val())
          this.$emit('select', ev['params']['data'])
        })
        .on('change', e => {
          if (this.select2.val()) {
            this.$refs.label?.classList.add(['active'])
          } else {
            this.$refs.label?.classList.remove(['active'])
          }
        })
        .on('select2:open', e => {
          this.$refs.label?.classList.add(['active'])
        })
        .on('select2:close', e => {
          if (!this.select2.val()) {
            this.$refs.label?.classList.remove(['active'])
          }
        })

      if (this.select2.val()) {
        this.$refs.label?.classList.add(['active'])
      }
      this.setValue(this.value)
    })
  },
  beforeDestroy () {
    this.$nextTick(() => {
      if (this.select2) {
        this.select2.select2('destroy')
      }
    })
  }
}
</script>

<style scoped>
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 4px;
  }

  .form-control + >>> .select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
  }

  >>> .select2-selection__rendered {
    margin-bottom: -6px!important;
  }

  .caret {
    position: absolute;
    bottom: 0.625rem;
    right: .5rem;
    font-size: .63rem!important;
    color: #757575;
    z-index: 1;
    cursor: pointer;
  }
  >>> .select2-selection__rendered {
    padding-left: 0!important;
  }

  >>> .select2-container--default .select2-selection--single {
    outline: none!important;
    border-top:none!important;
    border-left:none!important;
    border-right:none!important;
    border-radius: 0!important;
    border-bottom: 1px solid #ced4da!important;
    padding-bottom: 5px!important;
  }

  .is-invalid >>> .select2-container--default .select2-selection--single {
    border-bottom: 1px solid red!important;
  }

  .select2-single-wrap.is-invalid >>> .invalid-feedback {
    display: inline-block;
  }

  .select2-single-wrap.is-valid >>> .invalid-feedback {
    display: none;
  }

  >>> .select2-selection__choice {
    border: none!important;
    background-color: #fff!important;
    display: flex;
    flex-direction: row-reverse;
    padding-left: 0!important;
  }

  >>> .select2-selection__clear {
    /*margin-left: 2px;*/
    /*margin-top: -7px;*/
    /*margin-right: 5px!important;*/
    color: red!important;
  }

  >>> .select2-results__option {
    color: #757575;
  }

  >>> .select2-container {
    width: 100% !important;
    z-index: 2;
    /*max-width: 350px;*/
  }

  >>> .select2-search__field {
    width: 100% !important;
    font-size: 0.9rem !important;
  }

  /*.select2-single-wrap label {*/
  /*  position: absolute;*/
  /*  color: #757575;*/
  /*  margin-top: -4px;*/
  /*}*/

  /*.select2-single-wrap label.active {*/
  /*  margin-top: -20px;*/
  /*  font-size: 0.8rem;*/
  /*  z-index: 1;*/
  /*}*/

  >>> .select2-container--default .select2-selection--single {
    background: transparent !important;
  }

  >>> .select2-selection__rendered {
    line-height: 20px !important;
  }

  /*>>> .select2-search--inline  {*/
  /*  width: calc(100% - 16px);*/
  /*}*/

  >>> .select2-container--default.select2-container--disabled span {
    color: #757575;
  }

  .caret.disabled  {
    color: #c3c3c3 !important;
  }
</style>
