
import { Component, Prop } from 'vue-property-decorator'
import BasicComponent from '@/views/BasicComponent'
import { Styles } from '@/mixins/styles'

@Component({
  mixins: [Styles]
})
export default class EnviayaSpinner extends BasicComponent {
  @Prop({ default: '' }) public readonly text!: string
  @Prop({ default: false }) public readonly appLoading!: boolean

  public loadingText = ''

  public created () {
    if (this.text) {
      this.loadingText = this.text
    } else {
      this.loadingText = this.$t('app.loading').toString()
    }
  }
}
