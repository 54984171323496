import Vue from 'vue'

// MDB components

// @ts-ignore
import mdbContainer from 'mdbvue/lib/components/mdbContainer'
// @ts-ignore
import mdbProgress from 'mdbvue/lib/components/mdbProgress'
// @ts-ignore
import mdbCard from 'mdbvue/lib/components/mdbCard'
// @ts-ignore
import mdbCardBody from 'mdbvue/lib/components/mdbCardBody'
// @ts-ignore
import mdbRow from 'mdbvue/lib/components/mdbRow'
// @ts-ignore
import mdbCol from 'mdbvue/lib/components/mdbCol'
// @ts-ignore
import mdbTooltip from 'mdbvue/lib/components/mdbTooltip'
// @ts-ignore
import mdbPopover from 'mdbvue/lib/components/mdbPopover'
// @ts-ignore
import mdbAlert from 'mdbvue/lib/components/mdbAlert'
// @ts-ignore
import mdbBadge from 'mdbvue/lib/components/mdbBadge'
// @ts-ignore
import mdbTabs from 'mdbvue/lib/components/mdbTabs'
// @ts-ignore
import mdbTabItem from 'mdbvue/lib/components/mdbTabItem'
// @ts-ignore
import mdbTabContent from 'mdbvue/lib/components/mdbTabContent'
// @ts-ignore
import mdbTabPane from 'mdbvue/lib/components/mdbTabPane'
// @ts-ignore
import mdbSpinner from 'mdbvue/lib/components/mdbSpinner'
// @ts-ignore
import mdbBtn from 'mdbvue/lib/components/mdbBtn'
// @ts-ignore
import mdbIcon from 'mdbvue/lib/components/mdbIcon'
// @ts-ignore
import mdbChip from 'mdbvue/lib/components/mdbChip'
// @ts-ignore
import mdbInput from 'mdbvue/lib/components/mdbInput'
// @ts-ignore
import mdbSelect from 'mdbvue/lib/components/mdbSelect'
// @ts-ignore
import mdbFileInput from 'mdbvue/lib/components/mdbFileInput'
// @ts-ignore
import mdbDropdown from 'mdbvue/lib/components/mdbDropdown'
// @ts-ignore
import mdbDropdownToggle from 'mdbvue/lib/components/mdbDropdownToggle'
// @ts-ignore
import mdbDropdownMenu from 'mdbvue/lib/components/mdbDropdownMenu'
// @ts-ignore
import mdbDropdownItem from 'mdbvue/lib/components/mdbDropdownItem'
// @ts-ignore
import mdbTbl from 'mdbvue/lib/components/mdbTbl'
// @ts-ignore
import mdbTblHead from 'mdbvue/lib/components/mdbTblHead'
// @ts-ignore
import mdbTblBody from 'mdbvue/lib/components/mdbTblBody'
// @ts-ignore
import mdbModal from 'mdbvue/lib/components/mdbModal'
// @ts-ignore
import mdbModalBody from 'mdbvue/lib/components/mdbModalBody'
// @ts-ignore
import mdbModalFooter from 'mdbvue/lib/components/mdbModalFooter'
// @ts-ignore
import mdbModalTitle from 'mdbvue/lib/components/mdbModalTitle'
// @ts-ignore
import mdbModalHeader from 'mdbvue/lib/components/mdbModalHeader'
// @ts-ignore
import mdbCollapse from 'mdbvue/lib/components/mdbCollapse'
// @ts-ignore
import mdbCardHeader from 'mdbvue/lib/components/mdbCardHeader'
// @ts-ignore
import mdbSwitch from 'mdbvue/lib/components/mdbSwitch'

import enviayaDropdownItem from '@/overrides/mdb-components/EnviayaDropdownItem.vue'
import enviayaTabs from '@/overrides/mdb-components/EnviayaTabs.vue'
import enviayaColoredTabs from '@/overrides/mdb-components/EnviayaColoredTabs.vue'

import DataProvider from '@/vue3-migration/core/DataProvider.vue'
import AsyncDataProvider from '@/vue3-migration/core/AsyncDataProvider.vue'
import OrderTypesProvider from '@/vue3-migration/core/OrderTypesProvider.vue'

// @ts-ignore
import { enviayaSelect } from '@/overrides/Select.vue'

Vue.component('mdb-container', mdbContainer)
Vue.component('mdb-progress', mdbProgress)
Vue.component('mdb-row', mdbRow)
Vue.component('mdb-col', mdbCol)
Vue.component('mdb-card', mdbCard)
Vue.component('mdb-card-header',mdbCardHeader)
Vue.component('mdb-card-body', mdbCardBody)
Vue.component('mdb-card-header', mdbCardHeader)
Vue.component('mdb-tooltip', mdbTooltip)
Vue.component('mdb-popover', mdbPopover)
Vue.component('mdb-alert', mdbAlert)
Vue.component('mdb-badge', mdbBadge)

Vue.component('mdb-tabs', mdbTabs)
Vue.component('mdb-tab-item', mdbTabItem)
Vue.component('mdb-tab-content', mdbTabContent)
Vue.component('mdb-tab-pane', mdbTabPane)

Vue.component('mdb-spinner', mdbSpinner)
Vue.component('mdb-btn', mdbBtn)
Vue.component('mdb-icon', mdbIcon)
Vue.component('mdb-chip', mdbChip)
Vue.component('mdb-input', mdbInput)
Vue.component('mdb-select', mdbSelect)
Vue.component('mdb-file-input', mdbFileInput)

Vue.component('mdb-dropdown', mdbDropdown)
Vue.component('mdb-dropdown-toggle', mdbDropdownToggle)
Vue.component('mdb-dropdown-menu', mdbDropdownMenu)
Vue.component('mdb-dropdown-item', mdbDropdownItem)

Vue.component('mdb-tbl', mdbTbl)
Vue.component('mdb-tbl-head', mdbTblHead)
Vue.component('mdb-tbl-body', mdbTblBody)

Vue.component('mdb-modal', mdbModal)
Vue.component('mdb-modal-header', mdbModalHeader)
Vue.component('mdb-modal-title', mdbModalTitle)
Vue.component('mdb-modal-body', mdbModalBody)
Vue.component('mdb-modal-footer', mdbModalFooter)
Vue.component('mdb-collapse', mdbCollapse)
Vue.component('mdb-switch', mdbSwitch)

// ENVIAYA components

import EnviayaSpinner from '@/components/EnviayaSpinner.vue'
import Typeahead from '@/components/typeahead/Typeahead.vue'
import Select2 from '@/components/select/Select2.vue'
import Select2CitySearch from '@/components/select/select2-cities-search.vue'
import MdbBasicSelect from '@/components/select-boxes/MdbBasicSelect.vue'
import WhiteLabelSelectBox from '@/components/select-boxes/WhiteLabelSelectBox.vue'
import WhiteLabelMultiSelectBox from '@/components/select-boxes/WhiteLabelMultiSelectBox.vue'
import EnviayaAccountSelectBox from '@/components/select-boxes/EnviayaAccountSelectBox.vue'
import EnviayaAccountMultiSelectBox from '@/components/select-boxes/EnviayaAccountMultiSelectBox.vue'
import EnviayaUserSelectBox from '@/components/select-boxes/EnviayaUserSelectBox.vue'
import CorporateAccountSelectBox from '@/components/select-boxes/CorporateAccountSelectBox.vue'
import ReferrerSelectBox from '@/components/select-boxes/ReferrerSelectBox.vue'
import CarriersSelectBox from '@/components/select-boxes/CarriersSelectBox.vue'
import ApplicationsSelectBox from '@/components/select-boxes/ApplicationsSelectBox.vue'
import CarrierAccountSelectBox from '@/components/select-boxes/CarrierAccountSelectBox.vue'
import PackagingSelectBox from '@/components/select-boxes/PackagingSelectBox.vue'
import QuickShippingActionsSelectBox from '@/components/select-boxes/QuickShippingActionsSelectBox.vue'
import CurrenciesSelectBox from '@/components/select-boxes/CurrenciesSelectBox.vue'
import ServiceCodesSelectBox from '@/components/select-boxes/ServiceCodesSelectBox.vue'
import EnviayaModal from '@/components/popup/EnviayaModal.vue'
import CarrierAccountModal from '@/components/popup/CarrierAccountModal.vue'
import EnviayaCheckbox from '@/components/EnviayaCheckbox.vue'
import MdbBasicMultiSelect from '@/components/select-boxes/MdbBasicMultiSelect.vue'

Vue.component('enviaya-spinner', EnviayaSpinner)
Vue.component('enviaya-checkbox', EnviayaCheckbox)
Vue.component('enviaya-modal', EnviayaModal)
Vue.component('carrier-account-modal', CarrierAccountModal)
Vue.component('Typeahead', Typeahead)
// @ts-ignore
Vue.component('Select2', Select2)
// @ts-ignore
Vue.component('select2-cities-search', Select2CitySearch)
Vue.component('mdb-basic-select', MdbBasicSelect)
Vue.component('mdb-basic-multi-select', MdbBasicMultiSelect)
Vue.component('white-label-select-box', WhiteLabelSelectBox)
Vue.component('white-label-multi-select-box', WhiteLabelMultiSelectBox)

Vue.component('enviaya-account-select-box', EnviayaAccountSelectBox)
Vue.component('enviaya-account-multi-select-box', EnviayaAccountMultiSelectBox)
Vue.component('enviaya-user-select-box', EnviayaUserSelectBox)
Vue.component('corporate-account-select-box', CorporateAccountSelectBox)
Vue.component('referrer-select-box', ReferrerSelectBox)
Vue.component('carriers-select-box', CarriersSelectBox)
Vue.component('applications-select-box', ApplicationsSelectBox)
Vue.component('carrier-account-select-box', CarrierAccountSelectBox)
// @ts-ignore
Vue.component('packaging-select-box', PackagingSelectBox)
Vue.component('quick-shipping-actions-select-box', QuickShippingActionsSelectBox)
Vue.component('currencies-select-box', CurrenciesSelectBox)
Vue.component('service-codes-select-box', ServiceCodesSelectBox)

Vue.component('enviaya-dropdown-item', enviayaDropdownItem)
Vue.component('enviaya-tabs', enviayaTabs)
Vue.component('enviaya-colored-tabs', enviayaColoredTabs)

Vue.component('data-provider', DataProvider)
Vue.component('async-data-provider', AsyncDataProvider)
Vue.component('order-types-provider', OrderTypesProvider)
Vue.component('enviaya-select', enviayaSelect)
