<template>
    <mdb-row>
      <mdb-col>
        <div class="body-content-container">
          <div class="icon-container" :class="iconContainerColor(options.type)">
            <div class="icon-circle" :class="iconCircleColor(options.type)">
              <i :class="iconColor(options.type)"></i>
            </div>
          </div>
          <p v-if="options.bodyAsHtml" v-html="options.bodyText" class="n-text"></p>
          <p v-else class="n-text">{{options.bodyText}}</p>
        </div>
      </mdb-col>
    </mdb-row>
</template>

<script>

const defaultBodyIcons = {
  success: 'check-circle',
  info: 'info-circle',
  warning: 'exclamation-circle',
  danger: 'exclamation-circle'
}

export default {
  name: 'NotificationBody',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      bodyIcon: this.options.bodyIcon || defaultBodyIcons[this.options.type] || 'info-circle'
    }
  },
  methods: {
    iconContainerColor (type) {
      return `icon-container-${type}`
    },
    iconCircleColor (type) {
      return `icon-circle-${type}`
    },
    iconColor (type) {
      switch (type) {
        case 'danger':
        case 'warning':
          return `fa-sharp fa-regular fa-circle-exclamation ${type}`
        case 'notify':
          return `fa-solid fa-pen-to-square ${type}`
        case 'info':
          return `fa-solid fa-circle-info ${type}`
        case 'delete':
          return `fa-solid fa-trash-can ${type}`
        case 'success':
          return `fa-regular fa-circle-check ${type}`
        default:
          return `fa-solid fa-pen-to-square ${type}`
      }
    }
  }
}
</script>

<style scoped>
  .body-content-container {
    text-align: center;
  }

  .body-content-container >>> a {
    color: #3fa2f7;
  }

  .icon-container {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 33% 67% 48% 52% / 21% 65% 35% 79%;
  }

  .icon-container.icon-container-info {
    background: #EBF6FF;
  }

  .icon-container.icon-container-modify {
    background: #EBF6FF;
  }

  .icon-container.icon-container-delete {
    background: #FFECEC;
  }

  .icon-container.icon-container-danger {
    background: #FFECEC;
  }

  .icon-container.icon-container-warning {
    background: #ffedc8;
  }

  .icon-container.icon-container-success {
    background: #EBFFEF;
  }

  .icon-circle {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    margin: 0 auto;
  }

  .icon-circle.icon-circle-info {
    background: #E1F1FF;
  }

  .icon-circle.icon-circle-modify {
    background: #E1F1FF;
  }

  .icon-circle.icon-circle-delete {
    background: #FFF4F4;
  }

  .icon-circle.icon-circle-danger {
    background: #FFF4F4;
  }

  .icon-circle.icon-circle-warning {
    background: #fbe079;
  }

  .icon-circle.icon-circle-success {
    background: #D2FFDC;
  }

  .icon-circle i {
    position: absolute;
    top: 32%;
    left: 35%;
    font-size: 3rem;
  }

  .icon-circle i.info {
    color: #0D7EE7;
  }

  .icon-circle i.modify {
    color: #0D7EE7;
  }

  .icon-circle i.delete {
    color: #D72525;
  }

  .icon-circle i.danger {
    color: #D72525;
  }

  .icon-circle i.warning {
    color: #fa0;
  }

  .icon-circle i.success {
    color: #50BF69;
  }

  p.n-header {
    color: #1F1F1F;
    margin-top: 1rem;
    font-weight: 600;
    font-size: 22px;
  }
  p.n-text {
    font-size: 16px;
  }
</style>
