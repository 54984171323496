import ApiService from '@/common/api/api.service'
import { CorporateAccountStatuses } from '@/constants/corporate-account'
import { AbstractModelList } from '@/models/abstract-model-list'
import { AccountList } from '@/models/account-list'
import { Audit } from '@/models/Audit'
import { CorporateAccountList } from '@/models/corporate-account-list'
import { CorporateAccountTs, OnboardingData } from '@/models/corporate-account-ts'
import { EnviayaAccountTS } from '@/models/EnviayaAccountTS'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { ReferrerTS } from '@/models/ReferrerTS'
import CorporateAccountQuery from '@/models/search-filters/corporate-account-query'
import { WebhookConfiguration } from '@/models/WebhookConfiguration'
import { AxiosResponse } from 'axios'
import { plainToClass, plainToClassFromExist } from 'class-transformer'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import get from 'lodash/get'
import moment from 'moment'
import { ISO_DATE_FORMAT } from '../utility'
import AccountsApiService from './accounts.api.service'
import GraphQLService from './graphql.service'
import QuotingFilterApiService from './quoting-filter.api.service'
import { timeToNum } from '@/models/quoting-filter'
import { AuthModule } from '@/store/auth.module'
import Vue from 'vue'
import { UserCarrierAccountsList } from '@/models/user-carrier-accounts-list'
import { CarrierAccount } from '@/models/carrier-account'

export class CorporateAccountsApiService {
  public static search (query: string): Promise<AxiosResponse> {
    return ApiService.query('search_corporate_account', {
      params: { query }
    })
  }

  public static getCorporateAccounts (): Promise<AxiosResponse> {
    return ApiService.query(`corporate_accounts`, {})
  }

  public static getCorporateAccountForUser (corporateAccountId: number): Promise<AxiosResponse> {
    return ApiService.get(`corporate_accounts/${corporateAccountId}`)
  }

  public static get (slug: any) {
    return ApiService.get('corporate_accounts', slug)
  }


  public static async getAccountsByIds (ids: number[]): Promise<CorporateAccountTs[]> {
    let { data } = await ApiService.query(`corporate_accounts`, {
      params: { 'corporate_account_ids': ids }
    })
    return data.models.map((account: any) => { return plainToClass(CorporateAccountTs, account) } )
  }

  public static getGQL (id: any) {
    return GraphQLService.query(`
      query corporateAccount($id: ID!) {
        corporateAccount(id: $id) {
          ${this.corporateAccountGraphQLStruct()}
        }
      }
    `, {
      id
    })
  }

  public static quickOnboardingGQL (
    corporateAccount: CorporateAccountTs,
    onboardingData: OnboardingData
  ) {
    const { enviayaAccountId, totalAmount, saldoType, addFreeSaldo, currency, notifyCustomerAboutGift } = onboardingData
    const { id } = corporateAccount
    const { id: discountProfileId } = corporateAccount.discountProfile!

    return GraphQLService.query(`
      mutation corporateAccount(
        $id: ID!
        $enviayaAccountId: ID
        $discountProfileId: ID
        $totalAmount: Int
        $saldoType: Int
        $addFreeSaldo: Boolean
        $notifyCustomerAboutGift: Boolean
        $currency: String
      ) {
        quickOnboardingCorporateAccount (
          input: {
            id: $id
            enviayaAccountId: $enviayaAccountId
            discountProfileId: $discountProfileId
            totalAmount: $totalAmount
            saldoType: $saldoType
            addFreeSaldo: $addFreeSaldo
            notifyCustomerAboutGift: $notifyCustomerAboutGift
            currency: $currency
          }
        ) {
          corporateAccount {
            id
          }
          errors
        }
      }
    `, {
      id,
      discountProfileId,
      enviayaAccountId,
      totalAmount: parseInt(totalAmount as string, 10),
      saldoType,
      addFreeSaldo,
      currency,
      notifyCustomerAboutGift
    })
  }

  public static updateGQL (
    corporateAccount: CorporateAccountTs
  ) {
    const {
      id,
      company,
      status,
      creditLine,
      invoicingPeriod,
      paymentPeriod,
      gracePeriod,
      invoicingSchedule,
      nextInvoicingDate,
      nextTransactionFeeInvoicingDate,
      nextResellerInvoicingDate,
      nextResellerTransactionFeeInvoicingDate,
      currency,
      customDiscountsEnabled,
      enableThirdPartyProviders,
      invoicingEmails,
      invoiceDirectionId,
      includeVat,
      insurance,
      importTaxesPayment,
      insuranceAmount,
      insuranceCurrency,
      referrerId,
      whiteLabelId,
      excludeFromMarketing,
      customSurchargeDiscountsEnabled,
      verificationStatus,
      serviceNameLayout,
      quotingFilter,
      destinationCompany,
      carrierAccountTypes,
      passwordSecurity,
      force2fa
    } = corporateAccount

    const directionId = corporateAccount.direction?.id
    const vipSupportAgentId = corporateAccount.vipSupport?.vipSupportAgentId
    const level = corporateAccount.customerInfo?.level
    const discountProfileId = corporateAccount.discountProfile?.id
    const {
      transactionFeePercentage,
      transactionFeeFixedNetAmount,
      transactionFeeFixedCurrency,
      feeType
    } = corporateAccount.fee

    let advancedQuotingFilter = cloneDeep(quotingFilter)

    if (advancedQuotingFilter) {
      advancedQuotingFilter.sameDayAdditionalHours = timeToNum(advancedQuotingFilter.sameDayAdditionalHours)
      advancedQuotingFilter.nextDayAdditionalHours = timeToNum(advancedQuotingFilter.nextDayAdditionalHours)
      advancedQuotingFilter.globalAdditionalHours = timeToNum(advancedQuotingFilter.globalAdditionalHours)
    }

    const toInt = (value: any) => value instanceof Number ? value : parseInt(value || '', 10)
    const toFloat = (value: any) => value instanceof Number ? value : parseFloat(value || '')
    const formatDate = (value: any) => moment(value, 'L', true).isValid() ? moment(value, 'L', true).format(ISO_DATE_FORMAT) : undefined

    return GraphQLService.query(`
      mutation corporateAccount(
        $id: ID!
        $company: String
        $status: Int
        $verificationStatus: Int
        $directionId: ID
        $vipSupportAgentId: ID
        $level: Int,
        $discountProfileId: ID,
        $whiteLabelId: ID,
        $customDiscountsEnabled: Boolean,
        $enableThirdPartyProviders: Boolean,
        $invoiceDirectionId: ID,
        $invoicingEmails: [String],
        $creditLine: Int,
        $currency: String,
        $invoicingPeriod: Int,
        $paymentPeriod: Int,
        $gracePeriod: Int,
        $invoicingSchedule: Int,
        $nextInvoicingDate: ISO8601Date,
        $nextTransactionFeeInvoicingDate: ISO8601Date,
        $nextResellerInvoicingDate: ISO8601Date,
        $nextResellerTransactionFeeInvoicingDate: ISO8601Date,
        $resellerWhiteLabelId: ID,
        $transactionFeePercentage: Float,
        $transactionFeeFixedNetAmount: Float,
        $transactionFeeFixedCurrency: String,
        $feeType: Int,
        $includeVat: Boolean,
        $insurance: String,
        $importTaxesPayment: Int,
        $insuranceAmount: Int,
        $insuranceCurrency: String,
        $referrerId: ID,
        $excludeFromMarketing: Boolean,
        $customSurchargeDiscountsEnabled: Boolean,
        $serviceNameLayout: Int
        $advancedQuotingFilter: JSON
        $destinationCompany: String
        $carrierAccountTypes: Int
        $passwordSecurity: Int
        $force2fa: Boolean
      ) {
        updateCorporateAccount(
          input: {
            id: $id
            corporateAccount: {
              company: $company
              status: $status
              verificationStatus: $verificationStatus
              directionId: $directionId
              customDiscountsEnabled: $customDiscountsEnabled
              enableThirdPartyProviders: $enableThirdPartyProviders
              invoiceDirectionId: $invoiceDirectionId
              creditLine: $creditLine
              currency: $currency
              invoicingPeriod: $invoicingPeriod
              paymentPeriod: $paymentPeriod
              gracePeriod: $gracePeriod
              invoicingSchedule: $invoicingSchedule
              nextInvoicingDate: $nextInvoicingDate
              nextTransactionFeeInvoicingDate: $nextTransactionFeeInvoicingDate
              nextResellerInvoicingDate: $nextResellerInvoicingDate
              nextResellerTransactionFeeInvoicingDate: $nextResellerTransactionFeeInvoicingDate
              resellerWhiteLabelId: $resellerWhiteLabelId
              invoicingEmails: $invoicingEmails
              includeVat: $includeVat
              insurance: $insurance
              importTaxesPayment: $importTaxesPayment
              insuranceAmount: $insuranceAmount
              insuranceCurrency: $insuranceCurrency
              referrerId: $referrerId
              excludeFromMarketing: $excludeFromMarketing
              customSurchargeDiscountsEnabled: $customSurchargeDiscountsEnabled
              serviceNameLayout: $serviceNameLayout
              fee: {
                transactionFeePercentage: $transactionFeePercentage,
                feeType: $feeType,
                transactionFeeFixedNetAmount: $transactionFeeFixedNetAmount,
                transactionFeeFixedCurrency: $transactionFeeFixedCurrency
              }
              vipSupport: {
                vipSupportAgentId: $vipSupportAgentId
              }
              customerInfo: {
                level: $level
              }
              discountProfileId: $discountProfileId
              whiteLabelId: $whiteLabelId
              advancedQuotingFilter: $advancedQuotingFilter
              destinationCompany: $destinationCompany
              carrierAccountTypes: $carrierAccountTypes
              passwordSecurity: $passwordSecurity
              force2fa: $force2fa
            }
          }
        ) {
          corporateAccount {
            ${this.corporateAccountGraphQLStruct()}
          }
          errors
        }
      }
    `, omitBy({
      id,
      company,
      status,
      verificationStatus,
      directionId,
      vipSupportAgentId,
      level,
      discountProfileId,
      whiteLabelId,
      feeType,
      transactionFeeFixedCurrency,
      invoiceDirectionId,
      invoicingEmails,
      currency,
      includeVat,
      insurance,
      importTaxesPayment,
      customDiscountsEnabled,
      enableThirdPartyProviders,
      referrerId,
      excludeFromMarketing,
      customSurchargeDiscountsEnabled,
      serviceNameLayout,
      invoicingSchedule,
      insuranceAmount: toInt(insuranceAmount),
      insuranceCurrency,
      creditLine: toInt(creditLine),
      invoicingPeriod: toInt(invoicingPeriod),
      paymentPeriod: toInt(paymentPeriod),
      gracePeriod: toInt(gracePeriod),
      transactionFeePercentage: toFloat(transactionFeePercentage),
      transactionFeeFixedNetAmount: toFloat(transactionFeeFixedNetAmount),
      nextInvoicingDate: formatDate(nextInvoicingDate),
      nextTransactionFeeInvoicingDate: formatDate(nextTransactionFeeInvoicingDate),
      nextResellerInvoicingDate: formatDate(nextResellerInvoicingDate),
      nextResellerTransactionFeeInvoicingDate: formatDate(nextResellerTransactionFeeInvoicingDate),
      advancedQuotingFilter,
      destinationCompany: destinationCompany ? 'mandatory' : 'optional',
      carrierAccountTypes,
      passwordSecurity,
      force2fa
    }, isNil))
  }

  public static corporateAccountGraphQLStruct () {
    return `
      id
      createdAt
      company
      status
      accountType
      customDiscountsEnabled
      customSurchargeDiscountsEnabled
      enableThirdPartyProviders
      creditLine
      currency
      paymentPeriod
      gracePeriod
      invoicingPeriod
      invoicingSchedule
      nextInvoicingDate
      nextResellerInvoicingDate
      nextTransactionFeeInvoicingDate
      nextResellerTransactionFeeInvoicingDate
      includeVat
      insurance
      importTaxesPayment
      insuranceAmount
      insuranceCurrency
      serviceNameLayout
      referrerId
      excludeFromMarketing
      verificationStatus
      creditSaldo
      nationalSaldo
      internationalSaldo
      hasTransactions,
      destinationCompany,
      carrierAccountTypes,
      enviayaAccounts {
        id
        creditLine
      }
      carrierAccounts {
        id
      }
      invoicingEmails
      confirmedInvoicingEmails
      fee {
        transactionFeeFixedNetAmount
        transactionFeeFixedCurrency
        transactionFeePercentage
        feeType
      }
      resellerWhiteLabel {
        id
        enableReseller
        skipResellerRating
        operationMode
      }
      whiteLabel {
        id
        enableReseller
        skipResellerRating
        operationMode
      }
      invoiceDirection {
        id
        name
        taxId
      }
      vipSupport {
        id
        vipSupportAgentId
      }
      customerInfo {
        id
        level
        leadStatus
        category
        clientType
      }
      direction {
        id
      }
      discountProfile {
        id
        name
        selectBoxText
        level
      }
      shops {
        id
        provider
        status
        shopDomain
      }
      invitedUsers {
        id
        firstname
        lastname
        email
      }
      invitedCorporates {
        id
        company
      }
      contractedShipments {
        enviayaId
        date
        referrerId
        userEmail
        totalAmount
        carrierTrackingNumber
        getTimezone
        createdAt
        bookingTime
        carrier {
          name
        }
      }
      quotingFilter {
        ${QuotingFilterApiService.quotingFilterDataStrucGraphql()}
      }
      salesExecutiveAssignations {
        id
        salesExecutiveId
        assignedFrom
        assignedUntil
        approvedAt
        approvedBy
        corporateAccount {
          id
          company
        }
        corporateAccountId
        enviayaAccount {
          id
          account
          alias
        }
        enviayaAccountId
        salesExecutive {
          id
          firstname
          lastname
          email
        }
        salesExecutiveId
        user {
          id
          firstname
          lastname
          email
          whiteLabelId
        }
        userId
      }
      administrators {
        ${this.administratorsStructGQL()}
      }
      administratorIds
      groups {
        name
        usersCount
      }
      whiteLabelId,
      recalculateSaldoJobs,
      force2fa,
      passwordSecurity
    `
  }

  public static corporateAccountGraphQLIndexStruct () {
    return `
      id
      createdAt
      company
      accountType
      whiteLabelId
      administrators {
        ${this.administratorsStructGQL()}
      }
      status,
      enviayaAccounts {
        id
        creditLine
      }
      resellerWhiteLabel {
        id
        enableReseller
        operationMode
      }
      whiteLabel {
        id
        enableReseller
        operationMode
      }
      invoiceDirection {
        id
        name
        taxId
      }
      fee {
        transactionFeeFixedNetAmount
        transactionFeeFixedCurrency
        transactionFeePercentage
        feeType
      }
      creditLine
      currency
    `
  }

  public static administratorsStructGQL () {
    return `
      id
      firstname
      lastname
      phone
      email
      name
    `
  }

  public static async getAuditsGQL (corporateAccountId: number, page: number, itemsPerPage: number): Promise<AbstractModelList<Audit>> {
    try {
      const auditResponse = await GraphQLService.query(`
      query corporateAccount(
        $id: ID!
        $itemsPerPage: Int
        $page: Int
      ) {
        corporateAccount(id: $id) {
          auditsOffset (
            perPage: $itemsPerPage,
            page: $page
          ) {
            hasNextPage
            pagesCount
            nodesCount
            nodes {
              id
              remoteAddress
              comment
              humanizedAuditedChanges (className: "CorporateAccount")
              createdAt
              user {
                firstname
                lastname
                email
                id
                whiteLabelId
              }
            }
          }
        }
      }`, {
        id: corporateAccountId,
        itemsPerPage,
        page
      })
      const errors = get(auditResponse, 'data.errors')
      const audits = get(auditResponse, 'data.data.corporate_account.audits_offset')
      const auditList = new AbstractModelList<Audit>(Audit)
      if (!audits && !errors ) return auditList
      auditList.models = audits.nodes.map((account: any) => plainToClass(Audit, account))
      auditList.modelsCnt = audits.nodes_count

      return auditList
    } catch (e) {
      throw e
    }
  }
  public static async getAccountsGQL (corporateAccountId: number, page: number, itemsPerPage: number, params: Record<string, any> = {}): Promise<AccountList> {
    try {
      const accountResponse = await GraphQLService.query(`
      query corporateAccount(
        $id: ID!
        $itemsPerPage: Int
        $page: Int
      ) {
        corporateAccount(id: $id) {
          accountsOffset (
            perPage: $itemsPerPage,
            page: $page,
          ) {
            hasNextPage
            pagesCount
            nodesCount
            nodes {
              id
              alias
              account
              currency
              formatedCreditLine
              formattedCreditBalance
              status
              accountType
              discountProfileId
              corporateAccount {
                discountProfile {
                  id
                  name
                  level
                }
              }
              corporateAccountId
              discountProfile {
                id
                name
                level
              }
              accountUsersCount
              xeditableOptions {
                id
                selectBoxText
              }
            }
          }
        }
      }`, {
        id: corporateAccountId,
        ...params,
        itemsPerPage,
        page
      })
      const errors = get(accountResponse, 'data.errors')
      const accounts = get(accountResponse, 'data.data.corporate_account.accounts_offset')
      const accountList = new AccountList(EnviayaAccountTS)
      if (!accounts && !errors ) return accountList
      accountList.models = accounts.nodes.map((account: any) => plainToClass(EnviayaAccountTS, account))
      accountList.allAccountsCount = accounts.nodes_count

      return accountList
    } catch (e) {
      throw e
    }
  }

  public static async getAllAccountsGQL (corporateAccountId: number): Promise<EnviayaAccountTS[]> {
    try {
      const accountResponse = await GraphQLService.query(`
      query corporateAccount($id: ID!) {
        corporateAccount(id: $id) {
          accounts {
            id
            account
            alias
            accountUsersCount
          }
        }
      }`, { id: corporateAccountId })
      const accounts = get(accountResponse, 'data.data.corporate_account.accounts') || []
      return accounts.map((account: Record<string, any>) => plainToClass(EnviayaAccountTS, account))
    } catch (e) {
      throw e
    }
  }

  public static async getReferrersGQL (corporateAccountId: number, page: number, itemsPerPage: number): Promise<AbstractModelList<ReferrerTS>> {
    try {
      const referrerResponse = await GraphQLService.query(`
      query corporateAccount(
        $id: ID!
        $itemsPerPage: Int
        $page: Int
      ) {
        corporateAccount(id: $id) {
          referrersOffset (
            perPage: $itemsPerPage,
            page: $page
          ) {
            hasNextPage
            pagesCount
            nodesCount
            nodes {
              id
              disabled
              referrerKey
              referrableType
              referrableId
              userDiscountProfile {
                id
                selectBoxText
              }
              defaultDiscountProfile {
                id
                selectBoxText
              }
              invitationsCount
            }
          }
        }
      }`, {
        id: corporateAccountId,
        itemsPerPage,
        page
      })

      const errors = get(referrerResponse, 'data.errors')
      const referrers = get(referrerResponse, 'data.data.corporate_account.referrers_offset')
      const referrerList = new AbstractModelList<ReferrerTS>(ReferrerTS)
      if (!referrers && !errors ) return referrerList
      referrerList.models = referrers.nodes.map((referrer: any) => plainToClass(ReferrerTS, referrer))
      referrerList.modelsCnt = referrers.nodes_count

      return referrerList
    } catch (e) {
      throw e
    }
  }
  public static async getWebhooksGQL (corporateAccountId: number, page: number, itemsPerPage: number): Promise<AbstractModelList<WebhookConfiguration>> {
    try {
      const referrerResponse = await GraphQLService.query(`
      query corporateAccount(
        $id: ID!
        $itemsPerPage: Int
        $page: Int
      ) {
        corporateAccount(id: $id) {
          webhooksOffset (
            perPage: $itemsPerPage,
            page: $page
          ) {
            hasNextPage
            pagesCount
            nodesCount
            nodes {
              id
              disabled
              objectClassName
              custom
              url
              verifyToken
              eventTypes
              corporateAccountId
              whiteLabelId
              userId
              user {
                email
                whiteLabelId
              }
              enviayaAccounts {
                account
              }
              corporateAccount {
                company
              }
            }
          }
        }
      }`, {
        id: corporateAccountId,
        itemsPerPage,
        page
      })

      const errors = get(referrerResponse, 'data.errors')
      const webhooks = get(referrerResponse, 'data.data.corporate_account.webhooks_offset')
      const webhookList = new AbstractModelList<WebhookConfiguration>(WebhookConfiguration)
      if (!webhooks && !errors ) return webhookList
      webhookList.models = webhooks.nodes.map((referrer: any) => plainToClass(WebhookConfiguration, referrer))
      webhookList.modelsCnt = webhooks.nodes_count

      return webhookList
    } catch (e) {
      throw e
    }
  }

  public static getCarrierAccountsAndQuotingFilter (id: number | string) {
    return GraphQLService.query(
      `query corporateAccount($id: ID!) {
        corporateAccount(id: $id) {
          whiteLabel {
            id
            quotingFilter {
              ${QuotingFilterApiService.quotingFilterDataStrucGraphql()}
            }
          }
          quotingFilter {
            ${QuotingFilterApiService.quotingFilterDataStrucGraphql()}
          }
        }
      }`, {
        id
      }
    )
  }

  public static searchCorporateAccountsGraphql (searchQuery: string) {
    return GraphQLService.query(
      `query searchCorporateAccounts($searchQuery: String) {
        corporateAccountsConnection(searchQuery: $searchQuery) {
          ${AccountsApiService.connectionStructGraphql(AccountsApiService.accountStructGraphQL)}
        }
      }	`, {
        searchQuery
      }
    )
  }

  public static async getUsersGQL (corporateAccountId: number, page: number, itemsPerPage: number): Promise<AbstractModelList<EnviayaUserTS>> {
    try {
      const usersResponse = await GraphQLService.query(`
      query corporateAccount(
        $id: ID!
        $itemsPerPage: Int
        $page: Int
      ) {
        corporateAccount(id: $id) {
          usersOffset (
            perPage: $itemsPerPage,
            page: $page
          ) {
            hasNextPage
            pagesCount
            nodesCount
            nodes {
              id
              firstname
              lastname
              email
              phone
              role
              trueRole
              lastActivityAt
              corporateAccountId
              status
              enviayaAccounts {
                account
              }
              group {
                name
              }
            }
          }
        }
      }`, {
        id: corporateAccountId,
        itemsPerPage,
        page
      })

      const errors = get(usersResponse, 'data.errors')
      const users = get(usersResponse, 'data.data.corporate_account.users_offset')
      const userList = new AbstractModelList<EnviayaUserTS>(EnviayaUserTS)
      if (!users && !errors ) return userList
      userList.models = users.nodes.map((user: any) => plainToClass(EnviayaUserTS, user))
      userList.modelsCnt = users.nodes_count

      return userList
    } catch (e) {
      throw e
    }
  }

  public static async getCorporateAccountAccounts (slug: string, page?: number | undefined, perPage?: number | undefined): Promise<CorporateAccountList> {
    try {
      let { data } = await ApiService.query(`corporate_accounts/${slug}/accounts`, { params: { page: page || 1, per_page: perPage || 1000000 } })
      return plainToClassFromExist(new CorporateAccountList(CorporateAccountTs), data)
    } catch (e) {
      throw e
    }
  }

  public static getListGQL (filtersParams: CorporateAccountQuery, page?: number, perPage?: number) {
    const { searchQuery, whiteLabelId, enviayaAccountIds, scope, discountProfileId, type } = filtersParams
    const corporateAccountId: number | null | undefined = (Vue.$who.isVendorAdmin(AuthModule.user!)) ? AuthModule.user!.corporateAccountId : null
    return GraphQLService.query(
      `query getListGQL(
          $page: Int,
          $perPage: Int,
          $searchQuery: String,
          $whiteLabelId: ID,
          $enviayaAccountIds: [ID!],
          $scope: String,
          $discountProfileId: ID,
          $type: String
          $corporateAccountId: Int
        ) {
        corporateAccountsOffset(
          page: $page
          perPage: $perPage
          searchQuery: $searchQuery
          whiteLabelId: $whiteLabelId
          enviayaAccountIds: $enviayaAccountIds
          scope: $scope
          discountProfileId: $discountProfileId
          ) {
            hasNextPage
            hasPreviousPage
            nodes (type: $type, corporateAccountId: $corporateAccountId ) {
              ${this.corporateAccountGraphQLIndexStruct()}
            }
            companiesNodesCount
            customersNodesCount
            nodesCount
            pagesCount
            allNodeIds
        }
      }	`, {
        page,
        perPage,
        searchQuery,
        whiteLabelId,
        enviayaAccountIds,
        scope,
        discountProfileId,
        type,
        corporateAccountId
      }
    )
  }

  public static async quickUpdateStatus (id: number, status: CorporateAccountStatuses) {
    return GraphQLService.query(
      `mutation quickUpdateStatusCorporateAccount (
        $id: ID!
        $status: Int
      ) {
        quickUpdateStatusCorporateAccount (
          input: {
            id: $id
            status: $status
          }
        ) {
          corporateAccount {
            id
            status
          },
          errors
        }
      }`,
      {
        id,
        status
      }
    )
  }

  public static async invoiceShipments (id: number, nextInvoicingDate: string, runVendorInvoicing?: boolean) {
    return GraphQLService.query(
      `mutation invoiceShipmentsCorporateAccount (
        $id: ID!
        $nextInvoicingDate: ISO8601Date
        $runVendorInvoicing: Boolean
      ) {
        invoiceShipmentsCorporateAccount (
          input: {
            id: $id
            nextInvoicingDate: $nextInvoicingDate
            runVendorInvoicing: $runVendorInvoicing
          }
        ) {
          corporateAccount {
            id
          },
          messages
          errors
        }
      }`,
      {
        id,
        nextInvoicingDate,
        runVendorInvoicing
      }
    )
  }

  public static async transactionFeeInvoicing (id: number, nextTransactionFeeInvoicingDate: string, runVendorInvoicing?: boolean) {
    return GraphQLService.query(
      `mutation transactionFeeInvoicingCorporateAccount (
        $id: ID!
        $nextTransactionFeeInvoicingDate: ISO8601Date
        $runVendorInvoicing: Boolean
      ) {
        transactionFeeInvoicingCorporateAccount (
          input: {
            id: $id
            nextTransactionFeeInvoicingDate: $nextTransactionFeeInvoicingDate
            runVendorInvoicing: $runVendorInvoicing
          }
        ) {
          corporateAccount {
            id
          },
          messages
          errors
        }
      }`,
      {
        id,
        nextTransactionFeeInvoicingDate,
        runVendorInvoicing
      }
    )
  }

  public static bulkActionGraphQL ( action: string, corporateAccountIds: number[], bulkUpdateParams?: Record<string, any> ) {
    // @ts-ignore
    const fileContentType = bulkUpdateParams?.contentType

    return GraphQLService.query(
      `mutation corporateAccountBulkAction(
        $action: String,
        $corporateAccountIds: [ID],
        $fileContentType: String
      ) {
        corporateAccountBulkAction (
          input: {
            action: $action,
            corporateAccountIds: $corporateAccountIds,
            fileContentType: $fileContentType
          }
        ) {
          result
          jobId
          errors
        }
      }
    `, {
      action,
      corporateAccountIds,
      fileContentType
    })
  }

  public static createCorporateAccountGraphql (corporateAccount: CorporateAccountTs) {
    const INTattributerNames = ['insuranceAmount', 'creditLine', 'invoicingPeriod', 'paymentPeriod', 'gracePeriod']
    INTattributerNames.forEach((attr: string) => {
      // @ts-ignore
      let value = corporateAccount[attr]
      value = value instanceof Number ? value : parseInt(value || '', 10)
      // @ts-ignore
      corporateAccount[attr] = value
    })

    corporateAccount.directionId = Number(corporateAccount.direction?.id)
    corporateAccount.discountProfileId = Number(corporateAccount.discountProfile?.id)
    // @ts-ignore
    corporateAccount.advancedQuotingFilter = corporateAccount.quotingFilter
    corporateAccount.destinationCompany = corporateAccount.destinationCompany ? 'mandatory' : 'optional'

    return GraphQLService.query(`
      mutation ($corporateAccount: JSON) {
        createCorporateAccount (
          input: {
            corporateAccount: $corporateAccount
          }
        ) {
          corporateAccount {
            ${this.corporateAccountGraphQLStruct()}
          },
          errors
        }
      }`, {
        corporateAccount
      }
    )
  }

  public static async recalculateSaldo (id: number, saldoType: number) {
    return ApiService.put(`corporate_accounts/${id}/recalculate_saldo`, { saldo_type: saldoType })
  }

  public static sendInvoicingContactsConfirmationEmails (corporateAccountId: number): Promise<AxiosResponse> {
    return ApiService.get(`corporate_accounts/${corporateAccountId}/send_invoicing_contacts_confirmation_emails`)
  }

  public static async getCorporateCarrierAccounts (slug: number | string, status: number, page: number | undefined, perPage: number | undefined): Promise<UserCarrierAccountsList> {
    try {
      let { data } = await ApiService.query(`corporate_accounts/${slug}/carrier_accounts`, { params: { status: status, page: page || 1, per_page: perPage } })
      return plainToClassFromExist(new UserCarrierAccountsList(CarrierAccount), data)
    } catch (e) {
      throw e
    }
  }
}
