import i18n from '@/i18n'
import { ORDER_TYPES } from '@/constants/clearing-charge'

export function useOrderTypes(selectedValue: string[] | string | undefined | null, containsCorporateOptions: boolean, replaceRegularOptionsByCorporate: boolean = false) {
  let orderTypes = [
    {
      value: (!replaceRegularOptionsByCorporate) ? String(ORDER_TYPES.ORDER_TYPE_PREPAID_NATIONAL) : `${ORDER_TYPES.ORDER_TYPE_PREPAID_NATIONAL}_corporate` as string,
      text: (!replaceRegularOptionsByCorporate) ? i18n.t('properties.form.order.type.prepaid_national') as string : i18n.t('properties.form.order.type.corporate_account_prepaid_national') as string,
      selected: isSelected(selectedValue, (!replaceRegularOptionsByCorporate) ? String(ORDER_TYPES.ORDER_TYPE_PREPAID_NATIONAL) : `${ORDER_TYPES.ORDER_TYPE_PREPAID_NATIONAL}_corporate`)
    },
    {
      value: (!replaceRegularOptionsByCorporate) ? String(ORDER_TYPES.ORDER_TYPE_PREPAID_INTERNATIONAL) : `${ORDER_TYPES.ORDER_TYPE_PREPAID_INTERNATIONAL}_corporate` as string,
      text: (!replaceRegularOptionsByCorporate) ? i18n.t('properties.form.order.type.prepaid_international') as string : i18n.t('properties.form.order.type.corporate_account_prepaid_international') as string,
      selected: isSelected(selectedValue, (!replaceRegularOptionsByCorporate) ? String(ORDER_TYPES.ORDER_TYPE_PREPAID_INTERNATIONAL) : `${ORDER_TYPES.ORDER_TYPE_PREPAID_INTERNATIONAL}_corporate`)
    },
    {
      value: (!replaceRegularOptionsByCorporate) ? String(ORDER_TYPES.ORDER_TYPE_CREDIT) : `${ORDER_TYPES.ORDER_TYPE_CREDIT}_corporate` as string,
      text: (!replaceRegularOptionsByCorporate) ? i18n.t('properties.form.order.type.credit') as string : i18n.t('properties.form.order.type.corporate_account_credit') as string,
      selected: isSelected(selectedValue, (!replaceRegularOptionsByCorporate) ? String(ORDER_TYPES.ORDER_TYPE_CREDIT) : `${ORDER_TYPES.ORDER_TYPE_CREDIT}_corporate`)
    },
    {
      value: String(ORDER_TYPES.ORDER_TYPE_SHIPMENT),
      text: i18n.t('properties.form.order.type.shipment') as string,
      selected: isSelected(selectedValue, String(ORDER_TYPES.ORDER_TYPE_SHIPMENT))
    },
    {
      value: String(ORDER_TYPES.ORDER_TYPE_CLEARANCE),
      text: i18n.t('properties.form.order.type.adjustment_charges') as string,
      selected: isSelected(selectedValue, String(ORDER_TYPES.ORDER_TYPE_CLEARANCE))
    }
  ]

  if (containsCorporateOptions) {
    orderTypes.push(...[
      {
        value: `${ORDER_TYPES.ORDER_TYPE_PREPAID_NATIONAL}_corporate` as string,
        text: i18n.t('properties.form.order.type.corporate_account_prepaid_national') as string,
        selected: isSelected(selectedValue, `${ORDER_TYPES.ORDER_TYPE_PREPAID_NATIONAL}_corporate`)
      },
      {
        value: `${ORDER_TYPES.ORDER_TYPE_PREPAID_INTERNATIONAL}_corporate` as string,
        text: i18n.t('properties.form.order.type.corporate_account_prepaid_international') as string,
        selected: isSelected(selectedValue, `${ORDER_TYPES.ORDER_TYPE_PREPAID_INTERNATIONAL}_corporate`)
      },
      {
        value: `${ORDER_TYPES.ORDER_TYPE_CREDIT}_corporate` as string,
        text: i18n.t('properties.form.order.type.corporate_account_credit') as string,
        selected: isSelected(selectedValue, `${ORDER_TYPES.ORDER_TYPE_CREDIT}_corporate`)
      }
    ])
  }

  return orderTypes
}

function isSelected(selectedValue, currentValue) {
  return (
    !!selectedValue &&
    ((Array.isArray(selectedValue) &&
        selectedValue.map((val) => val.toString()).includes(currentValue.toString())) ||
      (!Array.isArray(selectedValue) &&
        selectedValue.toString() === currentValue.toString()))
  )
}
