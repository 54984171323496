export enum CarrierAccountStatuses {
  ACTIVE = 1,
  DISABLED = 2,
  DELETED = 3,
  DISABLED_SYSTEM = 5
}

export const CarrierAccountStatusHash = {
  ACTIVE: '#active',
  DISABLED: '#disabled',
  DELETE: '#delete'
}

export const CarrierAccountHashes = {
  ENVIAYA_CARRIER_ACCOUNT: '#enviaya-account',
  MY_ACCOUNT: '#my-account',
  GENERAL: '#general',
  ASSIGNATIONS: '#assignations',
  PICKUPS: '#pickups',
  FUNCTIONS: 'functions',
  AVAILABILITY: 'availability',
  ADVANCED_CONFIGURATION: '#advanced_configuration',
  DISCOUNT_PROFILES: '#discount_profiles',
  AUDIT: '#audit'
}

export const KNOWN_CARRIERS_NAMES_MAPPING = {
  borzo: 'Borzo',
  ups: 'UPS',
  dhl: 'DHL',
  redpack: 'Redpack',
  fedex: 'FedEx',
  minutos: 'Minutos',
  estafeta: 'Estafeta',
  ivoy: 'iVoy',
  deprisa: 'Deprisa',
  sendex: 'Sendex',
  paquetexpress: 'Paquetexpress',
  pickit: 'Pickit',
  four72: 'Four72',
  ampm: 'Ampm',
  treggo: 'Treggo',
  tresguerras: 'Tresguerras',
  zubut: 'Zubut',
  jt: 'JT'
}

export const CarrierAccountAction = {
  create: 'create',
  edit: 'edit',
  delete: 'delete'
}

export enum CarrierServiceType {
  NATIONAL = 1,
  INTERNATIONAL = 2
}

export enum CarrierAccountStatus {
  CORP_ACC_STATUS_ACTIVE = 1,
  CORP_ACC_STATUS_DISABLED = 2,
  CORP_ACC_STATUS_DELETED = 3,
  CORP_ACC_STATUS_DISABLED_SYSTEM = 5
}

export enum CoordinatesHandling {
  COORDINATES_HANDLING_OPTIONAL = 1,
  COORDINATES_HANDLING_RECOMMENDED = 2,
  COORDINATES_HANDLING_MANDATORY = 3,
  COORDINATES_HANDLING_STREET_MANDATORY = 4
}

export enum ScopeTypes {
  HYBRID = 1,
  OW_CARRIER_ACCOUNTS_ONLY = 2
}

export const NO_CACHE = 0

export enum CacheOptions {
  CACHE_LEVEL_NEIGHBORHOOD = 1,
  CACHE_LEVEL_CITY = 2,
  CACHE_LEVEL_STATE = 3,
  CACHE_LEVEL_COUNTRY = 4
}

export enum CarrierAccsCogsCalculationOpts {
  CARRIER_ACCS_COGS_CALCULATION_NEGOTIATED = 1,
  CARRIER_ACCS_COGS_CALCULATION_FIXED_DATA = 2,
  CARRIER_ACCS_COGS_CALCULATION_CARRIER_API = 3,
  CARRIER_ACCS_COGS_CALCULATION_SPECIAL = 4
}

export enum CarrierAccsDropOffIndicationOpts {
  CARRIER_ACCS_DROP_OFF_INDICATION_NO = 0,
  CARRIER_ACCS_DROP_OFF_INDICATION_YES = 1
}

export enum ImportTaxesPayment {
  DESTINATION = 1,
  CARRIER_ACCOUNT = 2
}

export const WeightUnits = {
  IMPERIAL_WEIGHT_UNIT: 'lbs',
  METRIC_WEIGHT_UNIT: 'kg'
}

export const authFieldNames = ['user_name', 'password', 'account_number', 'parameter_1', 'auth_token', 'parameter_3', 'parameter_4', 'parameter_2', 'client_id', 'client_secret', 'api_key']
