<template>
  <mdb-row>
    <mdb-col col="3">
      <mdb-icon :icon="this.icon" :size="bodyIconSize"/>
    </mdb-col>
    <mdb-col col="9">
      <p v-html="bodyText" class="body-content-container"></p>
    </mdb-col>
  </mdb-row>
</template>

<script>

const defaultBodyIcons = {
  success: 'check-circle',
  info: 'info-circle',
  warning: 'exclamation-circle',
  danger: 'exclamation-circle'
}

export default {
  name: 'CarrierAccountModalBody',
  props: {
    bodyIcon: {
      type: String
    },
    type: {
      type: String,
      required: true
    },
    bodyIconSize: {
      type: String
    },
    bodyText: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      icon: this.bodyIcon || defaultBodyIcons[this.type] || 'info-circle'
    }
  }
}
</script>

<style scoped>
.body-content-container >>> a {
  color: #3fa2f7;
}
</style>
