import { Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer'
import { CarrierServicesQuotingFilter } from './carrier-services-quoting-filter'
import { CarriersQuotingFilter } from './carriers-quoting-filter'
import isEmpty from 'lodash/isEmpty'
import { CutOffConfiguration } from './cut-off-configuration'
import { CarrierService } from './CarrierService'
import { EnviayaAccountTS } from '@/models/EnviayaAccountTS'
import { CorporateAccountTs } from '@/models/corporate-account-ts'
import { WhiteLabel } from '@/models/white-label'

export enum DISABLE_HIGHER_LEVEL_FACTOR {
  CORPORATE_ACCOUNT = 'CorporateAccount',
  WHITE_LABEL = 'WhiteLabel'
}

export function numToTime (num: number) {
  let hours = Math.floor(num / 60)
  let minutes: number | string = num % 60
  if (minutes + ''.length < 2) {
    minutes = '0' + minutes
  }
  return hours + ':' + minutes
}

export function timeToNum (time: string | number) {
  let timeParts = String(time).split(':')
  return Number(timeParts[0]) * 60 + Number(timeParts[1])
}

@Exclude()
export class QuotingFilter {
  static get modelName () {
    return 'QuotingFilter'
  }

  @Expose({ name: 'id' })
  public id: string | number | undefined

  @Expose({ name: 'enviaya_account_id' })
  public enviayaAccountId: number | undefined

  @Expose({ name: 'enviaya_account' })
  @Type(() => EnviayaAccountTS)
  public enviayaAccount: EnviayaAccountTS | null = null

  @Expose({ name: 'white_label_id' })
  public whiteLabelId: number | undefined

  @Expose({ name: 'white_label' })
  @Type(() => WhiteLabel)
  public whiteLabel: WhiteLabel | null = null

  @Expose({ name: 'corporate_account_id' })
  public corporateAccountId: number | undefined

  @Expose({ name: 'corporate_account' })
  @Type(() => CorporateAccountTs)
  public corporateAccount: CorporateAccountTs | null = null

  @Expose({ name: 'restrict_carriers' })
  public restrictCarriers: boolean | undefined

  @Expose({ name: 'carriers_quoting_filters' })
  @Transform(value => { return isEmpty(value) ? value : value.map((element: CarriersQuotingFilter) => plainToClass(CarriersQuotingFilter, element)) })
  public carriersQuotingFilters: CarriersQuotingFilter[] = []

  @Expose({ name: 'carrier_services_quoting_filters' })
  @Transform(value => { return isEmpty(value) ? value : value.map((element: CarrierServicesQuotingFilter) => plainToClass(CarrierServicesQuotingFilter, element)) })
  public carrierServicesQuotingFilters: CarrierServicesQuotingFilter[] = []

  @Expose({ name: 'configure_advanced_filters' })
  public configureAdvancedFilters: boolean | undefined

  @Expose({ name: 'only_cheapest_filter' })
  public onlyCheapestFilter: boolean | undefined

  @Expose({ name: 'cheapest_filter' })
  public cheapestFilter: boolean | undefined

  @Expose({ name: 'fastest_filter' })
  public fastestFilter: boolean | undefined

  @Expose({ name: 'cheapest_standard' })
  public cheapestStandard: boolean | undefined

  @Expose({ name: 'define_specific_cut_off_time' })
  public defineSpecificCutOffTime: boolean | undefined

  @Expose({ name: 'same_day_cut_off_time' })
  @Transform(value => { return (!value) ? '' : value })
  public sameDayCutOffTime: string = ''

  @Expose({ name: 'next_day_cut_off_time' })
  @Transform(value => { return (!value) ? '' : value })
  public nextDayCutOffTime: string = ''

  @Expose({ name: 'global_cut_off_time' })
  @Transform(value => { return (!value) ? '' : value })
  public globalCutOffTime: string = ''

  @Expose({ name: 'same_day_additional_hours' })
  @Transform(value => {
    return (value === null) ? '24:00' : numToTime(value)
  }, { toClassOnly: true })
  @Transform(value => {
    return !value ? '24:00' : timeToNum(value)
  }, { toPlainOnly: true })
  public sameDayAdditionalHours: string | number = '24:00'

  @Expose({ name: 'next_day_additional_hours' })
  @Transform(value => {
    return (value === null) ? '24:00' : numToTime(value)
  }, { toClassOnly: true })
  @Transform(value => {
    return !value ? '24:00' : timeToNum(value)
  }, { toPlainOnly: true })
  public nextDayAdditionalHours: string | number = '24:00'

  @Expose({ name: 'global_additional_hours' })
  @Transform(value => {
    return (value === null) ? '24:00' : numToTime(value)
  }, { toClassOnly: true })
  @Transform(value => {
    return !value ? '24:00' : timeToNum(value)
  }, { toPlainOnly: true })
  public globalAdditionalHours: string | number = '24:00'

  @Expose({ name: 'specific_carrier_cutoff_time' })
  public specificCarrierCutoffTime: boolean | undefined

  @Expose({ name: 'cut_off_configurations' })
  @Transform(value => { return isEmpty(value) ? value : value.map((element: CutOffConfiguration) => plainToClass(CutOffConfiguration, element)) })
  public cutOffConfigurations: CutOffConfiguration[] = []

  @Expose({ name: 'disabled_carriers_by_higher_level' })
  @Transform(value => { return isEmpty(value) ? value : value.map((element: DisabledCarriersByHigherLevel) => plainToClass(DisabledCarriersByHigherLevel, element)) })
  public disabledCarriersByHigherLevel: DisabledCarriersByHigherLevel[] = []

  @Expose({ name: 'disabled_carrier_services_by_higher_level' })
  @Transform(value => { return isEmpty(value) ? value : value.map((element: DisabledCarrierServicesByHigherLevel) => plainToClass(DisabledCarrierServicesByHigherLevel, element)) })
  public disabledCarrierServicesByHigherLevel: DisabledCarrierServicesByHigherLevel[] = []

  @Expose({ name: 'account_type' })
  @Transform(value => { return (!value) ? '' : value })
  public accountType: string = ''

  public get enviaya_account_id () {
    return this.enviayaAccountId
  }

  public get corporate_account_id () {
    return this.corporateAccountId
  }

  public get white_label_id () {
    return this.whiteLabelId
  }

  public get enviaya_account () {
    return this.enviayaAccount
  }

  public get corporate_account () {
    return this.corporateAccount
  }

  public get white_label () {
    return this.whiteLabel
  }
}

@Exclude()
export class DisabledCarriersByHigherLevel {

  @Expose({ name: 'id' })
  public id: string | number | undefined

  @Expose({ name: 'higher_level' })
  @Transform(value => { return (!value) ? '' : value })
  public higherLevel: string = ''

}

@Exclude()
export class DisabledCarrierServicesByHigherLevel {

  @Expose({ name: 'carrier_id' })
  public carrierId: string | number | undefined

  @Expose({ name: 'disabled_services' })
  public disabledServices: CarrierService[] = []

  @Expose({ name: 'disabled_services_by_corporate_account' })
  @Transform(value => { return isEmpty(value) ? [] : value }, { toClassOnly: true })
  public disabledServicesByCorporateAccount: number[] = []

  @Expose({ name: 'disabled_services_by_white_label' })
  @Transform(value => { return isEmpty(value) ? [] : value }, { toClassOnly: true })
  public disabledServicesByWhiteLabel: number[] = []

}
