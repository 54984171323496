<template>
  <mdb-modal
    :show="visible"
    @close="close"
    :tag="options.tag"
    v-bind:[options.type]="true"
    :position="options.position"
    :side="options.side"
    :centered="options.centered"
    :fullHeight="options.fullHeight"
    :frame="options.frame"
    removeBackdrop
    :cascade="options.cascade"
    :avatar="options.avatar"
    :tabs="options.tabs"
    :dark="options.dark"
    :bgSrc="options.bgSrc"
    :scrollable="options.scrollable"
    :direction="options.direction"
    :size="options.size"
    :class="[{ 'scrollable-full-height': options.scrollable}, options.widthClass]"
  >

    <!-- Body start -->
    <mdb-modal-body :tag="options.bodyTag" ref="bodyContainer">
      <component ref="popupInnerComponent" v-bind:is="options.component" :options="options" v-bind="options.props" v-if="options.lazy"></component>
    </mdb-modal-body>
    <!-- Body end -->

    <!-- Footer start -->
    <mdb-modal-footer
      :tag="options.footerTag"
      :start="options.start"
      :end="options.end"
      :closeAriaLabel="options.closeAriaLabel"
      :titleClasses="options.titleClasses"
    >
      <mdb-btn
        v-if="options.cancelAction"
        @click="callback('cancelAction')"
      >
        {{options.cancelBtnText}}
      </mdb-btn>
      <mdb-btn
        v-if="options.applyBtnText"
        @click="callback('applyAction')"
        :color="options.type"
      >
        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" v-show="isProcessing"/>
        {{options.applyBtnText}}
      </mdb-btn>
    </mdb-modal-footer>
    <!-- Footer end -->
  </mdb-modal>
</template>

<script>
import NotificationBody from './NotificationBody'

  /* possible options https://mdbootstrap.com/docs/vue/modals/basic/
*
* * * Modal options:
* @tag {String} - Allows to substitute native element's tag
* @type {String} - Styles modal in the selected type
* *  success | info | warning | danger
* @side {Boolean} - Makes modal a side-positioned one
* @position {String} - Determinates modal's position relative to the browser window
* *  @position if @side === true: top-right | top-left | bottom-right | bottom-left
* @centered {Boolean} - Center modal vertically and horizonally
* @fullHeight {Boolean} - Makes the modal take full height from a specified side
* * @position if @fullHeight === true: top | right | bottom | left
* @frame {Boolean} - Turns modal into a frame-type one
* *  @position if @frame === true: top | bottom
* @removeBackdrop {Boolean} - Removes the dark overlay from the background, along its functionality
* @cascade {Boolean} - Allows for a cascading nature of a modal
* @avatar {Boolean} - Gives modal styling fitting perfectly with avatar-based heading
* @tabs {Boolean} - Provides styles necessary for having a tab navigation in modal's header
* @dark {Boolean} - Determines whether style of a modal should not have a dark nature
* @bgSrc {String} - Dark style necessitate having a picture background, but the feature is for all the modals
* @scrollable {Boolean} - Allows to scroll through long contents with a scrollbar
* @direction {String} - Allows to change a transition's direction
*
* * * Header options:
* @headerTag {String} - Allows to substitute native element's tag
* @headerBgColor {String} - Determines color of the header
* @headerTextColor {String} - Helps to set font color
* @close {Boolean} - Turning it to false gets rid of the close "x" icon in the right top conrner
* @headerText {String} - Content
*
* * * Body options:
* @bodyTag {String} - Allows to substitute native element's tag
* @bodyText {String} - Content
* @bodyIcon {String} - https://mdbootstrap.com/docs/vue/content/icons-list/
* @bodyIconSize {String} - Sets icon size
*
* * * Footer options:
* @footerTag {String} - Allows to substitute native element's tag
* @start {Boolean} - Makes the ModalHeader contents align to the left
* @end {Boolean} - Makes the ModalHeader contents align to the right
* @closeAriaLabel {Boolean} - Makes the ModalHeader contents align with space between them
* @titleClasses {Boolean} - Makes the ModalHeader contents align with space around them
* @applyBtnText {String} - Caption for apply button
* @cancelBtnText {String} - Caption for cancel button
* @applyAction {Function} - Callback for the 'OK' button
* @cancelAction {Function} - Callback for the 'CANCEL' button
* @closeAction {Function} - Callback for the 'CLOSE' button
*
* */
const defaultOptions = {
  tag: 'div',
  type: '',
  position: '',
  side: false,
  centered: false,
  fullHeight: false,
  frame: false,
  removeBackdrop: false,
  cascade: false,
  avatar: false,
  tabs: false,
  dark: false,
  bgSrc: null,
  scrollable: false,
  size: null,
  direction: 'top',
  // header
  headerTag: 'div',
  headerBgColor: '',
  headerTextColor: 'black',
  close: true,
  headerText: '', // content
  // body
  bodyTag: 'div',
  bodyText: '', // content
  bodyAsHtml: true, // content
  bodyIcon: 'info-circle',
  bodyIconSize: '4x',
  component: 'notification-body',
  lazy: true,
  // footer
  footerTag: 'div',
  start: false,
  end: false,
  applyBtnText: 'Ok',
  cancelBtnText: 'Cancel',
  closeAriaLabel: false,
  titleClasses: false,
  applyAction: null,
  cancelAction: null,
  closeAction: null,
  widthClass: []
}

export default {
  components: {
    NotificationBody
  },
  data () {
    return {
      visible: false,
      isProcessing: false,
      options: { ...defaultOptions }
    }
  },
  methods: {
    setOptions (options) {
      if (options && typeof options === 'object') {
        Object.assign(this.options, options)
      }
    },
    resetOptions () {
      this.options = { ...defaultOptions }
    },
    show (options) {
      this.setOptions(options)
      this.visible = true
      if (!this.options.lazy) {
        this.$nextTick(() => {
          this.options.component.$mount()
          this.$refs.bodyContainer.$el.appendChild(this.options.component.$el)
        })
      }
    },
    async close () {
      // this.visible = false;
      if (this.options.closeAction && typeof this.options.closeAction === 'function') {
        await this.options.closeAction()
      }
      this.resetOptions()
      this.$nextTick(() => {
        this.$destroy()
        this.$el.innerHTML = '' // remove inner content
        this.$el.remove()
      })
    },
    async callback (actionType) {
      this.isProcessing = true
      if (this.$refs['popupInnerComponent'] && typeof this.$refs['popupInnerComponent'].applyAction === 'function' && actionType === 'applyAction') {
        await this.$refs['popupInnerComponent'].applyAction()
        this.isProcessing = false
      } else {
        const { [actionType]:action } = this.options
        if (action && typeof action === 'function') {
          try {
            await action()
            this.isProcessing = false
            await this.close()
          } catch (e) {
            this.isProcessing = false
          }
        } else {
          this.isProcessing = false
          await this.close()
        }
      }
    }
  }
}
</script>

<style scoped>
  /*.scrollable-full-height .modal-content,*/
  /*.modal-dialog-scrollable.scrollable-full-height .modal-content{*/
  /*  max-height: 100%;*/
  /*}*/

  .modal.modal-without-backdrop {
    pointer-events: auto !important;
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .modal .modal-frame {
    padding: 30px;
  }

  .modal-dialog .btn {
    border-radius: 4px;
    width: 40%;
    font-weight: 600;
    color: white;
  }

  .btn-modify {
    background-color: #0D7EE7 !important;
  }

  .btn-default {
    background-color: #F1F1F1 !important;
    color: #232323 !important;
  }

  .btn-delete {
    background-color: #D72525 !important;
  }

  .btn-success {
    background-color: #50BF69 !important;
  }

  .modal-dialog.modal-notify.modal-info .typeahead .input-group .close-btn i {
    color: red;
  }

 .modal-header {
    padding: 0.8rem 0 1rem 0;
    margin: 1rem 2rem 0 2rem;
    border-bottom: 1px solid #EDEDED !important;
  }

  .modal-body {
    padding: 2rem;
  }

  .modal-dialog .modal-header {
    background-color: white !important;
    box-shadow: none !important;
  }

  .modal-dialog .heading {
    align-self: center;
    color: #000 !important;
  }

  .modal-header .close {
    margin: 0;
    padding: 0;
    color: #000 !important;
    text-shadow: none;
    font-size: 1.7rem;
  }
  
  .modal-header .close:hover {
    color: #000 !important;
  }

  .modal-footer {
    border-top: none;
    justify-content: center;
  }
</style>

