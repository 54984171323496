
import { ISelectItem } from '@/models/SelectBoxItem'
import QuickSearchSelectBox from '@/components/select-boxes/QuickSearchSelectBox.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import validator from 'validator'

export enum ENTITY_TYPES {
  SHIPMENT = 'shipment',
  CLEARING_CHARGE = 'clearing_charge',
  USER = 'user',
  COMPANY = 'company',
  ACCOUNT = 'account',
  SERVICE = 'service',
  CUSTOM_SERVICE = 'custom_service',
  CARRIER_ACCOUNT = 'carrier_account',
  SURCHARGE_PRICE = 'surcharge_price',
  DISCOUNT_PROFILE = 'discount_profile'
}

@Component({
  components: {
    QuickSearchSelectBox
  }
})
export default class QuickSearchForm extends Vue {
  public search: { entityId: string, query: string, entityType: string } = { entityId: '', query: '', entityType: '' }
  public options: ISelectItem[] = []
  public formSubmitted: boolean = false
  public validation: any = {
    entityId: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    },
    entityType: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    }
  }
  public byId: boolean = true

  public created () {
    this.renderOptions()
  }

  public renderOptions () {
    for (const value of Object.values(ENTITY_TYPES)) {
      this.options.push({
        value: value,
        text: this.$t(`quick_search_form.entity_types.${value}`).toString()
      })
    }
    this.options.sort((a, b) => a.text.toString().localeCompare(b.text.toString()))
    this.options[0].selected = true
    this.search.entityType = this.options[0].value?.toLocaleString()!
  }

  public validate () {
    if (validator.isEmpty(this.search.entityId)) {
      this.validation.entityId.valid = false
      this.validation.entityId.invalidFeedback = this.$t('quick_search_form.validations.blank')
    } else {
      this.validation.entityId.valid = true
      this.validation.entityId.invalidFeedback = ''
    }
    this.validation.entityId.validated = true

    if (!this.search.entityType) {
      this.validation.entityType.valid = false
      this.validation.entityType.invalidFeedback = this.$t('quick_search_form.validations.blank')
    } else {
      this.validation.entityType.valid = true
      this.validation.entityType.invalidFeedback = ''
    }
    this.validation.entityType.validated = true

    this.$nextTick(() => {
      // @ts-ignore
      this.$refs['select'].validate()
    })
  }

  public isValidForm () {
    return Object.keys(this.validation).find(key => this.validation[key].valid === false) === undefined
  }

  public submit () {
    this.formSubmitted = true
    this.validate()
    if (this.isValidForm()) {
      this.$emit('redirectTo', { entityId: this.search.entityId, entityType: this.search.entityType, useId: this.byId })
    }
  }

  public renderSelect () {
    return [
      ENTITY_TYPES.USER as string,
      ENTITY_TYPES.ACCOUNT as string,
      ENTITY_TYPES.CARRIER_ACCOUNT as string,
      ENTITY_TYPES.COMPANY as string,
      ENTITY_TYPES.CLEARING_CHARGE as string,
      ENTITY_TYPES.DISCOUNT_PROFILE as string
    ].includes(this.search.entityType)
  }

  public get idSearch () {
    return [
      ENTITY_TYPES.CLEARING_CHARGE as string
    ].includes(this.search.entityType)
  }

  public get description () {
    if (this.$te(`quick_search_form.fields.entity_description.${this.search.entityType}`)) {
      return this.$t(`quick_search_form.fields.entity_description.${this.search.entityType}`)
    } else {
      return ''
    }
  }

  public async checkForm () { /**/ }

  @Watch('search', { deep: true })
  public searchChanged () {
    if (this.formSubmitted) {
      this.validate()
    }
  }
}
