export function useCurrencies(selectedValue: string | undefined | null) {
  const currency = require('country-currency-map')
  const getSymbolFromCurrency = require('currency-symbol-map')

  let currencies = []

  let currencyList = currency.getCurrencyList().map((c) => {
    let currencyCode = c.abbr
    if (currencyCode === 'AZM') currencyCode = 'AZN'
    if (currencyCode === 'VEB') currencyCode = 'VEF'
    return { currency: currencyCode, sign: (getSymbolFromCurrency(c.abbr)) ? getSymbolFromCurrency(c.abbr) : c.symbolFormat.replace(/\{[^()]*\}/g, '') }
  }).sort((a, b) => a.currency.localeCompare(b.currency, 'en', { 'sensitivity': 'base' }))

  currencyList.map((item: { currency: string, sign: string }, index: number) => {
    if (currencyList.indexOf(item) === index) {
      currencies.push({ text: `${item.currency} (${item.sign})`, value: item.currency, selected: (selectedValue === item.currency) })
    }
  })

  return currencies
}
