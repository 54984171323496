import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt.service'
import { NotFoundError } from '@/common/exceptions/not-found-error'
import { AppUser } from '@/models/AppUser'
import router from '@/router'
import { AVAILABLE_LANGUAGES } from '@/common/user.settings'
import { UnhandledError } from '@/common/exceptions/unhandled-error'
import { CONFIGURATION_KEYS } from '@/common/config'
import { AccessDeniedError } from '@/common/exceptions/access-denied-error'
import i18n from '@/i18n'
// @ts-ignore
import localeData from '@/locales/popup'
import { DomainService } from '@/common/domain.service'
import { ValidationError } from '@/common/exceptions/validation-error'

export default class ApiService {
  public static init () {
    i18n.setLocaleMessage('en', localeData.en)
    i18n.setLocaleMessage('de', localeData.de)
    i18n.setLocaleMessage('es', localeData.es)

    Vue.use(VueAxios, axios)
    let vueExpectedHost = DomainService.getHostFromURI(Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH))
    let vueActualHost = DomainService.getHostFromURI(window.location.href)

    if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
      Vue.axios.defaults.withCredentials = true
      if (vueExpectedHost !== vueActualHost) {
        Vue.axios.defaults.baseURL = `https://${DomainService.getHostFromURI(window.location.href)}/api/v2/`
      } else {
        Vue.axios.defaults.baseURL = Vue.$getConst(CONFIGURATION_KEYS.API_URL)
      }
    } else {
      if (vueExpectedHost !== vueActualHost) {
        Vue.axios.defaults.baseURL = `https://${DomainService.getDomainWithoutPort(window.location.href)}:${Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN_PORT)}/api/v2/`
      } else {
        Vue.axios.defaults.baseURL = Vue.$getConst(CONFIGURATION_KEYS.API_URL)
      }
    }

    Vue.axios.defaults.withCredentials = true
    Vue.axios.defaults.timeout = 300000
  }

  public static get config () {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    let locale = 'es'

    const appUser: AppUser | null = JwtService.getUser()
    if (appUser && appUser.locale) {
      locale = appUser.locale
    } else {
      if (Vue.cookies.get('locale')) {
        locale = Vue.cookies.get('locale') as string
      } else if (window.navigator.language) {
        if (AVAILABLE_LANGUAGES.includes(window.navigator.language.split('-')[0])) {
          locale = window.navigator.language.split('-')[0]
        } else {
          locale = 'es'
        }
      }
    }
    Object.assign(headers, { 'Accept-Language': locale })

    return { headers: headers }
  }

  public static query (resource: string, params: {}): Promise<any> {
    let config = this.config
    Object.assign(config, { ...params })
    return Vue.axios.get(resource, config).catch(error => {
      this.throwError(error)
    })
  }

  public static head (resource: string): Promise<any> {
    let config = this.config
    return Vue.axios.head(resource, config).catch(error => {
      this.throwError(error)
    })
  }

  public static get (resource: string, slug: string = '', configOptions?: {}): Promise<any> {
    return Vue.axios.get(`${resource}/${slug}`, { headers: { ...this.config.headers, ...configOptions } }).catch(error => {
      this.throwError(error)
    })
  }

  public static blob (resource: string): Promise<any> {
    let config = this.config
    Object.assign(config, { responseType: 'blob' })
    return Vue.axios.get(`${resource}`, config).catch(error => {
      this.throwError(error)
    })
  }

  public static post (resource: string, params: {}, configOptions?: {}): Promise<any> {
    return Vue.axios.post(`${resource}`, params, { headers: { ...this.config.headers, ...configOptions } } ).catch(error => {
      this.throwError(error)
    })
  }

  public static throwError (error: any) {
    if (!error || !error.response) {
      throw new UnhandledError(`[RWV] ApiService: Error. Server is not available during call ${error.config.baseURL}${error.config.url}`)
    }
    if (error.message === 'Network Error') {
      throw new UnhandledError(`[RWV] ApiService: Error. Server is not available during call ${error.config.baseURL}${error.config.url}`)
    }

    const appUser: AppUser | null = JwtService.getUser()
    const { id, email } = appUser || { id: 'Unknown', email: 'User' }

    switch (error.response.status) {
      case 401:
        if (error.response.data?.errors?.error_code === 40102) {
          throw error
        }
        if (error.response.data?.errors?.error_code === 40101) {
          router.push('/mfa_code').catch(() => { /**/ })
          throw error
        }
        if (router.currentRoute.name !== 'Login' && router.currentRoute.name !== 'MfaCode') {
          router.push('/logout').catch(() => { /**/ })
          throw error
        }
        break
      case 422:
        if (error.response.data.errors && error.response.data.errors.detail) {
          let errors: string[] = []
          if (Array.isArray(error.response.data.errors.detail)) {
            errors.push(error.response.data.errors.detail)
          } else {
            errors.push(error.response.data)
          }
          throw new ValidationError(error.response, errors, error.response.data.errors.error_code, error.response.data)
        } else if (error.response && error.response.data) {
          let errors: string[] = []
          errors.push(error.response.data)
          throw new ValidationError(error.response.data, errors)
        } else {
          throw error
        }
      case 404:
        let errors: string[] = []
        if (error.response.data.errors && error.response.data.errors.error_message) {
          if (Array.isArray(error.response.data.errors.detail)) {
            errors.push(error.response.data.errors.detail)
          } else {
            errors.push(error.response.data.errors.error_message)
          }
          throw new NotFoundError(`[RWV] ApiService ${error}`, errors)
        } else {
          throw error
        }
      case 403:
        const errMessage = `
          [RWV] ApiService ${error}
          currentUser(id: ${id}, email: ${email})
          route: ${router.currentRoute.fullPath}
        `
        throw new AccessDeniedError(errMessage)
      case 500:
        if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
          Vue.$rollbar.log(`Internal Server Error: ${error.config.baseURL}${error.config.url}`)
        }
        throw new UnhandledError(`[RWV] ApiService ${error}`)
      default:
        throw error
    }
  }

  public static downloadByPost (resource: string, params: {}) {
    let config = this.config
    Object.assign(config, { responseType: 'blob' })
    return Vue.axios.post(`${resource}`, params, config)
  }

  public static update (resource: string, slug: string, params: {}): Promise<any> {
    return Vue.axios.put(`${resource}/${slug}`, params, this.config).catch(error => {
      this.throwError(error)
    })
  }

  public static put (resource: string, params: {}): Promise<any> {
    return Vue.axios.put(`${resource}`, params, this.config).catch(error => {
      this.throwError(error)
    })
  }

  public static delete (resource: string) {
    return Vue.axios.delete(resource, this.config).catch(error => {
      this.throwError(error)
    })
  }

  public static deleteWithParams (resource: string, params: {}) {
    let config = this.config
    Object.assign(config, { data: { ...params } })

    return Vue.axios.delete(resource, config).catch(error => {
      this.throwError(error)
    })
  }

  public static deleteByIds (resource: string, params: {}) {
    let config = this.config
    Object.assign(config, { data: { ...params } })
    return Vue.axios.delete(resource, config).catch(error => {
      this.throwError(error)
    })
  }
}
