import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators'
import store from '@/store'
import LocaleCode from 'locale-code'
import I18n from '@/i18n'
import { Carrier } from '@/models/carrier'
import Vue from 'vue'
import { CONFIGURATION_KEYS } from '@/common/config'
import { Job } from '@/models/job'
import { CarriersApiService } from '@/common/api/carriers.api.service'
import { CountryData } from '@/models/country-data'
import CountriesDataApiService from '@/common/api/countries-data.api.service'
import get from 'lodash/get'
import { plainToClass } from 'class-transformer'

export interface IAppState {
  title: string
  preloader: boolean
  _carriersList: Carrier[]
  _jobs: Job[]
  whiteLabel: any
  locale: string
  momentLocale: string
  country: string
  _appError: string
  userHasAsyncJobs: boolean
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {
  public title = ''
  public preloader = false
  public whiteLabel = {}
  public locale = 'en'
  public momentLocale = 'en'
  public country = 'us'
  public _appError = ''
  public _carriersList: Carrier[] = []
  public _countryList: CountryData[] = []
  public _jobs: Job[] = []
  public _appMessage = ''
  public userHasAsyncJobs = false
  public _googleMapStateIsValid = true

  get currentTitle () {
    return this.title
  }

  get appError (): string {
    return this._appError
  }

  get appMessage (): string {
    return this._appMessage
  }

  get currentPreloader () {
    return this.preloader
  }

  get currentLocale () {
    return this.locale.toUpperCase()
  }

  get currentMomentLocale () {
    return this.momentLocale
  }

  get currentCountry () {
    return this.country
  }

  get redirectUrl () {
    return Vue.$getConst(CONFIGURATION_KEYS.REDIRECT_URL)
  }

  get carriersList (): Carrier[] {
    return this._carriersList
  }

  get countryList (): CountryData[] {
    return this._countryList
  }

  get jobsList (): Job[] {
    return this._jobs
  }

  get hasAsyncJob (): boolean {
    return this.userHasAsyncJobs
  }

  get googleMapStateIsValid (): boolean {
    return this._googleMapStateIsValid
  }

  @Action
  public updateLocale (locale: string) {
    this.SET_LOCALE(locale)
  }

  @Action
  public updateCountry (country: string) {
    this.SET_COUNTRY(country)
  }

  @Action
  public updateTitle (title: string) {
    this.SET_TITLE(title)
  }

  @Action
  public updatePreloader (status: boolean) {
    this.SET_PRELOADER(status)
  }

  @Action
  public updateError (errorMsg: string) {
    this.SET_ERROR(errorMsg)
  }

  @Action
  public setAppMessage (msg: string) {
    this.SET_MESSAGE(msg)
  }

  @Action({ rawError: true })
  public async setCarriers () {
    CarriersApiService.list().then(( list) => {
      this.UPDATE_CARRIERS(list)
    }).catch(() => {
      /**/
    })
  }

  @Action({ rawError: true })
  public async setCountries () {
    let list: CountryData[] = []
    CountriesDataApiService.getCountriesData().then(( countriesDataResponse) => {
      const data = get(countriesDataResponse, 'data.data.country_datums')
      data.forEach( (country: any) => {
        list.push(plainToClass(CountryData, country))
      })
      this.UPDATE_COUNTRIES(list)
    }).catch(() => {
      /**/
    })
  }

  @Action
  public setAsyncJob (state: boolean) {
    this.UPDATE_ASYNC_JOB(state)
  }

  @Action
  public setJobsList (jobs: Job[]) {
    this.UPDATE_JOBS_LIST(jobs)
  }

  @Action
  public setGMapStateValid (state: boolean) {
    this.UPDATE_GMAP_STATE(state)
  }

  @Mutation
  public SET_LOCALE (locale: string) {
    let code = LocaleCode.getLanguageCode(locale).trim().toLowerCase()
    this.locale = (code && code.length) ? code : locale.trim().toLowerCase()
    if (locale === 'en') {
      this.momentLocale = 'en-GB'
    } else {
      this.momentLocale = locale
    }
    I18n.locale = (code && code.length) ? code : locale.trim().toLowerCase()
  }

  @Mutation
  public SET_COUNTRY (country: string) {
    this.country = country
  }

  @Mutation
  public SET_TITLE (title: string) {
    this.title = title
  }

  @Mutation
  public SET_PRELOADER (status: boolean) {
    this.preloader = status
  }

  @Mutation
  public SET_ERROR (msg: string) {
    this._appError = msg
  }

  @Mutation
  public SET_MESSAGE (msg: string) {
    this._appMessage = msg
  }

  @Mutation
  public UPDATE_CARRIERS (carriers: Carrier[]) {
    this._carriersList = []
    this._carriersList.push(...carriers)
  }

  @Mutation
  public UPDATE_COUNTRIES (countries: CountryData[]) {
    this._countryList = []
    this._countryList.push(...countries)
  }

  @Mutation
  public UPDATE_JOBS_LIST (jobs: Job[]) {
    this._jobs = []
    this._jobs.push(...jobs)
  }

  @Mutation
  public UPDATE_ASYNC_JOB (state: boolean) {
    this.userHasAsyncJobs = state
  }

  @Mutation
  public UPDATE_GMAP_STATE (state: boolean) {
    this._googleMapStateIsValid = state
  }
}

export const AppModule = getModule(App)
