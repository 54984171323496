<script>
import BasicSelect from '@/components/select-boxes/BasicSelect'
import { CarrierAccountsApiService } from '@/common/api/carrier-accounts.api.service'

export default {
  name: 'CarrierAccountsSelectBox',
  extends: BasicSelect,
  data () {
    return {
      localOptions: []
    }
  },
  watch: {
    async value (val) {
      this.$emit('selectedModel', this.localOptions.filter(model => Number(model.id) === Number(val))[0].model)
    }
  },
  methods: {
    processResults (data, params) {
      const results = data.carrier_accounts.map((model) => (
          this.formatOption(model)
      ))
      this.localOptions = []
      this.localOptions.push(...results)
      return {
        results
      }
    },
    formatOption (model) {
      return { id: model.id, title: model.select_box_text, text: model.select_box_text, link: false, model }
    },
    formatResult (option) {
      return option.text
    },
    formatSelection (option) {
      return option.text
    }
  },
  async mounted () {
    if (this.value) {
      let account = await CarrierAccountsApiService.getCarrierAccountSelectItem(this.value)
      this.setOption([{ id: account.id, text: account.selectBoxText, account }])
      this.$emit('selectedModel', account)
    }
    this.setValue(this.value)
  }
}
</script>
