
import { Component, Prop, Watch } from 'vue-property-decorator'
import validator from 'validator'
import BasicComponent from '@/views/BasicComponent'
import EnviayaAccountSearchBox from '@/components/search-boxes/EnviayaAccountSearchBox.vue'
import RefTypeSelectBox from '@/components/select-boxes/RefTypeSelectBox.vue'
import CorporateAccountSearchBox from '@/components/search-boxes/CorporateAccountSearchBox.vue'
import { ISelectData } from '@/models/EnviayaAccountTS'
import {
  Ecommerce,
  MEASUREMENT_UNIT_LIST,
  MEASUREMENT_WEIGHT_UNIT_LIST,
  Packaging,
  PACKAGING_TYPE
} from '@/models/packaging'
import { PackagingParcelReference } from '@/models/packaging-parcel-reference'
// @ts-ignore
import { packagingWeightCalculator } from '@/mixins/packagingWeightCalculator'
import EnviayaNumberInput from '@/overrides/mdb-components/EnviayaNumberInput.vue'
import PackagingCatalogueSelections from '@/components/shipments_catalogues/PackagingCatalogueSelections.vue'
import Commodity from '@/components/packagings/Commodity.vue'
import { ShipmentCataloguesApiService } from '@/common/api/shipment-catalogues.api.service'
import get from 'lodash/get'
import { SHIPMENT_TYPE } from '@/models/shipment'
import { PackagingCommodity } from '@/models/packaging-commodity'
import { plainToClass } from 'class-transformer'
import Configuration from '@/components/packagings/Configuration.vue'

@Component({
  components: {
    Configuration,
    PackagingCatalogueSelections,
    CorporateAccountSearchBox,
    EnviayaAccountSearchBox,
    EnviayaNumberInput,
    RefTypeSelectBox,
    Commodity
  },
  mixins: [packagingWeightCalculator]
})

export default class PackagingForm extends BasicComponent {
  @Prop(Packaging) public package!: Packaging
  @Prop(Boolean) public readonly loading!: boolean
  @Prop(Boolean) public readonly submitted!: boolean
  @Prop(String) public readonly action!: string

  public links: any[] = []
  public showParcelContent: boolean = false
  public showCommodityInfo: boolean = false
  public TO_USER = 'user'
  public TO_CORP_ACCOUNT = 'corporate_account'
  public countryId: number | null = null

  public measurementList1: ISelectData[] = []
  public measurementList2: ISelectData[] = []

  public totals: any = {
    invoicedWeight: 0,
    physicalWeight: 0,
    volumetricWeight: 0
  }

  public validation: any = {
    name: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    },
    quantity: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    },
    length: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    },
    weight: {
      valid: false,
      validated: false,
      invalidFeedback: ''
    },
    width: {
      valid: true,
      validated: false,
      invalidFeedback: ''
    },
    height: {
      valid: true,
      validated: false,
      invalidFeedback: ''
    },
    value: {
      valid: true,
      validated: false,
      invalidFeedback: ''
    },
    content: {
      valid: true,
      validated: false,
      invalidFeedback: ''
    }
  }

  public async mounted () {
    if (this.action === 'create' && this.package.packagingMeta.packagingType === PACKAGING_TYPE.PARCEL_TEMPLATE) {
      this.package.packagingMeta.shipmentType = SHIPMENT_TYPE.PACKAGE
    }
    this.links.push({ text: this.$t('packaging_form.fields.packaging_type_packaging') })
    this.links.push({ text: this.$t('packaging_form.fields.packaging_type_template') })
    this.$nextTick(() => {
      this.package.measurementUnit = this.$currentUser.country === 'us' ? 'i' : 'm'
      this.package.weightUnit = this.$currentUser.country === 'us' ? 'i' : 'm'
      this.publishSelects()
      this.calculateWeight()

      this.unwatchAccessors.push(this.$watch('package.id', this.onPackageIdChanged))
      this.showCommodityInfo = this.package?.packagingMeta.packagingCommodities.length > 0
    })
    const countriesResponse = await ShipmentCataloguesApiService.getCountries()
    const countries = get(countriesResponse, 'data.countries')
    this.countryId = countries.find((country: Record<string, any>) => country.country_code === 'MX')?.id
  }

  public async validate (): Promise<boolean> {
    let isValidForm: boolean = true
    // @ts-ignore
    if (!this.package.isDocument && !this.$refs['cartaPorte'].validateCartaForte()) isValidForm = false
    if (validator.isEmpty(this.package.name)) {
      this.validation['name'].valid = false
      this.validation['name'].invalidFeedback = this.$t('packaging_form.validations.blank')
      isValidForm = false
    } else {
      this.validation['name'].valid = true
      this.validation['name'].invalidFeedback = ''
    }
    this.validation['name'].validated = true

    if (this.package.isParcelTemplate && this.package.isParcel && (!this.package.quantity || this.package.quantity <= 0)) {
      this.validation['quantity'].valid = false
      this.validation['quantity'].invalidFeedback = this.$t('packaging_form.validations.blank')
      isValidForm = false
    } else {
      this.validation['quantity'].valid = true
      this.validation['quantity'].invalidFeedback = ''
    }
    this.validation['quantity'].validated = true

    if ((!this.package.weight || this.package.weight <= 0) && this.package.isParcelTemplate) {
      this.validation['weight'].valid = false
      this.validation['weight'].invalidFeedback = this.$t('packaging_form.validations.blank')
      isValidForm = false
    } else {
      this.validation['weight'].valid = true
      this.validation['weight'].invalidFeedback = ''
    }
    this.validation['weight'].validated = true

    if ((!this.package.length || this.package.length <= 0) && !this.package.isDocument) {
      this.validation['length'].valid = false
      this.validation['length'].invalidFeedback = this.$t('packaging_form.validations.blank')
      isValidForm = false
    } else {
      this.validation['length'].valid = true
      this.validation['length'].invalidFeedback = ''
    }
    this.validation['length'].validated = true

    if ((!this.package.height || this.package.height <= 0) && !this.package.isDocument) {
      this.validation['height'].valid = false
      this.validation['height'].invalidFeedback = this.$t('packaging_form.validations.blank')
      isValidForm = false
    } else {
      this.validation['height'].valid = true
      this.validation['height'].invalidFeedback = ''
    }
    this.validation['height'].validated = true

    if ((!this.package.width || this.package.width <= 0) && !this.package.isDocument) {
      this.validation['width'].valid = false
      this.validation['width'].invalidFeedback = this.$t('packaging_form.validations.blank')
      isValidForm = false
    } else {
      this.validation['width'].valid = true
      this.validation['width'].invalidFeedback = ''
    }
    this.validation['width'].validated = true

    let isCommoditiesValid = await this.allCommoditiesValid()
    let isConfigurationssValid = await this.allConfigurationValid()
    return isValidForm && isCommoditiesValid && isConfigurationssValid
  }

  public changeMeasurementUnit (model: Packaging, value: string, elID: string) {
    if (value) {
      model.measurementUnit = value
      model.weightUnit = value
      // @ts-ignore
      this.$refs[elID].doClose()
    }
  }

  public calculateWeight () {
    let weightUnit = (this.package.weightUnit === 'i') ? 'lbs' : 'kg'
    // @ts-ignore
    this.totals.invoicedWeight = parseFloat(this.calculateTotalInvoicedWeight([this.package], weightUnit)).toFixed(2)
    // @ts-ignore
    this.totals.physicalWeight = parseFloat(this.calculatePhysicalWeight([this.package])).toFixed(2)
    // @ts-ignore
    let volumetricWeight = this.calculateVolWeight([this.package], weightUnit)
    this.totals.volumetricWeight = Number(parseFloat(volumetricWeight).toFixed(2)) >= 0.01
      ? parseFloat(volumetricWeight).toFixed(2)
      : '0.01'
  }

  @Watch('package.name')
  @Watch('package.quantity')
  @Watch('package.weight')
  @Watch('package.length')
  @Watch('package.height')
  @Watch('package.width')
  public async onPackageChanged () {
    if (this.submitted) {
      await this.validate()
    }
    this.calculateWeight()
  }

  public async onPackageIdChanged () {
    if (this.package.id) {
      this.publishSelects()
      if (this.package.packagingMeta.content.contentDescription || this.package.packagingMeta.insuranceAmount) {
        this.showParcelContent = true
      }
      this.showCommodityInfo = this.package?.packagingMeta.packagingCommodities.length > 0
    }
  }

  public publishSelects () {
    this.measurementList1 = []
    this.measurementList2 = []
    MEASUREMENT_UNIT_LIST.forEach((el) => this.measurementList1.push({
      text: this.$t(`packaging_form.unit_list.${el.value}`).toString(),
      value: el.value,
      selected: (this.package.measurementUnit === el.value)
    }))
    MEASUREMENT_WEIGHT_UNIT_LIST.forEach((el) => this.measurementList2.push({
      text: this.$t(`packaging_form.weight_unit_list.${el.value}`).toString(),
      value: el.value,
      selected: (this.package.weightUnit === el.value)
    }))
  }

  public async onUserChanged (value: number) {
    this.package.packagingMeta.owner.user.id = value
    this.package.packagingMeta.owner.company.id = null
  }

  public async onCorporateAccountChanged (value: number) {
    this.package.packagingMeta.owner.company.id = value
    this.package.packagingMeta.owner.user.id = null
  }

  public get weightKG () {
    return (this.package.weight && this.package.weightUnit === 'i')
      ? this.package.weight * 2.205
      : this.package.weight
  }

  public changePackagingType (index: number) {
    this.package.packagingMeta.packagingType = index + 1
    if (this.package.isParcelTemplate) {
      this.package.packagingMeta.shipmentType = SHIPMENT_TYPE.PACKAGE
    } else {
      this.package.packagingMeta.shipmentType = null
    }

    if (this.action === 'create') {
      if (this.package.isPackaging) {
        this.package.weight = 0.02
      } else {
        this.package.weight = undefined
      }
    }
  }

  public changeShipmentType () {
    if (this.action === 'update') {
      if (this.package.isDocument) {
        this.removeAllCommodities()
      }
    }
  }

  public get title () {
    return (this.package.isPackaging)
      ? this.$t('packaging_form.fields.packaging_type_packaging')
      : this.$t('packaging_form.fields.packaging_type_template')
  }

  public get isNotNewShipmentPage () {
    return this.$route.name !== 'shipments_new'
  }

  public get shipmentTypes () {
    return SHIPMENT_TYPE
  }

  public get packagingTypes () {
    return PACKAGING_TYPE
  }

  public addReference () {
    this.package?.packagingMeta.packagingParcelReferences.push(new PackagingParcelReference())
  }

  public removeReference (index: number) {
    this.package.packagingMeta.packagingParcelReferences.splice(index, 1)
  }

  public changeCommodityState (value: boolean) {
    if (value) {
      this.addCommodityItem()
    } else {
      this.removeAllCommodities()
    }
  }

  public removeAllCommodities () {
    this.package.packagingMeta.packagingCommodities = []
  }

  public addCommodityItem () {
    let packagingOrParcel = {}
    if (this.package?.id) packagingOrParcel = this.getCommodityReference(this.package)
    this.package?.packagingMeta.packagingCommodities.push(plainToClass(PackagingCommodity, { id: null, ...packagingOrParcel }))
  }

  public getCommodityReference (packaging: Packaging) {
    return (packaging.isPackaging) ? { packaging_id: packaging.id } : { packaging_parcel_id: packaging.id }
  }

  public removeCommodityItem (index: number ) {
    this.package.packagingMeta.packagingCommodities.splice(index, 1)
  }

  public async allCommoditiesValid () {
    for (const commodity of this.package.packagingMeta.packagingCommodities) {
      await commodity.validateModel()
      commodity.wasValidated = true
    }
    return this.package.packagingMeta.packagingCommodities.filter(commodity => !commodity.isValidModel).length === 0
  }

  public async allConfigurationValid () {
    for (const configuration of this.package.configurations.ecommerce) {
      await configuration.weight.validateModel()
      configuration.weight.wasValidated = true

      await configuration.dimensionalWeight.validateModel()
      configuration.dimensionalWeight.wasValidated = true

      await configuration.items.validateModel()
      configuration.items.wasValidated = true
    }
    return this.package.configurations.ecommerce.filter(
      configuration => !configuration.weight.isValidModel || !configuration.dimensionalWeight.isValidModel || !configuration.items.isValidModel
    ).length === 0
  }

  public addConfigurationItem () {
    this.package?.configurations.ecommerce.push(plainToClass(Ecommerce, { }))
  }

  public removeConfigurationItem (index: number ) {
    this.package.configurations.ecommerce.splice(index, 1)
  }
}
