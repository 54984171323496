import { Expose, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import { CogsConfiguration } from '@/models/cogs-configuration'
import { Service } from '@/models/service'

export enum SERVICE_TYPES {
  DOMESTIC = 1,
  INTERNATIONAL = 2
}


export class CarrierService extends AbstractModel {
  static get modelName () {
    return 'Service'
  }

  @Expose({ name: 'id' })
  public id!: number

  @Expose({ name: 'carrier_id' })
  public carrierId: number | null = null

  @Expose({ name: 'service_code' })
  public serviceCode: string = ''

  @Expose({ name: 'service_type' })
  public serviceTypeCode: number | null = null

  @Expose({ name: 'localized_service_name' })
  public localizedServiceName: string = ''

  public get serviceType (): string {
    return this.serviceTypeCode === SERVICE_TYPES.DOMESTIC ? 'domestic' : 'international'
  }

  @Expose({ name: 'calculation_type', toClassOnly: true })
  public calculationType: number | null = null

  @Expose({ name: 'carrier_service_standard_cogs_configurations', toClassOnly: true })
  @Type(() => CogsConfiguration)
  public cogsConfigurations: CogsConfiguration[] = []

  @Expose({ name: 'services', toClassOnly: true })
  @Type(() => Service)
  public services: Service[] = []
}
