import { Exclude, Expose, Transform, Type } from 'class-transformer'
import moment from 'moment'
import Vue from 'vue'
import 'reflect-metadata'
import { OrderCustomer } from '@/models/orders/order-customer'
import { OrderItem } from '@/models/orders/order-item'
import { ShipmentLine } from '@/models/orders/shipment-line'
import { OrderShipment } from '@/models/orders/order-shipment'
import { OrderDestination } from '@/models/orders/order-destination'
import { AbstractModel } from '@/models/abstract-model'
import { Shop } from '@/models/Shop'
import { Direction } from '@/models/direction'

export enum ORDER_PROVIDER {
  SHOPIFY = 'Shopify',
  WOOCOMMERCE = 'WooCommerce',
  MAGENTO_1 = 'Magento 1',
  MAGENTO_2 = 'Magento 2',
  PRESTASHOP = 'Prestashop',
  OPENCART = 'OpenCart',
  BIGCOMMERCE = 'BigCommerce',
  AMAZON = 'Amazon',
  GO_DADDY = 'GoDaddy',
  WIX = 'Wix',
  JUMPSELLER = 'Jumpseller',
  TIENDANUBE = 'TiendaNube',
  VTEX = 'Vtex',
  LIVERPOOL = 'Liverpool'
}

export enum ORDER_STATUSES {
  PENDING = 1,
  DELIVERED = 2,
  CANCELLED = 3,
  REFUNDED = 4,
  EXCEPTION = 5,
  CONVERTED_TO_PREPAID = 6,
  DEACTIVATED = 7,
  TEMPORARY_CREDIT = 97,
  DELETED = 99
}

export enum ORDER_TYPES {
  PREPAID_NATIONAL = 1,
  SHIPMENT = 2,
  CREDIT = 3,
  PREPAID_INTERNATIONAL = 4,
  NONE = 5,
  CLEARANCE = 6,
  PICKUP = 7,
  OWN_CARRIER_ACCOUNT = 8,
  TO_ASIGN_SPEI_RECURRENT = 9
}

export const ACTIVE_ORDER_STATUSES = [
  ORDER_STATUSES.PENDING
]

export const WOOCOMMERCE_STATUSES_LIST = [
  'pending',
  'processing',
  'on-hold',
  'completed',
  'cancelled',
  'refunded',
  'failed',
  'trash'
]

const ORDER_PAID_STATUSES = new Map<string, Array<string>>([
  [ORDER_PROVIDER.SHOPIFY, ['authorized', 'paid']],
  [ORDER_PROVIDER.WOOCOMMERCE, ['processing', 'completed']],
  [ORDER_PROVIDER.MAGENTO_2, ['processing', 'complete?']],
  [ORDER_PROVIDER.WIX, ['PAID']],
  [ORDER_PROVIDER.JUMPSELLER, ['paid']],
  [ORDER_PROVIDER.TIENDANUBE, ['paid']],
  [ORDER_PROVIDER.VTEX, ['on-order-completed', 'on-order-completed-ffm', 'payment-approved', 'invoiced', 'ready-for-handling']],
  [ORDER_PROVIDER.LIVERPOOL, ['paid']],
  [ORDER_PROVIDER.PRESTASHOP, ['paid']],
  [ORDER_PROVIDER.BIGCOMMERCE, ['processing']]
])

const WOO_ORDER_PAID_STATUSES = [
  'processing'
]

const WOO_ORDER_COMPLETED_STATUSES = [
  'completed'
]

const WOO_ORDER_UNPAID_STATUSES = [
  'pending',
  'on-hold',
  'refunded',
  'custom'
]

const WOO_ORDER_FAILED_STATUSES = [
  'cancelled',
  'failed',
  'trash'
]

export const WOO_ORDER_STATUS_TIP_COLORS = new Map<string, string>([
  ['pending', 'warning'],
  ['processing', 'info'],
  ['on-hold', 'warning'],
  ['completed', 'success'],
  ['cancelled', 'danger'],
  ['refunded', 'warning'],
  ['failed', 'danger'],
  ['trash', 'danger']
])

export class Order extends AbstractModel {
  @Expose({ name: 'name' })
  public name: string = ''

  @Expose({ name: 'currency' })
  public currency: string = ''

  @Expose({ name: 'select_box_text' })
  public selectBoxText: string = ''

  @Expose({ name: 'customer_email' })
  public customerEmail: string = ''

  @Expose({ name: 'order_status_url' })
  public orderStatusUrl: string = ''

  @Expose({ name: 'payment_method' })
  public paymentMethod: string = ''

  @Expose({ name: 'provider' })
  public provider: string = ''

  @Expose({ name: 'shop_customer_id' })
  public shopCustomerId: number | undefined

  @Expose({ name: 'shop_domain' })
  public shopDomain: string = ''

  @Expose({ name: 'shop_id' })
  public shopId: number | undefined

  @Expose({ name: 'shop_order_id' })
  public shopOrderId: number | undefined

  @Expose({ name: 'shop' })
  @Type(() => Shop)
  public shop: Shop | undefined

  @Expose({ name: 'status' })
  public status: string = ''

  @Expose({ name: 'fulfillment_status' })
  @Transform(value => value || 'unfulfilled')
  public fulfillmentStatus: string = 'unfulfilled'

  @Expose({ name: 'live_shipment_exists' })
  public liveShipmentExists: boolean = false

  @Expose({ name: 'total_amount' })
  public totalAmount: number | undefined

  @Expose({ name: 'total_products_amount' })
  public totalProductsAmount: number | undefined

  @Expose({ name: 'created_at' })
  @Transform(value => {
    if (value) {
      return `${moment(Date.parse(value)).format(Vue.moment.localeData().longDateFormat('L'))} ${moment(Date.parse(value)).format(Vue.moment.localeData().longDateFormat('LT'))} `
    } else {
      return ''
    }
  }, { toClassOnly: true })
  public createdAt: string | undefined

  @Expose({ name: 'original_created_at' })
  @Transform(value => {
    if (value) {
      return `${moment(value, 'YYYY-MM-DD HH:mm').format(Vue.moment.localeData().longDateFormat('L'))} ${moment(value, 'YYYY-MM-DD HH:mm').format(Vue.moment.localeData().longDateFormat('LT'))} `
    } else {
      return ''
    }
  }, { toClassOnly: true })
  public originalCreatedAt: string = ''

  @Expose({ name: 'customer' })
  @Type(() => OrderCustomer)
  @Transform(value => {
    return (value) ? value : new OrderCustomer('', '')
  }, { toClassOnly: true })
  public customer: OrderCustomer = new OrderCustomer('', '')

  @Expose({ name: 'parcels' })
  @Type(() => OrderItem)
  public parcels: OrderItem[] = []

  @Expose({ name: 'shipping_lines' })
  @Type(() => ShipmentLine)
  public shippingLines: ShipmentLine[] = []

  @Expose({ name: 'shipments' })
  @Type(() => OrderShipment)
  public shipments: OrderShipment[] = []

  @Expose({ name: 'destination' })
  @Type(() => OrderDestination)
  public destination: OrderDestination | undefined

  @Expose({ name: 'origin' })
  @Type(() => Direction)
  public origin: Direction | undefined

  @Exclude()
  public isShopifyOrder (): boolean {
    return this.provider === ORDER_PROVIDER.SHOPIFY
  }

  @Exclude()
  public isWooOrder (): boolean {
    return this.provider === ORDER_PROVIDER.WOOCOMMERCE
  }

  @Exclude()
  public isMagento1Order (): boolean {
    return this.provider === ORDER_PROVIDER.MAGENTO_1
  }

  @Exclude()
  public isMagento2Order (): boolean {
    return this.provider === ORDER_PROVIDER.MAGENTO_2
  }

  @Exclude()
  public isPrestaShopOrder (): boolean {
    return this.provider === ORDER_PROVIDER.PRESTASHOP
  }

  @Exclude()
  public isOpenCartOrder (): boolean {
    return this.provider === ORDER_PROVIDER.OPENCART
  }

  @Exclude()
  public isBigCommerceOrder (): boolean {
    return this.provider === ORDER_PROVIDER.BIGCOMMERCE
  }

  @Exclude()
  public isAmazonOrder (): boolean {
    return this.provider === ORDER_PROVIDER.AMAZON
  }

  @Exclude()
  public isGoDaddyOrder (): boolean {
    return this.provider === ORDER_PROVIDER.GO_DADDY
  }

  @Exclude()
  public isTiendaNubeOrder (): boolean {
    return this.provider === ORDER_PROVIDER.TIENDANUBE
  }

  @Exclude()
  public isLiverpoolOrder (): boolean {
    return this.provider === ORDER_PROVIDER.LIVERPOOL
  }

  @Exclude()
  public isPaid (): boolean {
    return !!ORDER_PAID_STATUSES.get(this.provider)!.find((status) => status === this.status)
  }

  @Exclude()
  public wooOrderIsPaid (): boolean {
    return !!WOO_ORDER_PAID_STATUSES.find((status) => status === this.status)
  }

  @Exclude()
  public wooOrderIsCompleted (): boolean {
    return !!WOO_ORDER_COMPLETED_STATUSES.find((status) => status === this.status)
  }

  @Exclude()
  public wooOrderIsUnpaid (): boolean {
    return !!WOO_ORDER_UNPAID_STATUSES.find((status) => status === this.status)
  }

  @Exclude()
  public wooOrderIsFailed (): boolean {
    return !!WOO_ORDER_FAILED_STATUSES.find((status) => status === this.status)
  }

  @Exclude()
  public isCanceled (): boolean {
    return Number(this.status) === ORDER_STATUSES.CANCELLED
  }
}
