import ApiService from '@/common/api/api.service'
import { DiscountProfile } from '@/models/discount-profile'
import DiscountProfileQuery from '@/models/search-filters/discount-profile-query'
import { plainToClass } from 'class-transformer'
import AccountsApiService from './accounts.api.service'
import GraphQLService from './graphql.service'

export class DiscountProfileApiService {
  public static async get (id?: number): Promise<DiscountProfile[]> {
    let { data } = await ApiService.query('discount_profiles', { params: { white_label_id: id } })
    return data.map((profile: any) => { return plainToClass(DiscountProfile, profile) } )
  }

  public static async search (params: {}) {
    let { data } = await ApiService.query('discount_profiles/search', { params })
    return data.map((profile: any) => { return plainToClass(DiscountProfile, profile) } )
  }

  public static getGQL (id: number): any {
    return GraphQLService.query(`
      query discountProfile ($id: ID!) {
        discountProfile(id: $id) {
          ${this.profileStructGraphQL()}
        }
      }
    `, {
      id
    })
  }

  public static profileStructGraphQL () {
    return `
      id
      name
      status
      level
      status
      selectBoxText
      defaultForUsers
      description
      whiteLabelId
      default
      kw
      quickOnboarding
      discountDisplay
      pickupAvailability
      priceRounding
      overrideExistingDiscountProfile
      minOverrideDiscountProfileLevel
      maxOverrideDiscountProfileLevel
      overrideMinimumLastShipmentPeriod
      unsentShipmentNotifications
    `
  }

  public static searchDiscountProfileGraphql (searchQuery: string, whiteLabel: number | null = null) {
    return GraphQLService.query(
      `query ($searchQuery: String, $whiteLabel: Int) {
        discountProfilesConnection(searchQuery: $searchQuery, whiteLabel: $whiteLabel) {
          ${AccountsApiService.connectionStructGraphql(this.profileStructGraphQL)}
        }
      }	`, {
        searchQuery,
        whiteLabel
      }
    )
  }

  public static downloadAdjustxampleFile (): Promise<any> {
    return ApiService.blob('discount_profiles/download_adjust_example_file')
  }

  public static createDiscountProfileGraphql (discountProfile: DiscountProfile) {
    return GraphQLService.query(`
      mutation ($discountProfile: JSON) {
        createDiscountProfile (
          input: {
            discountProfile: $discountProfile
          }
        ) {
          discountProfile {
            ${this.profileStructGraphQL()}
          },
          errors
        }
      }`, {
        discountProfile
      }
    )
  }

  public static updateDiscountProfileGraphql (id: number | string, discountProfile: DiscountProfile) {
    discountProfile.unsentShipmentNotifications = !discountProfile.unsentShipmentNotifications ? 0 : 1
    return GraphQLService.query(
      `mutation updateDiscountProfile(
        $id: ID
        $discountProfile: JSON
      ) {
        updateDiscountProfile (
          input: {
            id: $id
            discountProfile: $discountProfile
          }
        ) {
          discountProfile {
            ${this.profileStructGraphQL()}
          },
          errors
        }
      }`, {
        id,
        discountProfile
      }
    )
  }

  public static deleteDiscountProfile (id: number) {
    return GraphQLService.query(
      `mutation deleteDiscountProfile ($id: ID) {
        deleteDiscountProfile (
          input: {
            id: $id
          }
        ) {
          deleted,
          errors
        }
      }`, {
        id
      }
    )
  }

  public static discountProfilesListGraphql (filtersParams: DiscountProfileQuery, page?: number, perPage?: number) {
    let { searchQuery, whiteLabelId, userId, level, levelCondition, kw } = filtersParams
    level = Number(level)

    return GraphQLService.query(
      `query discountProfilesList(
          $page: Int,
          $perPage: Int,
          $searchQuery: String,
          $whiteLabelId: ID,
          $userId: ID,
          $level: Int,
          $levelCondition: String,
          $kw: String
        ) {
        discountProfilesOffset(
          page: $page
          perPage: $perPage
          searchQuery: $searchQuery
          whiteLabelId: $whiteLabelId
          userId: $userId
          level: $level
          levelCondition: $levelCondition
          kw: $kw
          ) {
            hasNextPage
            hasPreviousPage
            nodes {
              ${this.profileStructGraphQL()}
            }
            nodesCount
            pagesCount
            allNodeIds
        }
      }	`, {
        page,
        perPage,
        searchQuery,
        whiteLabelId,
        userId,
        level,
        levelCondition,
        kw
      }
    )
  }

  public static getCalculatedLevel () {
    return GraphQLService.query(`
      query calculateLevel {
        calculateLevel {
          profilesCountByLevels {
            level
            discountProfileCount
          }
        }
      }
    `, {})
  }

}
