import { render, staticRenderFns } from "./App.vue?vue&type=template&id=3be17d6e"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/locales/popup.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2FApp.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/update_available_popup.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2FApp.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/locales/page505.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2FApp.vue&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/locales/app_common.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2FApp.vue&external"
if (typeof block3 === 'function') block3(component)

export default component.exports