import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { SHIPMENT_STATUSES } from '@/filters/order-status-icon'
import { ShipmentLabel } from '@/models/orders/shipment-label'

export enum ACCOUNTING_STATUSES {
  ACCOUNTING_STATUS_STANDART = 0,
  ACCOUNTING_STATUS_PAID = 1,
  ACCOUNTING_STATUS_UNPAID = 2,
  ACCOUNTING_STATUS_CANCELLED = 3,
  ACCOUNTING_STATUS_REFUNDED = 4,
  ACCOUNTING_STATUS_CHARGEBACK = 5,
  ACCOUNTING_STATUS_DELETED = 6,
  ACCOUNTING_STATUS_PARTIALLY_PAID = 7,
  ACCOUNTING_STATUS_GOODWILL = 8,
  ACCOUNTING_STATUS_UNDER_REV_CHARGED = 9,
  ACCOUNTING_STATUS_UNDER_REV_CHARGE_ON_HOLD = 10,
  ACCOUNTING_STATUS_CANCEL_REQUESTED = 11,
  ACCOUNTING_STATUS_CANCELLATION_DENIED = 12,
  ACCOUNTING_STATUS_UNCOLLECTIBLE = 13,
  ACCOUNTING_STATUS_INTERNAL_SHIPMENT = 14,
  ACCOUNTING_STATUS_UNDER_REV_TO_BE_CHARGED = 15,
  ACCOUNTING_STATUS_NA_EXTERNAL = 16,
  ACCOUNTING_STATUS_NA_RESELLER = 17,
  ACCOUNTING_STATUS_NA_NONE_RESELLER = 18,
  ACCOUNTING_STATUS_EXCLUDED_FROM_CHARGE = 19,
  ACCOUNTING_STATUS_INTERNAL_REVIEW = 20
}

export class OrderShipment {
  @Expose({ name: 'id' })
  public id: number | undefined

  @Expose({ name: 'carrier_name' })
  public carrierName: string = ''

  @Expose({ name: 'carrier_id' })
  public carrierId: number | undefined

  @Expose({ name: 'service_code' })
  public serviceCode: string = ''

  @Expose({ name: 'status' })
  public status: number | undefined

  @Expose({ name: 'date_timestamp' })
  @Transform(value => { return (value === null) ? 0 : value }, { toClassOnly: true })
  public dateTimestamp: number | undefined

  @Expose({ name: 'carrier_tracking_number' })
  public carrierTrackingNumber: string = ''

  @Expose({ name: 'has_label' })
  public hasLabel: boolean = false

  @Expose({ name: 'labels_count' })
  public labelsCount: number | undefined

  @Expose({ name: 'accounting_status' })
  public accountingStatus: string = ''

  @Expose({ name: 'test' })
  public test: boolean | undefined

  @Expose({ name: 'shipment_labels' })
  @Type(() => ShipmentLabel)
  public shipmentLabels: ShipmentLabel[] = []

  @Exclude()
  public isAwaiting (): boolean {
    return Number(this.status) === SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_PENDING_PICKUP ||
        Number(this.status) === SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_PENDING_CUSTOMS_INFORMATION ||
        Number(this.status) === SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_WAITING_CARRIER_CONFIRMATION
  }

  @Exclude()
  public onHold (): boolean {
    return Number(this.accountingStatus) === ACCOUNTING_STATUSES.ACCOUNTING_STATUS_UNDER_REV_CHARGE_ON_HOLD
  }

  @Exclude()
  public isCanceled (): boolean {
    return Number(this.accountingStatus) === ACCOUNTING_STATUSES.ACCOUNTING_STATUS_CANCELLED
  }

  @Exclude()
  public isCancellationRequested (): boolean {
    return Number(this.accountingStatus) === ACCOUNTING_STATUSES.ACCOUNTING_STATUS_CANCEL_REQUESTED
  }

  @Exclude()
  public isSent (): boolean {
    return (
        ![
          SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_IN_CREATION,
          SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_PENDING_PICKUP,
          SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_PENDING_CUSTOMS_INFORMATION,
          SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_WAITING_CARRIER_CONFIRMATION,
          SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_BOOKING_ERROR
        ].includes(Number(this.status)) &&
        ![
          ACCOUNTING_STATUSES.ACCOUNTING_STATUS_CANCELLED,
          ACCOUNTING_STATUSES.ACCOUNTING_STATUS_DELETED
        ].includes(Number(this.accountingStatus))
    )
  }

  @Exclude()
  public isValidForShowTN (): boolean {
    return (
        ![
          ACCOUNTING_STATUSES.ACCOUNTING_STATUS_CANCELLED
        ].includes(Number(this.accountingStatus))
    )
  }
}
