<i18n src="@/locales/select_box_data/packaging_select_box.json"></i18n>
<i18n src="@/locales/packagings/packagings_form.json"></i18n>
<i18n src="@/locales/popup.json"></i18n>
<i18n src="@/locales/page505.json"></i18n>
<i18n src="@/locales/select2.json"></i18n>
<template>
  <section>
    <div :class="(submitted && !isValid) ? 'select2-single-wrap is-invalid' : 'select2-single-wrap'">
      <label ref="label" :class="{ disabled: disabled, active: isActive }">{{placeholder}}</label>
      <span class="caret" :class="{ disabled: disabled }">▼</span>
      <select :class="(submitted && !isValid) ? 'form-control select2-hidden-accessible is-invalid' : 'form-control select2-hidden-accessible'" :name="name" :allowClear="allowClear" :disabled="disabled" :required="required"/>
      <div class="invalid-feedback">{{invalidFeedback}}</div>
    </div>

    <portal to="packagingForm" v-if="showForm">
      <enviaya-modal
        :show="showForm"
        centered
        size="lg"
        :scrollable="true"
        :header-text="packagingTitle"
        :apply-btn-text="$t('packaging_form.save_btn')"
        :cancel-btn-text="$t('packaging_form.cancel_btn')"
        @apply="savePackaging()"
        @cancel="closeModal()"
        @close="closeModal()"
      >
        <template v-slot:body>
          <packaging-form
            ref="form"
            action="create"
            :package="model"
            :submitted="false"
          />
        </template>
      </enviaya-modal>
    </portal>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import PackagingForm from '@/components/packagings/PackagingForm.vue'
  import { Packaging, PACKAGING_TYPE } from '@/models/packaging'
  import { PackagingsApiService } from '@/common/api/packagings.api.service'
  import { UserRoles } from '@/common/user.roles'
  import { ValidationError } from '@/common/exceptions/validation-error'
  import { UnhandledError } from '@/common/exceptions/unhandled-error'
  import BasicSelect from '@/components/select-boxes/BasicSelect'
  import { plainToClass } from 'class-transformer'

  export default {
    name: 'packaging-select-box',
    extends: BasicSelect,
    components: { PackagingForm },
    props: {
      assignationType: {
        type: String,
        default: 'user'
      },
      assignationModelId: {
        type: Number,
        default: null
      }
    },
    data () {
      return {
        model: plainToClass(Packaging, {}),
        showForm: false
      }
    },
    computed: {
      ...mapGetters([
        'redirectUrl'
      ]),
      packagingTitle () {
        return (this.model.packagingMeta.packagingType === PACKAGING_TYPE.PACKAGING)
          ? `${this.$t('packaging_form.title_edit')} ${this.$t('packaging_form.fields.packaging_type_packaging')}`
          : `${this.$t('packaging_form.title_edit')} ${this.$t('packaging_form.fields.packaging_type_template')}`
      }
    },
    mounted () {
      if (!this.urlParams.model_type) {
        this.newModel = this.$t('packaging_select.actions.add_new_packaging')
      } else {
        this.newModel = (this.urlParams.model_type === PACKAGING_TYPE.PACKAGING)
          ? this.$t('packaging_select.actions.add_new_packaging')
          : this.$t('packaging_select.actions.add_new_parcel_template')
      }
    },
    methods: {
      processResults (data, params) {
        let options = []
        if (!data.models) {
          this.$modal.show({
            type: 'danger',
            centered: true,
            headerText: 'Error',
            bodyText: 'Invalid response with Packagings'
          })
        }
        options.push(...data.models.map((packaging) => { return { id: packaging.id, text: packaging.name, type: packaging.packaging_type, model: plainToClass(Packaging, packaging) } }))
        return {
          results: options
        }
      },
      formatResult (option) {
        if (option.type === PACKAGING_TYPE.PACKAGING) {
          return $.parseHTML(`<span><i class="fad fa-box pr-2 cyan-text"></i>${option.text}</span>`)
        } else if (option.type === PACKAGING_TYPE.PARCEL_TEMPLATE) {
          return $.parseHTML(`<span><i class="fad fa-file-invoice pr-2 purple-text"></i>${option.text}</span>`)
        }
        return option.text
      },
      formatSelection (option) {
        if (option.type === PACKAGING_TYPE.PACKAGING) {
          return $.parseHTML(`<span><i class="fad fa-box pr-2 cyan-text"></i>${option.text}</span>`)
        } else if (option.type === PACKAGING_TYPE.PARCEL_TEMPLATE) {
          return $.parseHTML(`<span><i class="fad fa-file-invoice pr-2 purple-text"></i>${option.text}</span>`)
        }
      },
      createModel () {
        this.model = new Packaging()
        if (!this.urlParams.model_type) {
          this.model.packagingMeta.packagingType = PACKAGING_TYPE.PACKAGING
        } else {
          this.model.packagingMeta.packagingType = this.urlParams.model_type
        }
        if (this.$currentUser.role === UserRoles.USER_ROLE_USER || this.$currentUser.role === UserRoles.USER_ROLE_CORPORATE_SUPERVISOR) {
          this.model.userId = Number(this.$currentUser.id)
        }
        this.model.measurementUnit = 'm'
        this.model.weightUnit = 'm'
        this.model.assignType = 'user'

        if (this.assignationModelId) {
          this.model.assignType = this.assignationType
          if (this.assignationType === 'user') {
            this.model.userId = this.assignationModelId
          } else {
            this.model.corporateAccountId = this.assignationModelId
          }
        }

        this.showForm = true
      },
      async savePackaging () {
        if (this.$refs['form'].validate()) {
          try {
            let { data } = await PackagingsApiService.create(this.model)
            let result = await PackagingsApiService.get(data.id)
            this.setOption([{ id: result.id, text: result.name, type: result.packagingMeta.packagingType }])
            this.$emit('change', result.id)
            this.$emit('select', result.id)
            this.showForm = false
          } catch (err) {
            switch (true) {
              case err instanceof ValidationError:
                this.$modal.show({
                  type: 'danger',
                  centered: true,
                  headerText: this.$t('popup.titles.error_title'),
                  bodyText: this.$options.filters.formatErrors({ ...err.errors })
                })
                break
              case err instanceof UnhandledError:
                this.$modal.show({
                  type: 'danger',
                  centered: true,
                  headerText: this.$t('505_error.title'),
                  bodyText: this.$t('505_error.sub_title')
                })
                break
            }
          }
        }
      },
      closeModal () {
        this.showForm = false
        this.model = plainToClass(Packaging, {})
      }
    }
  }
</script>

<style>
  .select2-results__option span.select-link {
    color: blue;
    font-weight: bold;
  }

  .select2-results__option span {
    color: #3fa2f7;
  }
  .select2-results__option:hover span,
  .select2-results__option.select2-results__option--highlighted span {
    font-weight: 600;
    color: #fff !important;
  }

  .select-custom-dropdown .select2-results__option {
    color: #757575;
    font-family: 'Lato';
    font-size: 14.4px;
    transition: .15s;
  }
  .select-custom-dropdown .select2-results__option.select2-results__option--highlighted {
    box-shadow: none!important;
  }

  @media (min-width: 500px) {

    .select-custom-dropdown .select2-results__options {
      max-height: 350px!important;
    }
  }
</style>

<style scoped>
  .form-control + >>> .select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
  }

  >>> .select2-selection__rendered {
    margin-bottom: -6px!important;
  }

  .caret {
    position: absolute;
    right: .5rem;
    font-size: .63rem!important;
    color: #757575;
    z-index: 1;
    cursor: pointer;
  }
  >>> .select2-selection__rendered {
    padding-left: 0!important;
  }

  >>> .select2-container--default .select2-selection--single{
    outline: none!important;
    border-top:none!important;
    border-left:none!important;
    border-right:none!important;
    border-radius: 0!important;
    border-bottom: 1px solid #ced4da!important;
    padding-bottom: 5px!important;
  }

  .is-invalid >>> .select2-container--default .select2-selection--single{
    border-bottom: 1px solid red!important;
  }

  >>> .select2-selection__choice {
    border: none!important;
    background-color: #fff!important;
    display: flex;
    flex-direction: row-reverse;
    padding-left: 0!important;
  }

  >>> .select2-selection__clear {
    color: red!important;
  }

  >>> .select2-selection__rendered :nth-last-child(2):before {
    content: none;
  }

  >>> .select2-results__option {
    color: #757575;
  }

  >>> .select2-container {
    width: 100% !important;
    /*max-width: 350px;*/
  }

  >>> .select2-search__field {
    width: 100% !important;
    font-size: 0.9rem !important;
  }

  >>> .select2-container--default .select2-selection--single {
    background: transparent !important;
  }

  >>> .select2-selection__rendered {
    line-height: 20px !important;
  }

  >>> .select2-container--default.select2-container--disabled span {
    color: #757575;
  }
</style>
