<template>
  <div class="card filters">
    <div class="card-body mt-lg-0 mt-md-3 pb-3 pb-lg-0">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar'
}
</script>

<style scoped lang="scss">
  @import "@/assets/css/mixin_topbar";
  @import "@/assets/css/mixin_filter-row";
</style>

<style scoped>
  .card.filters {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .card {
    z-index: auto!important;
  }
  .filters{
    margin-top: -15px;
    margin-left: -30px;
    margin-right: -30px;
  }
    @media (max-width: 998px) {
      .filters{
        padding: 0 20px;
      }
    }
  .filter {
    display: flex;
    margin-right: 1rem;
    padding-top: 5px;
    align-items: center;
    font-size: 0.9rem;
  }
  .filter-title {
    font-size: 0.9rem;
    font-weight: 600;
    }
  .clear-date {
    color: #4285f4;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .change-button {
    border-radius: 4px;
    margin: 0 auto;
    display: block;
  }
  .search_custom .select-wrapper + label {
    left: 12px;
  }
  .search_custom {
    border-bottom: 1px solid #CCC;
  }
  .wrapper-card-form .md-form,
  .search_custom .md-form {
    width: 100%;
    margin: 15px 0 0 0;
  }
  .search_custom input[type="text"] {
    width: 100%;
    display: block;
  }
  .hash_tags {
    margin-top: -0.5rem;
    width: 100%;
    color: #9b9b9b;
    font-size: 12px;
  }
  .search_custom {
    border-bottom: none !important;
  }
  .search_custom p {
    font-size: 1.2rem;
  }
  .search_custom button{
    font-weight: 500;
    font-size: 0.7rem;
    border-radius: 4px;
    padding: 0.7rem 1.6rem;
  }

  a {
    transition: all 0.3s;
  }

  a:hover {
    color: #fc7753;
  }

  @media(max-width: 992px) {
    .search_custom{
      margin-top: 1rem;
    }
  }
  @media(max-width: 800px) {
    .search_custom button{
      padding: 0.7rem 1.2rem;
    }
  }
  @media(max-width: 767px) {
    .search_custom button{
      width: 100%;
    }
    .filter-buttons div {
      width: 100%;
      padding: 0!important;
    }
    .filter-buttons div button {
      margin: 0 auto 1rem;
    }
    .main-color.filter p{
      font-size: 1rem;
      margin-top: 15px;
    }
  }
  .row_1.row.fullSize {
    margin: 0;
  }

  .col-12.d-flex.d-lg-none button {
    margin: 0;
  }
  .date-range{
    display: flex;
  }
  @media(min-width: 991px) {
    .date-range{
      justify-content: flex-end;
      padding-right: 20px;
    }
  }
  @media(max-width: 575px) {
    .search_custom{
    margin-top: -1rem;
    }
    .filter p{
    padding-left: 0!important;
    }
    .date-range{
      justify-content: space-between;
    }
  }
  .search_custom .search_custom button {
    font-weight: 700;
    font-size: 0.875rem;
    border-radius: 4px;
  }
  .search_custom .search_calendar {
    display: flex;
    justify-content: flex-end;
  }
  @media(max-width: 992px) {
    .search_custom .search_custom {
      padding-top: 20px;
    }
  }
  @media(max-width: 768px) {
    .search_custom  .search_calendar {
      padding-top: 20px;
      display: flex;
      justify-content: flex-start;
    }
  }
  .mydateSelect {
    position: relative;
    padding-left: 20px;
  }
  .mydateSelect >>> .caret {
    top: 10px;
    right: 0;
  }

  .mydateSelect >>> .select2-single-wrap {
    margin-top: 0 !important;
  }

  >>> .select2-container {
    max-width: none !important;
  }

  .mydateSelect:before {
    content:attr(data-content);
    transform: translate(-10px, -20px) scale(0.8);
    font-size: 0.9rem;
    font-weight: 400;
    position: absolute;
    top: -5px;
    left: 0;
    color: #757575;
    white-space: nowrap;
  }
  .card.filters >>> .dateRangeInput {
    min-width: 190px!important;
    margin-top: 10px;
  }

  .filters >>>  .md-form {
    /*margin-top: 1rem;*/
    margin-bottom: 1rem;
  }
  .filters >>>  .md-form label, .filters >>> .form-control {
    //font-size: .9rem!important;
  }
  .search_value >>>  .md-form .form-control {
    margin-bottom: 0;
  }
  .wrapper-card-form >>> .form-group {
    margin-bottom: 0;
  }
  .date-range__top-bar >>> .reportrange-text {
    padding-bottom: 0;
  }
  .date-range__top-bar .reportrange-text label, .date-range__top-bar .reportrange-text .form-control {
    font-size: 0.9rem;
  }
  .date-range__top-bar >>> .daterangepicker {
    transform: translate(-80%)!important;
  }
  .search_custom .md-form {
    margin-bottom: 0;
  }
  .filter-row {
    margin-bottom: 1rem;
  }
  .filter-row >>>.md-form {
   margin-bottom: 0!important;
  }

  div >>> .spinner-border-sm {
    width: 0.7rem;
    height: 0.7rem;
    border-width: .2em;
  }

  .search_value-users {
    margin-bottom: 0!important;
  }

  /*.top-bar-select {*/
  /*  padding-top: 6px;*/
  /*}*/

  >>> .select2-single-wrap {
    /*margin-top: 25px !important;*/
    margin-bottom: 0 !important;
    font-size: .9rem!important;
  }

  >>> .select2-single-wrap label {
    margin-top: 3px !important;
  }

  >>> .select2-single-wrap label.active {
    font-size: 12px !important;
    margin-top: -18px !important;
    color: #4285f4;
  }

  >>> .select2-single-wrap .caret {
    top: 5px;
  }

  >>> .select2-selection__clear {
    margin-top: 0px !important;
  }

</style>
