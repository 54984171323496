export class ValidationError extends Error {
  public errors: string[] = []
  public errorCode: number | undefined
  public response: any = {}

  constructor (message: any, errors: string[], errorCode?: number, response?: Object) {
    super(message)
    this.name = 'ValidationError'
    this.errors = errors
    this.errorCode = errorCode
    this.response = response
    this.stack = (new Error()).stack
  }
}
