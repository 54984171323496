export function useWeightUnits(selectedValue: string | undefined | null) {
  let units = [
    { text: 'Kg', value: 'kg' },
    { text: 'Lbs', value: 'lbs' }
  ]
  return units.map(option => {
    const { text, value } = option
    return {
      text,
      value,
      selected: selectedValue === value
    }
  })
}
