<script>
  import { plainToClass } from 'class-transformer'
  import BasicSelect from '@/components/select-boxes/BasicSelect'
  import AccountsApiService from '@/common/api/accounts.api.service'
  import { ACCOUNT_STATUSES, ACCOUNT_TYPE_RESELLER, EnviayaAccountTS } from '@/models/EnviayaAccountTS'
  import { getCharsLimit } from '@/constants/app-config'

  export default {
    name: 'EnviayaAccountSelectBox',
    extends: BasicSelect,
    watch: {
      async value (val) {
        let enviayaAccount = null
        if (val) {
          enviayaAccount = await AccountsApiService.get(val)
          this.setOption([this.formatOption(enviayaAccount.id, enviayaAccount.selectBoxText, enviayaAccount)])
          this.$emit('selectedAccount', enviayaAccount)
        } else {
          this.$emit('selectedAccount', null)
        }
        this.init = false
        this.setValue(val)
      }
    },
    methods: {
      processResults (data, params) {
        let options = []
        data.accounts.map((account) => {
          options.push(this.formatOption(account.id, account.select_box_text, account))
        })
        return {
          results: options
        }
      },
      formatOption (id, text, model) {
        if (model.status === ACCOUNT_STATUSES.DELETED) {
          return { id: id, title: `${text}`, text: `<i class="fal fa-user-times red-text pr-2"></i><span class="red-text text-decoration-line-through">${text}</span>`, link: false }
        } else if (model.status === ACCOUNT_STATUSES.DISABLED) {
          return { id: id, title: `${text}`, text: `<i class="fal fa-user-slash red-text pr-2"></i><span class="red-text text-decoration-line-through">${text}</span>`, link: false }
        } else if (model.status === ACCOUNT_STATUSES.DISABLED_SYSTEM) {
          return { id: id, title: `${text}`, text: `<i class="fal fa-user-shield red-text pr-2"></i><span class="red-text text-decoration-line-through">${text}</span>`, link: false }
        } else {
          if (model.referrer_id) {
            return { id: id, title: `${text}`, text: `<i class="fal fa-users blue-text pr-2"></i><span class="blue-text">${text}</span>`, link: false }
          } else if (model.account_type === ACCOUNT_TYPE_RESELLER) {
            return { id: id, title: `${text}`, text: `<i class="fal fa-users green-text pr-2"></i><span class="green-text">${text}</span>`, link: false }
          } else {
            return { id: id, title: `${text}`, text: `<i class="fal fa-user blue-text pr-2"></i><span class="blue-text">${text}</span>`, link: false }
          }
        }
      },
      formatResult (option) {
        if (option.loading) {
          return option.text
        } else {
          return $.parseHTML(option.text)
        }
      },
      formatSelection (option) {
        if (option.loading) {
          return option.text
        } else {
          return $.parseHTML(option.text)
        }
      }
    },
    async mounted () {
      this.minimumInputLength = getCharsLimit(this.$currentUser) - 1
      if (this.value && this.init) {
        let accountResponse = await AccountsApiService.basicInfo(this.value)
        let enviayaAccount = plainToClass(EnviayaAccountTS, accountResponse.data)
        this.setOption([this.formatOption(enviayaAccount.id, `${enviayaAccount.selectBoxText}`, enviayaAccount), enviayaAccount])
        this.$emit('selectedAccount', enviayaAccount)
      }

      this.setValue(this.value)
    }
  }
</script>
