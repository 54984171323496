
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FilterBar extends Vue {
  @Prop({ default: true }) public readonly emptyChip!: boolean
  @Prop({ default: false }) public readonly isLoading!: boolean
  @Prop({ default: false }) public readonly allowToCancel!: boolean
  @Prop({ default: 'top_bar.search' }) public readonly searchBtnTrKey!: string
  public showExtendedFilters: boolean = false
}
