export enum CLEARING_CHARGE_CODES {
  CHARGE_CODE_WEIGHT_ADJUSTMENT = 'W',
  CHARGE_CODE_RETURN = 'R',
  CHARGE_CODE_CHANGE_DIRECTION = 'C',
  CHARGE_CODE_RETURN_PICKUP_ATTEMPT = 'PRA',
  CHARGE_CODE_RETURN_THIRD_PICKUP_ATTEMPT = 'TPRA',
  CHARGE_CODE_RETURN_IMPOSSIBLE_DELIVERY = 'RI',
  CHARGE_CODE_IMPOSSIBLE_DELIVERY = 'ID',
  CHARGE_CODE_TEMP_STORAGE = 'TS',
  CHARGE_CODE_SATURDAY_PICKUP = 'SP',
  CHARGE_CODE_TRACKING_WEIGHT_ADJUSTMENT = 'TW',
  CHARGE_CODE_SPECIAL_HANDLING = 'SH',
  CHARGE_CODE_INDIRECT_SIGNATURE_REQUIRED = 'DSIE',
  CHARGE_CODE_WAIT_TIME = 'WT',
  CHARGE_CODE_FORMAL_EXPORT_REQUEST = 'SCER',
  CHARGE_CODE_IMPORT_TAXES = 'IT',
  CHARGE_CODE_MULTIPIECE = 'MP',
  CHARGE_CODE_REMOTE_AREA = 'RA',
  CHARGE_CODE_EMERGENCY_SITUATION = 'ES',
  CHARGE_CODE_OVERSIZE = 'SO',
  CHARGE_CODE_OVERWEIGHT = 'WO',
  CHARGE_CODE_INSURANCE = 'I',
  CHARGE_CODE_FUEL_SURCHARGE = 'F',
  CHARGE_CODE_INCORRECT_DECLARATION = 'SI',
  CHARGE_CODE_GENERAL_SURCHARGE = 'Z',
  CHARGE_CODE_FORBIDDEN_ITEM = 'SF',
  CHARGE_CODE_CANCELATION = 'CC',
  CHARGE_CODE_PEAK_SEASON = 'CP',
  CHARGE_CODE_REDIRECT = 'CREX',
  CHARGE_CODE_WRONG_SHIPMENT = 'CW',
  CHARGE_CODE_DELIVERY_WITH_APPOINTMENT = 'DA',
  CHARGE_CODE_DOOR_DELIVERY = 'DD',
  CHARGE_CODE_REMOTE_DOOR_DELIVERY = 'DDR',
  CHARGE_CODE_PROOF_OF_DELIVERY = 'DP',
  CHARGE_CODE_RESIDENTIAL_DELIVERY = 'DR',
  CHARGE_CODE_SATURDAY_DELIVERY = 'DS',
  CHARGE_CODE_SIGNATURE_ON_DELIVERY = 'DSI',
  CHARGE_CODE_PREMIUM_DELIVERY = 'E',
  CHARGE_CODE_PREMIUM_PICKUP_SERVICE = 'EPS',
  CHARGE_CODE_CUSTOM_TAXES = 'T',
  CHARGE_CODE_PICKUP = 'P',
  CHARGE_CODE_REMOTE_PICKUP = 'PR',
  CHARGE_CODE_SAME_DAY_PICKUP = 'PSD',
  CHARGE_CODE_EXTREME_OVERSIZE = 'SCE',
  CHARGE_CODE_PRICE_ADJUSTMENT = 'CPA'
}

export enum ADDITIONAL_CLEARING_CHARGE_CODES {
  CHARGE_CODE_CUSTOM_TAXES = 'CT',
  CHARGE_CODE_WRONG_SHIPMENT_INFO = 'WI',
  CHARGE_CODE_SURCHARGE = 'Z'
}

export enum SHIPMENT_REVISION_STATUSES {
  SHIPMENT_REVISION_STATUS_NO_NEEDED = 1,
  SHIPMENT_REVISION_STATUS_NEEDED = 2,
  SHIPMENT_REVISION_STATUS_IN_PROCESS = 3,
  SHIPMENT_REVISION_STATUS_REVIEWED_POSITIVE = 4,
  SHIPMENT_REVISION_STATUSE_REVIEWED_NEGATIVE = 5
}

export enum CLEARING_CHARGE_ACTIONS {
  GENERATE_ORDER = 'generate_order',
  CREATE_CLEARING_CHARGE = 'create',
  REQUEST_REVIEW = 'request_review',
  DOWNLOAD_CSV = 'download',
  SEE_DETAILS = 'see_detail',
  PAY = 'pay',
  ASK_FOR_ACLARATION = 'ask_for_aclaration',
  UPDATE_STATUS = 'update_status',
  UPDATE = 'update',
  DELETE = 'delete',
  BULK_UPDATE = 'bulk_update'
}

export const ClearingChargesHashes = {
  CLEARING_CHARGE: '#clearing-charge-props',
  INVOICING: '#invoicing',
  ORDER: '#order',
  AUDIT: '#audit',
  ALL: '#all',
  PAID: '#paid',
  UN_PAID: '#un-paid'
}

export enum ACCOUNTING_STATUSES {
  ACCOUNTING_STATUS_STANDART = 0,
  ACCOUNTING_STATUS_PAID = 1,
  ACCOUNTING_STATUS_UNPAID = 2,
  ACCOUNTING_STATUS_CANCELLED = 3,
  ACCOUNTING_STATUS_REFUNDED = 4,
  ACCOUNTING_STATUS_CHARGEBACK = 5,
  ACCOUNTING_STATUS_DELETED = 6,
  ACCOUNTING_STATUS_PARTIALLY_PAID = 7,
  ACCOUNTING_STATUS_GOODWILL = 8,
  ACCOUNTING_STATUS_UNDER_REV_CHARGED = 9,
  ACCOUNTING_STATUS_UNDER_REV_CHARGE_ON_HOLD = 10,
  ACCOUNTING_STATUS_CANCEL_REQUESTED = 11,
  ACCOUNTING_STATUS_CANCELLATION_DENIED = 12,
  ACCOUNTING_STATUS_UNCOLLECTIBLE = 13,
  ACCOUNTING_STATUS_INTERNAL_SHIPMENT = 14,
  ACCOUNTING_STATUS_UNDER_REV_TO_BE_CHARGED = 15,
  ACCOUNTING_STATUS_NA_EXTERNAL = 16,
  ACCOUNTING_STATUS_NA_RESELLER = 17,
  ACCOUNTING_STATUS_NA_NONE_RESELLER = 18,
  ACCOUNTING_STATUS_EXCLUDED_FROM_CHARGE = 19,
  ACCOUNTING_STATUS_INTERNAL_REVIEW = 20
}

export enum CARRIER_ACCOUNTING_STATUSES {
  CARRIER_ACCOUNTING_STATUS_NOT_TO_BE_INVOICED = 0,
  CARRIER_ACCOUNTING_STATUS_TO_BE_INVOICED = 1,
  CARRIER_ACCOUNTING_STATUS_INVOICED = 2,
  CARRIER_ACCOUNTING_STATUS_CREDIT_NOT_APPLIED = 3,
  CARRIER_ACCOUNTING_STATUS_INVOICED_PREPAID = 4
}

export enum ORDER_TYPES {
  ORDER_TYPE_PREPAID_NATIONAL = 1,
  ORDER_TYPE_SHIPMENT = 2,
  ORDER_TYPE_CREDIT = 3,
  ORDER_TYPE_PREPAID_INTERNATIONAL = 4,
  ORDER_TYPE_NONE = 5,
  ORDER_TYPE_CLEARANCE = 6,
  ORDER_TYPE_PICKUP = 7,
  ORDER_TYPE_OWN_CARRIER_ACCOUNT = 8
}

export const CLEARING_CHARGE_FORM_ACTIONS = {
  create: 'create',
  show: 'show',
  update: 'update'
}

export enum STATUS_COLUMNS {
  REVISION_STATUS = 'revision_status',
  ACCOUNTING_STATUS = 'accounting_status'
}

export enum CLEARING_CHARGES_SCOPE {
  UNPAID = 'unpaid',
  PAID = 'paid',
  GOODWILL = 'goodwill',
  NOT_CANCELLED_OR_GOODWILL = 'not_cancelled_or_goodwill',
  WITH_PAID_ORDER = 'with_paid_order'
}

export enum CHARGE_SHIPMENT_ORDER_STATUS {
  WITH_ORDER = 'with_order',
  WITHOUT_ORDER = 'without_order'
}
