import i18n from '@/i18n'
import { FILE_FORMATS } from '@/common/user.settings'

export function useSizeLetterFileFormats(selectedValue: number[] | number | undefined | null) {
  return useAllFileFormats(selectedValue).filter(format => format.value === FILE_FORMATS.FILE_FORMAT_PDF)
}

export function useAllFileFormats(selectedValue: number[] | number | undefined | null) {
  return Object.values(FILE_FORMATS)
    .filter((value) => Number.isInteger(value as number))
    .map((value) => {
      return {
        text: i18n.t(`user_settings.settings.file_formats.format_${value}`) as string,
        value: value,
        selected:
          !!selectedValue &&
          ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toString()).includes(value.toString())) ||
            (!Array.isArray(selectedValue) && selectedValue.toString() === value.toString()))
      }
    })
}
