import { WHO } from "@/common/user.roles"

const Who = {
  install(Vue) {
    Vue.prototype.$who = WHO
    Vue.$who = WHO
  }
}

export default Who
