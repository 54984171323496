import i18n from '@/i18n'

export function useOwnerTypes(selectedValue: string[] | string | undefined | null) {
  return [
    {
      text: i18n.t('clearing_charges.filter.reseller'),
      value: 'reseller',
      selected: isSelected(selectedValue, 'reseller')
    },
    {
      text: 'EnviaYa',
      value: 'enviaya',
      selected: isSelected(selectedValue, 'enviaya')
    }
  ]
}

function isSelected(selectedValue, currentValue) {
  return (
    !!selectedValue &&
    ((Array.isArray(selectedValue) && selectedValue.map((val) => val).includes(currentValue)) ||
      (!Array.isArray(selectedValue) && selectedValue === currentValue))
  )
}
