import { Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer'
import { CarrierAccount } from './carrier-account'
import { CarrierTS } from './CarrierTS'
import { DiscountProfile } from './discount-profile'
import { AbstractModel } from '@/models/abstract-model'
import { BOOKING_TYPES } from '@/constants/service'
import { WhiteLabelTemplate } from './white-label-template'

export enum SERVICE_STATUSES {
  ACTIVE = 1,
  DISABLED = 2,
  DELETED = 3
}

@Exclude()
export class Service extends AbstractModel {
  static get modelName () {
    return 'Service'
  }

  @Expose({ name: 'name' })
  public name: string = ''

  @Expose({ name: 'formatted_service_code', toClassOnly: true })
  public formattedServiceCode: string = ''

  @Expose({ name: 'status' })
  @Transform(value => value || undefined)
  public status: number | null | undefined

  @Expose({ name: 'origin_country_id' })
  @Transform(value => value ?? null)
  public originCountryId: number | null = null

  @Expose({ name: 'for_all_countries', toClassOnly: true })
  @Transform((_value, obj) => !obj.originCountryId)
  public forAllCountries: boolean = true

  @Expose({ name: 'service_type' })
  @Transform(value => value || undefined)
  public serviceType: number | null | undefined

  @Expose({ name: 'booking_type' })
  @Transform(value => value || BOOKING_TYPES.BOOKING_TYPE_LABEL)
  public bookingType: number | null | undefined

  @Expose({ name: 'reseller_id' })
  @Transform(value => value || undefined)
  public resellerId: number | null | undefined

  @Expose({ name: 'carrier_id' })
  @Transform(value => value || undefined)
  public carrierId: number | null | undefined

  @Expose({ name: 'carrier' })
  @Type(() => CarrierTS)
  @Transform(value => value || undefined)
  public carrier: CarrierTS | undefined

  @Expose({ name: 'carrier_service_id' })
  @Transform(value => value || undefined)
  public carrierServiceId: number | null | undefined

  @Expose({ name: 'carrier_account_id' })
  @Transform(value => value || undefined)
  public carrierAccountId: number | null | undefined

  @Expose({ name: 'carrier_account' })
  @Type(() => CarrierAccount)
  @Transform(value => value || undefined)
  public carrierAccount: CarrierAccount | undefined

  @Expose({ name: 'discount_profile_id' })
  public discountProfileId: number | undefined | null = null

  @Expose({ name: 'discount_profile', toClassOnly: true })
  @Type(() => DiscountProfile)
  public discountProfile: DiscountProfile | undefined | null = null

  @Expose({ name: 'allow_service_deletion' })
  @Transform((value) => (value) ? value : false)
  public allowServiceDeletion: boolean = false

  @Expose({ name: 'discount' })
  @Transform(value => value || undefined)
  public discount: number | undefined

  @Expose({ name: 'discount_as_percent', toClassOnly: true })
  @Transform(value => value || undefined)
  public discountAsPercent: number | undefined

  @Expose({ name: 'margin' })
  @Transform(value => value || undefined)
  public margin: number | undefined

  @Expose({ name: 'margin_as_percent', toClassOnly: true })
  @Transform(value => value || undefined)
  public marginAsPercent: number | undefined

  @Expose({ name: 'fixed_net_shipping_amount' })
  @Transform(value => value || undefined)
  public fixedNetShippingAmount: number | undefined

  @Expose({ name: 'margin_or_discount' })
  public marginOrDiscount: string = ''

  @Expose({ name: 'fixed_currency' })
  public fixedCurrency: string = ''

  @Expose({ name: 'rate_type' })
  public rateType: string | undefined | null = ''

  @Expose({ name: 'fixed_additional_unit_net_amount' })
  @Transform(value => value || undefined)
  public fixedAdditionalUnitNetAmount: number | undefined

  @Expose({ name: 'max_weight' })
  @Transform(value => value || undefined)
  public maxWeight: number | undefined

  @Expose({ name: 'min_weight' })
  @Transform(value => value || undefined)
  public minWeight: number | undefined

  @Expose({ name: 'fixed_base_weight' })
  @Transform(value => value || undefined)
  public fixedBaseWeight: number | undefined

  @Expose({ name: 'fixed_additional_unit_weight_increment' })
  @Transform(value => value || undefined)
  public fixedAdditionalUnitWeightIncrement: number | undefined

  @Expose({ name: 'weight_unit' })
  public weightUnit: string | undefined = 'kg'

  @Expose({ name: 'payment_fee_applies' })
  @Transform((value) => (value) ? value : false)
  public paymentFeeApplies: boolean = false

  @Expose({ name: 'white_label_template_id' })
  @Transform(value => value || undefined)
  public whiteLabelTemplateId: number | null | undefined

  @Exclude({ toPlainOnly: true })
  @Expose({ name: 'white_label_template' })
  @Type(() => WhiteLabelTemplate)
  @Transform(value => value || plainToClass(WhiteLabelTemplate, {}))
  public whiteLabelTemplate: WhiteLabelTemplate = new WhiteLabelTemplate()

  @Expose({ name: 'zones' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  @Transform(value => { return (value === []) ? null : value }, { toPlainOnly: true })
  public zones: number[] = []
}
