
import MdbAbstractSelectBox from '@/components/select-boxes/MdbAbstractSelectBox.vue'
import { ISelectBoxModel } from '@/models/select-box-model'
import { Component } from 'vue-property-decorator'
import { ApplicationsApiService } from '@/common/api/applications.api.service'
import { Application } from '@/models/application'

@Component
export default class ApplicationsSelectBox extends MdbAbstractSelectBox {
  public async fetchData (): Promise<ISelectBoxModel[]> {
    const options: ISelectBoxModel[] = []
    const types: Application[] = await ApplicationsApiService.search()
    types.forEach((type) => { this.options.push({ text: type.name, value: type.id }) })
    return options
  }
}
