import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { EnviayaAccountTS } from '@/models/EnviayaAccountTS'
import { Reference } from '@/models/reference'
import { Packaging } from '@/models/packaging'

export const FILL_SHIPMENT_FORM_FIELDS = 'fill_shipment_form_fields'
export const FILL_AND_RATE = 'fill_and_rate'
export const FILL_AND_OPEN_PAYMENT_PAGE = 'fill_and_open_payment_page'
export const FILL_AND_PAY = 'fill_and_pay'

export const SHIPPING_ACTIONS = new Map<string, string>([
  [FILL_SHIPMENT_FORM_FIELDS, 'fill_shipment_form_fields'],
  [FILL_AND_RATE, 'fill_and_rate'],
  [FILL_AND_OPEN_PAYMENT_PAGE, 'fill_and_open_payment_page'],
  [FILL_AND_PAY, 'fill_and_pay']
])

@Exclude()
export class ShipmentTemplate extends AbstractModel {
  static get modelName () {
    return 'ShipmentTemplate'
  }

  @Expose({ name: 'name' })
  @Transform(value => value || '', { toClassOnly: true })
  public name: string = ''

  @Expose({ name: 'shipment_action' })
  public shipmentAction: string = ''

  @Expose({ name: 'user_id' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  public userId: number | null = null

  public get user_id () {
    return this.userId
  }

  @Expose({ name: 'user' })
  @Type(() => EnviayaUserTS)
  public user: EnviayaUserTS | undefined

  @Expose({ name: 'enviaya_account' })
  @Type(() => EnviayaAccountTS)
  public account: EnviayaAccountTS | undefined

  @Expose({ name: 'packaging_id' })
  public packagingId: number | undefined

  @Expose({ name: 'packaging' })
  @Exclude({ toPlainOnly: true })
  @Type(() => Packaging)
  public packaging: Packaging | undefined

  @Expose({ name: 'carrier' })
  @Exclude({ toPlainOnly: true })
  public carrier: { name: string } | undefined

  @Expose({ name: 'request_invoice' })
  public requestInvoice: boolean = false

  @Expose({ name: 'request_pickup' })
  public requestPickup: boolean = false

  @Expose({ name: 'insured_amount' })
  @Transform(value => { return (!value) ? undefined : value }, { toClassOnly: true })
  @Transform(value => { return (!value) ? '' : value }, { toPlainOnly: true })
  public insuredAmount: number | undefined

  @Expose({ name: 'insured_amount_currency' })
  public insuredAmountCurrency: string | undefined

  @Expose({ name: 'cash_on_delivery' })
  @Transform(value => { return (!value) ? undefined : value }, { toClassOnly: true })
  @Transform(value => { return (!value) ? '' : value }, { toPlainOnly: true })
  public cashOnDelivery: number | undefined

  @Expose({ name: 'cash_on_delivery_currency' })
  public cashOnDeliveryCurrency: string | undefined

  @Expose({ name: 'account_id' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  public accountId: number | null = null

  @Expose({ name: 'corporate_account_id' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  public corporateAccountId: number | null = null

  public get corporate_account_id () {
    return this.corporateAccountId
  }

  @Expose({ name: 'corporate_account' })
  public corporateAccount: Object | undefined

  @Expose({ name: 'own_carrier_account_id' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  public ownCarrierAccountId: number | null = null

  @Expose({ name: 'carrier_id' })
  public carrierId: number | null = null

  @Expose({ name: 'service_code' })
  public serviceCode: string | undefined

  @Expose({ name: 'fallback_1_carrier_account_id' })
  @Transform(value => { return (!value) ? '' : value }, { toPlainOnly: true })
  public fallbackCarrierAccountId1: number | null = null

  @Expose({ name: 'fallback_1_carrier_id' })
  @Transform(value => { return (!value) ? '' : value }, { toPlainOnly: true })
  public fallbackCarrierId1: number | null = null

  @Expose({ name: 'fallback_1_service_code' })
  @Transform(value => { return (!value) ? '' : value }, { toPlainOnly: true })
  public fallbackServiceCode1: string | undefined

  @Expose({ name: 'fallback_2_carrier_account_id' })
  @Transform(value => { return (!value) ? '' : value }, { toPlainOnly: true })
  public fallbackCarrierAccountId2: number | null = null

  @Expose({ name: 'fallback_2_carrier_id' })
  @Transform(value => { return (!value) ? '' : value }, { toPlainOnly: true })
  public fallbackCarrierId2: number | null = null

  @Expose({ name: 'fallback_2_service_code' })
  @Transform(value => { return (!value) ? '' : value }, { toPlainOnly: true })
  public fallbackServiceCode2: string | undefined

  @Expose({ name: 'default_references', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => Reference)
  public defaultReferences: Reference[] = []

  @Expose({ name: 'default_references_attributes', toPlainOnly: true })
  public defaultReferencesAttributes: any

  public addReferences (reference: Reference) {
    this.defaultReferences.push(reference)
    let defaultReferencesAttributes: Object = {}
    if (this.defaultReferences.length) {
      this.defaultReferences.forEach((reference) => {
        Object.assign(defaultReferencesAttributes, { [(reference.id) ? reference.id.toString() : Math.floor(Math.random() * 10000)]: reference })
      })
    }
    this.defaultReferencesAttributes = defaultReferencesAttributes
  }

  public get references (): Reference[] {
    let defaultReferencesAttributes: Object = {}
    if (this.defaultReferences.length) {
      this.defaultReferences.forEach((reference) => {
        Object.assign(defaultReferencesAttributes, { [(reference.id) ? reference.id.toString() : Math.floor(Math.random() * 10000)]: reference })
      })
    }
    this.defaultReferencesAttributes = defaultReferencesAttributes
    return this.defaultReferences.filter((reference) => !reference.destroy)
  }
}
