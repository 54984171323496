import { Expose, Exclude, Transform } from 'class-transformer'

@Exclude()
export class CustomerInfo {
  static get modelName () {
    return 'CustomerInfo'
  }

  @Expose({ name: 'id' })
  public id: number | undefined

  @Expose({ name: 'shipments_per_month' })
  public shipmentsPerMonth: string = ''

  @Expose({ name: 'est_monthly_sales_volume' })
  public estMonthlySalesVolume: number | null = null

  @Expose({ name: 'est_monthly_sales_volume_currency' })
  @Transform((value, obj) => {
    return value || obj.currency
  }, { toClassOnly: true })
  public estMonthlySalesVolumeCurrency: string = ''

  @Expose({ name: 'avg_shipment_weight' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public avgShipmentWeight: string = ''

  @Expose({ name: 'insured_amount' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public insuredAmount: string = ''

  @Expose({ name: 'insured_amount_currency' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public insuredAmountCurrency: string = ''

  @Expose({ name: 'shipment_types' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public shipmentTypes: string[] = []

  @Expose({ name: 'destination_types' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public destinationTypes: string[] = []

  @Expose({ name: 'origin_countries' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public originCountries: string[] = []

  @Expose({ name: 'social_media_profiles' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public socialMediaProfiles: string[] = []

  @Expose({ name: 'comments' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public comments: string[] = []

  @Expose({ name: 'destination_countries' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public destinationCountries: string[] = []

  @Expose({ name: 'origin_cities' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public originCities: string[] = []

  @Expose({ name: 'destination_cities' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public destinationCities: string[] = []

  @Expose({ name: 'carriers' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public carriers: string[] = []

  @Expose({ name: 'products_of_interest' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public productsOfInterest: string[] = []

  @Expose({ name: 'functions_of_interest' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public functionsOfInterest: string[] = []

  @Expose({ name: 'competition' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public competition: string[] = []

  @Expose({ name: 'industry' })
  public industry: string = ''

  @Expose({ name: 'source' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  public source: string = ''

  @Expose({ name: 'lead_status' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public leadStatus: string = ''

  @Expose({ name: 'category' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public category: string = ''

  @Expose({ name: 'client_type' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public clientType: string = ''

  @Expose({ name: 'weight_unit' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public weightUnit: string = ''

  @Expose({ name: 'level' })
  public level: number | undefined | null = 1

  @Expose({ name: 'infoable' })
  public infoable: { id: number | null, white_label_id: number | null, corporate_account_id: number | null } = { id: null, white_label_id: null, corporate_account_id: null }
}
