import i18n from '@/i18n'

export function usePaymentTypes(selectedValue: string[] | string | undefined | null) {
  return [
    {
      text: i18n.t('properties.form.order.type.prepaid_national') as string,
      value: '1',
      selected: isSelected(selectedValue, '1')
    },
    {
      text: i18n.t('properties.form.order.type.credit') as string,
      value: '3',
      selected: isSelected(selectedValue, '3')
    },
    {
      text: `${i18n.t('properties.form.order.corporate_account')} ${i18n.t('properties.form.order.type.credit')}`,
      value: '3_corporate',
      selected: isSelected(selectedValue, '3_corporate')
    },
    {
      text: i18n.t('properties.form.order.type.prepaid_international') as string,
      value: '4',
      selected: isSelected(selectedValue, '4')
    },
    {
      text: i18n.t('properties.form.order.type.direct') as string,
      value: '2, 5, 6',
      selected: isSelected(selectedValue, '2, 5, 6')
    },
    {
      text: `${i18n.t('properties.form.order.corporate_account')} ${i18n.t('properties.form.order.type.prepaid_national')}`,
      value: '1_corporate',
      selected: isSelected(selectedValue, '1_corporate')
    },
    {
      text: `${i18n.t('properties.form.order.corporate_account')} ${i18n.t('properties.form.order.type.prepaid_international')}`,
      value: '4_corporate',
      selected: isSelected(selectedValue, '4_corporate')
    }
  ]
}

function isSelected(selectedValue, currentValue) {
  return (
    !!selectedValue &&
    ((Array.isArray(selectedValue) && selectedValue.map((val) => val).includes(currentValue)) ||
      (!Array.isArray(selectedValue) && selectedValue === currentValue))
  )
}
