import { Exclude, Expose, Transform } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import { IDateRange } from '@/mixins/rangePicker'
import moment from 'moment'

@Exclude()
export class Pickup extends AbstractModel {
  static get modelName () {
    return 'Pickup'
  }
  @Exclude()
  public id: number | null = null

  public dateRange: IDateRange = {
    startDate: undefined,
    endDate: undefined
  }

  @Expose({ name: 'date' })
  @Transform(value => {
    const dateTime = moment(value)
    if (dateTime.isValid()) return dateTime
    else return moment()
  }, { toClassOnly: true })

  @Transform(value => {
    return moment(value).format('YYYY-MM-DD')
  }, { toPlainOnly: true })
  public startDate: any = null

  @Expose({ name: 'schedule_from' })
  @Transform((value) => {
    return value ? moment(value, 'YYYY-MM-DD HH:mm z').format('HH:mm') : ''
  }, { toClassOnly: true })
  public scheduleFrom: string = ''

  @Expose({ name: 'schedule_to' })
  @Transform((value) => {
    return value ? moment(value, 'YYYY-MM-DD HH:mm z').format('HH:mm') : ''
  }, { toClassOnly: true })
  public scheduleTo: string = ''

  @Expose({ name: 'prevent_carrier_validation' })
  @Transform((value) => (value) ? value : true)
  public preventCarrierValidation: boolean = true
}
