import { AbstractModel } from '@/models/abstract-model'
import { Exclude, Expose, Transform } from 'class-transformer'
import moment from 'moment'

export enum INVOICE_STATUSES {
  PENDING = 1,
  PAID = 2,
  CANCELLED = 3,
  EXCEPTION = 4,
  UNKNOWN = 5,
  UNPAID = 6,
  PARTIALLY_PAID = 7,
  MERGED = 8,
  PENDING_AND_PAID = 9,
  ERROR_DURING_CREATION = 10,
  APPLIED = 11,
  NOT_APPLIED = 12,
  PENDING_RECEIPT_FOR_APPROVAL = 13,
  CANCELLATION_IN_PROGRESS = 14,
  DELETED = 99
}

@Exclude()
export class Invoice extends AbstractModel {
  static get modelName () {
    return 'Invoice'
  }

  public carrierId: number | null = null

  public carrierName: string | null = null

  public providerName: string | null = null

  @Expose({ name: 'carrier_account_id' })
  public carrierAccountId: number | undefined

  @Expose({ name: 'invoice_type' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  public invoiceType: number | undefined | null = null

  @Expose({ name: 'invoice' })
  public invoice: string | null = null

  @Expose({ name: 'currency' })
  public currency: string | null = null

  @Expose({ name: 'invoice_import_path' })
  public invoiceShipmentFile: string | null = null

  @Expose({ name: 'path' })
  public invoiceFile: string | null = null

  @Expose({ name: 'total_amount' })
  public totalAmount: number | null = null

  @Expose({ name: 'net_amount' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public netAmount: string = ''

  @Expose({ name: 'vat_amount' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public vatAmount: string = ''

  @Expose({ name: 'date' })
  @Transform(value => {
    const dateTime = moment(value)
    if (dateTime.isValid()) return dateTime
    else return moment()
  }, { toClassOnly: true })

  @Transform(value => {
    return moment(value).format('YYYY-MM-DD')
  }, { toPlainOnly: true })
  public date: any = null

  @Expose({ name: 'due_date' })
  @Transform(value => {
    const dateTime = moment(value)
    if (dateTime.isValid()) return dateTime
    else return moment()
  }, { toClassOnly: true })

  @Transform(value => {
    return moment(value).format('YYYY-MM-DD')
  }, { toPlainOnly: true })
  public dueDate: any = null

  @Expose({ name: 'status' })
  public status: number | null = null

  @Expose({ name: 'provider' })
  public layout: string | null = null

  @Expose({ name: 'related_invoice_ids' })
  @Transform(value => value || [], { toClassOnly: true })
  public relatedInvoiceIds: string[] = []

  // For abilities
  public get invoice_type () {
    return this.invoiceType
  }
}
