import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { DiscountProfile } from '@/models/discount-profile'
import { EnviayaUserTS } from './EnviayaUserTS'
import { CorporateAccountTs } from './corporate-account-ts'
import { Shipment } from './shipment'
import { AbstractModel } from './abstract-model'
import { IsNotEmpty, MaxLength } from 'class-validator'
// @ts-ignore
import localeData from '@/locales/referrers/referrer.json'
import i18n from '@/i18n'

// TODO Rename this model after JS version of this model

export interface ReferrerKeysData {
  referrers?: ReferrerTS[],
  invitedUsers: (EnviayaUserTS | CorporateAccountTs)[]
  shipments: Shipment[]
}

const MAXIMUM_REFERER_KEY_LENGTH = 50

@Exclude()
export class ReferrerTS extends AbstractModel {
  static get modelName () {
    return 'Referrer'
  }

  constructor () {
    super()

    i18n.mergeLocaleMessage('en', localeData.en)
    i18n.mergeLocaleMessage('de', localeData.de)
    i18n.mergeLocaleMessage('es', localeData.es)
  }


  @Expose({ name: 'name' })
  public name: string | undefined

  @Expose({ name: 'email' })
  public email: string | undefined

  @Expose({ name: 'referrable_type' })
  public referrableType: string | undefined

  @Expose({ name: 'referrable_id' })
  public referrableId: number | undefined

  @Expose({ name: 'referrer_key' })
  @IsNotEmpty({ message: () => i18n.t('referrer.invalid_msg.blank_referrer_key').toString() })
  @MaxLength(MAXIMUM_REFERER_KEY_LENGTH, {
    message: () => i18n.t('referrer.invalid_msg.referrer_key_too_long', { count: MAXIMUM_REFERER_KEY_LENGTH }).toString()
  })
  public referrerKey: string | undefined

  @Expose({ name: 'disabled' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public disabled: boolean | undefined

  @Expose({ name: 'invitations_count' })
  public invitationsCount: number| undefined

  @Expose({ name: 'shipments_count' })
  public shipmentsCount: number| undefined

  @Expose({ name: 'default_discount_profile_id' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public defaultDiscountProfileId: string | number | undefined

  @Expose({ name: 'user_discount_profile_id' })
  @Type(() => DiscountProfile)
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public userDiscountProfileId: string | number | undefined

  @Expose({ name: 'default_discount_profile' })
  @Type(() => DiscountProfile)
  @Transform(value => { return (value === null || value === undefined) ? new DiscountProfile() : value }, { toClassOnly: true })
  public defaultDiscountProfile: DiscountProfile | undefined

  @Expose({ name: 'user_discount_profile' })
  @Type(() => DiscountProfile)
  @Transform(value => { return (value === null || value === undefined) ? new DiscountProfile() : value }, { toClassOnly: true })
  public userDiscountProfile: DiscountProfile | undefined
}
