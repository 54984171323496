import { Exclude, Expose, Transform } from 'class-transformer'

@Exclude()
export class CarrierService {
  static get modelName () {
    return 'CarrierService'
  }

  @Expose({ name: 'id' })
  public id: number | undefined

  @Expose({ name: 'service_name' })
  @Transform(value => { return (!value) ? '' : value })
  public serviceName: string = ''

  @Expose({ name: 'service_type' })
  @Transform(value => { return (!value) ? '' : value })
  public serviceType: number = 1

  @Expose({ name: 'service_type_to_string' })
  @Transform(value => { return (!value) ? '' : value })
  public serviceTypeToString: string = ''

  @Expose({ name: 'service_code' })
  @Transform(value => { return (!value) ? '' : value })
  public serviceCode: string = ''

  @Expose({ name: 'full_service_select_box_text' })
  @Transform(value => { return (!value) ? '' : value })
  public fullServiceSelectBoxText: string = ''

  @Expose({ name: 'enable' })
  @Transform(value => { return (!value) ? '' : value })
  public enable: boolean = false

  @Expose({ name: 'disabled_level' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public disabledLevel: string = ''

  @Expose({ name: 'carrier_weight_increment', toClassOnly: true })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public carrierWeightIncrement: number = 0
}
