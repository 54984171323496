import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import { BALANCE_SALDO_TYPE_RESELLER, EnviayaAccountReference, EnviayaAccountTS } from '@/models/EnviayaAccountTS'
import { CorporateAccountTs } from '@/models/corporate-account-ts'
import { IDateRange } from '@/mixins/rangePicker'
import moment from 'moment'
import { Parcel } from '@/models/parcel'
import { Pickup } from '@/models/pickup'
import { InvoiceDirection } from '@/models/invoice-direction'
import { CarrierAccount } from '@/models/carrier-account'
import { WhiteLabel } from './white-label'
import { Direction } from './direction'
import { ShipmentEvent } from './ShipmentEvent'
import { AccessPoint } from '@/models/access-point'
import { Invoice } from './invoice'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { CarrierTS } from '@/models/CarrierTS'
import parsePhoneNumber from 'libphonenumber-js'
import { ShipmentOrder } from '@/models/shipment-order'
import { Surcharge } from '@/models/surcharge'
import { CarrierService } from '@/models/CarrierService'

export enum SHIPMENT_ACTION_TYPES {
  MANUAL_FILL = 1,
  USING_TEMPLATE = 2,
  INSTANT_SHIPMENT = 3,
  USING_SERVICE = 4,
  MANUAL_RATE = 5,
  MANUAL_NEW = 6
}

export enum SHIPMENT_TYPE {
  DOCUMENT = 1,
  PACKAGE = 2,
  PACKAGE_WITH_REF = 3
}

export enum CARRIER_ACCOUNTING_STATUSES {
  NOT_TO_BE_INVOICED = 0,
  TO_BE_INVOICED = 1,
  INVOICED = 2,
  CREDIT_NOT_APPLIED = 3,
  INVOICED_PREPAID = 4
}

export enum ACCOUNTING_STATUSES {
  STANDARD = 0,
  PAID = 1,
  UNPAID = 2,
  CANCELLED = 3,
  REFUNDED = 4,
  CHARGEBACK = 5,
  DELETED = 6,
  PARTIALLY_PAID = 7,
  GOODWILL = 8,
  UNDER_REV_CHARGED = 9,
  UNDER_REV_CHARGE_ON_HOLD = 10,
  CANCEL_REQUESTED = 11,
  CANCELLATION_DENIED = 12,
  UNCOLLECTIBLE = 13,
  INTERNAL_SHIPMENT = 14,
  UNDER_REV_TO_BE_CHARGED = 15,
  NA_EXTERNAL = 16,
  NA_RESELLER = 17,
  NA_NONE_RESELLER = 18,
  EXCLUDED_FROM_CHARGE = 19
}

export enum SHIPMENT_CHANNEL {
  PUBLIC = 'Public',
  USER = 'User',
  API = 'Api'
}

export enum SHIPMENT_STATUSES {
  IN_CREATION = 1,
  PENDING_CUSTOMS_INFORMATION = 2,
  WAITING_CARRIER_CONFIRMATION = 3,
  PENDING_PICKUP = 13,
  IN_TRANSIT = 14,
  HOLD_AT_LOCATION = 15,
  BOOKING_IN_PROCESS = 16,
  IN_CUSTOMS_CLEARANCE = 17,
  EXCEPTION = 40,
  DELIVERED = 50,
  STORE_PICKUP = 51,
  CANCELLED = 90,
  BOOKING_ERROR = 98
}

export enum SHIPMENT_TAB_NAME {
  ALL = 0,
  GENERATED = 1,
  IN_TRANSIT = 2,
  DELIVERED = 3,
  PENDING_CUSTOM_INFO = 4,
  BOOKING_IN_PROGRESS = 5,
  CUSTOMS_CLEARANCE = 6,
  INCIDENCE = 7,
  STORE_PICKUP = 8,
  CANCELLED = 9
}

export const TAB_NAMES = [
  'generated',
  'inTransit',
  'delivered',
  'pendingCustomInfo',
  'bookingInProgress',
  'customsClearance',
  'incidence',
  'storePickup',
  'cancelled'
]

export enum SHIPMENT_REVISION_STATUSES {
  NO_NEEDED = 1,
  NEEDED = 2,
  IN_PROCESS = 3,
  REVIEWED_POSITIVE = 4,
  REVIEWED_NEGATIVE = 5
}

export enum SHIPMENT_REVISION_TYPES {
  COST_OF_GODS = 1,
  NO_OF_PACKAGES = 2,
  UNSENT_AND_CHARGED = 3,
  NOT_BOOKED_WITH_PALTFORM = 4,
  UNCOMPLIED_DEL_GUARANTEE = 5,
  WITHOUT_COVERAGE = 6,
  WRONG_LIST_PRICE = 7,
  INCORRECT_DISCOUNT = 8,
  CUSTOM_TAXES_WRONG = 9,
  CUSTOM_TAXES_TO_BE_APPLIED = 10,
  LOST_SHIPMENT =11,
  STOLEN_SHIPMENT = 12,
  DAMAGED_OR_INCOMPLETE = 13,
  INCORRECT_OVER_WEIGHT_BY_CARRIER = 14,
  ADDRESS_CORRECTION_REQUESTED = 15,
  SHIPMENT_RETURNED = 16,
  WRONG_ADDITIONAL_CHARGES = 17,
  PRICE_MISSCALCULATED = 18,
  CLEARING_CHARGE_NOT_CREATED = 19,
  DUPLICATED_CTN = 20,
  SHIPMENT_CHARGED_TWICE = 21,
  NOT_EXPORTED = 22,
  OTHER = 99
}

export enum SHIPMENT_CHANNEL_TYPES {
  API = 'Api',
  USER = 'User',
  PUBLIC = 'Public',
  CARRIER_IMPORT = 'CarrierImport',
  EXTERNAL = 'External',
  SHOPIFY = 'Shopify',
  EXTERNAL_FULFILLMENT = 'ExternalFulfillment'
}

export const SHIPMENT_CHANNELS = [
  SHIPMENT_CHANNEL_TYPES.API,
  SHIPMENT_CHANNEL_TYPES.USER,
  SHIPMENT_CHANNEL_TYPES.PUBLIC,
  SHIPMENT_CHANNEL_TYPES.CARRIER_IMPORT,
  SHIPMENT_CHANNEL_TYPES.EXTERNAL,
  SHIPMENT_CHANNEL_TYPES.SHOPIFY,
  SHIPMENT_CHANNEL_TYPES.EXTERNAL_FULFILLMENT
]

export const CHANNELS_FOR_CREATE_SHIPMENT: string[] = [
  SHIPMENT_CHANNEL_TYPES.API,
  SHIPMENT_CHANNEL_TYPES.USER,
  SHIPMENT_CHANNEL_TYPES.PUBLIC
]

export enum SHIPMENT_BULK_ACTIONS {
  DOWNLOAD = 'download',
  GENERATE_ORDER = 'generate_order',
  GENERATE_CONSOLID_ORDER = 'generate_consolid_order',
  RECALCULATE_CONTROL_AMOUNTS = 'recalculate_control_amounts',
  RESEND_SHIPMENT_CONFIRMATION = 'resend_shipment_confirmation',
  CREATE_SHIPMENT_LABEL = 'create_shipment_label',
  DOWNLOAD_SHIPMENT_LABEL = 'download_shipment_label',
  BULK_UPDATE = 'bulk_update',
  TRACK_SHIPMENT = 'track_shipment',
  DELETE_SHIPMENT = 'delete_shipment',
  CANCEL_SHIPMENT = 'cancel_shipment',
  REQUEST_CANCELLATION = 'request_cancellation'
}

export enum CSV_TYPES {
  ACCOUNTING = 1,
  CUSTOMER = 2,
  FULL = 3,
  SUPER_ADMIN = 4,
  RESELLER_ADMIN = 5,
  SURCHARGES_USER = 6,
  SURCHARGES_ADMIN = 7,
  SURCHARGES_SUPER_ADMIN = 8,
  RESELLER_ADMIN_FOR_INVOICE = 9,
  CUSTOMER_FOR_INVOICE = 10,
  CSV_TYPE_ZOHO = 11
}

export const SHIPMENT_SCOPES = [
  'unsent_charged_shipments_carrier',
  'unsent_charged_shipments_customer',
  'wrong_billing_amount',
  'non_existing_shipments',
  'chargebacks',
  'unpaid_shipments',
  'paid_shipments',
  'good_will',
  'sold',
  'payment_pending',
  'with_additional_surcharges',
  'uncharged_sent_shipments',
  'wrong_saturday_pickup_charge',
  'missing_drop_off_discount',
  'with_premium_pickup_service',
  'sent_shipments',
  'contracted_shipments',
  'national',
  'international',
  'pakmail_shipments',
  'multiple_carrier_invoices',
  'google_adwords_shipments',
  'facebook_shipments',
  'new_google_adwords_shipments',
  'new_facebook_shipments',
  'with_unpaid_credit_invoices',
  'in_revision',
  'booking_error',
  'incidence',
  'with_loss',
  'with_smaller_gross_profit',
  'unpaid_delivered_orders',
  'partialy_paid_delivered_orders',
  'sent_shipments_with_unpaid_orders',
  'sent_shipments_with_partialy_paid_orders',
  'without_standard_cost_of_goods',
  'with_negative_standard_gross_profit',
  'credit_shipment_pending_to_be_invoiced',
  'vendor_shipments_to_be_invoiced',
  'transaction_fee_pending_to_be_invoiced',
  'without_price'
]

export const USER_SCOPES = [
  'new_customers',
  'existing_customers',
  'lost_customers',
  'recently_lost_customers',
  'reactivated_customers',
  'non_recurring_customers',
  'recurring_customers',
  'recurring_small_customers',
  'recurring_medium_customers',
  'recurring_big_customers',
  'recently_lost_small_customers',
  'recently_lost_medium_customers',
  'recently_lost_big_customers',
  'reactivated_small_customers',
  'reactivated_medium_customers',
  'reactivated_big_customers',
  'google_adwords_users',
  'facebook_users'
]

export const ACCOUNT_SCOPES = [
  'with_credit_line',
  'with_negative_national_prepaid_balance',
  'with_negative_international_prepaid_balance'
]

export const CARRIER_ACCOUNT_TYPE = {
  EY: 1,
  OWN: 2,
  VENDOR: 3
}

export const statusFilteredOptions = (activeTab: number, forSelect: boolean = false) => {
  if (forSelect) return (_status: number) => true
  switch (activeTab) {
    case SHIPMENT_TAB_NAME.ALL: // all
      return (_status: number) => true
    case SHIPMENT_TAB_NAME.GENERATED: // generated
      return (status: number) => {
        return status === SHIPMENT_STATUSES.PENDING_PICKUP
      }
    case SHIPMENT_TAB_NAME.IN_TRANSIT: // inTransit
      return (status: number) => {
        return [SHIPMENT_STATUSES.IN_TRANSIT, SHIPMENT_STATUSES.HOLD_AT_LOCATION].includes(status)
      }
    case SHIPMENT_TAB_NAME.DELIVERED: // delivered
      return (status: number) => {
        return status === SHIPMENT_STATUSES.DELIVERED
      }
    case SHIPMENT_TAB_NAME.PENDING_CUSTOM_INFO: // pendingCustomInfo
      return (status: number) => {
        return status === SHIPMENT_STATUSES.PENDING_CUSTOMS_INFORMATION
      }
    case SHIPMENT_TAB_NAME.BOOKING_IN_PROGRESS: // bookingInProgress
      return (status: number) => {
        return [SHIPMENT_STATUSES.WAITING_CARRIER_CONFIRMATION, SHIPMENT_STATUSES.BOOKING_IN_PROCESS].includes(status)
      }
    case SHIPMENT_TAB_NAME.CUSTOMS_CLEARANCE: // customsClearance
      return (status: number) => {
        return status === SHIPMENT_STATUSES.IN_CUSTOMS_CLEARANCE
      }
    case SHIPMENT_TAB_NAME.INCIDENCE: // incidence
      return (status: number) => {
        return status === SHIPMENT_STATUSES.EXCEPTION
      }
    case SHIPMENT_TAB_NAME.STORE_PICKUP: // storePickup
      return (status: number) => {
        return status === SHIPMENT_STATUSES.STORE_PICKUP
      }
    case SHIPMENT_TAB_NAME.CANCELLED: // cancelled
      return (status: number) => {
        return [SHIPMENT_STATUSES.CANCELLED, SHIPMENT_STATUSES.BOOKING_ERROR].includes(status)
      }
    default:
      return (_status: number) => true
  }
}

export const statusFilters = (tabName?: string | undefined | null) => {
  switch (tabName) {
    case TAB_NAMES[0]: // generated
      return (status: number) => {
        return status === SHIPMENT_STATUSES.PENDING_PICKUP
      }
    case TAB_NAMES[1]: // inTransit
      return (status: number) => {
        return [SHIPMENT_STATUSES.IN_TRANSIT, SHIPMENT_STATUSES.HOLD_AT_LOCATION].includes(status)
      }
    case TAB_NAMES[2]: // delivered
      return (status: number) => {
        return status === SHIPMENT_STATUSES.DELIVERED
      }
    case TAB_NAMES[3]: // pendingCustomInfo
      return (status: number) => {
        return status === SHIPMENT_STATUSES.PENDING_CUSTOMS_INFORMATION
      }
    case TAB_NAMES[4]: // bookingInProgress
      return (status: number) => {
        return [SHIPMENT_STATUSES.WAITING_CARRIER_CONFIRMATION, SHIPMENT_STATUSES.BOOKING_IN_PROCESS].includes(status)
      }
    case TAB_NAMES[5]: // customsClearance
      return (status: number) => {
        return status === SHIPMENT_STATUSES.IN_CUSTOMS_CLEARANCE
      }
    case TAB_NAMES[6]: // incidence
      return (status: number) => {
        return status === SHIPMENT_STATUSES.EXCEPTION
      }
    case TAB_NAMES[7]: // storePickup
      return (status: number) => {
        return status === SHIPMENT_STATUSES.STORE_PICKUP
      }
    case TAB_NAMES[8]: // cancelled
      return (status: number) => {
        return [SHIPMENT_STATUSES.CANCELLED, SHIPMENT_STATUSES.BOOKING_ERROR].includes(status)
      }
    default:
      return (_status: number) => true
  }
}

export const PM_PACKAGING_CATEGORY = 3
export const PM_PRODUCT_CATEGORY = 4

export const CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE: { value: string, text: string } = { value: 'XBX', text: 'Caja' }
export const CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE: { value: string, text: string } = { value: '31181701', text: 'Empaques' }
export const CATALOGUES_PRODUCT_CATEGORY_DEFAULT_MAX_WEIGHT_VALUE: { value: string, text: string } = { value: '01010101', text: 'No existe en el catálogo' }

@Exclude()
export class AccountingAmounts {
  @Expose({ name: 'currency', toClassOnly: true })
  public currency: string = ''

  @Expose({ name: 'net', toClassOnly: true })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public net: number = 0

  @Expose({ name: 'net_explanation', toClassOnly: true })
  public netExplanation: string = ''

  @Expose({ name: 'net_margin', toClassOnly: true })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public netMargin: number = 0

  @Expose({ name: 'total', toClassOnly: true })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public total: number = 0

  @Expose({ name: 'total_explanation', toClassOnly: true })
  public totalExplanation: string = ''

  @Expose({ name: 'total_margin', toClassOnly: true })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public totalMargin: number = 0

  @Expose({ name: 'vat', toClassOnly: true })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public vat: number = 0

  @Expose({ name: 'vat_explanation', toClassOnly: true })
  public vatExplanation: string = ''
}

@Exclude()
export class Amounts {
  @Expose({ name: 'carrier' })
  @Type(() => AccountingAmounts)
  public carrierAmounts: AccountingAmounts = new AccountingAmounts()

  @Expose({ name: 'control' })
  @Type(() => AccountingAmounts)
  public controlAmounts: AccountingAmounts = new AccountingAmounts()

  @Expose({ name: 'expected' })
  @Type(() => AccountingAmounts)
  public expectedAmounts: AccountingAmounts = new AccountingAmounts()
}

@Exclude()
export class Shipment extends AbstractModel {
  static get modelName () {
    return 'Shipment'
  }

  public validated: boolean = false
  public insureShipments: boolean = false
  public cashOnDelivery: boolean = false

  @Expose({ name: 'id' })
  public id: number | null = null

  @Expose({ name: 'currency', toClassOnly: true })
  public currency: string = ''

  @Expose({ name: 'channel' })
  public channel: string = ''

  @Expose({ name: 'actual_step' })
  public actualStep: number | undefined

  @Expose({ name: 'service_name' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public serviceName: string = ''

  @Expose({ name: 'enviaya_id' })
  public enviayaId: string = ''

  @Expose({ name: 'data_layer_product_id', toClassOnly: true })
  public dataLayerProductId: string = ''

  @Expose({ name: 'carrier_name', toClassOnly: true })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public carrierName: string = ''

  @Expose({ name: 'carrier_service' })
  @Type(() => CarrierService)
  public carrierService: CarrierService | undefined

  @Expose({ name: 'physical_weight' })
  @Transform(value => { return (value === null) ? 0.50 : parseFloat(value) }, { toClassOnly: true })
  public physicalWeight: number = 0.50

  @Expose({ name: 'volumetric_weight', toClassOnly: true })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value).toFixed(2) }, { toClassOnly: true })
  public volumetricWeight: number = 0

  @Expose({ name: 'weight_unit' })
  @Transform(value => { return (value === null) ? 'kg' : value }, { toClassOnly: true })
  public weightUnit: string = ''

  @Expose({ name: 'total_amount', toClassOnly: true })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public totalAmount: number = 0

  @Expose({ name: 'enviaya_account' })
  @Type(() => EnviayaAccountTS)
  public enviayaAccount: EnviayaAccountTS | undefined | null = null

  @Expose({ name: 'enviaya_account_id' })
  public enviayaAccountId: number | undefined | null = null

  @Expose({ name: 'origin_full_name' })
  @Transform(value => value || '', { toClassOnly: true })
  public originFullName: string | null = ''

  @Expose({ name: 'destination_full_name' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationFullName: string = ''

  @Expose({ name: 'origin_company' })
  @Transform(value => value || '', { toClassOnly: true })
  public originCompany: string | null = ''

  @Expose({ name: 'destination_company' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationCompany: string = ''

  @Expose({ name: 'origin_phone' })
  @Transform((value, obj) => {
    if (value) {
      if (obj.originPhoneCountry) {
        return parsePhoneNumber(value, obj.originPhoneCountry.toUpperCase()).number
      } else if (obj.originCountryCode) {
        return parsePhoneNumber(value, obj.originCountryCode.toUpperCase()).number
      } else {
        return parsePhoneNumber(value, 'MX')
      }
    } else {
      return ''
    }
  }, { toPlainOnly: true })
  @Transform((value, obj) => {
    let phone = parsePhoneNumber(value || '', (obj.origin_country_code) ? obj.origin_country_code.toUpperCase() : 'MX')
    return (!phone) ? '' : phone.nationalNumber
  }, { toClassOnly: true })
  public originPhone: string | null = ''

  public originPhoneCountry: string = ''

  @Expose({ name: 'destination_phone' })
  @Transform((value, obj) => {
    if (value) {
      if (obj.destinationPhoneCountry) {
        return parsePhoneNumber(value, obj.destinationPhoneCountry.toUpperCase()).number
      } else if (obj.destinationCountryCode) {
        return parsePhoneNumber(value, obj.destinationCountryCode.toUpperCase()).number
      } else {
        return parsePhoneNumber(value, 'MX')
      }
    } else {
      return ''
    }
  }, { toPlainOnly: true })
  @Transform((value, obj) => {
    let phone = parsePhoneNumber(value || '', (obj.destination_country_code) ? obj.destination_country_code.toUpperCase() : 'MX')
    return (!phone) ? '' : phone.nationalNumber
  }, { toClassOnly: true })
  public destinationPhone: string = ''

  public destinationPhoneCountry: string = ''

  @Expose({ name: 'origin_email' })
  @Transform(value => value || '', { toClassOnly: true })
  public originEmail: string | null = ''

  @Expose({ name: 'destination_email' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationEmail: string = ''

  @Expose({ name: 'origin_country_code' })
  @Transform(value => value || '', { toClassOnly: true })
  public originCountryCode: string | null = ''

  @Expose({ name: 'destination_country_code' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationCountryCode: string = ''

  @Expose({ name: 'origin_neighborhood' })
  @Transform(value => value || '', { toClassOnly: true })
  public originNeighborhood: string | null = ''

  @Expose({ name: 'destination_neighborhood' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationNeighborhood: string = ''

  @Expose({ name: 'origin_district' })
  @Transform(value => value || '', { toClassOnly: true })
  public originDistrict: string | null = ''

  @Expose({ name: 'destination_district' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationDistrict: string = ''

  @Expose({ name: 'origin_municipality' })
  @Transform(value => value || '', { toClassOnly: true })
  public originMunicipality: string | null = ''

  @Expose({ name: 'destination_municipality' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationMunicipality: string = ''

  @Expose({ name: 'origin_state_code' })
  @Transform(value => value || '', { toClassOnly: true })
  public originStateCode: string | null = ''

  @Expose({ name: 'destination_state_code' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationStateCode: string = ''

  @Expose({ name: 'origin_state' })
  @Transform(value => value || '', { toClassOnly: true })
  public originState: string | null = ''

  @Expose({ name: 'destination_state' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationState: string = ''

  @Expose({ name: 'origin_city' })
  public originCity: string | null = ''

  @Expose({ name: 'origin_city_id' })
  public originCityId: number | undefined | null

  @Expose({ name: 'destination_city' })
  public destinationCity: string = ''

  @Expose({ name: 'destination_city_id' })
  public destinationCityId: number | undefined | null

  @Expose({ name: 'origin_postal_code' })
  public originPostalCode: string | null = ''

  @Expose({ name: 'destination_postal_code' })
  public destinationPostalCode: string = ''

  @Expose({ name: 'origin_direction_1' })
  @Transform(value => value || '', { toClassOnly: true })
  public originDirection1: string | null = ''

  @Expose({ name: 'origin_direction_2' })
  @Transform(value => value || '', { toClassOnly: true })
  public originDirection2: string | null = ''

  @Expose({ name: 'destination_direction_1' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationDirection1: string = ''

  @Expose({ name: 'destination_direction_2' })
  @Transform(value => value || '', { toClassOnly: true })
  public destinationDirection2: string = ''

  @Expose({ name: 'origin_latitude' })
  public originLatitude: string | null = ''

  @Expose({ name: 'origin_longitude' })
  public originLongitude: string | null = ''

  @Expose({ name: 'origin_coordinates_autofetched' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public originCoordinatesAutofetched: boolean = false

  @Expose({ name: 'destination_latitude' })
  public destinationLatitude: string | null = ''

  @Expose({ name: 'destination_longitude' })
  public destinationLongitude: string | null = ''

  @Expose({ name: 'destination_coordinates_autofetched' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public destinationCoordinatesAutofetched: boolean = false

  @Expose({ name: 'origin_access_point_id' })
  @Transform(value => value || null, { toClassOnly: true })
  public originAccessPointId: number | null = null

  @Expose({ name: 'origin_address_id' })
  @Transform(value => value || null, { toClassOnly: true })
  public originAddressId: number | null = null

  @Expose({ name: 'destination_access_point_id' })
  @Transform(value => value || null, { toClassOnly: true })
  public destinationAccessPointId: number | null = null

  @Expose({ name: 'origin_access_point', toClassOnly: true })
  @Type(() => AccessPoint)
  public originAccessPoint: AccessPoint | null = null

  @Expose({ name: 'destination_access_point', toClassOnly: true })
  @Type(() => AccessPoint)
  public destinationAccessPoint: AccessPoint | null = null

  @Expose({ name: 'origin_direction_id' })
  @Transform(value => value || null, { toClassOnly: true })
  public originDirectionId: number | null = null

  @Expose({ name: 'destination_direction_id' })
  @Transform(value => value || null, { toClassOnly: true })
  public destinationDirectionId: number | null = null

  @Expose({ name: 'destination_address_id' })
  @Transform(value => value || null, { toClassOnly: true })
  public destinationAddressId: number | null = null

  @Expose({ name: 'shipment_type' })
  @Transform(value => value || SHIPMENT_TYPE.PACKAGE, { toClassOnly: true })
  public shipmentType: number | null = SHIPMENT_TYPE.PACKAGE

  public dateRange: IDateRange = {
    startDate: undefined,
    endDate: undefined
  }

  @Expose({ name: 'date' })
  @Transform(value => {
    const dateTime = moment(value)
    if (dateTime.isValid()) return dateTime
    else return moment()
  }, { toClassOnly: true })

  @Transform(value => {
    return moment(value).format('YYYY-MM-DD')
  }, { toPlainOnly: true })
  public date: any = null

  public get dateMoment () {
    return this.date
  }

  @Expose({ name: 'parcels' })
  @Transform(value => value || [], { toClassOnly: true })
  @Type(() => Parcel)
  public parcels: Parcel[] = []

  public get activeParcels () {
    return this.parcels.filter(item => !item.destroy)
  }

  @Expose({ name: 'content' })
  @Transform(value => value || '', { toClassOnly: true })
  public content: string = ''

  @Expose({ name: 'carrier_tracking_number' })
  @Transform(value => value || '', { toClassOnly: true })
  public carrierTrackingNumber: string = ''

  @Expose({ name: 'service_code' })
  public serviceCode: string = ''

  @Expose({ name: 'city_code' })
  public cityCode: string = ''

  @Expose({ name: 'accounting_status' })
  public accountingStatus: number | null = null

  @Expose({ name: 'revision_status' })
  public revisionStatus: number | null = null

  @Expose({ name: 'order_type' })
  public paymentType: number | null = null

  @Expose({ name: 'shipment_notifications_user' })
  public shipmentNotificationsUser: boolean = false

  @Expose({ name: 'status' })
  public status: SHIPMENT_STATUSES = SHIPMENT_STATUSES.IN_CREATION

  @Expose({ name: 'shipment_references' })
  @Transform(value => value || [], { toClassOnly: true })
  @Type(() => EnviayaAccountReference)
  public shipmentReferences: EnviayaAccountReference[] = []

  public get activeReferences () {
    return this.shipmentReferences.filter(item => !item.destroy)
  }

  @Expose({ name: 'insured_amount' })
  @Transform(value => { return (value === null) ? null : parseFloat(value) }, { toClassOnly: true })
  public insuranceAmount: number | null | undefined = null

  @Expose({ name: 'insured_amount_currency' })
  @Transform(value => { return (value === null) ? 'MX' : value }, { toClassOnly: true })
  public insuranceCurrency: string | null = null

  @Expose({ name: 'cash_on_delivery_amount' })
  @Transform(value => { return (value === null) ? null : parseFloat(value) }, { toClassOnly: true })
  public cashOnDeliveryAmount: number | null | undefined = null

  @Expose({ name: 'cash_on_delivery_currency' })
  public cashOnDeliveryCurrency: string | null = null

  public pickedDateRange: IDateRange = {
    startDate: undefined,
    endDate: undefined
  }

  @Expose({ name: 'accept_pickup_conditions' })
  @Transform((value) => (value) ? value : false)
  public acceptPickupConditions: boolean = false

  @Expose({ name: 'picked_up_at' })
  public set pickedDateFrom (value) {
    this.pickedDateRange.endDate = value
    this.pickedDateRange.startDate = (value) ? moment(value, 'YYYY-MM-DD').toDate() : undefined
  }
  public get pickedDateFrom (): string | undefined {
    return (this.pickedDateRange.startDate) ? moment(this.pickedDateRange.startDate).format('YYYY-MM-DD') : undefined
  }

  @Expose({ name: 'picked_up_at' })
  public set pickedDateTo (value) {
    this.pickedDateRange.endDate = (value) ? moment(value, 'YYYY-MM-DD').toDate() : undefined
  }
  public get pickedDateTo (): string | undefined {
    return (this.pickedDateRange.endDate) ? moment(this.pickedDateRange.endDate).format('YYYY-MM-DD') : undefined
  }

  @Expose({ name: 'request_pickup' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public requestPickup: boolean = false

  public defineTimeFrame: boolean = false
  public requestInvoice: boolean = false

  @Expose({ name: 'invoice_direction_id' })
  @Transform(value => value || null, { toClassOnly: true })
  public invoiceDirectionId: number | null = null

  @Expose({ name: 'invoice_direction', toClassOnly: true })
  @Type(() => InvoiceDirection)
  public invoiceDirection: InvoiceDirection | null = null

  @Expose({ name: 'premium_pickup_service' })
  @Transform((value) => (value) ? value : false)
  public premiumPickupService: boolean = false

  @Expose({ name: 'premium_pickup_service_amount' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public premiumPickupServiceAmount: number | undefined

  @Expose({ name: 'premium_pickup_service_amount_currency' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public premiumPickupServiceAmountCurrency: string | undefined

  @Expose({ name: 'pickups' })
  @Transform(value => value || [], { toClassOnly: true })
  @Type(() => Pickup)
  public pickups: Pickup[] = []

  @Expose({ name: 'carrier_accounts', toClassOnly: true })
  @Transform(value => value || [], { toClassOnly: true })
  @Type(() => CarrierAccount)
  public carrierAccounts: CarrierAccount[] = []

  @Expose({ name: 'carrier' })
  @Type(() => CarrierTS)
  public carrier: CarrierTS | null = null

  @Expose({ name: 'quoting_account' })
  @Type(() => EnviayaAccountTS)
  public quotingAccount: EnviayaAccountTS | null = null

  @Expose({ name: 'white_label' })
  @Type(() => WhiteLabel)
  public whiteLabel: WhiteLabel | null = null

  @Expose({ name: 'origin_direction' })
  @Type(() => Direction)
  public originDirection: Direction | null = null

  @Expose({ name: 'destination_direction' })
  @Type(() => Direction)
  public destinationDirection: Direction | null = null

  @Expose({ name: 'corporate_account' })
  @Type(() => CorporateAccountTs)
  public corporateAccount: CorporateAccountTs | undefined

  @Expose({ name: 'carrier_account_ids' })
  @Transform(value => value || [], { toClassOnly: true })
  public carrierAccountsIds: number[] = []

  // @Expose({ name: 'orders' })
  // @Transform(value => value ?? [], { toClassOnly: true })
  // public orders: { id: number, status: number, order_type: number, total_amount: number, currency: string }[] = []

  @Expose({ name: 'invoices' })
  @Transform(value => value ?? [], { toClassOnly: true })
  @Type(() => Invoice)
  public invoices: Invoice[] = []

  @Expose({ name: 'user_id' })
  @Transform(value => { return (!value) ? null : value }, { toPlainOnly: true })
  public userId: number | null = null

  @Expose({ name: 'user', toClassOnly: true })
  @Type(() => EnviayaUserTS)
  public user: EnviayaUserTS | undefined

  @Expose({ name: 'est_delivery_date' })
  @Transform(value => {
    if (!value) return null
    return moment(value, 'YYYY-MM-YY').isValid() ?
      moment(value, 'YYYY-MM-YY') :
      null
  }, { toClassOnly: true })
  public estDeliveryDate: moment.Moment | null = null

  @Expose({ name: 'delivered_at' })
  @Transform(value => {
    if (!value) return null

    return moment(value).isValid() ? moment(value) : null
  }, { toClassOnly: true })
  public deliveredAt: moment.Moment | null = null

  @Expose({ name: 'delivered_checkpoint' })
  @Type(() => ShipmentEvent)
  public deliveredCheckpoint: ShipmentEvent | null = null

  @Expose({ name: 'delivered_in_time' })
  public deliveredInTime: string | null = null

  @Expose({ name: 'actual_tracking_status' })
  public actualTrackingStatus: string | null = null

  @Expose({ name: 'last_checkpoint_date', toClassOnly: true })
  @Transform(value => {
    const dateTime = moment(value)
    if (dateTime.isValid()) return dateTime
    else return moment()
  }, { toClassOnly: true })
  @Transform(value => {
    return moment(value).format('YYYY-MM-DD')
  }, { toPlainOnly: true })
  public lastCheckpointDate: any = null

  @Expose({ name: 'last_tracking_in_days' })
  @Transform((value) => (value) ? value : 0)
  public lastTrackingInDays: number = 0

  @Expose({ name: 'delivery_days' })
  public deliveryDays: number | null = null

  @Expose({ name: 'referrer_id' })
  public referrerId: number | null = null

  @Expose({ name: 'user_email' })
  public userEmail: string | null = null

  @Expose({ name: 'editable', toClassOnly: true })
  public editable: boolean = true

  @Expose({ name: 'weight' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public weight: number | undefined

  @Expose({ name: 'weight_unit_deco' })
  @Transform(value => { return (value === null) ? 'kg' : value }, { toClassOnly: true })
  public weightUnitDeco: string = ''

  @Expose({ name: 'shipment_cutoff_time' })
  public shipmentCutoffTime: string | null = null

  @Expose({ name: 'own_carrier_account', toClassOnly: true })
  @Transform((value) => (value) ? value : false)
  public ownCarrierAccount: boolean = false

  @Expose({ name: 'vendor_carrier_account', toClassOnly: true })
  @Transform((value) => (value) ? value : false)
  public vendorCarrierAccount: boolean = false

  @Expose({ name: 'carrier_account_type', toClassOnly: true })
  @Transform(value => value || 1, { toClassOnly: true })
  public carrierAccountType: number | null = null

  @Expose({ name: 'white_label_id' })
  public whiteLabelId: number | undefined

  @Expose({ name: 'reseller_discount_profile_id' })
  public discountProfileId: number | undefined

  @Expose({ name: 'discount_profile_id' })
  public resellerDiscountProfileId: number | undefined

  @Expose({ name: 'api_application_id' })
  public apiApplicationId: number | undefined

  @Expose({ name: 'new_converted_carrier_tracking_weight' })
  public newConvertedCarrierTrackingWeight: number | undefined

  @Expose({ name: 'new_converted_carrier_invoiced_weight' })
  public newConvertedCarrierInvoicedWeight: number | undefined

  @Expose({ name: 'get_timezone' })
  public getTimezone: string | null = null

  @Expose({ name: 'created_at', toClassOnly: true })
  @Transform(value => {
    const dateTime = moment(value)
    if (dateTime.isValid()) return dateTime
    else return moment()
  }, { toClassOnly: true })
  @Transform(value => {
    return moment(value).format('YYYY-MM-DD')
  }, { toPlainOnly: true })
  public createdAt: any = null


  @Expose({ name: 'allow_download_label', toClassOnly: true })
  public allowDownloadLabel: boolean = true

  @Expose({ name: 'has_label', toClassOnly: true })
  public hasLabel: boolean = true

  @Expose({ name: 'shipment_labels_count', toClassOnly: true })
  public shipmentLabelsCount: number = 0

  @Expose({ name: 'any_negative_saldo', toClassOnly: true })
  public anyNegativeSaldo: boolean = false

  @Expose({ name: 'any_overdue_invoices', toClassOnly: true })
  public anyOverdueInvoices: boolean = false

  @Expose({ name: 'overdue_invoices_ids', toClassOnly: true })
  public overdueInvoicesIds: number[] = []

  @Expose({ name: 'cancelled', toClassOnly: true })
  public cancelled: boolean = false

  @Expose({ name: 'cancellation_requested', toClassOnly: true })
  public cancellationRequested: boolean = false

  @Expose({ name: 'is_possible_to_cancel', toClassOnly: true })
  public isPossibleToCancel: boolean = false

  @Expose({ name: 'non_ey_carrier_account', toClassOnly: true })
  public nonEyCarrierAccount: boolean = false

  @Expose({ name: 'booking_time', toClassOnly: true })
  @Transform(value => {
    const dateTime = moment(value)
    if (dateTime.isValid()) return dateTime
    else return moment()
  }, { toClassOnly: true })
  @Transform(value => {
    return moment(value).format('YYYY-MM-DD')
  }, { toPlainOnly: true })
  public bookingTime: any = null

  @Expose({ name: 'shipment_offer', toClassOnly: true })
  @Transform(value => { return (value === null) ? { localized_service_name: '' } : value })
  public selectedOffer: { localized_service_name: string, carrier_account: { id: number, is_enviaya_account: boolean } } | undefined

  @Expose({ name: 'shipment_booked', toClassOnly: true })
  public shipmentBooked: boolean = false

  public get isPackage () {
    return this.shipmentType === SHIPMENT_TYPE.PACKAGE || this.shipmentType === SHIPMENT_TYPE.PACKAGE_WITH_REF
  }

  @Expose({ name: 'invoiced_weight', toClassOnly: true })
  public invoicedWeight: string = ''

  @Expose({ name: 'rounded_to_next_integer_invoice_weight', toClassOnly: true })
  public invoicedWeightRounded: string = ''

  @Expose({ name: 'vat_rate', toClassOnly: true })
  public vatRate: string = ''

  @Expose({ name: 'net_total_amount', toClassOnly: true })
  public netTotalAmount: string = ''

  @Expose({ name: 'vat_amount', toClassOnly: true })
  public vatAmount: string = ''

  @Expose({ name: 'order_ids', toClassOnly: true })
  @Transform(value => value || [], { toClassOnly: true })
  public orderIds: number[] = []

  @Expose({ name: 'orders', toClassOnly: true })
  public orders: ShipmentOrder[] = []

  @Expose({ name: 'origin_tax_id' })
  public originTaxId: string | null = ''

  @Expose({ name: 'origin_gmaps_url' })
  public originGmapsUrl: string | null = ''

  @Expose({ name: 'destination_tax_id' })
  public destinationTaxId: string | null = ''

  @Expose({ name: 'destination_gmaps_url' })
  public destinationGmapsUrl: string | null = ''

  // account_pending_clearing_charges_available
  @Expose({ name: 'account_pcc_available', toClassOnly: true })
  public accountPccAvailable: boolean = false

  // account_pending_clearing_charges_net_amount
  @Expose({ name: 'account_pcc_net_amount', toClassOnly: true })
  public accountPccNetAmount: string = ''

  // account_pending_clearing_charges_vat_amount
  @Expose({ name: 'account_pcc_vat_amount', toClassOnly: true })
  public accountPccVatAmount: string = ''

  @Expose({ name: 'unpaid_sent_shipment_ids', toClassOnly: true })
  public unpaidSentShipmentIds: number[] = []

  @Expose({ name: 'unpaid_sent_shipment_sum', toClassOnly: true })
  public unpaidSentShipmentSum: string = ''

  @Expose({ name: 'unpaid_sent_shipment_vat', toClassOnly: true })
  public unpaidSentShipmentVat: string = ''

  public get shipmentWeight () {
    if (this.volumetricWeight > this.physicalWeight) {
      return this.volumetricWeight
    }
    return this.physicalWeight
  }

  public get isVolumetricWeight () {
    return this.volumetricWeight > this.physicalWeight
  }

  public get isPublicShipment () {
    return this.channel === SHIPMENT_CHANNEL.PUBLIC
  }

  public get isUserShipment () {
    return this.channel === SHIPMENT_CHANNEL.USER
  }

  @Expose({ name: 'contact_email_support' })
  public contactEmailSupport: string = ''

  @Expose({ name: 'has_dedicated_carta_porte_request', toClassOnly: true })
  public hasDedicatedCartaPorteRequest: boolean = false

  @Expose({ name: 'activate_shipment_confirm', toClassOnly: true })
  public activateShipmentConfirm: boolean = false

  @Expose({ name: 'hide_pickup_options', toClassOnly: true })
  public hidePickupOptions: boolean = true

  @Expose({ name: 'selected_offer_hide_pickup_options', toClassOnly: true })
  public selectedOfferHidePickupOptions: boolean = true

  @Expose({ name: 'is_allowed_for_download_invoices', toClassOnly: true })
  public isAllowedForDownloadInvoices: boolean = false

  @Expose({ name: 'first_invoice_digest', toClassOnly: true })
  public firstInvoiceDigest: string = ''

  @Expose({ name: 'uuid', toClassOnly: true })
  public uuid: string = ''

  @Expose({ name: 'customs_information_is_blank', toClassOnly: true })
  public customsInformationIsBlank: boolean = false

  @Expose({ name: 'requires_customs_information', toClassOnly: true })
  public requiresCustomsInformation: boolean = false

  @Expose({ name: 'tracking_url', toClassOnly: true })
  public trackingUrl: string = ''

  @Expose({ name: 'test', toClassOnly: true })
  public test: boolean = false

  @Expose({ name: 'allow_own_carrier_acc_activate', toClassOnly: true })
  public allowOwnCarrierAccActivate: boolean = false

  @Expose({ name: 'has_prepaid_or_credit_order_type', toClassOnly: true })
  public hasPrepaidOrCreditOrderType: boolean = true

  @Expose({ name: 'credit_saldo_positive', toClassOnly: true })
  public creditSaldoPositive: boolean = true

  @Expose({ name: 'oxxo_cash_payment', toClassOnly: true })
  public oxxoCashPayment: boolean = true

  @Expose({ name: 'invoice_pending_and_paid', toClassOnly: true })
  public invoicePendingAndPaid: boolean = false

  @Expose({ name: 'rmy', toClassOnly: true })
  public rmy: string = ''

  @Expose({ name: 'dsr', toClassOnly: true })
  public dsr: string = ''

  @Expose({ name: 'all_accounting_amounts_hash' })
  @Type(() => Amounts)
  public amounts: Amounts = new Amounts()

  @Expose({ name: 'surcharges' })
  @Transform(value => { return (value === null) ? [] : value }, { toClassOnly: true })
  @Type(() => Surcharge)
  public surcharges: Surcharge[] = []

  @Expose({ name: 'standard_cogs_amount' })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public standardCogsAmount: number = 0

  public get canPayByCorporate () {
    return !this.hasResellerBalance && !!this.corporateAccount?.id
  }

  public get payToVendor () {
    return this.hasResellerBalance || this.isPublicReseller
  }

  public get hasResellerBalance () {
    return this.enviayaAccount?.balanceSaldoType === BALANCE_SALDO_TYPE_RESELLER
  }

  public get isPublicReseller () {
    return !this.enviayaAccount && this.whiteLabel?.enableReseller
  }

  public get white_label_id () {
    return this.whiteLabelId
  }
}
