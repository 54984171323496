import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators'
import Vue from 'vue'
import { UsersApiService } from '@/common/api/users.api.service'
import store from '@/store'
import { classToPlain, plainToClass } from 'class-transformer'
import { AppUser } from '@/models/AppUser'
import { AppModule } from '@/store/app.module'
import JwtService from '@/common/jwt.service'
import { WhiteLabel } from '@/models/white-label'
import WhiteLabelsApiService from '@/common/api/white-labels.api.service'
import { AxiosResponse } from 'axios'
export interface IAuthState {
  errors: {}
  user: any
  whiteLabel: any
}

@Module({ dynamic: true, store, name: 'auth' })
class Auth extends VuexModule implements IAuthState {
  public user: AppUser | null = JwtService.getUser()
  public whiteLabel: WhiteLabel | boolean = JwtService.getWhiteLabel()
  public errors = {}

  public get currentUser () {
    return this.user
  }

  public get currentWhiteLabel () {
    return this.whiteLabel
  }

  public get userAuthenticated (): boolean {
    return !!AuthModule.currentUser
  }

  @Action({ rawError: true })
  public async login (userInfo: { email: string, password: string, locale: string }) {
    try {
      let { data } = await UsersApiService.login(userInfo)
      const appUser: AppUser = plainToClass(AppUser, data.user)
      this.SET_CURRENT_USER(appUser)
      this.SET_ERRORS({})
      JwtService.saveUser(classToPlain(appUser))
      AppModule.updateLocale(appUser.locale)
      Vue.cookies.set('locale', appUser.locale)
      return appUser
    } catch (error) {
      throw error
    }
  }

  @Action({ rawError: true })
  public async identWhiteLabel () {
    try {
      let { data } = await WhiteLabelsApiService.getClientWhiteLabel()
      if (!data) {
        this.SET_WHITE_LABEL(false)
        JwtService.saveWhiteLabel(false)
      } else {
        const whiteLabel: WhiteLabel = plainToClass(WhiteLabel, data)
        this.SET_WHITE_LABEL(whiteLabel)
        JwtService.saveWhiteLabel(classToPlain(whiteLabel))
      }
      return data
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true })
  public async tokenLogin () {
    try {
      const response = await UsersApiService.getCurrentUser()
      const appUser = plainToClass(AppUser, response.data.user)

      this.SET_CURRENT_USER(appUser)
      this.SET_ERRORS({})
      JwtService.saveUser(classToPlain(appUser))
      AppModule.updateLocale(appUser.locale)
      return response
    } catch (error) {
      // If an user is not active (status is not equal 1)
      if (error && error.errorCode === 42200) {
        await this.logout()
        Vue.$modal.show({
          type: 'danger',
          centered: true,
          headerText: 'Error',
          bodyText: error.errors[0][0],
          applyBtnText: 'OK',
          applyAction: async () => {
            window.location.reload()
          }
        })
      } else {
        throw error
      }
    }
  }

  @Action({ rawError: true })
  public async sessionCheck () {
    return UsersApiService.userSessionCheck()
  }

  @Action({ rawError: true })
  public async checkMfaCode (code: string) {
    try {
      const response = await UsersApiService.checkMfaCode(code)
      return response
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true })
  public async register (userData: any) {
    try {
      let response: AxiosResponse = await UsersApiService.register(userData.user, userData.params, userData.locale)
      return response
    } catch (e) {
      throw e
    }
  }

  @Action({ rawError: true })
  public async logout () {
    this.PURGE_AUTH()
  }

  @Mutation
  public SET_CURRENT_USER (user: AppUser) {
    this.user = user
  }

  @Mutation
  public SET_WHITE_LABEL (whiteLabel: WhiteLabel | boolean) {
    this.whiteLabel = whiteLabel
  }

  @Mutation
  public SET_ERRORS (errors: object) {
    this.errors = errors
  }

  @Mutation
  public PURGE_AUTH () {
    this.errors = {}
    this.user = null
    this.whiteLabel = false
    JwtService.destroyUser()
    JwtService.destroyWhiteLabel()
  }
}

export const AuthModule = getModule(Auth)
