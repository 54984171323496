import { Expose, Transform } from 'class-transformer'

export class OrderItem {
  @Expose({ name: 'id' })
  public id: number | undefined

  @Expose({ name: 'description' })
  public description: string = ''

  @Expose({ name: 'quantity' })
  @Transform(value => {
    return (value) ? value : 1
  }, { toClassOnly: true })
  public quantity: number = 1

  @Expose({ name: 'weight', toClassOnly: true })
  public weight: number = 1
}
