import { render, staticRenderFns } from "./MdbBasicSelect.vue?vue&type=template&id=3f989438"
import script from "./MdbBasicSelect.vue?vue&type=script&lang=ts"
export * from "./MdbBasicSelect.vue?vue&type=script&lang=ts"
import style0 from "./MdbBasicSelect.vue?vue&type=style&index=0&id=3f989438&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/locales/select2.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FMdbBasicSelect.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports