import parseDomain, { ParsedDomain } from 'parse-domain'
import Vue from 'vue'
import { CONFIGURATION_KEYS } from '@/common/config'
import { WhiteLabel } from '@/models/white-label'

export class DomainService {
  public static getHostFromURI (domain: string): string | null {
    if (!domain.includes('http') && !domain.includes('https')) {
      domain = `https://${domain}`
    }
    try {
      let locationData = new URL(domain)
      return locationData.host
    } catch (e) {
      return null
    }
  }

  public static getWLDomain (whiteLabel: WhiteLabel): string | null {
    if (whiteLabel && whiteLabel.domain && whiteLabel.domain.length && whiteLabel.domainValidated) {
      return this.getHostFromURI(whiteLabel.domain)
    } else if (whiteLabel && whiteLabel.subdomain && whiteLabel.subdomain.length) {
      if (whiteLabel.subdomain.includes('enviaya')) {
        return `${whiteLabel.subdomain}.com.mx`
      } else {
        return `${whiteLabel.subdomain}.enviaya.com.mx`
      }
    } else {
      return null
    }
  }

  public static isWLSubdomainOfEnviaya (): boolean {
    let fullDomain = this.getHostFromURI(window.location.href)
    const domainParts: ParsedDomain | null = parseDomain(fullDomain!)

    if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'development'
      || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
      return false
    } else {
      return (!!domainParts!.subdomain && domainParts!.subdomain !== 'app' && domainParts!.domain.includes('enviaya'))
    }
  }

  public static isEnviayaDomain (): boolean {
    let fullDomain = this.getHostFromURI(window.location.href)
    const domainParts: ParsedDomain | null = parseDomain(fullDomain!)

    return Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'development'
      || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging'
      || !domainParts
      || (domainParts.subdomain === 'app' && domainParts.domain.includes('enviaya'))
  }

  public static getDomainForCookies (_whiteLabel?: WhiteLabel | null): { domain: string } {
    let vueExpectedHost = this.getHostFromURI(Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH))
    let vueActualHost = this.getHostFromURI(window.location.href)
    const domainParts: ParsedDomain | null = parseDomain(vueActualHost!)
    let domain = ''
    let subDomain: string = ''
    if (domainParts) {
      if (domainParts.subdomain && domainParts.subdomain.length > 0) {
        const subDomainParts = domainParts.subdomain.split('.')
        if (subDomainParts.length > 1) {
          subDomain = subDomainParts[subDomainParts.length - 1] + '.'
        }
      }
      domain = '.' + subDomain + domainParts.domain + '.' + domainParts.tld
    }

    if (vueExpectedHost === vueActualHost) {
      return { domain: domain }
    }
    return { domain: '' }
  }

  public static getDomainWithoutPort (fullDomain: string): string {
    const domainParts: ParsedDomain | null = parseDomain(fullDomain)
    let domain = ''
    let subDomain: string = ''

    if (domainParts) {
      if (domainParts.subdomain && domainParts.subdomain.length > 0) {
        const subDomainParts = domainParts.subdomain.split('.')
        subDomain = subDomainParts[subDomainParts.length - 1] + '.'
      }
      domain = subDomain + domainParts.domain + '.' + domainParts.tld
    }

    return domain
  }

  public static getDomainWithoutSubdomainAndPort (fullDomain: string): string {
    const domainParts: ParsedDomain | null = parseDomain(fullDomain)
    if (domainParts) {
      return domainParts.domain + '.' + domainParts.tld
    }
    return ''
  }

  public static formatLink (link: string): string {
    return link.replace(/(^\w+:|^)\/\//, '')
  }
}
