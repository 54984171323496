<template>
  <component
    v-if="noWrapper"
    ref="input"
    :is="whatTagIsThis"
    :id="id"
    :class="inputClasses"
    :type="type"
    :step="step"
    :min="min"
    :max="max"
    :placeholder="placeholder"
    :disabled="disabled"
    :name="name"
    :required="required"
    :checked="inputChecked"
    :value="innerValue"
    :rows="rows"
    :maxlength="maxlength"
    :aria-label="label || ariaLabel || placeholder"
    :aria-describedby="ariaDescribedBy"
    :aria-labelledby="ariaLabelledBy"
    :aria-multiselectable="ariaMultiselectable"
    :aria-disabled="ariaDisabled"
    :aria-required="ariaRequired"
    :aria-haspopup="ariaHaspopup"
    :aria-expanded="ariaExpanded"
    :aria-owns="ariaOwns"
    :role="role"
    :autofocus="autofocus"
    :readOnly="readOnly"
    :autocomplete="autocomplete"
    @focus="focus"
    @blur="blur"
    @click="onClick"
    @change="onChange"
    @input="onInput"
  >{{whatTagIsThis==='textarea' && value}}</component>
  <div :class="wrapperClasses" v-else>
    <i v-if="icon" :class="iconClasses" />
    <div class="input-group-prepend" v-if="$slots.prepend" :id="prependSlotID">
      <slot name="prepend"></slot>
    </div>
    <component
      ref="input"
      :is="whatTagIsThis"
      :id="id"
      :class="inputClasses"
      :type="type"
      pattern="[0-9]+([\.][0-9]{1,2})?"
      :inputmode="inputmode"
      :step="step"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :disabled="disabled"
      :name="name"
      :required="required"
      :checked="inputChecked"
      :value="innerValue"
      :rows="rows"
      :maxlength="maxlength"
      :aria-label="label || ariaLabel || placeholder"
      :aria-describedby="ariaDescribedBy"
      :aria-labelledby="ariaLabelledBy"
      :aria-multiselectable="ariaMultiselectable"
      :aria-disabled="ariaDisabled"
      :aria-required="ariaRequired"
      :aria-haspopup="ariaHaspopup"
      :aria-expanded="ariaExpanded"
      :aria-owns="ariaOwns"
      :role="role"
      onpaste="return false;"
      @focus="focus"
      :readOnly="readOnly"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      @blur="blur"
      @click="onClick"
      @change="onChange"
      @input="onInput"
      @keypress="keyPress"
      @keyup="keyDown"
    >{{whatTagIsThis==='textarea' && value}}</component>
    <label v-if="label" :class="labelClasses" @click="focus" ref="label" :for="id">{{label}}</label>
    <label
      v-if="isThisCheckboxLabeless"
      :class="labelClasses"
      @click="focus"
      ref="label"
      :for="id"
    />
    <slot></slot>
    <div class="input-group-append" v-if="$slots.append" :id="appendSlotID">
      <slot name="append"></slot>
    </div>
    <div v-if="validFeedback" class="valid-feedback">{{validFeedback}}</div>
    <div v-if="invalidFeedback" class="invalid-feedback">{{invalidFeedback}}</div>
    <small v-if="small" class="form-text text-muted">{{small}}</small>
  </div>
</template>

<script>
import mdbInput from 'mdbvue/lib/components/mdbInput'

export default {
  name: 'EnviayaInput',
  extends: mdbInput,
  props: {
    inputmode: {
      type: String,
      default: 'numeric'
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    maxDigits: {
      type: Number,
      default: null
    }
  },

  created () {
    // let watchers = this._watchers.filter(watcher => watcher.expression === 'value' || watcher.expression === 'innerValue')
    // watchers[0] = Object.assign(watchers[0], { active: false, sync: true })
    // watchers[1] = Object.assign(watchers[1], { active: false, sync: true })
    // this.$watch('value', (val) => {
    //   if (val) {
    //     this.$refs.input.value = val
    //     this.innerValue = val
    //     this.$emit("change", this.innerValue)
    //   }
    // }, { deep: false })
  },

  methods: {
    keyPress (e) {
      if (this.type === 'number') {
        let keyCode = (e.keyCode ? e.keyCode : e.which)
        if (keyCode === 45 && !this.allowNegative) {
          e.preventDefault()
        }
        if (this.inputmode === 'numeric' && (keyCode === 46 || keyCode === 44)) {
          e.preventDefault()
        }
        if (this.inputmode === 'decimal' && this.innerValue && String(this.innerValue).indexOf('.') > -1) {
          // e.preventDefault()
        } else if (this.inputmode === 'numeric' && this.maxDigits && this.innerValue && String(this.innerValue).length >= this.maxDigits) {
          e.preventDefault()
        }
      }
    },
    keyDown (e) {
      if (this.inputmode === 'decimal' && this.innerValue && String(this.innerValue).indexOf('.') > -1) {
        const newVal = Number((Math.floor(this.innerValue * 100) / 100).toFixed(2))
        this.$refs.input.value = newVal
        this.$emit('input', e.target.value)
        this.$emit('change', newVal)
      }
    },
    blur (e) {
      if (this.type === 'number') {
        if (typeof this.min === 'number' && this.innerValue && this.innerValue < this.min) {
          this.innerValue = this.min
        } else if (typeof this.max === 'number' && this.innerValue > this.max) {
          this.innerValue = this.max
        }

        this.$refs.input.value = this.innerValue
        this.$emit('input', e.target.value)
        this.$emit('change', this.innerValue)
      }
      this.$emit('blur', e)
      this.isTouched = false
      // styles for navbar input
      if (this.navInput) {
        this.$el.firstElementChild.style.borderColor = '#fff'
      }
    }
  }
}
</script>
