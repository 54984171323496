/* For using the show method from the Popup component
*  insert this line into your component
*  this.$modal.show(options)
 */
import i18n from '@/i18n'
import store from '@/store'
import PopupContainer from '@/components/popup/PopupContainer.vue'
import NotificationPopup from '@/components/popup/NotificationPopup.vue'

const Modals = {
  install(Vue) {
    this.EventBus = new Vue();

    let modal = {
      show(options) {
        const node = document.createElement('div')
        document.body.querySelector('#app').lastElementChild.appendChild(node)
        let ComponentClass = Vue.extend(PopupContainer)
        this.instance = new ComponentClass({ i18n, store })
        this.instance.$mount(node)
        this.instance.show(options)
      },
      notify(options) {
        const node = document.createElement('div')
        document.body.querySelector('#app').lastElementChild.appendChild(node)
        let ComponentClass = Vue.extend(NotificationPopup)
        this.instance = new ComponentClass({ i18n, store })
        this.instance.$mount(node)
        this.instance.show(options)
      },
      close() {
        this.instance.close()
      }
    }

    Vue.prototype.$modal = modal

    Vue.$modal = modal
  }
};

export default Modals;
