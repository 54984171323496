import Vue from 'vue'

export const CONFIGURATION_KEYS = {
  IS_PRODUCTION: 'IS_PRODUCTION',
  APP_ENVIRONMENT: 'APP_ENVIRONMENT',
  APP_VUE_PUBLIC_PATH: 'APP_VUE_PUBLIC_PATH',
  API_URL: 'API_URL',
  GRAPHQL_API_URL: 'GRAPHQL_API_URL',
  REDIRECT_URL: 'REDIRECT_URL',
  RECAPTCHA_SITE_KEY: 'RECAPTCHA_SITE_KEY',
  RECAPTCHA_V2_SITE_KEY: 'RECAPTCHA_V2_SITE_KEY',
  GOOGLE_TAG_MANAGER_ID: 'GOOGLE_TAG_MANAGER_ID',
  APP_FACEBOOK_KEY: 'APP_FACEBOOK_KEY',
  APP_GOOGLE_KEY: 'APP_GOOGLE_KEY',
  APP_GOOGLE_MAP_KEY: 'APP_GOOGLE_MAP_KEY',
  APP_GOOGLE_MAP_GEOCODER_KEY: 'APP_GOOGLE_MAP_GEOCODER_KEY',
  LOGO_PATH: 'LOGO_PATH',
  SMALL_LOGO_PATH: 'SMALL_LOGO_PATH',
  ROLLBAR_ACCESS_TOKEN: 'ROLLBAR_ACCESS_TOKEN',
  WL_DOMAIN: 'WL_DOMAIN',
  WL_DOMAIN_PORT: 'WL_DOMAIN_PORT',
  USE_SSL: 'USE_SSL'
}

const CONFIGURATION: any = {
  IS_PRODUCTION: ['production', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) || false,
  APP_ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT || 'development',
  APP_VUE_PUBLIC_PATH: process.env.VUE_APP_PUBLIC_PATH || 'http://app.local.enviaya.com:8080/',
  API_URL: process.env.VUE_APP_ENVIAYA_HOST || 'https://enviaya.com.mx/api/v2/',
  GRAPHQL_API_URL: process.env.VUE_APP_GRAPHQL_ENVIAYA_HOST || 'https://enviaya.com.mx/graphql',
  REDIRECT_URL: process.env.VUE_APP_REDIRECT_HOST || 'https://local.enviaya.com:3333/',
  RECAPTCHA_SITE_KEY: process.env.VUE_APP_RECAPTHA_SITE_KEY || '',
  RECAPTCHA_V2_SITE_KEY: process.env.VUE_APP_RECAPTHA_V2_SITE_KEY || '',
  GOOGLE_TAG_MANAGER_ID: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID || '',
  APP_FACEBOOK_KEY: process.env.VUE_APP_FACEBOOK_KEY || '',
  APP_GOOGLE_KEY: process.env.VUE_APP_GOOGLE_KEY || '',
  APP_GOOGLE_MAP_KEY: process.env.VUE_APP_GOOGLE_MAP_KEY || '',
  APP_GOOGLE_MAP_GEOCODER_KEY: process.env.VUE_APP_GOOGLE_MAP_GEOCODER_KEY || '',
  // LOGO_PATH: '/img/enviaya-xmas-logo.svg',
  LOGO_PATH: '/img/enviaya-logo.svg',
  // SMALL_LOGO_PATH: '/img/enviaya-xmas-mundo-logo.svg',
  SMALL_LOGO_PATH: '/img/enviaya-mundo-logo.svg',
  ROLLBAR_ACCESS_TOKEN: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN || '',
  WL_DOMAIN: process.env.VUE_APP_WL_DOMAIN,
  WL_DOMAIN_PORT: process.env.VUE_APP_WL_DOMAIN_PORT,
  USE_SSL: process.env.VUE_APP_USE_SSL
}

export default class ConfigurationPlugin {
  public static install (vue: Vue | any, _options?: any): void {
    const func = (key: any) => {
      return CONFIGURATION[key]
    }
    vue['$getConst'] = func
    vue.prototype.$getConst = func
  }
}
