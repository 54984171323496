import { Exclude, Expose, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import { QuotingFilter } from '@/models/quoting-filter'
import { CorporateAccountTs } from './corporate-account-ts'

@Exclude()
export class WhiteLabel extends AbstractModel {
  static get modelName () {
    return 'WhiteLabel'
  }

  @Expose({ name: 'brand_name' })
  public brandName: string = ''

  @Expose({ name: 'enable_reseller' })
  public enableReseller: boolean = false

  @Expose({ name: 'skip_reseller_rating' })
  public skipResellerRating: boolean = false

  @Expose({ name: 'corporate_account_id' })
  public corporateAccountId?: number

  @Expose({ name: 'logo' })
  public logo: string = ''

  @Expose({ name: 'logo_svg' })
  public logoSvg: string = ''

  @Expose({ name: 'footer_logo' })
  public footerLogo: string = ''

  @Expose({ name: 'logo_minimized' })
  public logoMinimized: string = ''

  @Expose({ name: 'domain' })
  public domain: string = ''

  @Expose({ name: 'subdomain' })
  public subdomain: string = ''

  @Expose({ name: 'google_api_key' })
  public googleApiKey: string = ''

  @Expose({ name: 'google_oauth_key' })
  public googleOauthKey: string = ''

  @Expose({ name: 'oauth_facebook_key' })
  public oauthFacebookKey: string = ''

  @Expose({ name: 'recaptcha_site_key' })
  public recaptchaSiteKey: string = ''

  @Expose({ name: 'recaptcha_site_key_v2' })
  public recaptchaSiteKeyV2: string = ''

  @Expose({ name: 'google_tag_manager_key' })
  public googleTagManagerKey: string = ''

  @Expose({ name: 'forbid_new_user_registration' })
  public forbidNewUserRegistration: boolean = true

  @Expose({ name: 'terms_and_condition_url' })
  public termsAndConditionUrl: string = ''

  @Expose({ name: 'privacy_policy_url' })
  public privacyPolicyUrl: string = ''

  @Expose({ name: 'support_email' })
  public supportEmail: string = ''

  @Expose({ name: 'domain_validated' })
  public domainValidated: boolean = false

  @Expose({ name: 'quoting_filter', toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  @Type(() => QuotingFilter)
  public quotingFilter: QuotingFilter | undefined | null = null

  // Colors
  @Expose({ name: 'header_color' })
  public headerColor: string = ''

  @Expose({ name: 'user_menu_font_color' })
  public userMenuFontColor: string = ''

  @Expose({ name: 'login_page_background_color' })
  public loginPageBackgroundColor: string = ''

  @Expose({ name: 'login_page_background_image' })
  public loginPageBackgroundImage: string = ''

  @Expose({ name: 'login_page_background_size' })
  public loginPageBackgroundSize: string = ''

  @Expose({ name: 'login_page_background_repeat' })
  public loginPageBackgroundRepeat: string = ''

  @Expose({ name: 'login_page_background_position' })
  public loginPageBackgroundPosition: string = ''

  @Expose({ name: 'sidebar_background_color' })
  public sidebarBackgroundColor: string = ''

  @Expose({ name: 'sidebar_font_color' })
  public sidebarFontColor: string = ''

  @Expose({ name: 'button_background_color' })
  public buttonBackgroundColor: string = ''

  @Expose({ name: 'button_background_hover' })
  public buttonBackgroundHover: string = ''

  @Expose({ name: 'button_text_color' })
  public buttonTextColor: string = ''

  @Expose({ name: 'favicon' })
  public favicon: string = ''

  @Expose({ name: 'terms_and_condition_url' })
  public termsLink: string = ''

  @Expose({ name: 'privacy_policy_url' })
  public policyLink: string = ''

  @Expose({ name: 'footer_background_color' })
  public footerBackgroundColor: string = ''

  @Expose({ name: 'footer_font_color' })
  public footerFontColor: string = ''

  @Expose({ name: 'footer_link_color' })
  public footerLinkColor: string = ''

  @Expose({ name: 'footer_link_hover_color' })
  public footerLinkHoverColor: string = ''

  @Expose({ name: 'disable_shop_integration' })
  public disableShopIntegration: boolean = false

  @Expose({ name: 'supports_hours' })
  public supportsHours: string | undefined | null = null

  @Expose({ name: 'time_zone' })
  public timeZone: string | undefined | null = null

  @Expose({ name: 'operation_mode' })
  public operationMode: number | undefined

  @Expose({ name: 'enable_premium_pickup_support' })
  public enablePremiumPickupSupport: boolean = false

  @Expose({ name: 'reseller_corporate_account', toClassOnly: true })
  @Type(() => CorporateAccountTs)
  public resellerCorporateAccount: CorporateAccountTs | undefined | null = null

  // For abilities
  public get enable_reseller () {
    return this.enableReseller
  }

  public get operation_mode () {
    return this.operationMode
  }
}
