import { Exclude, Expose, Transform, Type } from 'class-transformer'

@Exclude()
export class AbstractModelList<T> {
  @Exclude()
  private type: Function

  @Expose({ name: 'all_models_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public modelsCnt: number = 0

  @Expose({ name: 'all_models_ids' })
  @Transform(value => value || [], { toClassOnly: true })
  public allModelsIds: number[] = []

  @Expose({ name: 'models' })
  @Transform(value => value || [], { toClassOnly: true })
  @Type(options => {
    return (options!.newObject as AbstractModelList<T>).type
  })
  public models: T[] = []

  constructor (type: Function) {
    this.type = type
  }
}
