export const COUNTRIES_WITHOUT_POSTCODES = [
  ['Angola', 'AO'], ['Antigua and Barbuda', 'AG'], ['Aruba', 'AW'],
  ['Bahamas', 'BS'], ['Belize', 'BZ'], ['Benin', 'BJ'], ['Bolivia', 'BO'], ['Botswana', 'BW'],
  ['Burkina Faso', 'BF'], ['Burundi', 'BI'], ['Cameroon', 'CM'],
  ['Central African Republic', 'CF'], ['Comoros', 'KM'], ['Congo', 'CG'],
  ['Congo, the Democratic Republic of the', 'CD'],
  ['Cook Islands', 'CK'], ['Cote d\'Ivoire', 'CI'],
  ['Djibouti', 'DJ'], ['Dominica', 'DM'], ['Equatorial Guinea', 'GQ'],
  ['Eritrea', 'ER'], ['Fiji', 'FJ'], ['French Southern Territories', 'TF'],
  ['Gambia', 'GM'], ['Ghana', 'GH'], ['Grenada', 'GD'], ['Guinea', 'GN'],
  ['Guyana', 'GY'], ['Hong Kong', 'HK'], ['Jamaica', 'JM'], ['Kenya', 'KE'],
  ['Kiribati', 'KI'], ['Macao', 'MO'], ['Malawi', 'MW'], ['Mali', 'ML'],
  ['Mauritania', 'MR'], ['Mauritius', 'MU'], ['Montserrat', 'MS'], ['Nauru', 'NR'],
  ['Netherlands Antilles', 'AN'], ['Niue', 'NU'], ['North Korea', 'KP'], ['Panama', 'PA'],
  ['Qatar', 'QA'], ['Rwanda', 'RW'], ['Saint Kitts and Nevis', 'KN'], ['Saint Lucia', 'LC'],
  ['Sao Tome and Principe', 'ST'], ['Saudi Arabia', 'SA'], ['Seychelles', 'SC'],
  ['Sierra Leone', 'SL'], ['Solomon Islands', 'SB'], ['Somalia', 'SO'], ['Suriname', 'SR'],
  ['Syria', 'SY'], ['Tanzania, United Republic of', 'TZ'], ['Timor-Leste', 'TL'], ['Tokelau', 'TK'],
  ['Tonga', 'TO'], ['Tuvalu', 'TV'], ['Uganda', 'UG'], ['United Arab Emirates', 'AE'], ['Vanuatu', 'VU'],
  ['Yemen', 'YE'], ['Zimbabwe', 'ZW'], ['Chile', 'CL']]

export const LANGUAGES = [
  {
    'id': 'af-NA',
    'text': 'Afrikaans (Namibia)'
  },
  {
    'id': 'af',
    'text': 'Afrikaans'
  },
  {
    'id': 'ak',
    'text': 'Akan'
  },
  {
    'id': 'sq',
    'text': 'Albanian'
  },
  {
    'id': 'am',
    'text': 'Amharic'
  },
  {
    'id': 'ar-DZ',
    'text': 'Arabic (Algeria)'
  },
  {
    'id': 'ar-BH',
    'text': 'Arabic (Bahrain)'
  },
  {
    'id': 'ar-EG',
    'text': 'Arabic (Egypt)'
  },
  {
    'id': 'ar-IQ',
    'text': 'Arabic (Iraq)'
  },
  {
    'id': 'ar-JO',
    'text': 'Arabic (Jordan)'
  },
  {
    'id': 'ar-KW',
    'text': 'Arabic (Kuwait)'
  },
  {
    'id': 'ar-LB',
    'text': 'Arabic (Lebanon)'
  },
  {
    'id': 'ar-LY',
    'text': 'Arabic (Libya)'
  },
  {
    'id': 'ar-MA',
    'text': 'Arabic (Morocco)'
  },
  {
    'id': 'ar-OM',
    'text': 'Arabic (Oman)'
  },
  {
    'id': 'ar-QA',
    'text': 'Arabic (Qatar)'
  },
  {
    'id': 'ar-SA',
    'text': 'Arabic (Saudi Arabia)'
  },
  {
    'id': 'ar-SD',
    'text': 'Arabic (Sudan)'
  },
  {
    'id': 'ar-SY',
    'text': 'Arabic (Syria)'
  },
  {
    'id': 'ar-TN',
    'text': 'Arabic (Tunisia)'
  },
  {
    'id': 'ar-AE',
    'text': 'Arabic (United Arab Emirates)'
  },
  {
    'id': 'ar-YE',
    'text': 'Arabic (Yemen)'
  },
  {
    'id': 'ar',
    'text': 'Arabic'
  },
  {
    'id': 'hy',
    'text': 'Armenian'
  },
  {
    'id': 'as',
    'text': 'Assamese'
  },
  {
    'id': 'asa',
    'text': 'Asu'
  },
  {
    'id': 'az',
    'text': 'Azerbaijani'
  },
  {
    'id': 'bm',
    'text': 'Bambara'
  },
  {
    'id': 'eu',
    'text': 'Basque'
  },
  {
    'id': 'be',
    'text': 'Belarusian'
  },
  {
    'id': 'bem',
    'text': 'Bemba'
  },
  {
    'id': 'bez',
    'text': 'Bena'
  },
  {
    'id': 'bn-IN',
    'text': 'Bengali (India)'
  },
  {
    'id': 'bn',
    'text': 'Bengali'
  },
  {
    'id': 'bs',
    'text': 'Bosnian'
  },
  {
    'id': 'bg',
    'text': 'Bulgarian'
  },
  {
    'id': 'my',
    'text': 'Burmese'
  },
  {
    'id': 'ca',
    'text': 'Catalan'
  },
  {
    'id': 'tzm',
    'text': 'Central Morocco Tamazight'
  },
  {
    'id': 'chr',
    'text': 'Cherokee'
  },
  {
    'id': 'cgg',
    'text': 'Chiga'
  },
  {
    'id': 'zh-Hans',
    'text': 'Chinese (Simplified Han)'
  },
  {
    'id': 'zh-Hans-CN',
    'text': 'Chinese (Simplified Han, China)'
  },
  {
    'id': 'zh-Hans-HK',
    'text': 'Chinese (Simplified Han, Hong Kong SAR China)'
  },
  {
    'id': 'zh-Hans-MO',
    'text': 'Chinese (Simplified Han, Macau SAR China)'
  },
  {
    'id': 'zh-Hans-SG',
    'text': 'Chinese (Simplified Han, Singapore)'
  },
  {
    'id': 'zh-Hant',
    'text': 'Chinese (Traditional Han)'
  },
  {
    'id': 'zh-Hant-HK',
    'text': 'Chinese (Traditional Han, Hong Kong SAR China)'
  },
  {
    'id': 'zh-Hant-MO',
    'text': 'Chinese (Traditional Han, Macau SAR China)'
  },
  {
    'id': 'zh-Hant-TW',
    'text': 'Chinese (Traditional Han, Taiwan)'
  },
  {
    'id': 'zh',
    'text': 'Chinese'
  },
  {
    'id': 'kw',
    'text': 'Cornish'
  },
  {
    'id': 'hr',
    'text': 'Croatian'
  },
  {
    'id': 'cs',
    'text': 'Czech'
  },
  {
    'id': 'da',
    'text': 'Danish'
  },
  {
    'id': 'nl-BE',
    'text': 'Dutch (Belgium)'
  },
  {
    'id': 'nl',
    'text': 'Dutch'
  },
  {
    'id': 'ebu',
    'text': 'Embu'
  },
  {
    'id': 'en-AS',
    'text': 'English (American Samoa)'
  },
  {
    'id': 'en-AU',
    'text': 'English (Australia)'
  },
  {
    'id': 'en-BE',
    'text': 'English (Belgium)'
  },
  {
    'id': 'en-BZ',
    'text': 'English (Belize)'
  },
  {
    'id': 'en-BW',
    'text': 'English (Botswana)'
  },
  {
    'id': 'en-CA',
    'text': 'English (Canada)'
  },
  {
    'id': 'en-GU',
    'text': 'English (Guam)'
  },
  {
    'id': 'en-HK',
    'text': 'English (Hong Kong SAR China)'
  },
  {
    'id': 'en-IN',
    'text': 'English (India)'
  },
  {
    'id': 'en-IE',
    'text': 'English (Ireland)'
  },
  {
    'id': 'en-JM',
    'text': 'English (Jamaica)'
  },
  {
    'id': 'en-MT',
    'text': 'English (Malta)'
  },
  {
    'id': 'en-MH',
    'text': 'English (Marshall Islands)'
  },
  {
    'id': 'en-MU',
    'text': 'English (Mauritius)'
  },
  {
    'id': 'en-NA',
    'text': 'English (Namibia)'
  },
  {
    'id': 'en-NZ',
    'text': 'English (New Zealand)'
  },
  {
    'id': 'en-MP',
    'text': 'English (Northern Mariana Islands)'
  },
  {
    'id': 'en-PK',
    'text': 'English (Pakistan)'
  },
  {
    'id': 'en-PH',
    'text': 'English (Philippines)'
  },
  {
    'id': 'en-SG',
    'text': 'English (Singapore)'
  },
  {
    'id': 'en-ZA',
    'text': 'English (South Africa)'
  },
  {
    'id': 'en-TT',
    'text': 'English (Trinidad and Tobago)'
  },
  {
    'id': 'en-UM',
    'text': 'English (U.S. Minor Outlying Islands)'
  },
  {
    'id': 'en-VI',
    'text': 'English (U.S. Virgin Islands)'
  },
  {
    'id': 'en-GB',
    'text': 'English (United Kingdom)'
  },
  {
    'id': 'en-US',
    'text': 'English (United States)'
  },
  {
    'id': 'en-ZW',
    'text': 'English (Zimbabwe)'
  },
  {
    'id': 'en',
    'text': 'English'
  },
  {
    'id': 'eo',
    'text': 'Esperanto'
  },
  {
    'id': 'et',
    'text': 'Estonian'
  },
  {
    'id': 'ee-TG',
    'text': 'Ewe (Togo)'
  },
  {
    'id': 'ee',
    'text': 'Ewe'
  },
  {
    'id': 'fo',
    'text': 'Faroese'
  },
  {
    'id': 'fil',
    'text': 'Filipino'
  },
  {
    'id': 'fi',
    'text': 'Finnish'
  },
  {
    'id': 'fr-BE',
    'text': 'French (Belgium)'
  },
  {
    'id': 'fr-BJ',
    'text': 'French (Benin)'
  },
  {
    'id': 'fr-BF',
    'text': 'French (Burkina Faso)'
  },
  {
    'id': 'fr-BI',
    'text': 'French (Burundi)'
  },
  {
    'id': 'fr-CM',
    'text': 'French (Cameroon)'
  },
  {
    'id': 'fr-CA',
    'text': 'French (Canada)'
  },
  {
    'id': 'fr-CF',
    'text': 'French (Central African Republic)'
  },
  {
    'id': 'fr-TD',
    'text': 'French (Chad)'
  },
  {
    'id': 'fr-KM',
    'text': 'French (Comoros)'
  },
  {
    'id': 'fr-CG',
    'text': 'French (Congo - Brazzaville)'
  },
  {
    'id': 'fr-CD',
    'text': 'French (Congo - Kinshasa)'
  },
  {
    'id': 'fr-CI',
    'text': 'French (Côte d’Ivoire)'
  },
  {
    'id': 'fr-DJ',
    'text': 'French (Djibouti)'
  },
  {
    'id': 'fr-GQ',
    'text': 'French (Equatorial Guinea)'
  },
  {
    'id': 'fr-FR',
    'text': 'French (France)'
  },
  {
    'id': 'fr-GA',
    'text': 'French (Gabon)'
  },
  {
    'id': 'fr-GP',
    'text': 'French (Guadeloupe)'
  },
  {
    'id': 'fr-GN',
    'text': 'French (Guinea)'
  },
  {
    'id': 'fr-LU',
    'text': 'French (Luxembourg)'
  },
  {
    'id': 'fr-MG',
    'text': 'French (Madagascar)'
  },
  {
    'id': 'fr-ML',
    'text': 'French (Mali)'
  },
  {
    'id': 'fr-MQ',
    'text': 'French (Martinique)'
  },
  {
    'id': 'fr-MC',
    'text': 'French (Monaco)'
  },
  {
    'id': 'fr-NE',
    'text': 'French (Niger)'
  },
  {
    'id': 'fr-RW',
    'text': 'French (Rwanda)'
  },
  {
    'id': 'fr-RE',
    'text': 'French (Réunion)'
  },
  {
    'id': 'fr-BL',
    'text': 'French (Saint Barthélemy)'
  },
  {
    'id': 'fr-MF',
    'text': 'French (Saint Martin)'
  },
  {
    'id': 'fr-SN',
    'text': 'French (Senegal)'
  },
  {
    'id': 'fr-CH',
    'text': 'French (Switzerland)'
  },
  {
    'id': 'fr-TG',
    'text': 'French (Togo)'
  },
  {
    'id': 'fr',
    'text': 'French'
  },
  {
    'id': 'ff',
    'text': 'Fulah'
  },
  {
    'id': 'gl',
    'text': 'Galician'
  },
  {
    'id': 'lg',
    'text': 'Ganda'
  },
  {
    'id': 'ka',
    'text': 'Georgian'
  },
  {
    'id': 'de-AT',
    'text': 'German (Austria)'
  },
  {
    'id': 'de-BE',
    'text': 'German (Belgium)'
  },
  {
    'id': 'de-LI',
    'text': 'German (Liechtenstein)'
  },
  {
    'id': 'de-LU',
    'text': 'German (Luxembourg)'
  },
  {
    'id': 'de-CH',
    'text': 'German (Switzerland)'
  },
  {
    'id': 'de',
    'text': 'German'
  },
  {
    'id': 'el-CY',
    'text': 'Greek (Cyprus)'
  },
  {
    'id': 'el',
    'text': 'Greek'
  },
  {
    'id': 'gu',
    'text': 'Gujarati'
  },
  {
    'id': 'guz',
    'text': 'Gusii'
  },
  {
    'id': 'ha',
    'text': 'Hausa'
  },
  {
    'id': 'haw',
    'text': 'Hawaiian'
  },
  {
    'id': 'he',
    'text': 'Hebrew'
  },
  {
    'id': 'hi',
    'text': 'Hindi'
  },
  {
    'id': 'hu',
    'text': 'Hungarian'
  },
  {
    'id': 'is',
    'text': 'Icelandic'
  },
  {
    'id': 'ig',
    'text': 'Igbo'
  },
  {
    'id': 'id',
    'text': 'Indonesian'
  },
  {
    'id': 'ga',
    'text': 'Irish'
  },
  {
    'id': 'it-CH',
    'text': 'Italian (Switzerland)'
  },
  {
    'id': 'it',
    'text': 'Italian'
  },
  {
    'id': 'ja',
    'text': 'Japanese'
  },
  {
    'id': 'kea',
    'text': 'Kabuverdianu'
  },
  {
    'id': 'kab',
    'text': 'Kabyle'
  },
  {
    'id': 'kl',
    'text': 'Kalaallisut'
  },
  {
    'id': 'kln',
    'text': 'Kalenjin'
  },
  {
    'id': 'kam',
    'text': 'Kamba'
  },
  {
    'id': 'kn',
    'text': 'Kannada'
  },
  {
    'id': 'kk',
    'text': 'Kazakh'
  },
  {
    'id': 'km',
    'text': 'Khmer'
  },
  {
    'id': 'ki',
    'text': 'Kikuyu'
  },
  {
    'id': 'rw',
    'text': 'Kinyarwanda'
  },
  {
    'id': 'kok',
    'text': 'Konkani'
  },
  {
    'id': 'ko',
    'text': 'Korean'
  },
  {
    'id': 'khq',
    'text': 'Koyra Chiini'
  },
  {
    'id': 'ses',
    'text': 'Koyraboro Senni'
  },
  {
    'id': 'lag',
    'text': 'Langi'
  },
  {
    'id': 'lv',
    'text': 'Latvian'
  },
  {
    'id': 'lt',
    'text': 'Lithuanian'
  },
  {
    'id': 'luo',
    'text': 'Luo'
  },
  {
    'id': 'luy',
    'text': 'Luyia'
  },
  {
    'id': 'mk',
    'text': 'Macedonian'
  },
  {
    'id': 'jmc',
    'text': 'Machame'
  },
  {
    'id': 'kde',
    'text': 'Makonde'
  },
  {
    'id': 'mg',
    'text': 'Malagasy'
  },
  {
    'id': 'ms',
    'text': 'Malay'
  },
  {
    'id': 'ml',
    'text': 'Malayalam'
  },
  {
    'id': 'mt',
    'text': 'Maltese'
  },
  {
    'id': 'gv',
    'text': 'Manx'
  },
  {
    'id': 'mr',
    'text': 'Marathi'
  },
  {
    'id': 'mas-TZ',
    'text': 'Masai (Tanzania)'
  },
  {
    'id': 'mas',
    'text': 'Masai'
  },
  {
    'id': 'mer',
    'text': 'Meru'
  },
  {
    'id': 'mfe',
    'text': 'Morisyen'
  },
  {
    'id': 'naq',
    'text': 'Nama'
  },
  {
    'id': 'ne',
    'text': 'Nepali'
  },
  {
    'id': 'nd',
    'text': 'North Ndebele'
  },
  {
    'id': 'nb',
    'text': 'Norwegian Bokmål'
  },
  {
    'id': 'nn',
    'text': 'Norwegian Nynorsk'
  },
  {
    'id': 'nyn',
    'text': 'Nyankole'
  },
  {
    'id': 'or',
    'text': 'Oriya'
  },
  {
    'id': 'om',
    'text': 'Oromo'
  },
  {
    'id': 'ps',
    'text': 'Pashto'
  },
  {
    'id': 'fa-AF',
    'text': 'Persian (Afghanistan)'
  },
  {
    'id': 'fa',
    'text': 'Persian'
  },
  {
    'id': 'pl',
    'text': 'Polish'
  },
  {
    'id': 'pt-BR',
    'text': 'Portuguese (Brazil)'
  },
  {
    'id': 'pt-GW',
    'text': 'Portuguese (Guinea-Bissau)'
  },
  {
    'id': 'pt-MZ',
    'text': 'Portuguese (Mozambique)'
  },
  {
    'id': 'pt',
    'text': 'Portuguese'
  },
  {
    'id': 'pa-Arab',
    'text': 'Punjabi (Arabic)'
  },
  {
    'id': 'pa-Arab-PK',
    'text': 'Punjabi (Arabic, Pakistan)'
  },
  {
    'id': 'pa-Guru',
    'text': 'Punjabi (Gurmukhi)'
  },
  {
    'id': 'pa-Guru-IN',
    'text': 'Punjabi (Gurmukhi, India)'
  },
  {
    'id': 'pa',
    'text': 'Punjabi'
  },
  {
    'id': 'ro-MD',
    'text': 'Romanian (Moldova)'
  },
  {
    'id': 'ro',
    'text': 'Romanian'
  },
  {
    'id': 'rm-CH',
    'text': 'Romansh (Switzerland)'
  },
  {
    'id': 'rm',
    'text': 'Romansh'
  },
  {
    'id': 'rof',
    'text': 'Rombo'
  },
  {
    'id': 'ru-MD',
    'text': 'Russian (Moldova)'
  },
  {
    'id': 'ru-UA',
    'text': 'Russian (Ukraine)'
  },
  {
    'id': 'ru',
    'text': 'Russian'
  },
  {
    'id': 'rwk',
    'text': 'Rwa'
  },
  {
    'id': 'saq',
    'text': 'Samburu'
  },
  {
    'id': 'sg',
    'text': 'Sango'
  },
  {
    'id': 'seh',
    'text': 'Sena'
  },
  {
    'id': 'sr-Cyrl-BA',
    'text': 'Serbian (Cyrillic, Bosnia and Herzegovina)'
  },
  {
    'id': 'sr-Cyrl-ME',
    'text': 'Serbian (Cyrillic, Montenegro)'
  },
  {
    'id': 'sr',
    'text': 'Serbian'
  },
  {
    'id': 'sn',
    'text': 'Shona'
  },
  {
    'id': 'ii',
    'text': 'Sichuan Yi'
  },
  {
    'id': 'si',
    'text': 'Sinhala'
  },
  {
    'id': 'sk',
    'text': 'Slovak'
  },
  {
    'id': 'sl',
    'text': 'Slovenian'
  },
  {
    'id': 'xog',
    'text': 'Soga'
  },
  {
    'id': 'so-DJ',
    'text': 'Somali (Djibouti)'
  },
  {
    'id': 'so-ET',
    'text': 'Somali (Ethiopia)'
  },
  {
    'id': 'so-KE',
    'text': 'Somali (Kenya)'
  },
  {
    'id': 'so',
    'text': 'Somali'
  },
  {
    'id': 'es-AR',
    'text': 'Spanish (Argentina)'
  },
  {
    'id': 'es-BO',
    'text': 'Spanish (Bolivia)'
  },
  {
    'id': 'es-CL',
    'text': 'Spanish (Chile)'
  },
  {
    'id': 'es-CO',
    'text': 'Spanish (Colombia)'
  },
  {
    'id': 'es-CR',
    'text': 'Spanish (Costa Rica)'
  },
  {
    'id': 'es-DO',
    'text': 'Spanish (Dominican Republic)'
  },
  {
    'id': 'es-EC',
    'text': 'Spanish (Ecuador)'
  },
  {
    'id': 'es-SV',
    'text': 'Spanish (El Salvador)'
  },
  {
    'id': 'es-GQ',
    'text': 'Spanish (Equatorial Guinea)'
  },
  {
    'id': 'es-GT',
    'text': 'Spanish (Guatemala)'
  },
  {
    'id': 'es-HN',
    'text': 'Spanish (Honduras)'
  },
  {
    'id': 'es-419',
    'text': 'Spanish (Latin America)'
  },
  {
    'id': 'es-MX',
    'text': 'Spanish (Mexico)'
  },
  {
    'id': 'es-NI',
    'text': 'Spanish (Nicaragua)'
  },
  {
    'id': 'es-PA',
    'text': 'Spanish (Panama)'
  },
  {
    'id': 'es-PY',
    'text': 'Spanish (Paraguay)'
  },
  {
    'id': 'es-PE',
    'text': 'Spanish (Peru)'
  },
  {
    'id': 'es-PR',
    'text': 'Spanish (Puerto Rico)'
  },
  {
    'id': 'es-US',
    'text': 'Spanish (United States)'
  },
  {
    'id': 'es-UY',
    'text': 'Spanish (Uruguay)'
  },
  {
    'id': 'es-VE',
    'text': 'Spanish (Venezuela)'
  },
  {
    'selected': 'selected',
    'id': 'es',
    'text': 'Spanish'
  },
  {
    'id': 'sw-KE',
    'text': 'Swahili (Kenya)'
  },
  {
    'id': 'sw',
    'text': 'Swahili'
  },
  {
    'id': 'sv-FI',
    'text': 'Swedish (Finland)'
  },
  {
    'id': 'sv',
    'text': 'Swedish'
  },
  {
    'id': 'gsw',
    'text': 'Swiss German'
  },
  {
    'id': 'shi',
    'text': 'Tachelhit'
  },
  {
    'id': 'dav',
    'text': 'Taita'
  },
  {
    'id': 'ta-LK',
    'text': 'Tamil (Sri Lanka)'
  },
  {
    'id': 'ta',
    'text': 'Tamil'
  },
  {
    'id': 'te',
    'text': 'Telugu'
  },
  {
    'id': 'teo-KE',
    'text': 'Teso (Kenya)'
  },
  {
    'id': 'teo',
    'text': 'Teso'
  },
  {
    'id': 'th',
    'text': 'Thai'
  },
  {
    'id': 'bo-IN',
    'text': 'Tibetan (India)'
  },
  {
    'id': 'bo',
    'text': 'Tibetan'
  },
  {
    'id': 'ti-ER',
    'text': 'Tigrinya (Eritrea)'
  },
  {
    'id': 'ti',
    'text': 'Tigrinya'
  },
  {
    'id': 'to',
    'text': 'Tonga'
  },
  {
    'id': 'tr',
    'text': 'Turkish'
  },
  {
    'id': 'uk',
    'text': 'Ukrainian'
  },
  {
    'id': 'ur-IN',
    'text': 'Urdu (India)'
  },
  {
    'id': 'ur',
    'text': 'Urdu'
  },
  {
    'id': 'uz-Arab-AF',
    'text': 'Uzbek (Arabic, Afghanistan)'
  },
  {
    'id': 'uz',
    'text': 'Uzbek'
  },
  {
    'id': 'vi',
    'text': 'Vietnamese'
  },
  {
    'id': 'vun',
    'text': 'Vunjo'
  },
  {
    'id': 'cy',
    'text': 'Welsh'
  },
  {
    'id': 'yo',
    'text': 'Yoruba'
  },
  {
    'id': 'zu',
    'text': 'Zulu'
  }
]

export const LOCALE_COUNTRIES_MAP = {
  'aa': 'ET',
  'ab': 'GE',
  'abr': 'GH',
  'ace': 'ID',
  'ach': 'UG',
  'ada': 'GH',
  'ady': 'RU',
  'ae': 'IR',
  'aeb': 'TN',
  'af': 'ZA',
  'agq': 'CM',
  'aho': 'IN',
  'ak': 'GH',
  'akk': 'IQ',
  'aln': 'XK',
  'alt': 'RU',
  'am': 'ET',
  'amo': 'NG',
  'aoz': 'ID',
  'apd': 'TG',
  'ar': 'EG',
  'arc': 'IR',
  'arc-Nbat': 'JO',
  'arc-Palm': 'SY',
  'arn': 'CL',
  'aro': 'BO',
  'arq': 'DZ',
  'ary': 'MA',
  'arz': 'EG',
  'as': 'IN',
  'asa': 'TZ',
  'ase': 'US',
  'ast': 'ES',
  'atj': 'CA',
  'av': 'RU',
  'awa': 'IN',
  'ay': 'BO',
  'az': 'AZ',
  'az-Arab': 'IR',
  'ba': 'RU',
  'bal': 'PK',
  'ban': 'ID',
  'bap': 'NP',
  'bar': 'AT',
  'bas': 'CM',
  'bax': 'CM',
  'bbc': 'ID',
  'bbj': 'CM',
  'bci': 'CI',
  'be': 'BY',
  'bej': 'SD',
  'bem': 'ZM',
  'bew': 'ID',
  'bez': 'TZ',
  'bfd': 'CM',
  'bfq': 'IN',
  'bft': 'PK',
  'bfy': 'IN',
  'bg': 'BG',
  'bgc': 'IN',
  'bgn': 'PK',
  'bgx': 'TR',
  'bhb': 'IN',
  'bhi': 'IN',
  'bhk': 'PH',
  'bho': 'IN',
  'bi': 'VU',
  'bik': 'PH',
  'bin': 'NG',
  'bjj': 'IN',
  'bjn': 'ID',
  'bjt': 'SN',
  'bkm': 'CM',
  'bku': 'PH',
  'blt': 'VN',
  'bm': 'ML',
  'bmq': 'ML',
  'bn': 'BD',
  'bo': 'CN',
  'bpy': 'IN',
  'bqi': 'IR',
  'bqv': 'CI',
  'br': 'FR',
  'bra': 'IN',
  'brh': 'PK',
  'brx': 'IN',
  'bs': 'BA',
  'bsq': 'LR',
  'bss': 'CM',
  'bto': 'PH',
  'btv': 'PK',
  'bua': 'RU',
  'buc': 'YT',
  'bug': 'ID',
  'bum': 'CM',
  'bvb': 'GQ',
  'byn': 'ER',
  'byv': 'CM',
  'bze': 'ML',
  'ca': 'ES',
  'cch': 'NG',
  'ccp': 'BD',
  'ce': 'RU',
  'ceb': 'PH',
  'cgg': 'UG',
  'ch': 'GU',
  'chk': 'FM',
  'chm': 'RU',
  'cho': 'US',
  'chp': 'CA',
  'chr': 'US',
  'cja': 'KH',
  'cjm': 'VN',
  'ckb': 'IQ',
  'co': 'FR',
  'cop': 'EG',
  'cps': 'PH',
  'cr': 'CA',
  'crh': 'UA',
  'crj': 'CA',
  'crk': 'CA',
  'crl': 'CA',
  'crm': 'CA',
  'crs': 'SC',
  'cs': 'CZ',
  'csb': 'PL',
  'csw': 'CA',
  'ctd': 'MM',
  'cu': 'RU',
  'cu-Glag': 'BG',
  'cv': 'RU',
  'cy': 'GB',
  'da': 'DK',
  'dak': 'US',
  'dar': 'RU',
  'dav': 'KE',
  'dcc': 'IN',
  'de': 'DE',
  'den': 'CA',
  'dgr': 'CA',
  'dje': 'NE',
  'dnj': 'CI',
  'doi': 'IN',
  'dsb': 'DE',
  'dtm': 'ML',
  'dtp': 'MY',
  'dty': 'NP',
  'dua': 'CM',
  'dv': 'MV',
  'dyo': 'SN',
  'dyu': 'BF',
  'dz': 'BT',
  'ebu': 'KE',
  'ee': 'GH',
  'efi': 'NG',
  'egl': 'IT',
  'egy': 'EG',
  'eky': 'MM',
  'el': 'GR',
  'en': 'US',
  'en-Shaw': 'GB',
  'es': 'ES',
  'eo': 'EO',
  'esu': 'US',
  'et': 'EE',
  'ett': 'IT',
  'eu': 'ES',
  'ewo': 'CM',
  'ext': 'ES',
  'fa': 'IR',
  'fan': 'GQ',
  'ff': 'SN',
  'ff-Adlm': 'GN',
  'ffm': 'ML',
  'fi': 'FI',
  'fia': 'SD',
  'fil': 'PH',
  'fit': 'SE',
  'fj': 'FJ',
  'fo': 'FO',
  'fon': 'BJ',
  'fr': 'FR',
  'frc': 'US',
  'frp': 'FR',
  'frr': 'DE',
  'frs': 'DE',
  'fub': 'CM',
  'fud': 'WF',
  'fuf': 'GN',
  'fuq': 'NE',
  'fur': 'IT',
  'fuv': 'NG',
  'fvr': 'SD',
  'fy': 'NL',
  'ga': 'IE',
  'gaa': 'GH',
  'gag': 'MD',
  'gan': 'CN',
  'gay': 'ID',
  'gbm': 'IN',
  'gbz': 'IR',
  'gcr': 'GF',
  'gd': 'GB',
  'gez': 'ET',
  'ggn': 'NP',
  'gil': 'KI',
  'gjk': 'PK',
  'gju': 'PK',
  'gl': 'ES',
  'glk': 'IR',
  'gn': 'PY',
  'gom': 'IN',
  'gon': 'IN',
  'gor': 'ID',
  'gos': 'NL',
  'got': 'UA',
  'grc': 'CY',
  'grc-Linb': 'GR',
  'grt': 'IN',
  'gsw': 'CH',
  'gu': 'IN',
  'gub': 'BR',
  'guc': 'CO',
  'gur': 'GH',
  'guz': 'KE',
  'gv': 'IM',
  'gvr': 'NP',
  'gwi': 'CA',
  'ha': 'NG',
  'hak': 'CN',
  'haw': 'US',
  'haz': 'AF',
  'he': 'IL',
  'hi': 'IN',
  'hif': 'FJ',
  'hil': 'PH',
  'hlu': 'TR',
  'hmd': 'CN',
  'hnd': 'PK',
  'hne': 'IN',
  'hnj': 'LA',
  'hnn': 'PH',
  'hno': 'PK',
  'ho': 'PG',
  'hoc': 'IN',
  'hoj': 'IN',
  'hr': 'HR',
  'hsb': 'DE',
  'hsn': 'CN',
  'ht': 'HT',
  'hu': 'HU',
  'hy': 'AM',
  'hz': 'NA',
  'ia': 'FR',
  'iba': 'MY',
  'ibb': 'NG',
  'id': 'ID',
  'ife': 'TG',
  'ig': 'NG',
  'ii': 'CN',
  'ik': 'US',
  'ikt': 'CA',
  'ilo': 'PH',
  'in': 'ID',
  'inh': 'RU',
  'is': 'IS',
  'it': 'IT',
  'iu': 'CA',
  'iw': 'IL',
  'izh': 'RU',
  'ja': 'JP',
  'jam': 'JM',
  'jgo': 'CM',
  'ji': 'UA',
  'jmc': 'TZ',
  'jml': 'NP',
  'jut': 'DK',
  'jv': 'ID',
  'jw': 'ID',
  'ka': 'GE',
  'kaa': 'UZ',
  'kab': 'DZ',
  'kac': 'MM',
  'kaj': 'NG',
  'kam': 'KE',
  'kao': 'ML',
  'kbd': 'RU',
  'kby': 'NE',
  'kcg': 'NG',
  'kck': 'ZW',
  'kde': 'TZ',
  'kdh': 'TG',
  'kdt': 'TH',
  'kea': 'CV',
  'ken': 'CM',
  'kfo': 'CI',
  'kfr': 'IN',
  'kfy': 'IN',
  'kg': 'CD',
  'kge': 'ID',
  'kgp': 'BR',
  'kha': 'IN',
  'khb': 'CN',
  'khn': 'IN',
  'khq': 'ML',
  'kht': 'IN',
  'khw': 'PK',
  'ki': 'KE',
  'kiu': 'TR',
  'kj': 'NA',
  'kjg': 'LA',
  'kk': 'KZ',
  'kk-Arab': 'CN',
  'kkj': 'CM',
  'kl': 'GL',
  'kln': 'KE',
  'km': 'KH',
  'kmb': 'AO',
  'kn': 'IN',
  'knf': 'SN',
  'ko': 'KR',
  'koi': 'RU',
  'kok': 'IN',
  'kos': 'FM',
  'kpe': 'LR',
  'krc': 'RU',
  'kri': 'SL',
  'krj': 'PH',
  'krl': 'RU',
  'kru': 'IN',
  'ks': 'IN',
  'ksb': 'TZ',
  'ksf': 'CM',
  'ksh': 'DE',
  'ku': 'TR',
  'ku-Arab': 'IQ',
  'kum': 'RU',
  'kv': 'RU',
  'kvr': 'ID',
  'kvx': 'PK',
  'kw': 'GB',
  'kxm': 'TH',
  'kxp': 'PK',
  'ky': 'KG',
  'ky-Arab': 'CN',
  'ky-Latn': 'TR',
  'la': 'VA',
  'lab': 'GR',
  'lad': 'IL',
  'lag': 'TZ',
  'lah': 'PK',
  'laj': 'UG',
  'lb': 'LU',
  'lbe': 'RU',
  'lbw': 'ID',
  'lcp': 'CN',
  'lep': 'IN',
  'lez': 'RU',
  'lg': 'UG',
  'li': 'NL',
  'lif': 'NP',
  'lif-Limb': 'IN',
  'lij': 'IT',
  'lis': 'CN',
  'ljp': 'ID',
  'lki': 'IR',
  'lkt': 'US',
  'lmn': 'IN',
  'lmo': 'IT',
  'ln': 'CD',
  'lo': 'LA',
  'lol': 'CD',
  'loz': 'ZM',
  'lrc': 'IR',
  'lt': 'LT',
  'ltg': 'LV',
  'lu': 'CD',
  'lua': 'CD',
  'luo': 'KE',
  'luy': 'KE',
  'luz': 'IR',
  'lv': 'LV',
  'lwl': 'TH',
  'lzh': 'CN',
  'lzz': 'TR',
  'mad': 'ID',
  'maf': 'CM',
  'mag': 'IN',
  'mai': 'IN',
  'mak': 'ID',
  'man': 'GM',
  'man-Nkoo': 'GN',
  'mas': 'KE',
  'maz': 'MX',
  'mdf': 'RU',
  'mdh': 'PH',
  'mdr': 'ID',
  'men': 'SL',
  'mer': 'KE',
  'mfa': 'TH',
  'mfe': 'MU',
  'mg': 'MG',
  'mgh': 'MZ',
  'mgo': 'CM',
  'mgp': 'NP',
  'mgy': 'TZ',
  'mh': 'MH',
  'mi': 'NZ',
  'min': 'ID',
  'mis': 'IQ',
  'mk': 'MK',
  'ml': 'IN',
  'mls': 'SD',
  'mn': 'MN',
  'mn-Mong': 'CN',
  'mni': 'IN',
  'mnw': 'MM',
  'moe': 'CA',
  'moh': 'CA',
  'mos': 'BF',
  'mr': 'IN',
  'mrd': 'NP',
  'mrj': 'RU',
  'mro': 'BD',
  'ms': 'MY',
  'mt': 'MT',
  'mtr': 'IN',
  'mua': 'CM',
  'mus': 'US',
  'mvy': 'PK',
  'mwk': 'ML',
  'mwr': 'IN',
  'mwv': 'ID',
  'mxc': 'ZW',
  'my': 'MM',
  'myv': 'RU',
  'myx': 'UG',
  'myz': 'IR',
  'mzn': 'IR',
  'na': 'NR',
  'nan': 'CN',
  'nap': 'IT',
  'naq': 'NA',
  'nb': 'NO',
  'nch': 'MX',
  'nd': 'ZW',
  'ndc': 'MZ',
  'nds': 'DE',
  'ne': 'NP',
  'new': 'NP',
  'ng': 'NA',
  'ngl': 'MZ',
  'nhe': 'MX',
  'nhw': 'MX',
  'nij': 'ID',
  'niu': 'NU',
  'njo': 'IN',
  'nl': 'NL',
  'nmg': 'CM',
  'nn': 'NO',
  'nnh': 'CM',
  'no': 'NO',
  'nod': 'TH',
  'noe': 'IN',
  'non': 'SE',
  'nqo': 'GN',
  'nr': 'ZA',
  'nsk': 'CA',
  'nso': 'ZA',
  'nus': 'SS',
  'nv': 'US',
  'nxq': 'CN',
  'ny': 'MW',
  'nym': 'TZ',
  'nyn': 'UG',
  'nzi': 'GH',
  'oc': 'FR',
  'om': 'ET',
  'or': 'IN',
  'os': 'GE',
  'osa': 'US',
  'otk': 'MN',
  'pa': 'IN',
  'pa-Arab': 'PK',
  'pag': 'PH',
  'pal': 'IR',
  'pal-Phlp': 'CN',
  'pam': 'PH',
  'pap': 'AW',
  'pau': 'PW',
  'pcd': 'FR',
  'pcm': 'NG',
  'pdc': 'US',
  'pdt': 'CA',
  'peo': 'IR',
  'pfl': 'DE',
  'phn': 'LB',
  'pka': 'IN',
  'pko': 'KE',
  'pl': 'PL',
  'pms': 'IT',
  'pnt': 'GR',
  'pon': 'FM',
  'pra': 'PK',
  'prd': 'IR',
  'ps': 'AF',
  'pt': 'BR',
  'puu': 'GA',
  'qu': 'PE',
  'quc': 'GT',
  'qug': 'EC',
  'raj': 'IN',
  'rcf': 'RE',
  'rej': 'ID',
  'rgn': 'IT',
  'ria': 'IN',
  'rif': 'MA',
  'rjs': 'NP',
  'rkt': 'BD',
  'rm': 'CH',
  'rmf': 'FI',
  'rmo': 'CH',
  'rmt': 'IR',
  'rmu': 'SE',
  'rn': 'BI',
  'rng': 'MZ',
  'ro': 'RO',
  'rob': 'ID',
  'rof': 'TZ',
  'rtm': 'FJ',
  'ru': 'RU',
  'rue': 'UA',
  'rug': 'SB',
  'rw': 'RW',
  'rwk': 'TZ',
  'ryu': 'JP',
  'sa': 'IN',
  'saf': 'GH',
  'sah': 'RU',
  'saq': 'KE',
  'sas': 'ID',
  'sat': 'IN',
  'sav': 'SN',
  'saz': 'IN',
  'sbp': 'TZ',
  'sc': 'IT',
  'sck': 'IN',
  'scn': 'IT',
  'sco': 'GB',
  'scs': 'CA',
  'sd': 'PK',
  'sd-Deva': 'IN',
  'sd-Khoj': 'IN',
  'sd-Sind': 'IN',
  'sdc': 'IT',
  'sdh': 'IR',
  'se': 'NO',
  'sef': 'CI',
  'seh': 'MZ',
  'sei': 'MX',
  'ses': 'ML',
  'sg': 'CF',
  'sga': 'IE',
  'sgs': 'LT',
  'shi': 'MA',
  'shn': 'MM',
  'si': 'LK',
  'sid': 'ET',
  'sk': 'SK',
  'skr': 'PK',
  'sl': 'SI',
  'sli': 'PL',
  'sly': 'ID',
  'sm': 'WS',
  'sma': 'SE',
  'smj': 'SE',
  'smn': 'FI',
  'smp': 'IL',
  'sms': 'FI',
  'sn': 'ZW',
  'snk': 'ML',
  'so': 'SO',
  'sou': 'TH',
  'sq': 'AL',
  'sr': 'RS',
  'srb': 'IN',
  'srn': 'SR',
  'srr': 'SN',
  'srx': 'IN',
  'ss': 'ZA',
  'ssy': 'ER',
  'st': 'ZA',
  'stq': 'DE',
  'su': 'ID',
  'suk': 'TZ',
  'sus': 'GN',
  'sv': 'SE',
  'sw': 'TZ',
  'swb': 'YT',
  'swc': 'CD',
  'swg': 'DE',
  'swv': 'IN',
  'sxn': 'ID',
  'syl': 'BD',
  'syr': 'IQ',
  'szl': 'PL',
  'ta': 'IN',
  'taj': 'NP',
  'tbw': 'PH',
  'tcy': 'IN',
  'tdd': 'CN',
  'tdg': 'NP',
  'tdh': 'NP',
  'te': 'IN',
  'tem': 'SL',
  'teo': 'UG',
  'tet': 'TL',
  'tg': 'TJ',
  'tg-Arab': 'PK',
  'th': 'TH',
  'thl': 'NP',
  'thq': 'NP',
  'thr': 'NP',
  'ti': 'ET',
  'tig': 'ER',
  'tiv': 'NG',
  'tk': 'TM',
  'tkl': 'TK',
  'tkr': 'AZ',
  'tkt': 'NP',
  'tl': 'PH',
  'tly': 'AZ',
  'tmh': 'NE',
  'tn': 'ZA',
  'to': 'TO',
  'tog': 'MW',
  'tpi': 'PG',
  'tr': 'TR',
  'tru': 'TR',
  'trv': 'TW',
  'ts': 'ZA',
  'tsd': 'GR',
  'tsf': 'NP',
  'tsg': 'PH',
  'tsj': 'BT',
  'tt': 'RU',
  'ttj': 'UG',
  'tts': 'TH',
  'ttt': 'AZ',
  'tum': 'MW',
  'tvl': 'TV',
  'twq': 'NE',
  'txg': 'CN',
  'ty': 'PF',
  'tyv': 'RU',
  'tzm': 'MA',
  'udm': 'RU',
  'ug': 'CN',
  'ug-Cyrl': 'KZ',
  'uga': 'SY',
  'uk': 'UA',
  'uli': 'FM',
  'umb': 'AO',
  'und': 'US',
  'unr': 'IN',
  'unr-Deva': 'NP',
  'unx': 'IN',
  'ur': 'PK',
  'uz': 'UZ',
  'uz-Arab': 'AF',
  'vai': 'LR',
  've': 'ZA',
  'vec': 'IT',
  'vep': 'RU',
  'vi': 'VN',
  'vic': 'SX',
  'vls': 'BE',
  'vmf': 'DE',
  'vmw': 'MZ',
  'vot': 'RU',
  'vro': 'EE',
  'vun': 'TZ',
  'wa': 'BE',
  'wae': 'CH',
  'wal': 'ET',
  'war': 'PH',
  'wbp': 'AU',
  'wbq': 'IN',
  'wbr': 'IN',
  'wls': 'WF',
  'wni': 'KM',
  'wo': 'SN',
  'wtm': 'IN',
  'wuu': 'CN',
  'xav': 'BR',
  'xcr': 'TR',
  'xh': 'ZA',
  'xlc': 'TR',
  'xld': 'TR',
  'xmf': 'GE',
  'xmn': 'CN',
  'xmr': 'SD',
  'xna': 'SA',
  'xnr': 'IN',
  'xog': 'UG',
  'xpr': 'IR',
  'xsa': 'YE',
  'xsr': 'NP',
  'yao': 'MZ',
  'yap': 'FM',
  'yav': 'CM',
  'ybb': 'CM',
  'yo': 'NG',
  'yrl': 'BR',
  'yua': 'MX',
  'yue': 'HK',
  'yue-Hans': 'CN',
  'za': 'CN',
  'zag': 'SD',
  'zdj': 'KM',
  'zea': 'NL',
  'zgh': 'MA',
  'zh': 'CN',
  'zh-Bopo': 'TW',
  'zh-Hanb': 'TW',
  'zh-Hant': 'TW',
  'zlm': 'TG',
  'zmi': 'MY',
  'zu': 'ZA',
  'zza': 'TR'
}

export const COUNTRY_LOCALES_MAP = {
  ad: 'ca',
  ae: 'ar',
  af: 'fa,ps',
  ag: 'en',
  ai: 'en',
  al: 'sq',
  am: 'hy',
  an: 'nl,en',
  ao: 'pt',
  aq: 'en',
  ar: 'es',
  as: 'en,sm',
  at: 'de',
  au: 'en',
  aw: 'nl,pap',
  ax: 'sv',
  az: 'az',
  ba: 'bs,hr,sr',
  bb: 'en',
  bd: 'bn',
  be: 'nl,fr,de',
  bf: 'fr',
  bg: 'bg',
  bh: 'ar',
  bi: 'fr',
  bj: 'fr',
  bl: 'fr',
  bm: 'en',
  bn: 'ms',
  bo: 'es,qu,ay',
  br: 'pt',
  bq: 'nl,en',
  bs: 'en',
  bt: 'dz',
  bv: 'no',
  bw: 'en,tn',
  by: 'be,ru',
  bz: 'en',
  ca: 'en,fr',
  cc: 'en',
  cd: 'fr',
  cf: 'fr',
  cg: 'fr',
  ch: 'de,fr,it,rm',
  ci: 'fr',
  ck: 'en,rar',
  cl: 'es-CL',
  cm: 'fr,en',
  cn: 'zh',
  co: 'es-CO',
  cr: 'es',
  cu: 'es',
  cv: 'pt',
  cw: 'nl',
  cx: 'en',
  cy: 'el,tr',
  cz: 'cs',
  de: 'de',
  dj: 'fr,ar,so',
  dk: 'da',
  dm: 'en',
  do: 'es',
  dz: 'ar',
  ec: 'es',
  ee: 'et',
  eg: 'ar',
  eh: 'ar,es,fr',
  er: 'ti,ar,en',
  es: 'es,ast,ca,eu,gl',
  et: 'am,om',
  fi: 'fi,sv,se',
  fj: 'en',
  fk: 'en',
  fm: 'en',
  fo: 'fo',
  fr: 'fr',
  ga: 'fr',
  gb: 'en,ga,cy,gd,kw',
  gd: 'en',
  ge: 'ka',
  gf: 'fr',
  gg: 'en',
  gh: 'en',
  gi: 'en',
  gl: 'kl,da',
  gm: 'en',
  gn: 'fr',
  gp: 'fr',
  gq: 'es,fr,pt',
  gr: 'el',
  gs: 'en',
  gt: 'es',
  gu: 'en,ch',
  gw: 'pt',
  gy: 'en',
  hk: 'zh,en',
  hm: 'en',
  hn: 'es',
  hr: 'hr',
  ht: 'fr,ht',
  hu: 'hu',
  id: 'id',
  ie: 'en,ga',
  il: 'he',
  im: 'en',
  in: 'hi,en',
  io: 'en',
  iq: 'ar,ku',
  ir: 'fa',
  is: 'is',
  it: 'it,de,fr',
  je: 'en',
  jm: 'en',
  jo: 'ar',
  jp: 'ja',
  ke: 'sw,en',
  kg: 'ky,ru',
  kh: 'km',
  ki: 'en',
  km: 'ar,fr',
  kn: 'en',
  kp: 'ko',
  kr: 'ko,en',
  kw: 'ar',
  ky: 'en',
  kz: 'kk,ru',
  la: 'lo',
  lb: 'ar,fr',
  lc: 'en',
  li: 'de',
  lk: 'si,ta',
  lr: 'en',
  ls: 'en,st',
  lt: 'lt',
  lu: 'lb,fr,de',
  lv: 'lv',
  ly: 'ar',
  ma: 'ar',
  mc: 'fr',
  md: 'ru,uk,ro',
  me: 'srp,sq,bs,hr,sr',
  mf: 'fr',
  mg: 'mg,fr',
  mh: 'en,mh',
  mk: 'mk',
  ml: 'fr',
  mm: 'my',
  mn: 'mn',
  mo: 'zh,en,pt',
  mp: 'ch',
  mq: 'fr',
  mr: 'ar,fr',
  ms: 'en',
  mt: 'mt,en',
  mu: 'mfe,fr,en',
  mv: 'dv',
  mw: 'en,ny',
  mx: 'es-MX',
  my: 'ms,zh,en',
  mz: 'pt',
  na: 'en,sf,de',
  nc: 'fr',
  ne: 'fr',
  nf: 'en,pih',
  ng: 'en',
  ni: 'es',
  nl: 'nl',
  no: 'nb,nn,no,se',
  np: 'ne',
  nr: 'na,en',
  nu: 'niu,en',
  nz: 'en,mi',
  om: 'ar',
  pa: 'es',
  pe: 'es',
  pf: 'fr',
  pg: 'en,tpi,ho',
  ph: 'en,tl',
  pk: 'en,ur',
  pl: 'pl',
  pm: 'fr',
  pn: 'en,pih',
  pr: 'es,en',
  ps: 'ar,he',
  pt: 'pt',
  pw: 'en,pau,ja,sov,tox',
  py: 'es,gn',
  qa: 'ar',
  re: 'fr',
  ro: 'ro',
  rs: 'sr',
  ru: 'ru',
  rw: 'rw,fr,en',
  sa: 'ar',
  sb: 'en',
  sc: 'fr,en,crs',
  sd: 'ar,en',
  se: 'sv',
  sg: 'en,ms,zh,ta',
  sh: 'en',
  si: 'sl',
  sj: 'no',
  sk: 'sk',
  sl: 'en',
  sm: 'it',
  sn: 'fr',
  so: 'so,ar',
  sr: 'nl',
  st: 'pt',
  ss: 'en',
  sv: 'es',
  sx: 'nl,en',
  sy: 'ar',
  sz: 'en,ss',
  tc: 'en',
  td: 'fr,ar',
  tf: 'fr',
  tg: 'fr',
  th: 'th',
  tj: 'tg,ru',
  tk: 'tkl,en,sm',
  tl: 'pt,tet',
  tm: 'tk',
  tn: 'ar',
  to: 'en',
  tr: 'tr',
  tt: 'en',
  tv: 'en',
  tw: 'zh',
  tz: 'sw,en',
  ua: 'uk',
  ug: 'en,sw',
  um: 'en',
  us: 'en,es',
  uy: 'es',
  uz: 'uz,kaa',
  va: 'it',
  vc: 'en',
  ve: 'es',
  vg: 'en',
  vi: 'en',
  vn: 'vi',
  vu: 'bi,en,fr',
  wf: 'fr',
  ws: 'sm,en',
  ye: 'ar',
  yt: 'fr',
  za: 'zu,xh,af,st,tn,en',
  zm: 'en',
  zw: 'en,sn,nd'
}
