<template>
  <div class="vue-daterange-picker">
    <div class="reportrange-text px-0" @click="togglePicker(null, true)">
      <slot
              name="input"
              :startDate="start"
              :endDate="end"
              :ranges="ranges"
      >
        <i class="glyphicon glyphicon-calendar fa fa-calendar"/>&nbsp;
        <span>{{rangeText}}</span>
        <b class="caret"/>
      </slot>
      <mdb-icon fad icon="calendar-alt"/>
    </div>
    <transition name="slide-fade" mode="out-in">
      <div
              class="daterangepicker dropdown-menu ltr"
              :class="pickerStyles"
              v-if="open"
              v-on-clickaway="clickAway"
      >
        <div class="calendars row no-gutters">
          <slot name="ranges" v-if="ranges !== false">
            <calendar-ranges class="col-12 col-md-auto"
                             @clickRange="clickRange"
                             :ranges="ranges"
                             :days-up-to-today-placeholder="locale.daysUpToTodayPlaceholder"
                             :selected="{ startDate: start, endDate: end }"
            />
          </slot>

          <div class="drp-calendar col left" :class="{single: singleDatePicker}">
            <div class="daterangepicker_input d-none d-sm-block" v-if="false">
              <input class="input-mini form-control" type="text" name="daterangepicker_start"
                     :value="startText"/>
              <i class="fa fa-calendar glyphicon glyphicon-calendar"/>
            </div>
            <div class="calendar-table">
              <calendar :monthDate="monthDate"
                        :locale-data="locale"
                        :start="start" :end="end"
                        :minDate="min" :maxDate="max"
                        :show-dropdowns="showDropdowns"

                        @change-month="changeLeftMonth"
                        :date-format="dateFormatFn"

                        @dateClick="dateClick" @hoverDate="hoverDate"
                        :showWeekNumbers="showWeekNumbers"
              />
            </div>
            <calendar-time v-if="timePicker"
                           @update="onUpdateStartTime"
                           :miniute-increment="timePickerIncrement"
                           :hour24="timePicker24Hour"
                           :second-picker="timePickerSeconds"
                           :current-time="start"
            />
          </div>

          <div class="drp-calendar col right" v-if="!singleDatePicker">
            <div class="daterangepicker_input" v-if="false">
              <input class="input-mini form-control" type="text" name="daterangepicker_end"
                     :value="endText"/>
              <i class="fa fa-calendar glyphicon glyphicon-calendar"/>
            </div>
            <div class="calendar-table">
              <calendar :monthDate="nextMonthDate"
                        :locale-data="locale"
                        :start="start" :end="end"
                        :minDate="min" :maxDate="max"
                        :show-dropdowns="showDropdowns"

                        @change-month="changeRightMonth"
                        :date-format="dateFormatFn"

                        @dateClick="dateClick" @hoverDate="hoverDate"
                        :showWeekNumbers="showWeekNumbers"
              />
            </div>
            <calendar-time v-if="timePicker"
                           @update="onUpdateEndTime"
                           :miniute-increment="timePickerIncrement"
                           :hour24="timePicker24Hour"
                           :second-picker="timePickerSeconds"
                           :current-time="end"
            />
          </div>
        </div>

        <div class="drp-buttons d-flex align-items-baseline justify-content-between" v-if="!autoApply">
          <span class="ml-2 drp-selected">{{rangeText}}</span>
          <section class="mr-2">
            <button
                    v-if="allowClear"
                    class="clearBtn btn btn-sm btn-default"
                    type="button"
                    @click="clearRange"
            >{{locale.clearLabel}}
            </button>
            <button
                    class="cancelBtn btn btn-sm btn-default"
                    type="button"
                    @click="clickAway"
            >{{locale.cancelLabel}}
            </button>
            <mdb-btn class='applyBtn btn btn-sm btn-success' :disabled="in_selection || loading" @click="clickedApply">
              <span v-show='loading' class="spinner-border spinner-border-sm mr-1 mb-1" role="status" aria-hidden="true"/> {{locale.applyLabel}}
            </mdb-btn>
          </section>
        </div>

      </div>
    </transition>
  </div>
</template>
<script>
import CalendarRanges from '@/components/date-range-picker/CalendarRanges'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css'
import { localeData, nextMonth } from 'vue2-daterange-picker/src/components/util'

export default {
  extends: DateRangePicker,
  components: {
    CalendarRanges
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: false
    }
  },
  data () {
    let data = { locale: localeData(this.localeData) }

    let startDate = null
    let endDate = null
    if (!this.singleDatePicker) {
      startDate = this.dateRange.startDate || null
      endDate = this.dateRange.endDate || null
    } else {
      if (typeof this.dateRange === 'object' && this.dateRange.hasOwnProperty('startDate') && this.dateRange.hasOwnProperty('endDate')) {
        startDate = this.dateRange.startDate || null
        endDate = this.dateRange.endDate || null
      } else {
        startDate = this.dateRange || null
        endDate = null
      }
    }

    data.monthDate = startDate ? new Date(startDate) : new Date()
    data.nextMonthDate = nextMonth(data.monthDate)
    data.start = startDate ? new Date(startDate) : null
    if (this.singleDatePicker) {
      // ignore endDate for singleDatePicker
      data.end = data.start
    } else {
      data.end = endDate ? new Date(endDate) : null
    }
    data.in_selection = false
    data.open = false

    // update day names order to firstDay
    if (data.locale.firstDay !== 0) {
      let iterator = data.locale.firstDay
      while (iterator > 0) {
        data.locale.daysOfWeek.push(data.locale.daysOfWeek.shift())
        iterator--
      }
    }
    return data
  },
  methods: {
    togglePicker (value, event) {
      if (!this.disabled) {
        if (typeof value === 'boolean') {
          this.open = value
        } else {
          this.open = !this.open
        }
      }

      if (event === true && !this.disabled) {
        this.$emit('toggle', this.open, this.togglePicker)
      }
    },
    clickedApply () {
      this.togglePicker(false, true)
      if (!this.singleDatePicker) {
        this.$emit('update', { startDate: this.start, endDate: this.end })
      } else {
        if (typeof this.dateRange === 'object' && this.dateRange.hasOwnProperty('startDate') && this.dateRange.hasOwnProperty('endDate')) {
          this.$emit('update', { startDate: this.start, endDate: this.end })
        } else {
          this.$emit('update', this.start)
        }
      }
    },
    clearRange () {
      this.togglePicker(false, true)
      this.dateRange.startDate = null
      this.dateRange.endDate = null
      this.$emit('update', { startDate: null, endDate: null })
    }
  }
}
</script>

<style>

  body .daterangepicker {
    position: absolute!important;
    z-index: 3006;
  }
    body .daterangepicker_inline .daterangepicker {
    position: inherit!important;
  }

    body .daterangepicker_inline  .daterangepicker.openscenter.show-calendar {
    top: 25px!important;
  }

  .daterangepicker_inline .form-control {
    padding-left: 0;
  }

  .reportrange-text {
  border:none!important
  }

.dateRangeInput {
    margin-top: 0!important;
    cursor: pointer;
    margin-right: 0!important;
  margin-bottom: 0!important;
   width: 230px;
}
div div .cancelBtn.btn {
    box-shadow: none;
    border: none !important;
    background-color: transparent!important;
    color: #4285f4!important;
    box-shadow: none!important;
  }
  div div .cancelBtn.btn:hover {
    box-shadow: none;
    border: none !important;
    text-decoration: underline;
    background-color: #fff!important;
  }
  div div .clearBtn.btn {
    box-shadow: none;
    border: none !important;
    background-color: transparent!important;
    color: red!important;
    box-shadow: none!important;
  }
  div div .clearBtn.btn:hover {
    box-shadow: none;
    border: none !important;
    text-decoration: underline;
    background-color: #fff!important;
  }
  .applyBtn {
    background-color: #4285f4!important;
    color: #fff;
  }
  .applyBtn:hover {
    background-color: #FF6600!important;
  }
  .daterangepicker .ranges li, .daterangepicker .calendar-table td, .daterangepicker .calendar-table th, .daterangepicker .drp-selected, .daterangepicker .drp-buttons .btn{
    font-size: 0.9rem;
  }
.vue-daterange-picker i {
  position: absolute;
  top: 15px;
  right: 3px;
}

  @media(min-width:769px){
  .daterangepicker.openscenter.show-calendar{
    top: 75px!important;
    right: 2px!important;
    left: auto!important;
    transform:none!important;
    /*min-width: 638px;*/

}
  }
@media(max-width:600px){
  .ranges ul{
    display: flex;
    flex-wrap: wrap;
  }
  .ranges ul li{
    width: 50%;
  }
}

  @media (min-width: 564px){
    .daterangepicker .ranges ul {
      width: 185px;
}
  .calendars{
    flex-wrap: nowrap !important;
  }
}
@media (max-width: 991px){
  .date-range .daterangepicker.openscenter.show-calendar{
    right: auto!important;
    transform: translate(-10%);
  }
}

  @media (max-width: 564px){
     .date-range .daterangepicker.openscenter.show-calendar{
    right: auto!important;
    transform: translate(-15%);
    top:46px!important;
  }
  }

.vue-daterange-picker {
  position: relative;
  align-self: flex-start;
}
.daterangepicker{
top: 40px;
}

</style>

<style scoped>

>>> .daterangepicker .ranges li:hover {
  background-color: #3fa2f7 !important;
  color: #fff !important;
}

/*Position TOP*/

.daterangepicker_top .daterangepicker.openscenter.show-calendar {
  top: -300px!important;
}

.daterangepicker_top .daterangepicker.openscenter:after, .daterangepicker.openscenter:before {
  content: none;
}
</style>
