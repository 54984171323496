import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import { DomainService } from '@/common/domain.service'
import { AuthModule } from '@/store/auth.module'
import { WhiteLabel } from '@/models/white-label'

export const CONVERSION_TRACKING_NAMES = [
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'adgroupid',
  'network',
  'device',
  'adposition',
  'matchtype',
  'placement',
  'gclid',
  'msclkid',
  '_uetmsclkid'
]

@Mixin
export class UtmParamsProcessor extends Vue {
  public updated () {
    let whiteLabel: WhiteLabel | null = null
    if (AuthModule.currentWhiteLabel) {
      whiteLabel = AuthModule.currentWhiteLabel as WhiteLabel
    }
    const domain = DomainService.getDomainForCookies(whiteLabel)
    let gclId: string = ''
    let utmContent: string = ''
    CONVERSION_TRACKING_NAMES.map((utmParamName) => {
      if (this.$route.query[utmParamName]) {
        if (utmParamName === 'gclid') {
          this.$cookies.set(`click_id`, this.$route.query['gclid'] as string || '', { expires: '1d', ...domain, secure: false })
          this.$cookies.set(`provider_id`, 1, { expires: '1d', ...domain, secure: false })
        } else if (utmParamName === 'fbclid') {
          this.$cookies.set(`click_id`, this.$route.query['fbclid'] as string || '', { expires: '1d', ...domain, secure: false })
          this.$cookies.set(`provider_id`, 2, { expires: '1d', ...domain, secure: false })
        } else if (utmParamName === '_uetmsclkid') {
          this.$cookies.set(`click_id`, this.$route.query['_uetmsclkid'] as string || '', { expires: '1d', ...domain, secure: false })
          this.$cookies.set(`provider_id`, 4, { expires: '1d', ...domain, secure: false })
        } else if (utmParamName === 'msclkid') {
          this.$cookies.set(`click_id`, this.$route.query['msclkid'] as string || '', { expires: '1d', ...domain, secure: false })
          this.$cookies.set(`provider_id`, 4, { expires: '1d', ...domain, secure: false })
        } else {
          this.$cookies.set(utmParamName, this.$route.query[utmParamName], { expires: '1d', ...domain, secure: false })
        }
        if (utmParamName === 'utm_content') utmContent = this.$route.query['utm_content'] as string || ''
        if (utmParamName === 'gclid') gclId = this.$route.query['gclid'] as string || ''
      }
    })
    if (gclId === '' && utmContent !== '') this.$cookies.set('gclid', utmContent, { ...domain, secure: false })
    if (this.$cookies.get('gclid') === 'undefined') this.$cookies.set('gclid', '', { ...domain, secure: false })
  }
}
