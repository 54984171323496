import ApiService from '@/common/api/api.service'
import { CarrierAccountStatuses } from '@/constants/carrier-account'
import { CarrierAccount } from '@/models/carrier-account'
import { CarrierAccountsList } from '@/models/carrier-accounts-list'
import CarrierAccountQuery from '@/models/search-filters/carrier-account-query'
import { CarrierAccountSelectItem } from '@/models/select-boxes/carrier-account-select-item'
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer'
import { CarriersApiService } from './carriers.api.service'
import CountriesDataApiService from './countries-data.api.service'
import { DiscountProfileApiService } from './discount-profile.api.service'
import GraphQLService from './graphql.service'
import { UserCarrierAccountsList } from '@/models/user-carrier-accounts-list'
import { CarrierAccountsQuery } from '@/models/search-filters/carrier-accounts-query'
import { Ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'

export class CarrierAccountsApiService {
  public static async getAll (params: Object): Promise<CarrierAccountsList> {
    try {
      // @ts-ignore
      let { data } = await ApiService.query('carrier_accounts', { params: { ...params } })
      return plainToClassFromExist(new CarrierAccountsList(CarrierAccount), data)
    } catch (err) {
      throw err
    }
  }

  public static async search (params: Object): Promise<any> {
    try {
      // @ts-ignore
      let { data } = await ApiService.query('carrier_accounts/search', { params: { ...params } })
      return data
    } catch (err) {
      throw err
    }
  }

  public static getGQL (id: any) {
    return GraphQLService.query(`
      query carrierAccount($id: ID!) {
        carrierAccount(id: $id) {
          ${this.carrierAccountStrucGraphql()}
          relatedCarrierAccount {
            id
            selectBoxText
          }
        }
      }
    `, {
      id
    })
  }

  public static async getCarrierAccountsForSelectBox (query: string, userId: number | null, corpAccountId: number | null): Promise<CarrierAccountSelectItem[]> {
    let params = { query: query, is_enviaya_account: false }
    if (userId) {
      Object.assign(params, { user_id: userId })
    } else {
      Object.assign(params, { corporate_account_id: corpAccountId })
    }
    let { data } = await ApiService.query('carrier_accounts/search', {
      params: params
    })
    return data.carrier_accounts.map((account: any) => { return plainToClass(CarrierAccountSelectItem, account) } )
  }

  public static async getCarrierAccountSelectItem (slug: any): Promise<CarrierAccount> {
    let { data } = await ApiService.get('carrier_accounts', slug)
    return plainToClass(CarrierAccount, data)
  }

  public static async changeStatusGQL (id: number, status: CarrierAccountStatuses) {
    return GraphQLService.query(
      `mutation updateCarrierAccount (
        $id: ID!
        $status: Int
      ) {
        updateCarrierAccount (
          input: {
            id: $id
            status: $status
          }
        ) {
          carrierAccount {
            id
            status
          },
          errors
        }
      }`,
      {
        id,
        status
      }
    )
  }

  public static carrierAccountStrucGraphql () {
    return `
      id
      aliasName
      status
      carrierId
      carrier {
        ${CarriersApiService.carrierAndServicesGraphQLStruct()}
      },
      isEnviayaAccount,
      userId,
      user {
        firstname
        lastname
        email
        whiteLabelId
      }
      userIds,
      corporateAccountId,
      corporateAccountIds,
      corporateAccounts {
        id
        company
        status
        accountType
      },
      users {
        id
        firstname
        lastname
        email
        status
      },
      discountProfileIds,
      discountProfiles {
        ${DiscountProfileApiService.profileStructGraphQL()}
      },
      accountNumber
      userName
      password
      apiKey
      authToken
      parameter1
      parameter2
      parameter3
      parameter4
      selectBoxText,
      thirdPartyProvider
      shipmentBookingMethod
      carrierServiceIds
      carrierServices {
        id,
        serviceCode
      }
      coordinatesHandling
      minWeight
      pickupMinimumDiscountLevel
      pickupMinimumNetShippingAmount
      pickupMinimumNetShippingAmountCurrency
      maxWeight
      weightUnit
      defaultCurrency
      termsAndConditionsLink
      domestic
      import
      export
      requiresShipmentNumberRanges
      enablePublicDiscount
      applyDiscountOnFuelSurcharge
      ratingPriceConfiguration
      testAccount
      booking
      isPrepaid
      prepaidConfiguration
      tracking
      cancelable
      pickup
      quoting
      coverage
      enabled
      resellingPermitted
      originCountryDatumIds
      originCountryDatums {
        ${CountriesDataApiService.countryDataStructGraphQL()}
      }
      destinationCountryDatumIds
      destinationCountryDatums {
        ${CountriesDataApiService.countryDataStructGraphQL()}
      }
      resellingPermittedExceptionIds
      billingCountryCode
      dropOffCenterIndication
      importTaxesPayment
      rateListPrice
      clientId
      clientSecret
      cashOnDelivery
      cacheLevel
      zones
      relatedCarrierAccountId
    `
  }

  public static createCarrierAccount (carrierAccount: CarrierAccount) {
    const { ratingPriceConfiguration, zones } = carrierAccount
    carrierAccount.ratingPriceConfiguration = ratingPriceConfiguration ? 2 : 1
    carrierAccount.zones = (carrierAccount.availableZones && carrierAccount.availableZones.length > 0 && carrierAccount.zones.length === carrierAccount.availableZones.length) ? [] : zones
    carrierAccount.relatedCarrierAccountId = Number(carrierAccount.relatedCarrierAccountId) > 0 ? Number(carrierAccount.relatedCarrierAccountId) : null

    return GraphQLService.query(`
      mutation ($carrierAccount: JSON) {
        createCarrierAccount (
          input: {
            carrierAccount: $carrierAccount
          }
        ) {
          carrierAccount {
            ${this.carrierAccountStrucGraphql()}
          },
          errors
        }
      }`, {
        carrierAccount
      }
    )
  }

  public static searchCarrierAccountGraphql (status: CarrierAccountStatuses, filterParams: CarrierAccountQuery, page?: number, perPage?: number) {
    const { carrierAccountIds, searchQuery, accountId, userId, accountType } = filterParams
    const statuses = [status]

    return GraphQLService.query(
      `query searchCarrierAccount(
          $page: Int,
          $perPage: Int,
          $carrierAccountIds: [Int!],
          $searchQuery: String,
          $accountType: String,
          $accountId: ID,
          $userId: ID,
          $statuses: [Int!]
        ) {
        carrierAccountsOffset(
          page: $page
          perPage: $perPage
          carrierAccountIds: $carrierAccountIds
          searchQuery: $searchQuery
          accountType: $accountType
          accountId: $accountId
          userId: $userId
          ) {
            hasNextPage
            hasPreviousPage
            nodes (statuses: $statuses) {
              ${this.carrierAccountStrucGraphql()}
            }
            nodesCount
            pagesCount
            activeNodesCount
            disabledNodesCount
            deletedNodesCount
        }
      }	`, {
        page,
        perPage,
        carrierAccountIds: (carrierAccountIds instanceof Array) ? carrierAccountIds.map((id: string) => Number(id)) : null,
        searchQuery,
        accountType,
        accountId,
        userId,
        statuses
      }
    )
  }

  public static updateCarrierAccount (carrierAccount: CarrierAccount) {
    const { id, ratingPriceConfiguration , zones } = carrierAccount
    carrierAccount.ratingPriceConfiguration = ratingPriceConfiguration ? 2 : 1
    carrierAccount.zones = (carrierAccount.availableZones && carrierAccount.availableZones.length > 0 && carrierAccount.zones.length === carrierAccount.availableZones.length) ? [] : zones
    carrierAccount.relatedCarrierAccountId = Number(carrierAccount.relatedCarrierAccountId) > 0 ? Number(carrierAccount.relatedCarrierAccountId) : null
    return GraphQLService.query(`
      mutation (
        $id: ID
        $carrierAccount: JSON
        ) {
        updateCarrierAccount (
          input: {
            id: $id,
            carrierAccount: $carrierAccount
          }
        ) {
          carrierAccount {
            ${this.carrierAccountStrucGraphql()}
          },
          errors
        }
      }`, {
        id,
        carrierAccount
      }
    )
  }

  public static async createDownloadJob (params: Object) {
    return ApiService.post('carrier_accounts/download', {
      ...params
    })
  }

  public static async getUserCarrierAccounts (slug: number | string, status: number, page: number | undefined, perPage: number | undefined): Promise<UserCarrierAccountsList> {
    try {
      let { data } = await ApiService.query(`users/${slug}/carrier_accounts`, { params: { status: status, page: page || 1, per_page: perPage } })
      return plainToClassFromExist(new UserCarrierAccountsList(CarrierAccount), data)
    } catch (e) {
      throw e
    }
  }

  public static async setAsDefault (userId: number, carrierAccountId: number){
    return ApiService.put(`users/${userId}/carrier_accounts/${carrierAccountId}/select_default`, { carrier_account_id: carrierAccountId })
  }
}

export const useCarrierAccountIndex = (
  applyOnLoad: Ref<boolean>,
  filter: Ref<CarrierAccountsQuery>
) => {
  const { isLoading, data } = useQuery({
    queryKey: ['carrierAccountIndex', filter],
    queryFn: () => indexFn(filter.value),
    select: ({ data }) => {
      return plainToClassFromExist(new CarrierAccountsList(CarrierAccount), data)
    },
    staleTime: 1000 * 60 * 2, // Two minutes for caching results
    retry: 0,
    enabled: applyOnLoad
  })

  return { isLoading, data }
}

export const indexFn = async (filter: CarrierAccountsQuery) => {
  filter.carrierId = 1
  return ApiService.query('carrier_accounts', {
    params: classToPlain(filter)
  })
}
