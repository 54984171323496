<i18n src="@/locales/select2.json"></i18n>
<template>
  <div :class="(submitted && !isValid) ? 'select2-single-wrap is-invalid' : 'select2-single-wrap'">
    <label ref="label" :class="{ disabled: disabled, active: isActive }">{{placeholder}}</label>
    <span class="caret" :class="{ disabled: disabled }">▼</span>
    <select :class="(submitted && !isValid) ? 'form-control select2-hidden-accessible is-invalid' : 'form-control select2-hidden-accessible'" :name="name" :allowClear="allowClear" :disabled="disabled" :required="required"/>
    <div v-if="description" class="select_description">{{ description }}</div>
    <div class="invalid-feedback">{{invalidFeedback}}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { appConfig, getCharsLimit } from '@/constants/app-config'
import { DomainService } from '@/common/domain.service'
import { CONFIGURATION_KEYS } from '@/common/config'

export default {
  name: 'BasicSelect',
  data () {
    return {
      init: true,
      select2: null,
      newModel: null,
      minimumInputLength: null,
      ajaxOptions: {
        url: `${this.getDomain()}${this.url}`,
        xhrFields: { withCredentials: true },
        dataType: 'json',
        delay: appConfig.searchDelay,
        tags: true,
        data: (params) => {
          return {
            query: params.term,
            value: params.term,
            ...this.urlParams
          }
        },
        transport: (async (params, success, failure) => {
          try {
            const data = await this.$queryClient.fetchQuery({
              queryKey: [this.url, params.data],
              queryFn: async () => {
                let $request = $.ajax(params)
                $request.then(success)
                return $request
              }
            })
            success(data)
          } catch (error) {
            failure(error)
            this.$modal.show({
              type: 'danger',
              centered: true,
              headerText: 'Error',
              bodyText: error
            })
          }

          // let $request = $.ajax(params)
          // $request.then(success)
          // $request.fail((failure) => {
          //   if (failure.status) {
          //     switch (failure.status) {
          //       case 401:
          //         // this.$router.push('/logout').catch(() => { /**/ })
          //         break
          //       default:
          //         this.$modal.show({
          //           type: 'danger',
          //           centered: true,
          //           headerText: 'Error',
          //           bodyText: failure
          //         })
          //         break
          //     }
          //   }
          // })
          // console.log($request)
          // return $request
        }),
        processResults: (data, params) => {
          return this.processResults(data, params)
        },
        cache: true
      }
    }
  },
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    settings: {
      type: Object,
      default: () => { /**/ }
    },
    value: null,
    urlParams: {
      type: Object,
      default: () => { /**/ }
    },
    url: {
      type: String,
      default: ''
    },
    invalidFeedback: {
      type: String,
      default: 'invalidFeedback'
    },
    required: {
      type: Boolean,
      default: false
    },
    submitted: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    },
    validationClass: {
      type: String,
      default: ''
    },
    valueAsText: {
      type: Boolean,
      default: false
    },
    useLimit: {
      type: Boolean,
      default: true
    },
    idSearch: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    isActive: function () {
      return !!this.value
    }
  },
  watch: {
    options (val) {
      this.setOption(val)
    },
    value (val) {
      this.setValue(val)
    }
  },
  methods: {
    getDomain () {
      let vueExpectedHost = DomainService.getHostFromURI(Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH))
      let vueActualHost = DomainService.getHostFromURI(window.location.href)
      let url = ''
      if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
        Vue.axios.defaults.withCredentials = true
        if (vueExpectedHost !== vueActualHost) {
          url = `https://${DomainService.getHostFromURI(window.location.href)}/api/v2/`
        } else {
          url = Vue.$getConst(CONFIGURATION_KEYS.API_URL)
        }
      } else {
        if (vueExpectedHost !== vueActualHost) {
          url = `https://${DomainService.getDomainWithoutPort(window.location.href)}:${Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN_PORT)}/api/v2/`
        } else {
          url = Vue.$getConst(CONFIGURATION_KEYS.API_URL)
        }
      }
      return url
    },
    setOption (val = []) {
      let settings = {
        ...this.settings,
        minimumInputLength: this.getMinInputLength()
      }
      let popup = $(this.$el).parents('.modal')
      this.select2.empty()
      this.select2.select2({
        dropdownParent: (popup.length) ? popup : this.$parent.$el,
        ...settings,
        templateResult: this.formatResult,
        templateSelection: this.formatSelection,
        placeholder: '',
        allowClear: this.allowClear,
        selectionTitleAttribute: false,
        ajax: this.ajaxOptions,
        data: val,
        language: {
          noResults: () => {
            return this.$t('select2.no_results_found')
          },
          inputTooShort: () => {
            return this.$t('select2.input_too_short')
          },
          searching: () => {
            return this.$t('select2.searching')
          }
        }
      })
      this.setValue(this.value)
    },
    getMinInputLength () {
      if (this.idSearch) return 1
      if (!this.useLimit) return false
      if (this.minimumInputLength) return this.minimumInputLength
      if (!this.$currentUser) return this.minimumInputLength
      return this.$who.isVendorAdmin(this.$currentUser) || this.$currentUser.role >= 90 ? getCharsLimit(this.$currentUser) : false
    },
    setValue (val) {
      if (val !== 0) {
        this.select2.val([val])
        this.select2.trigger('change')
      }
    }
  },
  async mounted () {
    let self = this
    let settings = {
      ...this.settings,
      ajax: this.ajaxOptions
    }
    this.select2 = $(this.$el).find('select')
    this.$nextTick(() => {
      let popup = $(this.$el).parents('.modal')
      this.select2.select2({
        dropdownParent: (popup.length) ? popup : this.$parent.$el,
        ...settings,
        minimumInputLength: this.getMinInputLength(),
        templateResult: this.formatResult,
        templateSelection: this.formatSelection,
        placeholder: '',
        allowClear: this.allowClear,
        data: this.options,
        language: {
          noResults: () => {
            return this.$t('select2.no_results_found')
          },
          inputTooShort: () => {
            return this.$t('select2.input_too_short')
          },
          searching: () => {
            return this.$t('select2.searching')
          }
        }
      })
      .on('select2:select', ev => {
        let val = null
        if(ev['params']['data'].id && ev['params']['data'].id !== 0) {
          val = (!this.valueAsText) ? Number(ev.currentTarget.value) : ev.currentTarget.value
          this.$emit('change', val)
          this.$emit('select', ev['params']['data'])
          this.$emit('update', val)
          this.setValue(ev.currentTarget.value)
        } else {
          val = (!this.valueAsText) ? Number(this.value) : this.value
          this.select2.val(val)
          this.select2.trigger('change')
          this.$emit('update', undefined)
        }
      })
      .on('select2:unselect', ev => {
        this.$emit('change', ev.currentTarget.value)
        this.$emit('select', ev['params']['data'])
        this.$emit('clear')
      })
      .on('select2:open', e => {
        this.$nextTick(() => {
          if (this.newModel) {
            $('.add-new-select-item').parent().remove()
            $('.select2-results').off('click', 'a')

            let button = $('.add-new-select-item')
            if (!button.length) {
              $('.select2-results').append(`<div style='width: 100%; padding-top: 5px; padding-bottom: 5px;'><a
                class='main-color add-new-select-item'
                >`+ self.newModel +`</a></div>`)

              $('.select2-results').on('click', 'a', function () {
                self.createModel()
              })
            }
          }
          this.$refs.label?.classList.add(['active'])
          $('.select2-dropdown--above').attr('id','fix')
          $('#fix').removeClass('select2-dropdown--above')
          $('#fix').addClass('select2-dropdown--below')
        })
      })
      .on('select2:close', e => {
        if (!this.select2.val()) {
          this.$nextTick(() => {
            this.$refs.label?.classList.remove(['active'])
          })
        }
      })
      .on('select2:selecting', e => {
        if (e.params.args.data.link) {
          e.params.args.data.action()
          e.preventDefault()
        }
      })
      this.$nextTick(() => {
        if (this.select2.val()) {
          if (this.$refs && this.$refs.label) {
            this.$refs.label?.classList.add(['active'])
          }
        }
      })
    })
  },
  beforeDestroy () {
    this.$nextTick(() => {
      this.select2.select2('destroy')
    })
  }
}
</script>

<style>
.select2-single-wrap {
  width: 100%;
  margin-top: calc(1.75rem + 6px);
  margin-bottom: 0;
  position: relative;
}

.new-dir-block-content-item .select2-single-wrap {
  /*.new-dir-block-content-item-full .select2-single-wrap {*/
  margin-top: 0;
}

.select2-single-wrap label {
  position: absolute;
  color: #757575;
  margin-top: 0;
}

.select2-single-wrap label.active {
  margin-top: -20px;
  font-size: 0.8rem;
  z-index: 1;
}

.full_width .select2-container {
  max-width: 100%!important;
}
.select2-results__option span {
  color: #757575;
}
.select2-results__option:hover span,
.select2-results__option.select2-results__option--highlighted span {
  color: #fff !important;
}

.select2-results__option:hover i,
.select2-results__option.select2-results__option--highlighted i {
  color: #fff !important;
}

.select-custom-dropdown .select2-results__option {
  color: #757575;
  font-family: 'Lato';
  font-size: 14.4px;
  transition: .15s;
}
.select-custom-dropdown .select2-results__option.select2-results__option--highlighted {
  box-shadow: none!important;
}

.select-custom-dropdown-with-300 {
  min-width: 300px;
}

.select2-results .add-new-select-item {
  padding: 7px !important;
  font-weight: bold;
  font-size: 0.9rem;
}

@media (min-width: 500px) {

  .select-custom-dropdown .select2-results__options {
    max-height: 350px!important;
  }
}
</style>

<style scoped>

.select_description {
  margin-top: 5px;
  width: 100%;
  color: #9b9b9b;
  font-size: 12px;
}

.form-control + >>> .select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

>>> .select2-container .select2-selection--single {
  height: 30px;
}

>>> .select2-selection__rendered {
  margin-bottom: -6px!important;
}

.select2-single-wrap .caret {
  position: absolute;
  bottom: 0.625rem;
  right: .5rem;
  font-size: .63rem!important;
  color: #757575;
  z-index: 1;
  cursor: pointer;
}

.select2-single-wrap.is-invalid .caret {
  position: absolute;
  bottom: 2rem;
  right: .5rem;
  font-size: .63rem!important;
  color: #757575;
  z-index: 1;
  cursor: pointer;
}

.select2-single-wrap.is-invalid .invalid-feedback {
  display: inline-block;
}

.select2-single-wrap.is-valid .invalid-feedback {
  display: none;
}

>>> .select2-selection__rendered {
  padding-left: 0!important;
}

>>> .select2-container--default .select2-selection--single{
  outline: none!important;
  border-top:none!important;
  border-left:none!important;
  border-right:none!important;
  border-radius: 0!important;
  border-bottom: 1px solid #ced4da!important;
  padding-bottom: 5px!important;
}

.is-invalid >>> .select2-container--default .select2-selection--single{
  border-bottom: 1px solid red!important;
}

>>> .select2-selection__choice {
  border: none!important;
  background-color: #fff!important;
  display: flex;
  flex-direction: row-reverse;
  padding-left: 0!important;
}

>>> .select2-selection__clear {
  color: red!important;
}

>>> .select2-results__option {
  color: #757575;
}

>>> .select2-container {
  width: 100% !important;
  /*max-width: 350px;*/
  z-index: 2;
}

>>> .select2-search__field {
  width: 100% !important;
  font-size: 0.9rem !important;
}

>>> .select2-container--default .select2-selection--single {
  background: transparent !important;
}

>>> .select2-container--default.select2-container--disabled span {
  color: #757575;
}

>>> .select2-selection__rendered {
  line-height: 20px !important;
}

>>> .select2-container--default.select2-container--disabled span {
  color: #757575;
}

.select2-single-wrap label.disabled {
  color: #c3c3c3;
}

.caret.disabled  {
  color: #c3c3c3 !important;
}
</style>
