export enum SHIPMENT_STATUSES {
  SHIPMENT_NEW_STATUS_IN_CREATION = 1,
  SHIPMENT_NEW_STATUS_PENDING_CUSTOMS_INFORMATION = 2,
  SHIPMENT_NEW_STATUS_WAITING_CARRIER_CONFIRMATION = 3,
  SHIPMENT_NEW_STATUS_PENDING_PICKUP = 13,
  SHIPMENT_NEW_STATUS_IN_TRANSIT = 14,
  SHIPMENT_NEW_STATUS_HOLD_AT_LOCATION = 15,
  SHIPMENT_NEW_STATUS_BOOKING_IN_PROCESS = 16,
  SHIPMENT_STATUS_IN_CUSTOMS_CLEARANCE = 17,
  SHIPMENT_NEW_STATUS_EXCEPTION = 40,
  SHIPMENT_NEW_STATUS_DELIVERED = 50,
  SHIPMENT_NEW_STATUS_STORE_PICKUP = 51,
  SHIPMENT_NEW_STATUS_CANCELLED = 90,
  SHIPMENT_NEW_STATUS_BOOKING_ERROR = 98
}

export default function (status: number): any {
  let icons = new Map<number, any>()
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_IN_CREATION, 'far fa-ellipsis-h grey-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_PENDING_CUSTOMS_INFORMATION, 'fad fa-file-invoice dark-grey-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_WAITING_CARRIER_CONFIRMATION, 'fad fa-inbox-in dark-grey-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_PENDING_PICKUP, 'fad fa-truck-loading light-blue-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_STORE_PICKUP, 'fad fa-truck light-blue-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_IN_TRANSIT, 'fad fa-shipping-fast green-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_HOLD_AT_LOCATION, 'fad map-marked blue-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_BOOKING_IN_PROCESS, 'fad fa-clock dark-grey-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_STATUS_IN_CUSTOMS_CLEARANCE, 'fad fa-clipboard-list-check teal-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_EXCEPTION, 'fas fa-exclamation-triangle red-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_DELIVERED, 'fad fa-box-check green-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_CANCELLED, 'fas fa-times-octagon orange-text pr-2')
  icons.set(SHIPMENT_STATUSES.SHIPMENT_NEW_STATUS_BOOKING_ERROR, 'fad fa-exclamation-circle red-text pr-2')
  return icons.get(status)
}
