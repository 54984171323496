<template>
  <div class="ranges">
    <ul v-if="ranges">
      <li
              v-for="(value, key) in ranges"
              @click="$emit('clickRange', value)"
              :data-range-key="key"
              :key="key"
              :class="range_class(value)"
      >{{key}}
      </li>

      <div class="key-day">
        <mdb-input id="customValueRange" ref="customValueRange" v-model="customValueRange"/>
        <li @click="$emit('clickRange', [$moment().subtract($refs.customValueRange.$el.firstElementChild.value, 'days'), $moment()])">{{daysUpToTodayPlaceholder}}</li>
      </div>
      <!-- <div class="key-day">
        <mdb-input id="customValueRange" ref="customValueRange" v-model="customValueRange"/>
        <li @click="$emit('clickRange', [$moment().subtract($refs.customValueRange.$el.firstElementChild.value, 'days'), $moment()])">Days up to yesterday</li>
      </div> -->

    </ul>
  </div>
</template>

<script>
  import moment from 'moment'
  import Inputmask from 'inputmask'

  export default {
    props: {
      ranges: Object,
      selected: Object,
      daysUpToTodayPlaceholder: String
    },
    data () {
      return {
        customValueRange: 30
      }
    },
    mounted () {
      Inputmask({ 'mask': '9', 'repeat': 3 }).mask(document.getElementById('customValueRange'))
    },
    methods: {
      range_class (range) {
        return { active: moment(this.selected.startDate).isSame(range[0], 'date') && moment(this.selected.endDate).isSame(range[1], 'date') }
      }
    }
  }
</script>

<style scoped>

.key-day {
  display: flex;
  align-items: baseline;
  margin-left: 12px;
}

.key-day >>> div {
  width: 20%;
}

.key-day >>> li {
  width: 80%;
  padding-left: 5px!important;
  padding-right: 5px!important;

}

.key-day >>> .md-form {
  margin-bottom: 0!important;
  margin-top: 0!important;
  min-width: auto;
}
</style>
