import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import moment from 'moment'
import { IDateRange } from '@/mixins/rangePicker'
import { CorporateAccountTs } from '../corporate-account-ts'
import { IsNumber } from 'class-validator'

@Exclude()
export class Assignation extends AbstractModel {
  static get modelName () {
    return 'SalesExecutiveAssignation'
  }

  @Expose({ name: 'id' })
  @Transform(value => typeof value === 'string' ? Number(value) : value || undefined, { toClassOnly: true })
  public declare id: number

  @Expose({ name: 'sales_executive' })
  @Transform(value => { return (value === null) ? { id: '', firstname: '', lastname: '', email: '' } : value }, { toClassOnly: true })
  public salesExecutive: { id: number | string, firstname: string, lastname: string, email: string } | undefined

  @IsNumber({},{ message: 'blank_sales_executive_id' })
  @Expose({ name: 'sales_executive_id' })
  @Transform(value => typeof value === 'string' ? Number(value) : value || undefined, { toClassOnly: true })
  public salesExecutiveId: number | string = ''

  @Expose({ name: 'user' })
  @Transform(value => { return (value === null) ? { id: '', firstname: '', lastname: '', email: '' } : value }, { toClassOnly: true })
  public user: { id: number, firstname: string, lastname: string, email: string } | undefined

  @Expose({ name: 'enviaya_account' })
  public enviayaAccount: { id: number, account: string, alias: string } | undefined

  @Expose({ name: 'user_id' })
  public userId: number | null = null

  @Expose({ name: 'enviaya_account_id' })
  @Transform(value => typeof value === 'string' ? Number(value) : value || undefined, { toClassOnly: true })
  public enviayaAccountId: number | undefined

  @Expose({ name: 'corporate_account' })
  @Type(() => CorporateAccountTs)
  public corporateAccount?: CorporateAccountTs

  @Expose({ name: 'corporate_account_id' })
  @Transform(value => typeof value === 'string' ? Number(value) : value || undefined, { toClassOnly: true })
  public corporateAccountId: number | undefined

  @Expose({ name: 'approved_by' })
  public approvedBy: string = ''

  public assignedFromDateRange: IDateRange = {
    startDate: undefined,
    endDate: undefined
  }

  @Expose({ name: 'assigned_from' })
  public set assignedFromDateFrom (value) {
    this.assignedFromDateRange.endDate = value
    this.assignedFromDateRange.startDate = (value) ? moment(value).format('YYYY-MM-DD') : undefined
  }
  public get assignedFromDateFrom (): string | undefined {
    return (this.assignedFromDateRange.startDate) ? moment(this.assignedFromDateRange.startDate).format('YYYY-MM-DD') : undefined
  }

  @Expose({ name: 'assigned_from' })
  public set assignedFromDateTo (value) {
    this.assignedFromDateRange.endDate = (value) ? moment(value).format('YYYY-MM-DD') : undefined
  }
  public get assignedFromDateTo (): string | undefined {
    return (this.assignedFromDateRange.endDate) ? moment(this.assignedFromDateRange.endDate).format('YYYY-MM-DD') : undefined
  }

  public assignedUntilDateRange: IDateRange = {
    startDate: undefined,
    endDate: undefined
  }

  @Expose({ name: 'assigned_until' })
  public set assignedUntilDateFrom (value) {
    this.assignedUntilDateRange.endDate = value
    this.assignedUntilDateRange.startDate = (value) ? moment(value).format('YYYY-MM-DD') : undefined
  }
  public get assignedUntilDateFrom (): string | undefined {
    return (this.assignedUntilDateRange.startDate) ? moment(this.assignedUntilDateRange.startDate).format('YYYY-MM-DD') : undefined
  }

  @Expose({ name: 'assigned_until' })
  public set assignedUntilDateTo (value) {
    this.assignedUntilDateRange.endDate = (value) ? moment(value).format('YYYY-MM-DD') : undefined
  }
  public get assignedUntilDateTo (): string | undefined {
    return (this.assignedUntilDateRange.endDate) ? moment(this.assignedUntilDateRange.endDate).format('YYYY-MM-DD') : undefined
  }

  public approvedDateRange: IDateRange = {
    startDate: undefined,
    endDate: undefined
  }

  @Expose({ name: 'approved_at' })
  public set approvedDateFrom (value) {
    this.approvedDateRange.endDate = value
    this.approvedDateRange.startDate = (value) ? moment(value).format('YYYY-MM-DD') : undefined
  }
  public get approvedDateFrom (): string | undefined {
    return (this.approvedDateRange.startDate) ? moment(this.approvedDateRange.startDate).format('YYYY-MM-DD') : undefined
  }

  @Expose({ name: 'approved_at' })
  public set approvedDateTo (value) {
    this.approvedDateRange.endDate = (value) ? moment(value).format('YYYY-MM-DD') : undefined
  }
  public get approvedDateTo (): string | undefined {
    return (this.approvedDateRange.endDate) ? moment(this.approvedDateRange.endDate).format('YYYY-MM-DD') : undefined
  }

  public get corporate_account_id () {
    return this.corporateAccountId
  }
}
