
import { Component, Prop, Watch } from 'vue-property-decorator'
import BasicComponent from '@/views/BasicComponent'
import { ISelectBoxModel } from '@/models/select-box-model'

// Abstract select box component. Extend this class to create select component
@Component
export default class MdbAbstractSelectBox extends BasicComponent {
  @Prop({ default: 10 }) public visibleOptions!: number
  @Prop({ default: 'primary' }) public color!: string
  @Prop(String) public label!: string
  @Prop(String) public searchPlaceholder!: string
  @Prop(String) public classes!: string
  @Prop(String) public icon!: string
  @Prop({ default: false }) public multiple!: boolean
  @Prop({ default: false }) public selectAll!: boolean
  @Prop({ default: false }) public search!: boolean
  @Prop({ default: false }) public disabled!: boolean
  @Prop({ default: false }) public resetBtn!: boolean
  @Prop({ default: false }) public customValidation!: boolean
  @Prop({ default: false }) public isValid!: boolean
  @Prop({ default: false }) public show!: boolean

  @Prop(String) public name!: string
  @Prop(String) public invalidFeedback!: string
  @Prop({ default: null }) public result!: number | string | null

  public options: ISelectBoxModel[] = []

  public async beforeMount () {
    await this.fillSelectBox(this.result)
  }

  public async fetchData (): Promise<ISelectBoxModel[]> {
    throw Error('Method fetch data has not been implemented')
  }

  public async fillSelectBox (result?: number | string | null) {
    this.options = []
    this.options.push(...await this.fetchData())
    this.options.map((option) => { option.selected = (result === option.value) })
  }

  public change (e: any) {
    this.$emit('change', e)
  }

  @Watch('result')
  public async onResultChanged () {
    this.options.map((option) => { option.selected = (this.result === option.value) })
    this.options.sort()
  }
}
