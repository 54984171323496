import { AppModule } from '@/store/app.module'
import Vue from 'vue'
import { CONFIGURATION_KEYS } from '@/common/config'
import { DomainService } from '@/common/domain.service'

export default {
  computed: {
    $redirectUrl () {
      if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
        let vueExpectedHost = DomainService.getHostFromURI(Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH))
        let vueActualHost = DomainService.getHostFromURI(window.location.href)
        if (vueExpectedHost === vueActualHost) {
          return Vue.$getConst(CONFIGURATION_KEYS.REDIRECT_URL)
        } else {
          return '/'
        }
      }

      if (Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN) && Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN).length) {
        let localDomain = '';
        (Vue.$getConst(CONFIGURATION_KEYS.USE_SSL) === 'true') ? localDomain = 'https://' : 'http://'
        return `${localDomain}${Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN)}:${Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN_PORT)}/`
      } else {
        return AppModule.redirectUrl
      }
    }
  }
}

export function redirectUrl () {
  if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
    let vueExpectedHost = DomainService.getHostFromURI(Vue.$getConst(CONFIGURATION_KEYS.APP_VUE_PUBLIC_PATH))
    let vueActualHost = DomainService.getHostFromURI(window.location.href)
    if (vueExpectedHost === vueActualHost) {
      return Vue.$getConst(CONFIGURATION_KEYS.REDIRECT_URL)
    } else {
      return '/'
    }
  }

  if (Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN) && Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN).length) {
    let localDomain = '';
    (Vue.$getConst(CONFIGURATION_KEYS.USE_SSL) === 'true') ? localDomain = 'https://' : 'http://'
    return `${localDomain}${Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN)}:${Vue.$getConst(CONFIGURATION_KEYS.WL_DOMAIN_PORT)}/`
  } else {
    return AppModule.redirectUrl
  }
}
