import { render, staticRenderFns } from "./BasicSelect.vue?vue&type=template&id=59025f6c&scoped=true"
import script from "./BasicSelect.vue?vue&type=script&lang=js"
export * from "./BasicSelect.vue?vue&type=script&lang=js"
import style0 from "./BasicSelect.vue?vue&type=style&index=0&id=59025f6c&prod&lang=css"
import style1 from "./BasicSelect.vue?vue&type=style&index=1&id=59025f6c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59025f6c",
  null
  
)

/* custom blocks */
import block0 from "@/locales/select2.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FBasicSelect.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports