import { Ability, AbilityBuilder } from '@casl/ability'
Ability.addAlias('manage', [
  'select_scope',
  'change_account_status',
  'destroy',
  'edit',
  'index',
  'allowed_see_shipments_of_account',
  'new',
  'invoicing',
  'assign_credit',
  'change_account_type',
  'change_discount_profile',
  'update_to_active_status',
  'update_xeditable_attribute',
  'change_status',
  'delete',
  'destroy',
  'approve',
  'create',
  'update',
  'read',
  'upgrade_corporate_user',
  'downgrade_corporate_user',
  'download_csv',
  'download_user_csv',
  'download_admin_csv',
  'quick_onboarding_button',
  'connect',
  'create_invoice',
  'reconnect_shop',
  'resync',
  'carrier_invoices',
  'show',
  'update_revision_status',
  'update_accounting_status',
  'perform_date_search',
  'see_contact_information',
  'see_settings',
  'see_region',
  'see_spei_bank_accounts',
  'see_accounts',
  'see_carrier_accounts',
  'see_stores',
  'see_general',
  'see_invoicing',
  'show_reseller',
  'see_requirements',
  'see_payment_restrictions',
  'see_additional_information',
  'see_security',
  'see_assignations',
  'see_api_info',
  'see_stores',
  'see_sales_information',
  'see_pricing',
  'see_configuration',
  'see_audit',
  'see_api_configuration',
  'see_rating_filters',
  'perform_date_search',
  'init_return_label_shipment',
  'replace_or_return_shipment',
  'new_carrier_invoice',
  'edit_reseller',
  'cancel',
  'request_cancellation',
  'see_pricing_discount',
  'see_pricing_transactions_fee',
  'change_additional_configuration'
])

export function subjectName(subject) {
  if (!subject || typeof subject === 'string') {
    return subject;
  }

  const Type = typeof subject === 'object' ? subject.constructor : subject;
  return Type.modelName || Type.name;
}

export default AbilityBuilder.define({ subjectName }, can => {
})
