import { render, staticRenderFns } from "./ServiceCodesSelectBox.vue?vue&type=template&id=4af05476&scoped=true"
import script from "./ServiceCodesSelectBox.vue?vue&type=script&lang=ts"
export * from "./ServiceCodesSelectBox.vue?vue&type=script&lang=ts"
import style0 from "./ServiceCodesSelectBox.vue?vue&type=style&index=0&id=4af05476&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af05476",
  null
  
)

/* custom blocks */
import block0 from "@/locales/shipment_templates/shipment_template_form.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fselect-boxes%2FServiceCodesSelectBox.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports