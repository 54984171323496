import ApiService from '@/common/api/api.service'
import { CarrierService } from '@/models/carrier-service'
import { classToPlain, plainToClass } from 'class-transformer'
import { PricingQuery } from '@/models/search-filters/pricing-query'
import ServiceQuery from '@/models/search-filters/service-query'

export interface CarrierServicesIndexParams {
  carrierId?: number,
  carrierAccountId?: number
}

export class CarrierServicesApiService {
  public static async getAllByCarrier (carrierId?: number, carrierAccountId?: number, scope: string = 'services'): Promise<CarrierService[]> {
    let { data } = await ApiService.query('carrier_services', {
      params: { carrier_id: carrierId, carrier_account_id: carrierAccountId, with_cogs: true, scope: scope, per_page: 100 }
    })
    return data.models.map((service: any) => { return plainToClass(CarrierService, service) } )
  }

  public static async getAllByCarrierWithServices (carrierId: number, query: PricingQuery, carrierAccountId: number | undefined | null): Promise<CarrierService[]> {
    let { data } = await ApiService.query('carrier_services', {
      params: { carrier_id: carrierId, carrier_account_id: carrierAccountId, discount_profile_id: query.discountProfileId, white_label_id: query.whiteLabelId, with_services: true, per_page: 100 }
    })
    return data.models.map((service: any) => { return plainToClass(CarrierService, service) } )
  }

  public static async carrierServicesIndex (params: CarrierServicesIndexParams) {
    return ApiService.query('carrier_services', {
      params: { carrier_id: params.carrierId, carrier_account_id: params.carrierAccountId }
    })
  }

  public static async carrierServiceWithCogsIndex (filter: ServiceQuery) {
    filter.carrierId = 1
    filter.perPage = 100
    return ApiService.query('carrier_services', {
      params: classToPlain(filter)
    })
  }
}
