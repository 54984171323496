
import { Component, Vue, Prop } from 'vue-property-decorator'
import ConfigurationItem from '@/components/packagings/ConfigurationItem.vue'
import { Ecommerce } from '@/models/packaging'

@Component({
  components: {
    ConfigurationItem
  }
})
export default class Configuration extends Vue {
  @Prop(Number) public index!: number
  @Prop(Object) public configuration!: Ecommerce
  @Prop({ default: false }) public isShipmentScope!: Boolean
  @Prop({ default: '' }) public weightUnit!: String

  public removeConfigurationItem (index: number) {
    this.$emit('removeConfigurationItem', index)
  }

  // @Watch('commodity.name')
  // @Watch('commodity.unitValue')
  // @Watch('commodity.quantity')
  // @Watch('commodity.unitWeight')
  // public async commodityChanged () {
  //   if (this.configuration.wasValidated) {
  //     await this.configuration.validateModel()
  //   }
  // }
}
