
import { Component, Prop } from 'vue-property-decorator'
import EnviayaNumberInput from '@/overrides/mdb-components/EnviayaNumberInput.vue'
import CategoriesSelectBox from '@/components/select-boxes/CategoriesSelectBox.vue'
import {
  Range,
  Ecommerce,
  NORMALISED_WEIGHT_UNIT_LIST, ICategory, DimensionalRange
} from '@/models/packaging'
import { ISelectData } from '@/models/EnviayaAccountTS'
import BasicComponent from '@/views/BasicComponent'

@Component({
  components: {
    EnviayaNumberInput,
    CategoriesSelectBox
  }
})
export default class ConfigurationItem extends BasicComponent {
  @Prop(Number) public index!: number
  @Prop(Object) public configuration!: Ecommerce
  @Prop({ default: '' }) public weightUnit!: String

  public measurementList: ISelectData[] = []
  public selectedCategories: ICategory[] = []
  public itemCategories: string[] = []

  public created () {
    this.publishSelects()

    if (this.configuration.categories) {
      this.configuration.categories.forEach(category => {
        this.itemCategories.push(category.name)
        this.selectedCategories.push({ code: category.code as number, name: category.name })
      })
    }
  }

  public publishSelects () {
    this.measurementList = []
    NORMALISED_WEIGHT_UNIT_LIST.forEach((el) => this.measurementList.push({ text: this.$t(`packaging_form.normalised_weight_unit_list.${el.value}`).toString(), value: el.value, selected: (this.configuration.weight.weightUnit === el.value) }))
  }

  public changeMeasurementUnit (model: Range, value: string, elID: string) {
    if (value) {
      model.weightUnit = value
      this.$emit('changeMeasurementUnit', value)
      // @ts-ignore
      this.$refs[elID].doClose()
    }
  }

  public changeDimensionallUnit (model: DimensionalRange, value: string, elID: string) {
    if (value) {
      model.dimensionUnit = value
      this.$emit('changeMeasurementUnit', value)
      // @ts-ignore
      this.$refs[elID].doClose()
    }
  }

  public categoriesChanged (categories: string[]) {
    this.configuration.categories = []
    this.configuration.categories.push(...categories.map(category => { return { name: category } } ))
  }

  public clearWeight (checked: boolean, field: string) {
    if (!checked) {
      this.configuration[field].minimum = undefined
      this.configuration[field].maximum = undefined
    }
  }

  public clearCategories (checked: boolean) {
    if (!checked) {
      this.configuration.categories = []
      this.selectedCategories = []
      this.itemCategories = []
    }
  }
}
