
  import { Component, Prop } from 'vue-property-decorator'
  import BasicComponent from '@/views/BasicComponent'
  import { AxiosResponse } from 'axios'
  import { plainToClass } from 'class-transformer'
  import { CorporateAccountSelectItem } from '@/models/CorporateAccountSelectItem'
  import { CorporateAccountsApiService } from '@/common/api/corporate_accounts.api.service'
  import { CorporateAccountTs } from '@/models/corporate-account-ts'

  @Component
  export default class CorporateAccountSearchBox extends BasicComponent {
    @Prop(Number) public result!: number | undefined
    @Prop(String) public placeholder!: string | ''

    public corporateAccountSearch: string = ''
    public corporateAccounts: CorporateAccountSelectItem[] = []

    public $refs!: {
      corporateAccountSearch: HTMLFormElement
    }

    public async created () {
      let account: CorporateAccountTs
      if (this.result) {
        let shopResponse: AxiosResponse = await CorporateAccountsApiService.get(this.result)
        account = plainToClass(CorporateAccountTs, shopResponse.data)
        this.corporateAccountSearch = `${account.company} (${account.id})`
      }

      this.unwatchAccessors.push(this.$watch('result', this.onModelChanged))
      this.unwatchAccessors.push(this.$watch('corporateAccountSearch', this.onCorporateAccountSearchChanged))
    }

    // Searching corporate Accounts
    public getCorporateAccounts (query: string) {
      this.$refs.corporateAccountSearch.loading = true
      CorporateAccountsApiService.search(query).then((response: AxiosResponse) => {
        this.corporateAccounts = []
        response.data.accounts.forEach((user: any) => {
          this.corporateAccounts.push(plainToClass(CorporateAccountSelectItem, user))
        })
        this.$refs.corporateAccountSearch.loading = false
      }).catch((error) => {
        console.log(error)
      })
    }

    public onCorporateAccountSearchChanged (value: string) {
      if (!value.length) {
        this.corporateAccounts = []
      }
      if (!value.length && this.result) {
        this.$emit('update', undefined)
      } else if (this.$refs.corporateAccountSearch.isFocused && value.length > 3) {
        this.getCorporateAccounts(value)
      }
    }

    public async onModelChanged (value: string | undefined) {
      if (!value) {
        this.corporateAccountSearch = ''
      } else {
        let response: AxiosResponse = await CorporateAccountsApiService.get(this.result)
        let account = plainToClass(CorporateAccountTs, response.data)
        this.corporateAccountSearch = `${account.company} (${account.id})`
      }
    }
  }
