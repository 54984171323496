
  import { Component, Prop } from 'vue-property-decorator'
    // @ts-ignore
  import mdbSelect from 'mdbvue/lib/components/mdbSelect'
  import BasicComponent from '@/views/BasicComponent'

  @Component({
    extends: mdbSelect
  })

  export default class MdbBasicSelect extends BasicComponent {
    @Prop({ default: 10 }) public visibleOptions!: number
    @Prop({ default: 'primary' }) public color!: string
    @Prop(Array) public options!: { text: string, value: string | number | undefined }[]
    @Prop(String) public label!: string
    @Prop(String) public placeholder!: string
    @Prop(String) public searchPlaceholder!: string
    @Prop(String) public classes!: string
    @Prop({ default: false }) public multiple!: boolean
    @Prop({ default: false }) public top!: boolean
    @Prop({ default: false }) public selectAll!: boolean
    @Prop({ default: false }) public search!: boolean
    @Prop({ default: false }) public disabled!: boolean
    @Prop({ default: false }) public resetBtn!: boolean
    @Prop({ default: false }) public customValidation!: boolean
    @Prop({ default: true }) public isValid!: boolean
    @Prop({ default: false }) public loading!: boolean
    @Prop({ default: false }) public appendToBody!: boolean
    @Prop(String) public name!: string
    @Prop(String) public invalidFeedback!: string

    public toggleSelect (e: any) {
      this.$emit('toggleSelect', e)
    }

    public validate () {
      // @ts-ignore
      this.$refs['select'].validate()
    }
  }
