<template>
  <div class="list-group shadow">
    <TypeaheadListItem
            v-for="(item, id) in matchedItems" :key="id"
            :data="item.data"
            :html-text="highlight(item.text)"
            :background-variant="backgroundVariant"
            :text-variant="textVariant"
            @click.native="handleHit(item, $event)"
            @keydown="$listeners.keydown"
    >
      <template v-if="$scopedSlots.suggestion" slot="suggestion" slot-scope="{ data, htmlText }">
        <slot name="suggestion" v-bind="{ data, htmlText }" />
      </template>
    </TypeaheadListItem>
  </div>
</template>

<script>
import VueBootstrapTypeaheadList from 'vue-bootstrap-typeahead/src/components/VueBootstrapTypeaheadList.vue'
import TypeaheadListItem from './TypeaheadListItem'

function sanitize (text) {
  return text.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}

function escapeRegExp (str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export default {
  extends: VueBootstrapTypeaheadList,
  components: { TypeaheadListItem },
  props: {
    ignoreMatching: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.$parent.$on('selectionKeyPressed', this.handleSelectionKey.bind(this))
  },
  methods: {
    handleSelectionKey (evt) {
      if (this.data.length < 1) return
      let active = this.findActiveItemIndex()
      let ch = this.$children
      switch (evt.code) {
        case 'ArrowUp':
          this.setActiveItem((active > 0) ? (active - 1) : (ch.length - 1))
          break
        case 'ArrowDown':
          this.setActiveItem((active + 1) % ch.length)
          break
        case 'Enter':
          this.$emit('hit', this.findActiveItem())
          break
      }
    },
    findActiveItem () {
      let idx = this.findActiveItemIndex()
      return (idx < 0) ? null : this.matchedItems[idx]
    },
    findActiveItemIndex () {
      let ch = this.$children
      for (let i = 0; i < ch.length; i++) {
        let item = ch[i]
        if (item.active) {
          return i
        }
      }
      return -1
    },
    setActiveItem (idx) {
      this.$children.forEach((item, i) => {
        item.active = (i === idx)
        if (item.active) {
          item.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
      })
    }
  },
  computed: {
    highlight () {
      return (text) => {
        text = sanitize(text)
        if (this.query.length === 0) {
          return text
        }
        const re = new RegExp(this.escapedQuery, 'gi')

        return text.replace(re, `<strong>$&</strong>`)
      }
    },

    escapedQuery () {
      return escapeRegExp(sanitize(this.query))
    },

    matchedItems () {
      if (this.minMatchingChars > 0) {
        if (!this.query || this.query.length === 0 || this.query.length < this.minMatchingChars) {
          return []
        }
      }

      if ((!this.query || this.query.length === 0) && this.minMatchingChars === 0) {
        return this.data
      }

      const re = new RegExp(this.escapedQuery, 'gi')
      if (this.ignoreMatching) {
        return this.data
      } else {
        // Filter, sort, and concat
        return this.data
          .filter(i => i.text.match(re) !== null)
          .sort((a, b) => {
            const aIndex = a.text.indexOf(a.text.match(re)[0])
            const bIndex = b.text.indexOf(b.text.match(re)[0])

            if (aIndex < bIndex) { return -1 }
            if (aIndex > bIndex) { return 1 }
            return 0
          }).slice(0, this.maxMatches)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .list-group {
    padding: 0.5rem;
    background: #fff;
  }
  .list-group .list-group-item {
    ::v-deep i {
      margin-right: 4px;
    }
    font-size: 0.8rem;
    padding: 0.5rem;
    border: none;
  }
  .list-group-item {
    font-weight: 400;
  }
  ::v-deep .list-group-item strong {
    font-weight: 600;
  }
  .list-group .list-group-item:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
</style>
