import i18n from '@/i18n'
import { CHARGE_SHIPMENT_ORDER_STATUS } from '@/constants/clearing-charge'

export function useChargeShipmentOrderStatuses(selectedValue: string[] | string | undefined | null) {
  return Object.values(CHARGE_SHIPMENT_ORDER_STATUS).map((orderStatus) => {
    return {
      text: i18n.t(`clearing_charges.filter.charge_order_status.${orderStatus.toLowerCase()}`),
      value: orderStatus,
      selected:
        !!selectedValue &&
        ((Array.isArray(selectedValue) &&
          selectedValue.map((val) => val.toLowerCase()).includes(orderStatus.toLowerCase())) ||
          (!Array.isArray(selectedValue) && selectedValue.toLowerCase() === orderStatus.toLowerCase()))
    }
  })
}
