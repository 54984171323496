
  import { Component, Prop } from 'vue-property-decorator'
  import BasicComponent from '@/views/BasicComponent'
  import { ISelectBoxModel } from '@/models/select-box-model'
  import { AVAILABLE_LANGUAGES } from '@/common/user.settings'
  import { UsersApiService } from '@/common/api/users.api.service'
  import Vue from 'vue'
  import { AppModule } from '@/store/app.module'

  @Component
  export default class LanguageSelectBox extends BasicComponent {
    @Prop(String) public label!: string | 'Default label'
    @Prop(String) public classes!: string | ''
    @Prop({ default: () => { return Vue.cookies.get('locale') } }) public result!: string | undefined

    @Prop({ default: true }) public showText!: boolean
    @Prop(Boolean) public customValidation!: boolean
    @Prop(Boolean) public isValid!: boolean
    @Prop(String) public invalidFeedback!: string

    public statuses: ISelectBoxModel[] = []
    public skipReload: boolean = true

    public mounted () {
      if (this.$cookies.get('locale')) this.skipReload = true
      this.fillSelectBox(AppModule.currentLocale.toLowerCase())
      this.unwatchAccessors.push(this.$watch('result', this.onModelChanged))
    }

    public fillSelectBox (result?: string) {
      this.statuses = []
      AVAILABLE_LANGUAGES.forEach((language: string) => {
        this.statuses.push({
          text: this.$t(`languages_data.${language}`).toString(),
          value: language,
          selected: result === language
        })
      })
    }

    public onModelChanged () {
      this.fillSelectBox(this.result)
    }

    public validate () {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs['select'].validate()
      })
    }

    public countryText () {
      return this.result ? this.$t(`languages_data.${this.result}`).toString() : this.$t(`languages_data.es`)
    }

    public svg (countryCode: string) {
      // @ts-ignore
      let code = !!countryCode ? countryCode.split('-')[0] : 'es'
      return require('@/assets/img/flag/4x3/' + code.toLowerCase() + '.svg')
    }

    public async change (value: string) {
      if (value !== this.result && AVAILABLE_LANGUAGES.includes(value)) {
        this.$emit('update:result', value)
        this.$cookies.set('locale', value)
        if (this.$userAuthenticated) {
          this.$currentUser.locale = value
          await UsersApiService.changeLocaleGQL(this.$currentUser)
        }
        if (!this.skipReload) {
          document.location.reload()
        }
      }
      this.skipReload = false
    }
  }
