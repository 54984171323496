import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  customerInformationInitData: {
    'shipments_per_month': '',
    'destination_types': [
      { key: 'local' + 'false', label: 'Local', id: 'local', checked: false },
      { key: 'national' + 'false', label: 'National', id: 'national', checked: false },
      { key: 'international_export' + 'false', label: 'International (Export)', id: 'international_export', checked: false },
      { key: 'international_import' + 'false', label: 'International (Import)', id: 'international_import', checked: false }
    ],
    'shipment_types': [
      { key: 'document' + 'false', label: 'Documents', id: 'document', checked: false },
      { key: 'freight' + 'false', label: 'Packages', id: 'freight', checked: false },
      { key: 'heavy_parcel' + 'false', label: 'Heavy Packages (>70kg)', id: 'heavy_parcel', checked: false },
      { key: 'package' + 'false', label: 'Freight', id: 'package', checked: false }
    ],
    'avg_shipment_weight': '',
    'weight_unit': '',
    'carriers': [
      { key: 'FedEx' + 'false', label: 'FedEx', id: 'FedEx', checked: false },
      { key: 'iVoy' + 'false', label: 'iVoy', id: 'iVoy', checked: false },
      { key: 'Redpack' + 'false', label: 'Redpack', id: 'Redpack', checked: false },
      { key: 'Estafeta' + 'false', label: 'Estafeta', id: 'Estafeta', checked: false },
      { key: 'Deprisa' + 'false', label: 'Deprisa', id: 'Deprisa', checked: false },
      { key: 'Minutos' + 'false', label: 'Minutos', id: 'Minutos', checked: false },
      { key: 'DHL' + 'false', label: 'DHL', id: 'DHL', checked: false },
      { key: 'UPS' + 'false', label: 'UPS', id: 'UPS', checked: false },
      { key: 'Sendex' + 'false', label: 'Sendex', id: 'Sendex', checked: false }
    ]
  },
  contactInformation: {},
  customerInformation: {},
  step: 1
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
