import ApiService from '@/common/api/api.service'
import { plainToClass } from 'class-transformer'
import { Application } from '@/models/application'
import { AxiosResponse } from 'axios'

export class ApplicationsApiService {
  public static async search (query?: any): Promise<Application[]> {
    let { data } = await ApiService.query('api_applications', {
      params: { ...query }
    })
    return data.map((model: any) => { return plainToClass(Application, model) })
  }

  public static get (slug: number): Promise<AxiosResponse> {
    return ApiService.get('api_applications', String(slug))
  }
}
