import { CarrierService } from '@/models/CarrierService'
import { Exclude, Expose, plainToClass, Transform } from 'class-transformer'
import { CONFIGURATION_KEYS } from '@/common/config'
import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { numToTime, timeToNum } from '@/models/quoting-filter'

type LogoImageUrls = {png_jpg: string, svg: string}
export const CARRIERS_LOGOS: Record<string, LogoImageUrls & { authorized?: LogoImageUrls }> = {
  ampm: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ampm/ampm.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ampm/ampm.svg'
  },
  borzo: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/borzo/borzo.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/borzo/borzo.svg'
  },
  ups: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ups/ups.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ups/ups.svg'
  },
  dhl: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/dhl/dhl.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/dhl/dhl.svg'
  },
  redpack: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/redpack/redpack.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/redpack/redpack.svg'
  },
  fedex: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/fedex/fedex.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/fedex/fedex.svg',
    authorized:  {
      png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/fedex/fedex-centro-autorizado-de-envio.png',
      svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/fedex/fedex-centro-autorizado-de-envio.svg'}
  },
  minutos: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/99minutos/99minutos.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/99minutos/99minutos.svg'
  },
  ivoy: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ivoy/ivoy.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ivoy/ivoy.svg'
  },
  trunk: {
    png_jpg: 'https://s3-us-west-1.amazonaws.com/enviaya-public/images/logo-trunk.svg',
    svg: 'https://s3-us-west-1.amazonaws.com/enviaya-public/images/logo-trunk.svg'
  },
  estafeta: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/estafeta/estafeta.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/estafeta/estafeta.svg'
  },
  deprisa: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/deprisa/deprisa.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/estafeta/deprisa.svg'
  },
  four72: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/4-72/4-72.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/4-72/4-72.svg'
  },
  paquetexpress: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/paquetexpress/paquetexpress.png',
    svg: 'https://enviaya-public.s3.us-west-1.amazonaws.com/carriers/paquetexpress/paquetexpress.svg'
  },
  pickit: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/pickit/pickit.png',
    svg: 'https://enviaya-public.s3.us-west-1.amazonaws.com/carriers/pickit/pickit.svg'
  },
  treggo: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/treggo/treggo.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/treggo/treggo.svg'
  },
  tresguerras: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/tresguerras/tresguerras.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/tresguerras/tresguerras.svg'
  },
  sendex: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/sendex/sendex.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/sendex/sendex.svg'
  },
  zubut: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/zubut/zubut.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/zubut/zubut.svg'
  },
  uber: {
    png_jpg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/uber/uber.png',
    svg: 'https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/uber/uber.svg'
  },
  jt: {
    png_jpg: 'https://enviaya-public.s3.us-west-1.amazonaws.com/carriers/jt-express/jt-express.png',
    svg: 'https://enviaya-public.s3.us-west-1.amazonaws.com/carriers/jt-express/jt-express.svg'
  }
}

@Exclude()
export class CarrierTS {
  static get modelName () {
    return 'Carrier'
  }

  public logoUrl (defaultLogo: boolean) {
    const carrierName =
      this.name.toLocaleLowerCase() === 'deprisa' && Vue.$getConst(CONFIGURATION_KEYS.IS_PRODUCTION) ?
        'ups' :
        this.name.toLocaleLowerCase()

    const carrier = CARRIERS_LOGOS[carrierName] ?? null

    if (!carrier) return ''
    if (defaultLogo) {
      return carrier['png_jpg']
    } else return get(carrier, 'authorized.png_jpg') ?? carrier['png_jpg']
  }

  @Expose({ name: 'id' })
  public id: number | undefined

  @Expose({ name: 'status' })
  @Transform(value => { return (!value) ? false : value })
  public status: boolean = true

  @Expose({ name: 'name' })
  @Transform(value => { return (!value) ? '' : value })
  public name: string = ''

  @Expose({ name: 'domestic' })
  @Transform(value => { return (!value) ? false : value })
  public domestic: boolean = false

  @Expose({ name: 'international' })
  @Transform(value => { return (!value) ? false : value })
  public international: boolean = false

  @Expose({ name: 'select_service_type' })
  @Transform(value => { return (!value) ? '' : value })
  public selectServiceType: string = ''

  @Expose({ name: 'services_collapsed' })
  @Transform(value => { return (!value) ? false : value })
  public servicesCollapsed: boolean = false

  @Expose({ name: 'carrier_services' })
  @Transform(value => { return isEmpty(value) ? value : value.map((element: CarrierService) => plainToClass(CarrierService, element)) })
  public carrierServices: CarrierService[] = []

  @Expose({ name: 'same_day_cut_off_time' })
  @Transform(value => { return (!value) ? '' : value })
  public sameDayCutOffTime: string = ''

  @Expose({ name: 'next_day_cut_off_time' })
  @Transform(value => { return (!value) ? '' : value })
  public nextDayCutOffTime: string = ''

  @Expose({ name: 'global_cut_off_time' })
  @Transform(value => { return (!value) ? '' : value })
  public globalCutOffTime: string = ''

  @Expose({ name: 'same_day_additional_hours' })
  @Transform(value => {
    return (value === null) ? '24:00' : numToTime(value)
  }, { toClassOnly: true })
  @Transform(value => {
    return !value ? '24:00' : timeToNum(value)
  }, { toPlainOnly: true })
  public sameDayAdditionalHours: string = '24:00'

  @Expose({ name: 'next_day_additional_hours' })
  @Transform(value => {
    return (value === null) ? '24:00' : numToTime(value)
  }, { toClassOnly: true })
  @Transform(value => {
    return !value ? '24:00' : timeToNum(value)
  }, { toPlainOnly: true })
  public nextDayAdditionalHours: string = '24:00'

  @Expose({ name: 'global_additional_hours' })
  @Transform(value => {
    return (value === null) ? '24:00' : numToTime(value)
  }, { toClassOnly: true })
  @Transform(value => {
    return !value ? '24:00' : timeToNum(value)
  }, { toPlainOnly: true })
  public globalAdditionalHours: string = '24:00'

  @Expose({ name: 'enable_cut_off_config' })
  @Transform(value => { return (!value) ? false : value })
  public enableCutOffConfig: boolean = false

  @Expose({ name: 'disabled_level' })
  @Transform(value => { return (!value) ? '' : value })
  public disabledLevel: string = ''

  @Expose({ name: 'tracking_site_link' })
  @Transform(value => { return (!value) ? '' : value })
  public trackingSiteLink: string = ''

  @Expose( { name: 'live_tracking' } )
  public liveTracking: boolean = false

  @Expose({ name: 'carrier_name_formatted' })
  @Transform(value => { return (!value) ? '' : value })
  public carrierNameFormatted: string = ''

  public hasServicesDisabledByWL: boolean = false

  public hasServicesDisabledByCorpAcc: boolean = false

  public minShopOrderAmount: number | undefined | null = undefined
  public maxShopOrderAmount: number | undefined | null = undefined
  public shopOrderAmountCurrency: string = 'MXN'
  public remoteHandling: number = 1
}
