import { ACCOUNT_TYPE_RESELLER, ACCOUNT_TYPE_ENVIAYA } from '@/models/EnviayaAccountTS'
import { RawRule } from '@casl/ability'
import mapKeys from 'lodash/mapKeys'
import camelCase from 'lodash/camelCase'

export const dig = (obj: Record<string, any>, target: string): any =>
  target in obj ?
    obj[target] :
    Object.values(obj).reduce((acc, val) => {
      if (acc !== undefined) return acc
      if (typeof val === 'object') return dig(val, target)
    }, undefined)

export const asyncEvery = async (arr: any[], predicate: any) => {
  for (let e of arr) {
    if (!await predicate(e)) return false
  }
  return true
}

export const arrayToPlainModelList = <T extends { id: number | string }>(arr: T[]) => ({
  models: arr,
  modelsCnt: arr.length,
  allModelsIds: arr.map(object => object.id)
})

export const arrayToAccountList = <T extends { id: number | string, account_type: number }>(arr: T[]) => ({
  models: arr,
  all_accounts_count: arr.length,
  reseller_accounts_count: arr.filter(account =>
    account.account_type === ACCOUNT_TYPE_RESELLER
  ).length,
  enviaya_accounts_count: arr.filter(account =>
    account.account_type === ACCOUNT_TYPE_ENVIAYA
  ).length,
  all_models_ids: arr.map(object => object.id)
})

export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const WEBHOOK_ENDPOINT_REGEX = /^(https{0,1}:\/\/)((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})(\/(\S)*)*$/g

export const convertAbilities = (abilities: RawRule[]) => {
  return abilities.map((ability: RawRule) => {
    if (Object.getOwnPropertyNames(ability.conditions).length) {
      ability.conditions = mapKeys(ability.conditions, (_value: any, key: any) => {
        return ['$elemMatch', '$in'].includes(key) ? key : camelCase(key)
      })
    } else {
      delete ability.conditions
    }
    return ability
  })
}
