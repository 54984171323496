import { Exclude, Expose, Transform } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'

export enum REFERENCE_TYPE {
  REF_TYPE_1 = 1,
  REF_TYPE_2 = 2,
  REF_TYPE_3 = 3,
  REF_TYPE_4 = 4,
  REF_TYPE_5 = 5,
  REF_TYPE_6 = 6,
  REF_TYPE_7 = 7
}

@Exclude()
export class PackagingParcelReference extends AbstractModel {
  static get modelName () {
    return 'ShipmentReference'
  }

  @Expose({ name: 'reference' })
  public reference: string = ''

  @Expose({ name: 'reference_type' })
  @Transform(value => (value) ? value : REFERENCE_TYPE.REF_TYPE_1)
  public referenceType: undefined | number = REFERENCE_TYPE.REF_TYPE_1

}
