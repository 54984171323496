import Vue from 'vue'
import i18n from "@/i18n"

Vue.filter('formatErrors', function (errors) {
  let errorMessages = []
  Object.entries(errors).forEach((value) => {
    errorMessages.push(value[1])
  })
  return errorMessages.join(', ')
})

Vue.directive('numericOnly', {
  bind(el, binding, vnode) {
    el.addEventListener('keydown', (e) => {
      if (e.key) {
        const charCode = e.key.charCodeAt(0)
        if (charCode === 101 || charCode === 69 || charCode === 45 || charCode === 43 || charCode === 46 || charCode === 44) {
          e.preventDefault()
        }
      }
    })
  }
})

Vue.filter('formatError', function (data) {
  let errorMessages = []
  Object.entries(data.errors.detail).forEach((value) => {
    if (value[0] !== 'base') {
      value[1] = value[1].map((err) => { return `${data.formatter.t(`${data.keyPath}.${value[0]}`)} ${err}` }).join(', ')
    }
    errorMessages.push(value[1])
  })
  return errorMessages.join(', ')
})

Vue.filter('formatDirectionsError', function (data) {
  let errorMessages = []
  Object.entries(data.errors.detail).forEach((value) => {
    value[1] = value[1].map((err) => { return `${data.formatter.t(`form.shipping_address.${value[0]}`)} ${err}` }).join(', ')
    errorMessages.push(value[1])
  })
  return errorMessages.join(', ')
})

Vue.filter('formatDashboardNumberWithCurrency', function (val, currency, i18n) {
  if (!currency) currency = 'MXN'
  val = Number(val).toLocaleString('en-EN',{ maximumFractionDigits: 0 })
  return i18n.t(`short_abbr_format.${currency.toLowerCase()}`,  { amount: val })
})

Vue.filter('formatDashboardNumber', function (val, format) {
  if (!val) return 0
  return new Intl.NumberFormat('en-EN', { maximumFractionDigits: format }).format(val)
})

Vue.filter('currencyFormat', function (val, currency) {
  return i18n.t(`short_abbr_format.${currency.toLowerCase()}`, { amount: '' })
})

Vue.filter('formatNumberWithCurrency', function (val, currency, removeDecimals = false, decimalsCount = 2) {
  if (!currency) currency = 'MXN'
  if (currency.toLowerCase() === 'clp') {
    val = new Intl.NumberFormat('es-CL', { maximumFractionDigits: 0 }).format(val)
  } else {
    val = new Intl.NumberFormat('en-EN', { minimumFractionDigits: (removeDecimals) ? 0 : decimalsCount , maximumFractionDigits: (removeDecimals) ? 0 : decimalsCount }).format(val)
  }
  return i18n.t(`short_abbr_format.${currency.toLowerCase()}`,  { amount: val })
})
