import { AbstractModel } from '@/models/abstract-model'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import i18n from '@/i18n'
import {
  isEmpty, IsNotEmpty, isNotEmpty,
  Validate, ValidateIf,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator'
// @ts-ignore
import localeData from '@/locales/directions/direction_form.json'
// @ts-ignore
import validateRfc from 'validate-rfc'
import parsePhoneNumber from 'libphonenumber-js'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { IsEmailValid } from '@/common/validators/email-validator'
// @ts-ignore
import { COUNTRIES_WITHOUT_POSTCODES } from '@/common/common'

export enum DIRECTION_TYPES {
  IS_ORIGIN_DIRECTION = 'origin',
  IS_DESTINATION_DIRECTION = 'destination'
}

export const DIRECTION_SOURCE = {
  FROM_ADDRESS_BOOK: 1,
  NEW_DIRECTION: 2,
  USE_ACCESS_POINT: 3
}

@ValidatorConstraint()
export class IsFullNameValid implements ValidatorConstraintInterface {
  public validate (value: string, _args: ValidationArguments) {
    return isNotEmpty(value) && value.length <= 35 && /(\w.+\s).+/i.test(value)
  }
}

@ValidatorConstraint()
export class IsPhoneValid implements ValidatorConstraintInterface {
  public validate (value: string, args: ValidationArguments) {
    const countryCode = (args.object as Direction).phoneCountry || (args.object as Direction).countryCode
    const phoneNumber = parsePhoneNumber(value, countryCode.toUpperCase())
    return value && phoneNumber && phoneNumber.isPossible()
  }
}

@ValidatorConstraint()
export class IsTaxIdCorrect implements ValidatorConstraintInterface {
  public validate (value: string, args: ValidationArguments) {
    if ((args.object as Direction).countryCode.toLowerCase() === 'mx') {
      // @ts-ignore
      const rfc: {
        isValid: boolean
        rfc: string
        type: string
      } = validateRfc(value, { omitVerificationDigit: true })
      return isNotEmpty(value) && rfc.isValid
    } else {
      return true
    }
  }
}

@Exclude()
export class AddressAttributes {
  @Expose({ name: 'district' })
  public district: boolean = true

  @Expose({ name: 'neighborhood' })
  public neighborhood: boolean = true

  @Expose({ name: 'postal_code' })
  public postalCode: boolean = true

  @Expose({ name: 'state_code' })
  public stateCode: boolean = true
}

@Exclude()
export class Direction extends AbstractModel {
  constructor () {
    super()
    i18n.mergeLocaleMessage('en', localeData.en)
    i18n.mergeLocaleMessage('de', localeData.de)
    i18n.mergeLocaleMessage('es', localeData.es)
  }

  static get modelName () {
    return 'Direction'
  }

  @Validate(IsFullNameValid, {
    message: (args: ValidationArguments) => {
      if (isEmpty(args.value)) {
        return i18n.t('form.contact_information_valid_msg.full_name').toString()
      } else {
        return i18n.t('form.contact_information_valid_msg.full_name_format').toString()
      }
    }
  })
  @Expose({ name: 'full_name' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public fullName: string = ''

  @ValidateIf(o => isNotEmpty(o.email))
  @Validate(IsEmailValid, {
    message: () => {
      return i18n.t('form.contact_information_valid_msg.invalid_email').toString()
    }
  })
  @Expose({ name: 'email' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public email: string = ''

  @Validate(IsPhoneValid, {
    message: (args: ValidationArguments) => {
      const countryCode = (args.object as Direction).countryCode
      const phoneLength: number = (countryCode === 'MX') ? 10 : 6
      return i18n.t('form.contact_information_valid_msg.phone', { number: phoneLength }).toString()
    }
  })
  @Expose({ name: 'phone' })
  @Transform((value, obj) => {
    return (value) ? parsePhoneNumber(value, obj.phoneCountry || obj.countryCode).number : ''
  }, { toPlainOnly: true })
  @Transform((value, obj) => {
    let phone = parsePhoneNumber(value || '', obj.country_code || 'MX')
    return (!phone) ? '' : phone.nationalNumber
  }, { toClassOnly: true })
  public phone: string = ''

  public phoneCountry = ''

  @ValidateIf(o => isNotEmpty(o.taxId))
  @Validate(IsTaxIdCorrect, {
    message: () => {
      return i18n.t('form.contact_information_valid_msg.tax_id_invalid_format').toString()
    }
  })
  @Expose({ name: 'tax_id' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public taxId: string = ''

  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.direction_1').toString() })
  @Expose({ name: 'direction_1' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public direction1: string = ''

  @Expose({ name: 'direction_2' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public direction2: string = ''

  // @ts-ignore
  @ValidateIf(o => ![].concat.apply([], COUNTRIES_WITHOUT_POSTCODES).includes(o.countryCode.toUpperCase()))
  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.direction_1').toString() })
  @Expose({ name: 'postal_code' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public postalCode: string = ''

  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.direction_1').toString() })
  @Expose({ name: 'city' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public city: string = ''

  @Expose({ name: 'municipality' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public municipality: string = ''

  @Expose({ name: 'district' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public district: string = ''

  @ValidateIf(o => o.countryCode === 'MX')
  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.direction_1').toString() })
  @Expose({ name: 'neighborhood' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public neighborhood: string = ''

  @Expose({ name: 'company' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public company: string = ''

  @Expose({ name: 'state_code' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public stateCode: string = ''

  @Expose({ name: 'state' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public state: string = ''

  @Expose({ name: 'is_destination' })
  public isDestination: boolean = true

  @Expose({ name: 'is_origin' })
  public isOrigin: boolean = false

  @Expose({ name: 'default_origin' })
  public defaultOrigin: boolean = false

  @Expose({ name: 'default_pickup' })
  public defaultPickup: boolean = false

  @Expose({ name: 'coordinates_autofetched' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public coordinatesAutofetched: boolean = false

  @Expose({ name: 'country_code' })
  @Transform(value => { return (!value) ? 'MX' : value }, { toClassOnly: true })
  public countryCode: string = ''

  @Expose({ name: 'latitude' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public latitude: string | null = ''

  @Expose({ name: 'longitude' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public longitude: string | null = ''

  @Expose({ name: 'locale' })
  @Transform(value => { return (!value) ? 'es-MX' : value }, { toClassOnly: true })
  public locale: string = 'es-MX'

  @IsNotEmpty({ message: () => i18n.t('form.additional_configuration.select_user_warning').toString() })
  @Expose({ name: 'user_id' })
  public userId: number | null = null

  @Expose({ name: 'corporate_account_id' })
  public corporateAccountId: number | null = null

  @Exclude()
  public shareToCompany: boolean = false

  @Expose({ name: 'user' })
  @Exclude({ toPlainOnly: true })
  @Type(() => EnviayaUserTS)
  public user: EnviayaUserTS | undefined

  public additionalAddressBlockHidden: boolean = true

  @ValidateIf(o => o.additionalAddressBlockHidden && isEmpty(o.postalCode) && isEmpty(o.neighborhood) && isEmpty(o.city))
  @IsNotEmpty({ message: () => i18n.t('form.contact_information_valid_msg.direction_1').toString() })
  @Exclude()
  public addressSearch: string = ''

  public get showPostalCodeSearch () {
    return this.countryCode && this.countryCode.toLowerCase() !== 'ca' && this.additionalAddressBlockHidden
  }

  public get isAdditionalAddressLinkShow () {
    return this.countryCode && this.countryCode.toLowerCase() !== 'ca' && this.additionalAddressBlockHidden
  }

  public get isAdditionalAddressBlockShow () {
    return this.countryCode && this.countryCode.toLowerCase() === 'ca'
      || !this.additionalAddressBlockHidden
  }

  @Exclude()
  public wasValidated: boolean = false

  @Exclude()
  public saveToAddressBook: boolean = false

  @Exclude()
  public directionSource: number = DIRECTION_SOURCE.FROM_ADDRESS_BOOK

  @Exclude()
  public accessPointId: number | null = null
}

@Exclude()
export class DirectionCoordinates {
  @Expose({ name: 'latitude' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public latitude: string | null = ''

  @Expose({ name: 'longitude' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public longitude: string | null = ''

  @Expose({ name: 'coordinates_autofetched' })
  @Transform(value => { return (value === null) ? false : value }, { toClassOnly: true })
  public coordinatesAutofetched: boolean = false
}
