<script>
  import BasicSelect from '@/components/select-boxes/BasicSelect'
  import $ from 'jquery'

  export default {
    name: 'ReferrerSelectBox',
    extends: BasicSelect,
    props: {
      enableReseller: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      processResults (data, params) {
        let options = []
        options.push(...data.referrers.map((el) => { return { id: el.id, text: this.getText(el) } }))
        return {
          results: options
        }
      },
      formatResult (option) {
        if (option.link) {
          return $.parseHTML('<span>' + option.text + '</span>')
        }
        return option.text
      },
      getText (el) {
        if (el.referrer_key) {
          return `${ el.referrer_key } (${(el.email) ? el.email : el.name})`
        } else {
          return `${ el.id } (${(el.email) ? el.email : el.name})`
        }
      }
    }
  }
</script>
