import ApiService from '@/common/api/api.service'

export interface CacheResults {
  dbCacheState: boolean,
  redisCacheState: boolean,
  logsState?: boolean
}

export interface LogsState {
  logsState: boolean
}

export default class AppApiService {
  public static async checkCacheSettings (): Promise<CacheResults> {
    let { data } = await ApiService.query('check_cache_settings', {})
    return {
      dbCacheState: data.db_cache_state,
      redisCacheState: data.redis_cache_state,
      logsState: data.logs_state
    }
  }

  public static async toggleLogsState (): Promise<LogsState> {
    let { data } = await ApiService.update('toggle_webservice_logs_writing', '', {})
    return {
      logsState: data.logs_state
    }
  }

  public static async toggleRedisCache (): Promise<CacheResults> {
    let { data } = await ApiService.update('toggle_db_price_redis_cache', '', {})
    return {
      dbCacheState: data.db_cache_state,
      redisCacheState: data.redis_cache_state
    }
  }

  public static async toggleDBCache (): Promise<CacheResults> {
    let { data } = await ApiService.update('toggle_db_cache', '', {})
    return {
      dbCacheState: data.db_cache_state,
      redisCacheState: data.redis_cache_state
    }
  }
}
