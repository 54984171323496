import { render, staticRenderFns } from "./ConfigurationItem.vue?vue&type=template&id=43b373ed&scoped=true"
import script from "./ConfigurationItem.vue?vue&type=script&lang=ts"
export * from "./ConfigurationItem.vue?vue&type=script&lang=ts"
import style0 from "./ConfigurationItem.vue?vue&type=style&index=0&id=43b373ed&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43b373ed",
  null
  
)

/* custom blocks */
import block0 from "@/locales/packagings/commodity_form.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpackagings%2FConfigurationItem.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/shipments/shipment_form.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpackagings%2FConfigurationItem.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/locales/packagings/packagings_form.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpackagings%2FConfigurationItem.vue&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/locales/packagings/packaging_configuration.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpackagings%2FConfigurationItem.vue&external"
if (typeof block3 === 'function') block3(component)

export default component.exports