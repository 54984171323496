import { Expose } from 'class-transformer'

export class ShipmentLine {
  @Expose({ name: 'id' })
  public id: number | undefined

  @Expose({ name: 'carrier_name' })
  public carrierName: string = ''

  @Expose({ name: 'title' })
  public title: string = ''

  @Expose({ name: 'code' })
  public code: string = ''
}
