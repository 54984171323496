<template>
  <div class="typeahead">
    <div :class="sizeClasses" class="md-form">
      <div ref="prependDiv" v-if="$slots.prepend || prepend" class="input-group-prepend">
        <slot name="prepend">
          <span class="input-group-text">{{ prepend }}</span>
        </slot>
      </div>
      <input
        :disabled="disabled"
        ref="input"
        type="text"
        :class="`form-control ${inputClass} ${validationClass}`"
        placeholder=""
        :aria-label="placeholder"
        :value="inputValue"
        :required="required"
        :invalidFeedback="invalidFeedback"
        @focus="isFocused = true"
        @blur="handleBlur"
        @input="handleInput($event.target.value)"
        @keydown="handleKeyDown"
        autocomplete="off"
        @loading=false
      />
      <span
        class="close-btn"
      >
          <mdb-btn
            v-if="resetBtn && inputValue && inputValue.length > 0 && !isFocused"
            class="p-0 m-0"
            @click.native.stop="reset"
            flat
            icon="times"
            size="sm"
          ></mdb-btn>
        </span>
      <label ref="label" :class="(isFocused || (value && value.length !== 0)) ? 'active' : ''" @click="setFocus()">{{placeholder}}</label>
      <div class="invalid-feedback">
        {{invalidFeedback}}
      </div>
      <i ref="spinner" v-show="loading" class="fas new-dir-block-content-item-after" v-bind:class="{ 'fa-sync-alt': !loading, 'fa-sync-alt fa-spin': loading }" ></i>
      <div v-if="$slots.append || append" class="input-group-append">
        <slot name="append">
          <span class="input-group-text">{{ append }}</span>
        </slot>
      </div>
    </div>
    <TypeaheadList
      :ignoreMatching="ignoreMatching"
      class="vbt-autcomplete-list"
      ref="list"
      v-show="isFocused && data.length > 0"
      :query="inputValue"
      :data="formattedData"
      :background-variant="backgroundVariant"
      :text-variant="textVariant"
      :maxMatches="maxMatches"
      :minMatchingChars="minMatchingChars"
      @hit="handleHit"
      @keydown="handleKeyDown"
    >
      <!-- pass down all scoped slots -->
      <template v-for="(slot, slotName) in $scopedSlots" :slot="slotName" slot-scope="{ data, htmlText }">
        <slot :name="slotName" v-bind="{ data, htmlText }"></slot>
      </template>
      <!-- below is the right solution, however if the user does not provide a scoped slot, vue will still set $scopedSlots.suggestion to a blank scope
      <template v-if="$scopedSlots.suggestion" slot="suggestion" slot-scope="{ data, htmlText }">
        <slot name="suggestion" v-bind="{ data, htmlText }" />
      </template>-->
    </TypeaheadList>
  </div>
</template>

<script>
  import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
  import TypeaheadList from './TypeaheadList'

  export default {
    extends: VueBootstrapTypeahead,
    components: { TypeaheadList },
    props: {
      ignoreMatching: {
        type: Boolean,
        default: true
      },
      invalidFeedback: {
        type: String,
        default: 'invalidFeedback'
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      validationClass: {
        type: String,
        default: ''
      },
      resetBtn: {
        type: Boolean,
        default: false
      },
      minMatchingChars: {
        type: Number,
        default: 2
      },
      ignoreSetValue: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isFocused: false,
        loading: false,
        inputValue: this.value
      }
    },
    methods: {
      handleKeyDown (evt) {
        if (!this.isFocused || this.data.length < 1) return
        switch (evt.code) {
          case 'ArrowUp':
          case 'ArrowDown':
          case 'Enter':
            evt.preventDefault()
            this.$emit('selectionKeyPressed', evt)
            break
        }
      },
      setFocus () {
        if (this.$refs.input) {
          this.$refs.input.focus()
        }
      },
      handleHit (evt) {
        if (typeof this.value !== 'undefined' && evt && !this.ignoreSetValue) {
          this.inputValue = evt.text
        }

        if (evt) {
          // this.inputValue = evt.text
          this.$emit('hit', evt.data)
          this.$refs.input.blur()
          this.isFocused = false
        }
      },
      handleInput (newValue) {
        this.inputValue = newValue

        // If v-model is being used, emit an input event
        if (typeof this.value !== 'undefined') {
          this.$emit('input', newValue)
        }
      },
      reset () {
        this.$emit('input', '')
        this.$emit('reset')
      }
    },
    computed: {
      empty () {
        return this.value.length === 0
      }
    },
    watch: {
      'value' (newValue) {
        this.inputValue = newValue
      }
    }
  }
</script>

<style scoped>
.typeahead .input-group {
  display: block;
}
.list-group {
  position: absolute;
  z-index: 3;
  max-height: 400px;
  overflow-x: hidden;
}
input {
  background-image: none !important;
}
.close-btn {
  position: absolute;
  top: 9px;
  right: 5px;
  transition: all 0.2s ease-out;
  color: red;
}

.close-btn button {
  min-width: auto;
}

.close-btn:hover {
  transform: scale(1.2) ;
}
</style>
