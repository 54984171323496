import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class CarrierSelectItem {
  @Expose({ name: 'id' })
  public value: string | undefined

  @Expose({ name: 'name' })
  public text: string = ''

  @Expose({ name: 'selected' })
  public selected: boolean = false
}
