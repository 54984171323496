

// import '../assets/font/fontAwesome.css'
import '../assets/css/style.css'
// @ts-ignore
import mdbNavbar from 'mdbvue/lib/components/mdbNavbar'
// @ts-ignore
import mdbNavbarBrand from 'mdbvue/lib/components/mdbNavbarBrand'
// @ts-ignore
import mdbNavItem from 'mdbvue/lib/components/mdbNavItem'
// @ts-ignore
import mdbNavbarNav from 'mdbvue/lib/components/mdbNavbarNav'
// @ts-ignore
import mdbNavbarToggler from 'mdbvue/lib/components/mdbNavbarToggler'
// @ts-ignore
import waves from 'mdbvue/src/mixins/waves'
// @ts-ignore
import mdbTbl from 'mdbvue/lib/components/mdbTbl'
// @ts-ignore
import mdbTblBody from 'mdbvue/lib/components/mdbTblBody'
// @ts-ignore
import mdbContainer from 'mdbvue/lib/components/mdbContainer'
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import QuickSearchForm, { ENTITY_TYPES } from '@/components/QuickSearchForm.vue'
import { AuthModule } from '@/store/auth.module'
import { AppModule } from '@/store/app.module'
import { UsersApiService } from '@/common/api/users.api.service'
import { Styles } from '@/mixins/styles'
import { RawLocation } from 'vue-router/types/router'
import { convertAbilities } from '@/common/utility'
import AppApiService, { CacheResults, LogsState } from '@/common/api/app.api.service'
// @ts-ignore
import { LOGOUT } from '@/store/actions.type'
import { QuickSearchApiService } from '@/common/api/quick-search.api.service'
import { SALDO_TYPES } from '@/models/saldo'
import { UserRoles } from '@/common/user.roles'

@Component({
  components: {
    QuickSearchForm,
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbTbl,
    mdbTblBody
  },
  mixins: [waves, Styles]
})

export default class SiteHeader extends Vue {
  @Prop(Boolean) public showMenu!: boolean
  @Prop(Boolean) public slimCollapsed!: boolean
  @Prop(Object) public cacheResults!: CacheResults

  public modal: boolean = false
  public notifications: number = 18
  public offset: number = 1000
  public testMode: boolean = false
  public redisCache: boolean = false
  public dbCache: boolean = false
  public logsState: boolean = false

  public search: { entityId: string, entityType: string } = { entityId: '', entityType: '' }
  public searching: boolean = false

  public async created () {
    this.redisCache = this.cacheResults?.redisCacheState
    this.dbCache = this.cacheResults?.dbCacheState
    this.logsState = this.cacheResults?.logsState!
    this.testMode = this.$currentUser.options.includes('enable_test_mode')
  }

  public get showTestModeSwitch () {
    if (this.$route.name === 'shipments_index') return false
    if (this.$currentUser && this.$currentUser.options) {
      return this.$currentUser.options.includes('enable_test_mode')
    }
    return false
  }

  public get logoutTitle () {
    return (this.$currentUser && !this.$currentUser.adminUserId ? this.$t('header.logout') : this.$t('header.become_admin'))
  }


  public get accountIdFieldName () {
    return (this.$who.isCorporateAdministrator(this.$currentUser) || this.$who.isCorporateSupervisor(this.$currentUser)) ? 'corporate_account_id' : 'account_id'
  }

  public get currentTitle (): string {
    return AppModule.currentTitle
  }

  public get currentPreloader (): boolean {
    return AppModule.currentPreloader
  }

  public get activeJobs () {
    return AppModule.jobsList
  }

  public async toggleLogsState () {
    const logState: LogsState = await AppApiService.toggleLogsState()
    this.logsState = logState.logsState
  }

  public async toggleDBCache () {
    const cacheResults: CacheResults = await AppApiService.toggleDBCache()
    this.dbCache = cacheResults.dbCacheState
    this.redisCache = cacheResults.redisCacheState
  }

  public async toggleRedisCache () {
    const cacheResults: CacheResults = await AppApiService.toggleRedisCache()
    this.dbCache = cacheResults.dbCacheState
    this.redisCache = cacheResults.redisCacheState
  }

  public async onLogout () {
    if (this.$currentUser.adminUserId) {
      await UsersApiService.becomeAdmin()
      AuthModule.tokenLogin().then(async ({ data }) => {
        await AuthModule.identWhiteLabel().catch((e) => {
          this.$modal.show({
            type: 'danger',
            centered: true,
            headerText: this.$t('popup.titles.error_title'),
            bodyText: `${e}`,
            applyAction: async () => {/**/}
          })
        })
        this.$ability.update(convertAbilities(data.user.abilities))
        await this.$router.replace({ name: 'welcome', query: { t: new Date().getTime() } as {} } as RawLocation )
      }).catch((e) => {
        this.$modal.show({
          type: 'danger',
          centered: true,
          headerText: 'Error',
          bodyText: e
        })
      })
    } else {
      this.$modal.notify({
        type: 'warning',
        centered: true,
        headerText: this.$t('header.processing'),
        bodyText: '',
        applyBtnText: ''
      })
      await UsersApiService.tokenLogout()
      this.$router.push({ name: 'Logout' }).catch(() => { /**/ })
    }
  }

  public async toggleLeftSideNav () {
    this.$emit('showMenu', true)
  }

  public findRecord () {
    // @ts-ignore
    this.$refs['quickSearchForm'].submit()
  }

  public async redirectTo (params: { entityId: string, entityType: string, useId: boolean }) {
    this.searching = true
    switch (params.entityType) {
      case ENTITY_TYPES.ACCOUNT:
        if (params.useId) {
          const sResponse = await QuickSearchApiService.query(params.entityId, params.entityType, params.useId)
          if (sResponse) {
            const { data } = sResponse
            await this.$router.push({ name: 'enviaya_accounts_edit', params: { id: data[0].id.toString() }, hash: '#general' })
          } else {
            this.$modal.show({
              type: 'danger',
              centered: true,
              headerText: this.$t('quick_search_form.not_found.title'),
              bodyText: this.$t('quick_search_form.not_found.message')
            })
          }
        } else {
          await this.$router.push({ name: 'enviaya_accounts_edit', params: { id: params.entityId.toString() }, hash: '#general' })
        }
        this.modal = false
        break
      case ENTITY_TYPES.CARRIER_ACCOUNT:
        if (params.useId) {
          const sResponse = await QuickSearchApiService.query(params.entityId, params.entityType, params.useId)
          if (sResponse) {
            const { data } = sResponse
            await this.$router.push({ name: 'carrier_accounts_edit', params: { id: data[0].id.toString() }, hash: '#general' })
          } else {
            this.$modal.show({
              type: 'danger',
              centered: true,
              headerText: this.$t('quick_search_form.not_found.title'),
              bodyText: this.$t('quick_search_form.not_found.message')
            })
          }
        } else {
          await this.$router.push({ name: 'create_edit_rating_filters', query: { id: params.entityId.toString() }, hash: '#general' })
        }
        this.modal = false
        break
      case ENTITY_TYPES.CLEARING_CHARGE:
        if (params.useId) {
          const sResponse = await QuickSearchApiService.query(params.entityId, params.entityType, params.useId)
          if (sResponse) {
            const { data } = sResponse
            await this.$router.push({ name: 'clearing_charges_index', query: { id: data[0].id.toString() } }).catch(() => { /**/ })
          } else {
            this.$modal.show({
              type: 'danger',
              centered: true,
              headerText: this.$t('quick_search_form.not_found.title'),
              bodyText: this.$t('quick_search_form.not_found.message')
            })
          }
        } else {
          await this.$router.push({ name: 'clearing_charges_index', query: { id: params.entityId.toString() } }).catch(() => { /**/ })
        }
        this.modal = false
        break
      case ENTITY_TYPES.COMPANY:
        if (params.useId) {
          const sResponse = await QuickSearchApiService.query(params.entityId, params.entityType, params.useId)
          if (sResponse) {
            const { data } = sResponse
            await this.$router.push({ name: 'corporate_accounts_edit', params: { id: data[0].id.toString() } })
          } else {
            this.$modal.show({
              type: 'danger',
              centered: true,
              headerText: this.$t('quick_search_form.not_found.title'),
              bodyText: this.$t('quick_search_form.not_found.message')
            })
          }
        } else {
          await this.$router.push({ name: 'corporate_accounts_edit', params: { id: params.entityId.toString() } })
        }
        this.modal = false
        break
      case ENTITY_TYPES.SHIPMENT:
        const sResponse = await QuickSearchApiService.query(params.entityId, params.entityType)
        if (sResponse) {
          const { data } = sResponse
          if (data[0].editable) {
            await this.$router.push({ name: 'shipments_edit', params: { id: data[0].id.toString() } })
          } else {
            window.location.href = `${this.$redirectUrl}shipping/shipments/${data[0].id}/show_details`
          }
        } else {
          this.$modal.show({
            type: 'danger',
            centered: true,
            headerText: this.$t('quick_search_form.not_found.title'),
            bodyText: this.$t('quick_search_form.not_found.message')
          })
        }
        this.modal = false
        break
      case ENTITY_TYPES.USER:
        if (params.useId) {
          const sResponse = await QuickSearchApiService.query(params.entityId, params.entityType, params.useId)
          if (sResponse) {
            const { data } = sResponse
            await this.$router.push({ name: 'users_edit', params: { id: data[0].id.toString() } })
          } else {
            this.$modal.show({
              type: 'danger',
              centered: true,
              headerText: this.$t('quick_search_form.not_found.title'),
              bodyText: this.$t('quick_search_form.not_found.message')
            })
          }
        } else {
          await this.$router.push({ name: 'users_edit', params: { id: params.entityId.toString() } })
        }
        this.modal = false
        break
      case ENTITY_TYPES.SERVICE:
        const serviceResponse = await QuickSearchApiService.query(params.entityId, params.entityType)
        if (serviceResponse) {
          const { data } = serviceResponse
          await this.$router.push({ name: 'services_edit', params: { id: data[0].id.toString() } })
        } else {
          this.$modal.show({
            type: 'danger',
            centered: true,
            headerText: this.$t('quick_search_form.not_found.title'),
            bodyText: this.$t('quick_search_form.not_found.message')
          })
        }
        this.modal = false
        break
      case ENTITY_TYPES.SURCHARGE_PRICE:
        const profileResponse = await QuickSearchApiService.query(params.entityId, params.entityType)
        if (profileResponse) {
          const { data } = profileResponse
          window.location.href=`${this.$redirectUrl}shipping/surcharge_discounts/${data[0].id}/edit`
        } else {
          this.$modal.show({
            type: 'danger',
            centered: true,
            headerText: this.$t('quick_search_form.not_found.title'),
            bodyText: this.$t('quick_search_form.not_found.message')
          })
        }
        this.modal = false
        break
      case ENTITY_TYPES.CUSTOM_SERVICE:
        const cserviceResponse = await QuickSearchApiService.query(params.entityId, params.entityType)
        if (cserviceResponse) {
          const { data } = cserviceResponse
          window.location.href=`${this.$redirectUrl}shipping/custom_discounts/${data[0].id}/edit`
        } else {
          this.$modal.show({
            type: 'danger',
            centered: true,
            headerText: this.$t('quick_search_form.not_found.title'),
            bodyText: this.$t('quick_search_form.not_found.message')
          })
        }
        this.modal = false
        break
      case ENTITY_TYPES.DISCOUNT_PROFILE:
        if (params.useId) {
          const sResponse = await QuickSearchApiService.query(params.entityId, params.entityType, params.useId)
          if (sResponse) {
            const { data } = sResponse
            await this.$router.push({ name: 'discount_profiles', query: { id: data[0].id.toString() } }).catch(() => { /**/ })
          } else {
            this.$modal.show({
              type: 'danger',
              centered: true,
              headerText: this.$t('quick_search_form.not_found.title'),
              bodyText: this.$t('quick_search_form.not_found.message')
            })
          }
        } else {
          await this.$router.push({ name: 'discount_profiles', query: { id: params.entityId.toString() } }).catch(() => { /**/ })
        }
        this.modal = false
        break
    }
  }

  public saldoLink (type) {
    if (this.$currentUser.role === UserRoles.USER_ROLE_CORPORATE_SUPERVISOR || this.$who.isCorporateUser(this.$currentUser)) {
      return '#'
    }
    switch (type) {
      case SALDO_TYPES.CREDIT:
        return `${this.$redirectUrl}shipping/credit_orders_balance/index?${this.$currentUser.creditSaldo.accountIdFieldName}=${this.$currentUser.creditSaldo.accountId}&order_type=${this.$currentUser.creditSaldo.orderType}`
      case SALDO_TYPES.NATIONAL:
        return `${this.$redirectUrl}shipping/credit_orders_balance/index?${this.$currentUser.nationalSaldo.accountIdFieldName}=${this.$currentUser.nationalSaldo.accountId}&order_type=${this.$currentUser.nationalSaldo.orderType}`
      case SALDO_TYPES.INTERNATIONAL:
        return `${this.$redirectUrl}shipping/credit_orders_balance/index?${this.$currentUser.internationalSaldo.accountIdFieldName}=${this.$currentUser.internationalSaldo.accountId}&order_type=${this.$currentUser.internationalSaldo.orderType}`
    }
  }

  public get saldoTypes () {
    return SALDO_TYPES
  }

  @Watch('testMode')
  public async updateTestMode () {
    if (this.testMode && !this.$currentUser.options.includes('enable_test_mode')) {
      this.$currentUser.options.push('enable_test_mode')
    } else if (!this.testMode && this.$currentUser.options.includes('enable_test_mode')) {
      this.$currentUser.options = this.$currentUser.options.filter((option: string) => option !== 'enable_test_mode')
    }
    await UsersApiService.updateUserTestModeGQL(this.$currentUser.id, this.testMode)
  }
}
