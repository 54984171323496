import { AbstractModelList } from '@/models/abstract-model-list'
import { CarrierAccount } from '@/models/carrier-account'
import { Exclude, Expose, Transform } from 'class-transformer'

@Exclude()
export class UserCarrierAccountsList extends AbstractModelList<CarrierAccount> {
  @Expose({ name: 'active_nodes_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public activeNodesCount: number = 0

  @Expose({ name: 'disabled_nodes_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public disabledNodesCount: number = 0

  @Expose({ name: 'deleted_nodes_count' })
  @Transform(value => Number(value) || 0, { toClassOnly: true })
  public deletedNodesCount: number = 0
}
