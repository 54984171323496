<i18n src="@/locales/footer.json"></i18n>
<template>
<!-- Footer -->
  <footer class="page-footer">
    <div class="row flex-nowrap">
      <div class="d-none d-md-flex col-md-3 col-lg-3 col-xl-2 align-self-start">
        <div class="foo-wrapper links">
          <img
            class="footer_logo"
            :alt="brandName"
            :title="brandName"
            :src="footerLogo"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-5 col-xl-5 align-self-start mt-md-0">
        <div class="foo-wrapper links">
          <h3>{{$t('footer.links_title')}}</h3>
          <a :href="termsLink">{{$t('footer.links.terms_and_conditions')}}</a>
          <a :href="policyLink">{{$t('footer.links.policy')}}</a>
          <a v-if="!$currentWhiteLabel" href="https://enviaya.com.mx/es/contacto">{{$t('footer.links.contacts')}}</a>
        </div>
      </div>
      <div class="col-md-3 col-lg-3 col-xl-2 align-self-start mt-md-0">
        <div class="foo-wrapper foo-wrapper_contact text-left">
          <h3>{{$t('footer.tickets')}}</h3>
          <div class="mb-1">
            <mdb-icon icon="envelope" size="lg" class="pr-3" />
            <a v-if="!$currentWhiteLabel" href="https://soporte.enviaya.com.mx/portal/es/signin">{{$t('footer.open_ticket')}}</a>
            <a v-else :href="`mailto:${$currentWhiteLabel.supportEmail}`">{{$currentWhiteLabel.supportEmail}}</a>
          </div>
          <div class="d-flex">
            <mdb-icon far icon="clock" size="lg" class="pr-3" />
            <div>
              <p class='mb-0'>{{supportsHours}}</p>
              <p class="foo-intel">{{timeZone}}</p>
            </div>
          </div>
          <language-select-box />
        </div>
      </div>
    </div>
    <swiper class="swiper" :options="swiperOptions" v-if="!$currentWhiteLabel">
      <swiper-slide class="slide-1" title="Fedex" />
      <swiper-slide class="slide-2" title="99 Minutos" />
      <swiper-slide class="slide-3" title="AMPM" />
      <swiper-slide class="slide-4" title="Estafeta" />
      <swiper-slide class="slide-5" title="iVoy" />
      <swiper-slide class="slide-6" title="Paquetexpress" />
      <swiper-slide class="slide-7" title="Redpack" />
      <swiper-slide class="slide-8" title="Sendex" />
      <swiper-slide class="slide-9" title="Tracusa" />
      <swiper-slide class="slide-10" title="Tresguerras" />
      <swiper-slide class="slide-11" title="UPS" />
    </swiper>
</footer>
<!--Footer-->
</template>

<script>
import Vue from 'vue'
import mdbCardImage from 'mdbvue/lib/components/mdbCardImage'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
import 'swiper/swiper-bundle.css'
import { Brand } from '@/mixins/brand'
import { Styles } from '@/mixins/styles'
import { CONFIGURATION_KEYS } from '@/common/config'
import LanguageSelectBox from '@/components/select-boxes/LanguageSelectBox.vue'

export default {
  name: 'SiteFooter',
  mixins: [Brand, Styles],
  components: {
    mdbCardImage,
    Swiper,
    SwiperSlide,
    LanguageSelectBox
  },
  data () {
    return {
      title: {
        country: 'Country',
        links: 'Links',
        payment: 'Payment Methods',
        about: 'About us',
        contact: 'Contact us'
      },
      swiperOptions: {
        slidesPerView: 8,
        autoplay: ['production', 'staging'].includes(Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT)) ? {
          delay: 2500,
          disableOnInteraction: false
        } : false,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4
          },
          1125: {
            lidesPerView: 8
          }
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    supportsHours () {
      if (!this.$currentWhiteLabel) {
        return '09:00 - 19:00'
      } else {
        return (this.$currentWhiteLabel.supportsHours) ? this.$currentWhiteLabel.supportsHours : '09:00 - 19:00'
      }
    },
    timeZone () {
      if (!this.$currentWhiteLabel) {
        return '(Horario UTC -6:00 Mexico City)'
      } else {
        return (this.$currentWhiteLabel.timeZone) ? this.$currentWhiteLabel.timeZone : '(Horario UTC -6:00 Mexico City)'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper {
  padding: 10px 20px 0 20px;
  .swiper-slide {
    height: 100px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    &.slide-1 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/fedex/fedex-centro-autorizado-de-envio.png');
      background-size: 160px;
    }
    &.slide-2 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/99minutos/99minutos.svg');
      background-size: 90px;
    }
    &.slide-3 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ampm/ampm.svg');
      background-size: 90px;
    }
    &.slide-4 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/estafeta/estafeta.svg');
      background-size: 90px;
    }
    &.slide-5 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ivoy/ivoy.svg');
      background-size: 90px;
    }
    &.slide-6 {
      background-image:url('https://enviaya-public.s3.us-west-1.amazonaws.com/carriers/paquetexpress/paquetexpress.svg');
      background-size: 90px;
    }
    &.slide-7 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/redpack/redpack.svg');
      background-size: 90px;
    }
    &.slide-8 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/sendex/sendex.svg');
      background-size: 90px;
    }
    &.slide-9 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/tracusa/tracusa.png');
      background-size: 90px;
    }
    &.slide-10 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/tresguerras/tresguerras.svg');
      background-size: 130px;
    }
    &.slide-11 {
      background-image:url('https://enviaya-public.s3-us-west-1.amazonaws.com/carriers/ups/ups.svg');
      background-size: 30px;
    }
  }
}
</style>

<style scoped>
footer {
  margin-top: 6rem;
  padding-top: 30px;
  border-top: 1px solid #CCC;
  background: var(--footer-bg-color);
}
.footer_logo {
  width: 150px;
  cursor: pointer;
}
footer .md-form {
    margin: 0;
}
.foo-wrapper {
    margin-bottom: 0.5rem;
    color: var(--footer-font-color);
}
footer h3 {
    font-weight: 700;
    font-size: 16px;
    color: var(--footer-font-color);
}
footer.page-footer a {
    font-size: 1rem;
    line-height: 1.4;
    color: var(--footer-font-color);
}
footer p, footer span{
    line-height: 1.4;
    font-size: 1rem;
}
.foo-wrapper .icn-input_email {
    color: #f60;
    font-size: 14px;
    line-height: 1.4;
}
.foo-wrapper .icn-shipmennts_user_schedule {
    font-size: 16px;
    line-height: 1.4;
    color: #000;
}
.foo-intel {
    font-size: 0.75rem;
}
.links a{
  display: block;
  color: var(--footer-link-color)!important;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 26px!important;
}
footer a:hover{
  color: var(--footer-link-hover-color)!important;
}
.fa-lg{
  vertical-align: middle;
}
.fa-map-marker-alt, .fa-clock{
  margin-top: 10px;
}
.page-footer .row {
  justify-content: space-between;
}
.page-footer {
  margin-top: auto;
  padding-bottom: 10px;
}
@media (max-width: 640px) {
  .footer_logo {
    width: 100px;
  }
  .foo-wrapper {
    font-size: 12px;
  }
  .links a {
    font-size: 12px !important;
  }
}
@media (max-width: 540px) {
  ::v-deep .swiper-wrapper {
    display: none;
  }
  .links a {
    font-size: 12px !important;
  }
  .footer_logo {
    width: 80px;
  }
  .page-footer {
    background-color: white;
  }
}

@media (max-width: 767px) {
  .foo-wrapper.links {
    margin: 0 auto;
    width: 80% !important;
  }
  .foo-wrapper_contact a{
    font-size: 12px !important;
  }
}

.foo-wrapper_contact p{
  margin-bottom: .25rem;
}

.foo-wrapper.links {
  margin: 0 auto;
  width: 50%;
}
</style>

<style>
.footer_country .select2-selection--single{
    outline: none!important;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    border-radius: 0!important;
    border-bottom: 1px solid #ced4da!important;
    padding-bottom: 5px!important;
    min-width: 200px!important;
    background: transparent;
}
.footer_country .select2-container .select2-selection--single .select2-selection__rendered{
  padding-left: 0;
}
.footer_country .select2-selection--single span{
  font-size: 1rem;
  vertical-align: middle;
  padding-left: 5px;
}
</style>
