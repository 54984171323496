<template>
  <div v-html="html">
  </div>
</template>

<script>
import { countrySelectHelper } from '@/mixins/countrySelectHelper'
export default {
  props: ['val'],
  mixins: [ countrySelectHelper ],
  data () {
    return {
      html: `
      <img class="img-flag" src="${require('../../assets/img/flag/4x3/' + this.getCountryByLocale(this.val).toLowerCase() + '.svg')}" width="20">
  `
    }
  },
  watch: {
    'val': {
      immediate: true,
      handler: function (after, before) {
        this.html = `
      <img class="img-flag" src="${require('../../assets/img/flag/4x3/' + this.getCountryByLocale(after).toLowerCase() + '.svg')}" width="20">
  `
      }
    }
  }
}
</script>
