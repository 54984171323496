import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class CombinedAddress {
  @Expose({ name: 'id' })
  public id: string = ''

  @Expose({ name: 'class_name' })
  public entityType: string = ''

  @Expose({ name: 'municipality' })
  public municipality: string = ''

  @Expose({ name: 'city' })
  public city: string = ''

  @Expose({ name: 'id' })
  public cityId: number | undefined | null = null

  @Expose({ name: 'country_code' })
  public countryCode: string = ''

  @Expose({ name: 'neighborhood' })
  public neighborhood: string = ''

  @Expose({ name: 'postal_code' })
  public postalCode: string = ''

  @Expose({ name: 'state_code' })
  public stateCode: string = ''

  @Expose({ name: 'value' })
  public value: string = ''

  @Expose({ name: 'district' })
  public district: string = ''

  @Expose({ name: 'phone' })
  public phone: string = ''

  @Expose({ name: 'full_name' })
  public fullName: string = ''

  @Expose({ name: 'company' })
  public company: string = ''

  @Expose({ name: 'email' })
  public email: string = ''

  @Expose({ name: 'direction_1' })
  public direction1: string = ''

  @Expose({ name: 'direction_2' })
  public direction2: string = ''

  @Expose({ name: 'tax_id' })
  public taxId: string = ''

  @Expose({ name: 'longitude' })
  public longitude: string = ''

  @Expose({ name: 'latitude' })
  public latitude: string = ''

  public get text () {
    if (this.company) {
      return `${this.value} (${this.company})`
    }
    return this.value
  }
}
