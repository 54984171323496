import i18n from '@/i18n'
import { WHO } from '@/common/user.roles'
import { AuthModule } from '@/store/auth.module'
import { CorporateAccountStatuses } from '@/constants/corporate-account'

export function useCorporateAccountRegularStatuses(selectedValue: number[] | number | undefined | null) {
  return Object.values(CorporateAccountStatuses)
    .filter((value) => Number.isInteger(value as number) && value !== CorporateAccountStatuses.DISABLED_SYSTEM)
    .map((status) => {
      return {
        text: i18n.t(`user_statuses.status_${status}`) as string,
        value: status,
        selected: isSelected(selectedValue, status)
      }
    })
}

export function useCorporateAccountStatuses(selectedValue: number[] | number | undefined | null) {
  return Object.values(CorporateAccountStatuses)
    .filter(
      (value) =>
        (Number.isInteger(value as number) && value !== CorporateAccountStatuses.DISABLED_SYSTEM) ||
        (value === CorporateAccountStatuses.DISABLED_SYSTEM && WHO.adminCSorAccounting(AuthModule.currentUser.role))
    )
    .map((status) => {
      return {
        text: i18n.t(`user_statuses.status_${status}`) as string,
        value: status,
        selected: isSelected(selectedValue, status)
      }
    })
}

function isSelected(selectedValue, currentValue) {
  return (
    !!selectedValue &&
    ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toString()).includes(currentValue.toString())) ||
      (!Array.isArray(selectedValue) && selectedValue.toString() === currentValue.toString()))
  )
}
