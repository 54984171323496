import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class Group {
  @Expose({ name: 'name' })
  public name: string | undefined

  @Expose({ name: 'users_count' })
  public usersCount: number | undefined
}
