import { Mixin } from 'vue-mixin-decorator'
import BasicComponent from '@/views/BasicComponent'

@Mixin
export class TimeZoneSelectHelper extends BasicComponent {
  public timeZone: { value: string, text: string, selected: boolean }[] = [{
    value: 'Etc/GMT+12',
    text: '(UTC-12:00) International Date Line West',
    selected: false
  }, {
    value: 'Pacific/Pago_Pago',
    text: '(UTC-11:00) American Samoa',
    selected: false
  }, {
    value: 'Pacific/Midway',
    text: '(UTC-11:00) Midway Island',
    selected: false
  }, {
    value: 'Pacific/Honolulu',
    text: '(UTC-10:00) Hawaii',
    selected: false
  }, {
    value: 'America/Juneau',
    text: '(UTC-09:00) Alaska',
    selected: false
  }, {
    value: 'America/Los_Angeles',
    text: '(UTC-08:00) Pacific Time (US & Canada)',
    selected: false
  }, {
    value: 'America/Tijuana',
    text: '(UTC-08:00) Tijuana',
    selected: false
  }, {
    value: 'America/Phoenix',
    text: '(UTC-07:00) Arizona',
    selected: false
  }, {
    value: 'America/Chihuahua',
    text: '(UTC-07:00) Chihuahua',
    selected: false
  }, {
    value: 'America/Mazatlan',
    text: '(UTC-07:00) Mazatlan',
    selected: false
  }, {
    value: 'America/Denver',
    text: '(UTC-07:00) Mountain Time (US & Canada)',
    selected: false
  }, {
    value: 'America/Guatemala',
    text: '(UTC-06:00) Central America',
    selected: false
  }, {
    value: 'America/Chicago',
    text: '(UTC-06:00) Central Time (US & Canada)',
    selected: false
  }, {
    value: 'America/Mexico_City',
    text: '(UTC-06:00) Mexico City',
    selected: false
  }, {
    value: 'America/Mexico_City',
    text: '(UTC-06:00) Guadalajara',
    selected: false
  }, {
    value: 'America/Monterrey',
    text: '(UTC-06:00) Monterrey',
    selected: false
  }, {
    value: 'America/Regina',
    text: '(UTC-06:00) Saskatchewan',
    selected: false
  }, {
    value: 'America/Bogota',
    text: '(UTC-05:00) Bogota',
    selected: false
  }, {
    value: 'America/New_York',
    text: '(UTC-05:00) Eastern Time (US & Canada)',
    selected: false
  }, {
    value: 'America/Indiana/Indianapolis',
    text: '(UTC-05:00) Indiana (East)',
    selected: false
  }, {
    value: 'America/Lima',
    text: '(UTC-05:00) Lima',
    selected: false
  }, {
    value: 'America/Lima',
    text: '(UTC-05:00) Quito',
    selected: false
  }, {
    value: 'America/Halifax',
    text: '(UTC-04:00) Atlantic Time (Canada)',
    selected: false
  }, {
    value: 'America/Caracas',
    text: '(UTC-04:00) Caracas',
    selected: false
  }, {
    value: 'America/Guyana',
    text: '(UTC-04:00) Georgetown',
    selected: false
  }, {
    value: 'America/La_Paz',
    text: '(UTC-04:00) La Paz',
    selected: false
  }, {
    value: 'America/Puerto_Rico',
    text: '(UTC-04:00) Puerto Rico',
    selected: false
  }, {
    value: 'America/Santiago',
    text: '(UTC-04:00) Santiago',
    selected: false
  }, {
    value: 'America/St_Johns',
    text: '(UTC-03:30) Newfoundland',
    selected: false
  }, {
    value: 'America/Sao_Paulo',
    text: '(UTC-03:00) Brasilia',
    selected: false
  }, {
    value: 'America/Argentina/Buenos_Aires',
    text: '(UTC-03:00) Buenos Aires',
    selected: false
  }, {
    value: 'America/Godthab',
    text: '(UTC-03:00) Greenland',
    selected: false
  }, {
    value: 'America/Montevideo',
    text: '(UTC-03:00) Montevideo',
    selected: false
  }, {
    value: 'Atlantic/South_Georgia',
    text: '(UTC-02:00) Mid-Atlantic',
    selected: false
  }, {
    value: 'Atlantic/Azores',
    text: '(UTC-01:00) Azores',
    selected: false
  }, {
    value: 'Atlantic/Cape_Verde',
    text: '(UTC-01:00) Cape Verde Is.',
    selected: false
  }, {
    value: 'Africa/Casablanca',
    text: '(UTC+00:00) Casablanca',
    selected: false
  }, {
    value: 'Europe/Dublin',
    text: '(UTC+00:00) Dublin',
    selected: false
  }, {
    value: 'Europe/London',
    text: '(UTC+00:00) Edinburgh',
    selected: false
  }, {
    value: 'Europe/Lisbon',
    text: '(UTC+00:00) Lisbon',
    selected: false
  }, {
    value: 'Europe/London',
    text: '(UTC+00:00) London',
    selected: false
  }, {
    value: 'Africa/Monrovia',
    text: '(UTC+00:00) Monrovia',
    selected: false
  }, {
    value: 'Etc/UTC',
    text: '(UTC+00:00) UTC',
    selected: false
  }, {
    value: 'Europe/Amsterdam',
    text: '(UTC+01:00) Amsterdam',
    selected: false
  }, {
    value: 'Europe/Belgrade',
    text: '(UTC+01:00) Belgrade',
    selected: false
  }, {
    value: 'Europe/Berlin',
    text: '(UTC+01:00) Berlin',
    selected: false
  }, {
    value: 'Europe/Zurich',
    text: '(UTC+01:00) Bern',
    selected: false
  }, {
    value: 'Europe/Bratislava',
    text: '(UTC+01:00) Bratislava',
    selected: false
  }, {
    value: 'Europe/Brussels',
    text: '(UTC+01:00) Brussels',
    selected: false
  }, {
    value: 'Europe/Budapest',
    text: '(UTC+01:00) Budapest',
    selected: false
  }, {
    value: 'Europe/Copenhagen',
    text: '(UTC+01:00) Copenhagen',
    selected: false
  }, {
    value: 'Europe/Ljubljana',
    text: '(UTC+01:00) Ljubljana',
    selected: false
  }, {
    value: 'Europe/Madrid',
    text: '(UTC+01:00) Madrid',
    selected: false
  }, {
    value: 'Europe/Paris',
    text: '(UTC+01:00) Paris',
    selected: false
  }, {
    value: 'Europe/Prague',
    text: '(UTC+01:00) Prague',
    selected: false
  }, {
    value: 'Europe/Rome',
    text: '(UTC+01:00) Rome',
    selected: false
  }, {
    value: 'Europe/Sarajevo',
    text: '(UTC+01:00) Sarajevo',
    selected: false
  }, {
    value: 'Europe/Skopje',
    text: '(UTC+01:00) Skopje',
    selected: false
  }, {
    value: 'Europe/Stockholm',
    text: '(UTC+01:00) Stockholm',
    selected: false
  }, {
    value: 'Europe/Vienna',
    text: '(UTC+01:00) Vienna',
    selected: false
  }, {
    value: 'Europe/Warsaw',
    text: '(UTC+01:00) Warsaw',
    selected: false
  }, {
    value: 'Africa/Algiers',
    text: '(UTC+01:00) West Central Africa',
    selected: false
  }, {
    value: 'Europe/Zagreb',
    text: '(UTC+01:00) Zagreb',
    selected: false
  }, {
    value: 'Europe/Zurich',
    text: '(UTC+01:00) Zurich',
    selected: false
  }, {
    value: 'Europe/Athens',
    text: '(UTC+02:00) Athens',
    selected: false
  }, {
    value: 'Europe/Bucharest',
    text: '(UTC+02:00) Bucharest',
    selected: false
  }, {
    value: 'Africa/Cairo',
    text: '(UTC+02:00) Cairo',
    selected: false
  }, {
    value: 'Africa/Harare',
    text: '(UTC+02:00) Harare',
    selected: false
  }, {
    value: 'Europe/Helsinki',
    text: '(UTC+02:00) Helsinki',
    selected: false
  }, {
    value: 'Asia/Jerusalem',
    text: '(UTC+02:00) Jerusalem',
    selected: false
  }, {
    value: 'Europe/Kaliningrad',
    text: '(UTC+02:00) Kaliningrad',
    selected: false
  }, {
    value: 'Europe/Kiev',
    text: '(UTC+02:00) Kyiv',
    selected: false
  }, {
    value: 'Africa/Johannesburg',
    text: '(UTC+02:00) Pretoria',
    selected: false
  }, {
    value: 'Europe/Riga',
    text: '(UTC+02:00) Riga',
    selected: false
  }, {
    value: 'Europe/Sofia',
    text: '(UTC+02:00) Sofia',
    selected: false
  }, {
    value: 'Europe/Tallinn',
    text: '(UTC+02:00) Tallinn',
    selected: false
  }, {
    value: 'Europe/Vilnius',
    text: '(UTC+02:00) Vilnius',
    selected: false
  }, {
    value: 'Asia/Baghdad',
    text: '(UTC+03:00) Baghdad',
    selected: false
  }, {
    value: 'Europe/Istanbul',
    text: '(UTC+03:00) Istanbul',
    selected: false
  }, {
    value: 'Asia/Kuwait',
    text: '(UTC+03:00) Kuwait',
    selected: false
  }, {
    value: 'Europe/Minsk',
    text: '(UTC+03:00) Minsk',
    selected: false
  }, {
    value: 'Europe/Moscow',
    text: '(UTC+03:00) Moscow',
    selected: false
  }, {
    value: 'Africa/Nairobi',
    text: '(UTC+03:00) Nairobi',
    selected: false
  }, {
    value: 'Asia/Riyadh',
    text: '(UTC+03:00) Riyadh',
    selected: false
  }, {
    value: 'Europe/Moscow',
    text: '(UTC+03:00) St. Petersburg',
    selected: false
  }, {
    value: 'Asia/Tehran',
    text: '(UTC+03:30) Tehran',
    selected: false
  }, {
    value: 'Asia/Muscat',
    text: '(UTC+04:00) Abu Dhabi',
    selected: false
  }, {
    value: 'Asia/Baku',
    text: '(UTC+04:00) Baku',
    selected: false
  }, {
    value: 'Asia/Muscat',
    text: '(UTC+04:00) Muscat',
    selected: false
  }, {
    value: 'Europe/Samara',
    text: '(UTC+04:00) Samara',
    selected: false
  }, {
    value: 'Asia/Tbilisi',
    text: '(UTC+04:00) Tbilisi',
    selected: false
  }, {
    value: 'Europe/Volgograd',
    text: '(UTC+04:00) Volgograd',
    selected: false
  }, {
    value: 'Asia/Yerevan',
    text: '(UTC+04:00) Yerevan',
    selected: false
  }, {
    value: 'Asia/Kabul',
    text: '(UTC+04:30) Kabul',
    selected: false
  }, {
    value: 'Asia/Yekaterinburg',
    text: '(UTC+05:00) Ekaterinburg',
    selected: false
  }, {
    value: 'Asia/Karachi',
    text: '(UTC+05:00) Islamabad',
    selected: false
  }, {
    value: 'Asia/Karachi',
    text: '(UTC+05:00) Karachi',
    selected: false
  }, {
    value: 'Asia/Tashkent',
    text: '(UTC+05:00) Tashkent',
    selected: false
  }, {
    value: 'Asia/Kolkata',
    text: '(UTC+05:30) Chennai',
    selected: false
  }, {
    value: 'Asia/Kolkata',
    text: '(UTC+05:30) Kolkata',
    selected: false
  }, {
    value: 'Asia/Kolkata',
    text: '(UTC+05:30) Mumbai',
    selected: false
  }, {
    value: 'Asia/Kolkata',
    text: '(UTC+05:30) New Delhi',
    selected: false
  }, {
    value: 'Asia/Colombo',
    text: '(UTC+05:30) Sri Jayawardenepura',
    selected: false
  }, {
    value: 'Asia/Kathmandu',
    text: '(UTC+05:45) Kathmandu',
    selected: false
  }, {
    value: 'Asia/Almaty',
    text: '(UTC+06:00) Almaty',
    selected: false
  }, {
    value: 'Asia/Dhaka',
    text: '(UTC+06:00) Astana',
    selected: false
  }, {
    value: 'Asia/Dhaka',
    text: '(UTC+06:00) Dhaka',
    selected: false
  }, {
    value: 'Asia/Urumqi',
    text: '(UTC+06:00) Urumqi',
    selected: false
  }, {
    value: 'Asia/Rangoon',
    text: '(UTC+06:30) Rangoon',
    selected: false
  }, {
    value: 'Asia/Bangkok',
    text: '(UTC+07:00) Bangkok',
    selected: false
  }, {
    value: 'Asia/Bangkok',
    text: '(UTC+07:00) Hanoi',
    selected: false
  }, {
    value: 'Asia/Jakarta',
    text: '(UTC+07:00) Jakarta',
    selected: false
  }, {
    value: 'Asia/Krasnoyarsk',
    text: '(UTC+07:00) Krasnoyarsk',
    selected: false
  }, {
    value: 'Asia/Novosibirsk',
    text: '(UTC+07:00) Novosibirsk',
    selected: false
  }, {
    value: 'Asia/Shanghai',
    text: '(UTC+08:00) Beijing',
    selected: false
  }, {
    value: 'Asia/Chongqing',
    text: '(UTC+08:00) Chongqing',
    selected: false
  }, {
    value: 'Asia/Hong_Kong',
    text: '(UTC+08:00) Hong Kong',
    selected: false
  }, {
    value: 'Asia/Irkutsk',
    text: '(UTC+08:00) Irkutsk',
    selected: false
  }, {
    value: 'Asia/Kuala_Lumpur',
    text: '(UTC+08:00) Kuala Lumpur',
    selected: false
  }, {
    value: 'Australia/Perth',
    text: '(UTC+08:00) Perth',
    selected: false
  }, {
    value: 'Asia/Singapore',
    text: '(UTC+08:00) Singapore',
    selected: false
  }, {
    value: 'Asia/Taipei',
    text: '(UTC+08:00) Taipei',
    selected: false
  }, {
    value: 'Asia/Ulaanbaatar',
    text: '(UTC+08:00) Ulaanbaatar',
    selected: false
  }, {
    value: 'Asia/Tokyo',
    text: '(UTC+09:00) Osaka',
    selected: false
  }, {
    value: 'Asia/Tokyo',
    text: '(UTC+09:00) Sapporo',
    selected: false
  }, {
    value: 'Asia/Seoul',
    text: '(UTC+09:00) Seoul',
    selected: false
  }, {
    value: 'Asia/Tokyo',
    text: '(UTC+09:00) Tokyo',
    selected: false
  }, {
    value: 'Asia/Yakutsk',
    text: '(UTC+09:00) Yakutsk',
    selected: false
  }, {
    value: 'Australia/Adelaide',
    text: '(UTC+09:30) Adelaide',
    selected: false
  }, {
    value: 'Australia/Darwin',
    text: '(UTC+09:30) Darwin',
    selected: false
  }, {
    value: 'Australia/Brisbane',
    text: '(UTC+10:00) Brisbane',
    selected: false
  }, {
    value: 'Australia/Melbourne',
    text: '(UTC+10:00) Canberra',
    selected: false
  }, {
    value: 'Pacific/Guam',
    text: '(UTC+10:00) Guam',
    selected: false
  }, {
    value: 'Australia/Hobart',
    text: '(UTC+10:00) Hobart',
    selected: false
  }, {
    value: 'Australia/Melbourne',
    text: '(UTC+10:00) Melbourne',
    selected: false
  }, {
    value: 'Pacific/Port_Moresby',
    text: '(UTC+10:00) Port Moresby',
    selected: false
  }, {
    value: 'Australia/Sydney',
    text: '(UTC+10:00) Sydney',
    selected: false
  }, {
    value: 'Asia/Vladivostok',
    text: '(UTC+10:00) Vladivostok',
    selected: false
  }, {
    value: 'Asia/Magadan',
    text: '(UTC+11:00) Magadan',
    selected: false
  }, {
    value: 'Pacific/Noumea',
    text: '(UTC+11:00) New Caledonia',
    selected: false
  }, {
    value: 'Pacific/Guadalcanal',
    text: '(UTC+11:00) Solomon Is.',
    selected: false
  }, {
    value: 'Asia/Srednekolymsk',
    text: '(UTC+11:00) Srednekolymsk',
    selected: false
  }, {
    value: 'Pacific/Auckland',
    text: '(UTC+12:00) Auckland',
    selected: false
  }, {
    value: 'Pacific/Fiji',
    text: '(UTC+12:00) Fiji',
    selected: false
  }, {
    value: 'Asia/Kamchatka',
    text: '(UTC+12:00) Kamchatka',
    selected: false
  }, {
    value: 'Pacific/Majuro',
    text: '(UTC+12:00) Marshall Is.',
    selected: false
  }, {
    value: 'Pacific/Auckland',
    text: '(UTC+12:00) Wellington',
    selected: false
  }, {
    value: 'Pacific/Chatham',
    text: '(UTC+12:45) Chatham Is.',
    selected: false
  }, {
    value: 'Pacific/Tongatapu',
    text: '(UTC+13:00) Nuku\'alofa',
    selected: false
  }, {
    value: 'Pacific/Apia',
    text: '(UTC+13:00) Samoa',
    selected: false
  }, {
    value: 'Pacific/Fakaofo',
    text: '(UTC+13:00) Tokelau Is.',
    selected: false
  }
  ]

  public initTimeZoneList (selectedZone?: string) {
    this.timeZone.forEach((zone: { value: string, text: string, selected: boolean }) => {
      if (zone.value === selectedZone) {
        zone.selected = true
      } else {
        zone.selected = false
      }
    })
    this.timeZone.sort()
  }
}
