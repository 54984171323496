import { render, staticRenderFns } from "./ExtendedFilters.vue?vue&type=template&id=1f1f84f4&scoped=true"
import script from "./ExtendedFilters.vue?vue&type=script&lang=ts"
export * from "./ExtendedFilters.vue?vue&type=script&lang=ts"
import style0 from "./ExtendedFilters.vue?vue&type=style&index=0&id=1f1f84f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f1f84f4",
  null
  
)

/* custom blocks */
import block0 from "@/locales/top_bar.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FExtendedFilters.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports