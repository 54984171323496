import { AuthModule } from "@/store/auth.module"
import {AppModule} from "@/store/app.module";

export default {
  computed: {
    $currentUser() {
      return AuthModule.currentUser
    },
    $currentWhiteLabel() {
      return AuthModule.currentWhiteLabel
    },
    $userAuthenticated () {
      return AuthModule.currentUser
    },
    $carriers () {
      return AppModule.carriersList
    }
  }
}
