import i18n from '@/i18n'
import { SERVICE_NAME_LAYOUTS } from '@/models/EnviayaUserTS'

export function useServiceNameLayouts(
  selectedValue: number[] | number | undefined | null,
  nullableOptionText?: string
) {
  let layouts = [
    ...Object.values(SERVICE_NAME_LAYOUTS)
      .filter((value) => Number.isInteger(value as number))
      .map((value) => {
        return {
          text: i18n.t(`user_settings.settings.layout_names.${value}`) as string,
          value: value,
          selected:
            !!selectedValue &&
            ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toString()).includes(value.toString())) ||
              (!Array.isArray(selectedValue) && selectedValue.toString() === value.toString()))
        }
      })
  ]
  if (nullableOptionText) {
    layouts.unshift({
      text: nullableOptionText,
      value: null,
      selected: !selectedValue
    })
  }
  return layouts
}
