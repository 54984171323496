import i18n from '@/i18n'
import { SHIPMENT_REVISION_STATUSES } from '@/constants/clearing-charge'

export function useRevisionStatuses(selectedValue: string[] | string | undefined | null) {
  return Object.values(SHIPMENT_REVISION_STATUSES)
    .filter((value) => Number.isInteger(value as number))
    .map((status) => {
      return {
        text: i18n.t(`revision_status.revision_status_${status}`) as string,
        value: status,
        selected:
          !!selectedValue &&
          ((Array.isArray(selectedValue) && selectedValue.map(val => Number(val)).includes(status as number)) ||
            (!Array.isArray(selectedValue) && Number(selectedValue) === status as number))
      }
    })
}
