import AccountService from '@/common/api/accounts.api.service.ts'

const updateStep = async (context, step) => {
  context.commit('STEP_UPDATED', step)
}

const initContactInformation = async (context) => {
  context.commit('CONTACT_INFORMATION_UPDATED', {
    'first_name': context.rootGetters.currentUser.firstName,
    'last_name': context.rootGetters.currentUser.lastName,
    'email': context.rootGetters.currentUser.email,
    'company': context.rootGetters.currentUser.company,
    'phone': context.rootGetters.currentUser.phone
  })
}

const initCustomerInformation = async (context) => {
  context.commit('CUSTOMER_INFORMATION_UPDATED', context.getters. customerInformationInitData)
}

const requestDiscount = async (context, params) => {
  params['customer_information']['shipment_types'] = params['customer_information']['shipment_types'].filter(type => type.checked === true).map(type => type.id)
  params['customer_information']['destination_types'] = params['customer_information']['destination_types'].filter(type => type.checked === true).map(type => type.id)
  params['customer_information']['carriers'] = params['customer_information']['carriers'].filter(carrier => carrier.checked === true).map(carrier => carrier.id)
  params['customer_information']['weight_unit'] = [params['customer_information']['weight_unit']]
  await AccountService.requestDiscount('request_discount', params)
}

export default {
  updateStep,
  requestDiscount,
  initContactInformation,
  initCustomerInformation
}
