var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(_vm.tag,{tag:"component",class:_vm.className,style:('z-index:' + _vm.zIndex)},_vm._l((_vm.filteredLinks),function(link,index){return _c('li',{key:index,staticClass:"nav-item"},[(link.dropdown)?_c('mdb-dropdown',{style:(_vm.justify && {display: 'block'})},[_c('mdb-dropdown-toggle',{class:link.class,attrs:{"slot":"toggle","tag":"a","color":!_vm.pills ? _vm.color : '',"navLink":""},slot:"toggle"},[_vm._v(_vm._s(link.text))]),_c('mdb-dropdown-menu',{attrs:{"color":_vm.color}},_vm._l((link.dropdownItems),function(item,index){return _c('div',{key:index},[(item.divider)?_c('div',{staticClass:"dropdown-divider"}):_c('mdb-dropdown-item',{attrs:{"href":item.href,"target":item.target}},[_vm._v(_vm._s(item.text))])],1)}),0)],1):_c('a',{class:[
          'nav-link ripple-parent',
          index === _vm.activeTab && 'active',
          link.disabled === true && 'disabled'
        ],style:(_vm.tabColor(index, _vm.activeTab, index === _vm.activeTab)),attrs:{"href":"#","role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.changeTab(index)}}},[(link.icon)?_c('mdb-icon',{class:[link.bigIcon ? 'pb-2' : 'pr-1', link.index === _vm.activeTab ? 'white-color' : 'grey-color'],attrs:{"icon":link.icon,"fab":link.fab,"far":link.far,"fal":link.fal,"fad":link.fad,"fas":!link.fab && !link.far && !link.fal && !link.fad,"size":link.bigIcon && '2x',"title":link.title}}):_vm._e(),(link.bigIcon)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(link.text)+" ")],1)],1)}),0),(_vm.content || _vm.hasSlots)?_c('div',{class:_vm.contentClass,style:({
      'z-index': _vm.zIndex - 1,
      height: _vm.height,
      transition: `height ${_vm.transitionDuration}s ${_vm.transitionStyle}`
    })},[_c('transition-group',{on:{"enter":_vm.enter,"leave":_vm.leave}},_vm._l((_vm.filteredLinks),function(link){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(link.index === _vm.activeTab),expression:"link.index === activeTab"}],key:link.index,staticClass:"tab-pane animated fadeIn",style:({
          position: `${link.index === _vm.activeTab ? 'relative' : 'absolute'};`,
          top: 0,
          left: 0,
          'z-index': `${link.index === _vm.activeTab ? '1' : '-1'}`,
          transitionDuration: _vm.transitionDuration,
          transitionStyle: _vm.transitionStyle
        })},[(_vm.content)?_c('p',{staticClass:"p-0 m-0",domProps:{"innerHTML":_vm._s(_vm.content[link.index])}}):_vm._t(link.slot || link.text)],2)}),0)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }