import ApiService from '@/common/api/api.service'
import { AxiosResponse } from 'axios'
import { Ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import get from 'lodash/get'

export class ShipmentCataloguesApiService {
  public static async getCountries (): Promise<AxiosResponse> {
    return ApiService.query('/shipment_catalogues/countries', {})
  }

  public static async search (countryId: number = 164, name: string, value: string = ''): Promise<AxiosResponse> {
    return ApiService.query('/shipment_catalogues/search',
      {
        params: {
          country_id: countryId,
          name: name,
          value: value
        }
      })
  }
}

export const useCountriesIndex = (
  applyOnLoad: Ref<boolean>
) => {
  const { isLoading, data } = useQuery({
    queryKey: ['carrierAccountIndex'],
    queryFn: () => countriesFn(),
    select: ({ data }) => {
      return get(data, 'countries')
    },
    staleTime: 1000 * 60 * 2, // Two minutes for caching results
    retry: 0,
    enabled: applyOnLoad
  })

  return { isLoading, data }
}

export const countriesFn = async () => {
  return ApiService.query('/shipment_catalogues/countries', {})
}
