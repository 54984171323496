import { Expose, Transform } from 'class-transformer'

export const SALDO_TYPES = {
  NATIONAL: 1,
  CREDIT: 3,
  INTERNATIONAL: 4
}

export class Saldo {
  @Expose({ name: 'order_type' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public orderType: number | undefined

  @Expose({ name: 'amount' })
  @Transform(value => value || 0)
  public amount: number = 0

  @Expose({ name: 'currency' })
  public currency: string = ''

  @Expose({ name: 'account_id_field_name' })
  public accountIdFieldName: string = ''

  @Expose({ name: 'account_id' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public accountId: number | undefined

  @Expose({ name: 'count' })
  @Transform(value => value || 0, { toClassOnly: true })
  public count: number = 0
}
