<script>
  import BasicSelect from '@/components/select-boxes/BasicSelect'
  import $ from 'jquery'
  import { plainToClass } from 'class-transformer'
  import WhiteLabelsApiService from '@/common/api/white-labels.api.service'
  import { WhiteLabel } from '@/models/white-label'

  export default {
    name: 'WhiteLabelSelectBox',
    extends: BasicSelect,
    watch: {
      async value (val) {
        if (val) {
          let userResponse = await WhiteLabelsApiService.get(this.value)
          let model = plainToClass(WhiteLabel, userResponse.data)
          this.$emit('selectedModel', model)
        }
      }
    },
    methods: {
      processResults (data, params) {
        let options = []
        options.push(...data.map((el) => { return { id: el.id, text: `${ el.select_box_text }` } }))
        return {
          results: options
        }
      },
      formatResult (option) {
        if (option.link) {
          return $.parseHTML('<span>' + option.text + '</span>')
        }
        return option.text
      }
    },
    async mounted () {
      if (this.value && this.init) {
        let userResponse = await WhiteLabelsApiService.get(this.value)
        let model = plainToClass(WhiteLabel, userResponse.data)
        this.setOption([{ id: model.id, text: `${model.brandName} (${model.id})`, model }])
      }
      this.setValue(this.value)
    }
  }
</script>
