<script>
  import { UsersApiService } from '@/common/api/users.api.service'
  import { EnviayaUserTS } from '@/models/EnviayaUserTS'
  import { plainToClass } from 'class-transformer'
  import BasicSelect from '@/components/select-boxes/BasicSelect'
  import $ from 'jquery'
  import { USER_STATUSES } from '@/common/user.settings'

  export default {
    name: 'EnviayaUserSelectBox',
    extends: BasicSelect,
    watch: {
      async value (val) {
        if (val) {
          let userResponse = await UsersApiService.get(val)
          let enviayaUser = plainToClass(EnviayaUserTS, userResponse.data)
          this.setOption([this.formatOption(enviayaUser.id, `${enviayaUser.firstName} ${enviayaUser.lastName} (${enviayaUser.email})`, enviayaUser)])
          this.$emit('selectedUser', enviayaUser)
        } else {
          this.$emit('clearSelection')
        }
        this.init = false
        this.setValue(val)
      }
    },
    methods: {
      processResults (data, params) {
        let options = []
        let users = []
        users = data.users
        users.map((user) => {
          options.push(this.formatOption(user.id, user.select_box_text, user))
        })
        return {
          results: options
        }
      },
      formatOption (id, text, model) {
        switch (model.status) {
          case USER_STATUSES.USER_STATUS_ACTIVE:
            return { id: id, title: `${text}`, text: `<i class="fal fa-user blue-text pr-2"></i><span class="blue-text">${text}</span>`, link: false }
          case USER_STATUSES.USER_STATUS_EMAIL_CONFIRMATION_PENDING:
            return { id: id, title: `${text}`, text: `<i class="fal fa-user orange-text pr-2"></i><span class="orange-text">${text}</span>`, link: false }
          case USER_STATUSES.USER_STATUS_DELETED:
            return { id: id, title: `${text}`, text: `<i class="fal fa-user-slash red-text pr-2"></i><span class="red-text text-decoration-line-through">${text}</span>`, link: false }
          case USER_STATUSES.USER_STATUS_DISABLED:
            return { id: id, title: `${text}`, text: `<i class="fal fa-user-times red-text pr-2"></i><span class="red-text text-decoration-line-through">${text}</span>`, link: false }
          case USER_STATUSES.USER_STATUS_DISABLED_SYSTEM:
            return { id: id, title: `${text}`, text: `<i class="fal fa-user-shield blue-text pr-2"></i><span class="red-text text-decoration-line-through">${text}</span>`, link: false }
          default:
            return { id: id, title: `${text}`, text: `<i class="fal fa-user-times red-text pr-2"></i><span class="red-text">${text}</span>`, link: false }
        }
      },
      formatResult (option) {
        if (option.loading) {
          return option.text
        } else {
          return $.parseHTML(option.text)
        }
      },
      formatSelection (option) {
        if (option.loading) {
          return option.text
        } else {
          return $(option.text)
        }
      }
    },
    async mounted () {
      if (this.value && this.init) {
        let userResponse = await UsersApiService.get(this.value)
        let enviayaUser = plainToClass(EnviayaUserTS, userResponse.data)
        this.setOption([this.formatOption(enviayaUser.id, `${enviayaUser.firstName} ${enviayaUser.lastName} (${enviayaUser.email})`, enviayaUser)])
        this.$emit('selectedUser', enviayaUser)
      }

      this.setValue(this.value)
    }
  }
</script>
