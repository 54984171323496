
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import validator from 'validator'
  import { UsersApiService } from '@/common/api/users.api.service'
  import { AuthModule } from '@/store/auth.module'
  import { ValidationError } from '@/common/exceptions/validation-error'
  import { UnhandledError } from '@/common/exceptions/unhandled-error'
  import { convertAbilities } from '@/common/utility'

  @Component
  export default class CorporateAccountModal extends Vue {
    @Prop(String) public readonly currentCompany?: string
    @Prop(Boolean) public readonly newWhiteLabel!: boolean
    @Prop(Number) public readonly userID!: number
    @Prop(Object) public readonly router!: any

    public isProcessing: boolean = false
    public company: string = ''

    public validation: any = {
      company: {
        valid: false,
        validated: false,
        invalidFeedback: ''
      }
    }

    public mounted () {
      this.company = this.currentCompany || ''
    }

    public validate (key: string) {
      if (key === 'company') {
        if (validator.isEmpty(this.company)) {
          this.validation[key].valid = false
          this.validation[key].invalidFeedback = this.$t('corporate_account_modal.validation_error')
        } else {
          this.validation[key].valid = true
          this.validation[key].invalidFeedback = ''
        }
        this.validation[key].validated = true
      }
    }

    public isValidForm () {
      return Object.keys(this.validation).find(key => this.validation[key].valid === false) === undefined
    }

    public async checkForm () { /**/ }

    public async applyAction () {
      this.validate('company')
      if (this.isValidForm()) {
        try {
          let { data } = await UsersApiService.upgradeCorporateUser(this.userID, this.company, this.newWhiteLabel)
          if (data.new_white_label) {
            this.$modal.show({
              type: 'success',
              centered: true,
              headerText: this.$t('popup.titles.success_title'),
              bodyText: this.$t('popup.messages.waiting_for_redirect'),
              applyAction: async () => {
                if (this.userID.toString() === this.$currentUser.id.toString()) {
                  AuthModule.tokenLogin().then(async ({ data }) => {
                    this.$ability.update(convertAbilities(data.user.abilities))
                    window.location.href = window.location.href=`${this.$redirectUrl}white_labels/new`
                  }).catch((_e) => { /**/ })
                }
              }
            })
          } else {
            this.$modal.close()
            this.$modal.show({
              type: 'success',
              centered: true,
              headerText: this.$t('popup.titles.success_title'),
              bodyText: this.$t('corporate_account_modal.success_msg'),
              applyAction: async () => {
                if (this.userID.toString() === this.$currentUser.id.toString()) {
                  AuthModule.tokenLogin().then(async ({ data }) => {
                    this.$ability.update(convertAbilities(data.user.abilities))
                  }).catch((_e) => { /**/ })
                }
                document.location.reload()
              }
            })
          }
        } catch (err) {
          switch (true) {
            case err instanceof ValidationError:
              this.$modal.show({
                type: 'danger',
                centered: true,
                headerText: this.$t('popup.titles.error_title'),
                bodyText: this.$options.filters!.formatErrors({ ...err.errors })
              })
              this.isProcessing = false
              break
            case err instanceof UnhandledError:
              this.$modal.show({
                type: 'danger',
                centered: true,
                headerText: this.$t('505_error.title'),
                bodyText: this.$t('505_error.sub_title'),
                applyAction: () => {
                  this.isProcessing = false
                }
              })
              break
            default:
              this.isProcessing = false
              throw err
          }
        }
      }
    }
  }
