import { Exclude, Expose, Transform } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'

@Exclude()
export class Reference extends AbstractModel {
  @Expose({ name: 'id' })
  public id: number | null = null

  @Expose({ name: 'reference' })
  public reference: string = ''

  @Expose({ name: '_destroy' })
  @Transform((value) => (value) ? value : false)
  public destroy: boolean = false
}
