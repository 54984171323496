import { QueryClient } from '@tanstack/vue-query'

const queryClient = {
  install(Vue) {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 1000 * 60 * 2,
        },
      },
    })

    Vue.prototype.$queryClient = queryClient
    Vue.$queryClient = queryClient
  }
}

export default queryClient
