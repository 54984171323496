import ApiService from '@/common/api/api.service'
import GraphQLService, { GraphQLResponse } from '@/common/api/graphql.service'
import { AxiosResponse } from 'axios'
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { AbstractModelList } from '@/models/abstract-model-list'
import { ACTIVE_ORDER_STATUSES, ORDER_TYPES } from '@/models/orders/order'
import { AppUser } from '@/models/AppUser'

export class UsersApiService {
  public static async getUsers (params: Object): Promise<AbstractModelList<EnviayaUserTS>> {
    try {
      // @ts-ignore
      if (params['last_shipment_date_cond'] === 'empty') params['last_shipment_date_cond'] = 'eq'
      let { data } = await ApiService.query('users', { params: { ...params } })
      return plainToClassFromExist(new AbstractModelList<EnviayaUserTS>(EnviayaUserTS), data)
    } catch (e) {
      throw e
    }
  }

  public static async getAdmins (): Promise<AxiosResponse> {
    return ApiService.query('users/search', { params: { role_ids: [99, 95] } })
  }

  public static search (query: string, corporateAccountId?: number): Promise<AxiosResponse> {
    let queryParams = {
      query
    }
    if (corporateAccountId) {
      Object.assign(queryParams, { only_users_of_account: corporateAccountId })
    }
    return ApiService.query('users/search', {
      params: {
        ...queryParams
      }
    })
  }

  public static async register (user: any, queryParams: any, locale: string) {
    let data = { user: user }
    if (queryParams && queryParams.kw && queryParams.kw.length > 0) {
      Object.assign(data, { 'kw': queryParams.kw })
    }
    if (queryParams && queryParams.referrer_key && queryParams.referrer_key.length > 0) {
      Object.assign(data, { 'referrer_key': queryParams.referrer_key })
    }

    if (queryParams && queryParams.sales_executive && queryParams.sales_executive.length > 0) {
      Object.assign(data, { 'sales_executive': queryParams.sales_executive })
    }

    if (queryParams && queryParams.invitation && queryParams.invitation.length > 0) {
      Object.assign(data, { 'invitation': queryParams.invitation })
    }

    Object.assign(data, { 'locale': locale })
    return ApiService.post('sign_up', {
      ...data
    })
  }

  public static async activateUser (token: string) {
    let data = { token: token }
    return ApiService.post('activate', {
      ...data
    })
  }

  public static pendingUser (slug: any): Promise<{ id: number, email: string, activation_token: string }> {
    return ApiService.get('pending_user', slug)
  }

  public static getCountryByIp (): Promise<AxiosResponse> {
    return ApiService.get('country_by_ip')
  }

  public static login (userInfo: { email: string, password: string, locale: string }): Promise<AxiosResponse> {
    let params: any = {
      user_session: { email: userInfo.email, password: userInfo.password },
      locale: userInfo.locale
    }
    return ApiService.post('sign_in', params)
  }

  public static checkMfaCode (code: string): Promise<AxiosResponse> {
    return ApiService.post('mfa_code', {
      mfa_code: code
    })
  }

  public static getCurrentUser () {
    return ApiService.post('current_user', { })
  }

  public static userSessionCheck () {
    return ApiService.head('current_user')
  }

  public static tokenLogout () {
    return ApiService.delete('logout')
  }

  public static async updateStatus (id: number, value: number): Promise<void> {
    await ApiService.put(`users/${id}/change_status`, {
      name: 'status',
      value: value
    })
  }

  public static async quickOnBoarding (id: number, data: any): Promise<any> {
    return ApiService.put(`users/${id}/perform_quick_onboarding`,{
      ...data
    })
  }

  public static get (slug: any): Promise<any> {
    return ApiService.get('users', slug)
  }

  public static async getUsersByIds (ids: number[]): Promise<EnviayaUserTS[]> {
    let { data } = await ApiService.query(`users`, {
      params: { 'user_ids': ids }
    })
    return data.models.map((account: any) => { return plainToClass(EnviayaUserTS, account) } )
  }

  public static forgottenPassword (email: string) {
    return ApiService.post('forgotten_password.json', {
      user_session: {
        email: email
      }
    })
  }

  public static async upgradeCorporateUser (userId: number, company: string, newWhiteLabel: boolean) {
    return ApiService.post(`users/${userId}/upgrade_corporate_user`, {
      new_white_label: newWhiteLabel,
      company: company
    })
  }

  public static async downgradeCorporateUser (userId: number) {
    return ApiService.post(`users/${userId}/downgrade_corporate_user`, {})
  }

  public static getEmailForReset (token: string) {
    return ApiService.get('reset_password', token)
  }

  public static async resetPassword (resetData: any) {
    return ApiService.put('reset_password', {
      ...resetData
    })
  }

  public static async destroyAllActiveSessions (id: number) {
    return ApiService.post(`users/${id}/delete_active_sessions`, {})
  }

  public static getUserByInvitation (invitationNumber: string) {
    return ApiService.query('get_user_by_invitation', {
      params: { invitation: invitationNumber }
    })
  }

  public static activateEmail (token: string) {
    return ApiService.query('confirm_email_change', {
      params: { token: token }
    })
  }

  public static createUser (user: EnviayaUserTS) {
    return ApiService.post('users', {
      user: classToPlain(user)
    })
  }

  public static updateUser (userId: string, user: EnviayaUserTS, customerInfoAttributes: { shipmentsPerMonth: string, estMonthlySalesVolume: number, estMonthlySalesVolumeCurrency: string } | null = null): Promise<any> {
    let plainUser = classToPlain(user)
    if (customerInfoAttributes) {
      Object.assign(plainUser, { 'customer_info_attributes': customerInfoAttributes })
      // @ts-ignore
      delete plainUser['customer_info']
    }
    return ApiService.update('users', userId, {
      user: plainUser
    })
  }

  public static resendEmailChange (id: string) {
    return ApiService.query('resend_email_change', {
      params: { id: id }
    })
  }

  public static discardEmailChange (id: string) {
    return ApiService.query('discard_email_change', {
      params: { id: id }
    })
  }

  public static resendActivationMail (id: number) {
    return ApiService.post(`resend_activation_mail`, {
      user_id: id
    })
  }

  public static becomeUser (id: string) {
    return ApiService.get('become_user', id)
  }

  public static becomeAdmin () {
    return ApiService.query('become_admin', {})
  }

  public static async resetTwoFactorAuth (id: number) {
    return ApiService.put(`users/${id}/reset_two_factor_auth`, {})
  }

  public static updatePassword (userId: string, passInfo: { password?: string, password_confirmation?: string, two_factor_authentication?: boolean }) {
    return ApiService.update('users', userId, {
      user: passInfo
    })
  }

  public static updateAssignations (userId: string, assignationInfo: { white_label_id?: number, administrator_id?: number, referrer_id?: number }) {
    return ApiService.update('users', userId, {
      user: assignationInfo
    })
  }

  public static async createDownloadJob (params: Object) {
    return ApiService.post('users/actions?actions=download', {
      ...params
    }).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }

  public static async blockedPaymentOptions (userId: string, options: { title: string, code: number, prefix?: string }[]) {
    let params: any = {
      blocked_payment_options: {
        user_id: userId, block_type: []
      }
    }
    options.forEach((type) => {
      params.blocked_payment_options.block_type.push({ code: type.code, prefix: type.prefix })
    })
    return ApiService.post(`blocked_payment_options`, { ...params })
  }

  public static async dataForSidebarConfiguration (): Promise<GraphQLResponse | undefined> {
    return GraphQLService.query(`
      query (
        $status: [Int!],
        $orderType: [Int!]
      ) {
        me{
          orders (
            status: $status
            orderType: $orderType
          ) {
            id
          }
          corporateAccount{
            carrierAccounts{
              aliasName
            }
          }
        }
      }
    `, {
      status: ACTIVE_ORDER_STATUSES,
      orderType: [ORDER_TYPES.CLEARANCE]
    })
  }

  public static async removeUserFromCorporateAccountGQL (user: EnviayaUserTS): Promise<GraphQLResponse | undefined> {
    const { id } = user

    return GraphQLService.query(`
      mutation removeUserFromCorporateAccount(
        $id: ID!
      ) {
        removeUserFromCorporateAccount (
          input: {
            id: $id
          }
        ) {
          user {
            id
            corporateAccountId
          }
          errors
        }
      }
    `, {
      id
    })
  }

  public static async toggleUserAdminRightsGQL (user: EnviayaUserTS): Promise<GraphQLResponse | undefined> {
    const { id, role, corporateAccountId } = user

    return GraphQLService.query(`
      mutation toggleAdminRights (
        $id: ID!
        $role: Int
        $corporateAccountId: ID
      ) {
        updateUser (
          input: {
            id: $id
            role: $role
            corporateAccountId: $corporateAccountId
          }
        ) {
          user {
            id
            corporateAccountId
          }
          errors
        }
      }
    `, {
      id,
      role: role === 1 ? 2 : 1,
      corporateAccountId
    })
  }

  public static async changeLocaleGQL (user: AppUser): Promise<GraphQLResponse | undefined> {
    const { id, locale } = user

    return GraphQLService.query(`
      mutation changeLocale (
        $id: ID!
        $locale: String
      ) {
        updateLocale (
          input: {
            id: $id
            locale: $locale
          }
        ) {
          user {
            id
          }
          errors
        }
      }
    `, {
      id,
      locale
    })
  }

  public static async inviteExistingUser (userId: number, params: { corporateAccountId: number, migrateAccountsToCorporateAccount: boolean }): Promise<GraphQLResponse | undefined> {
    const { corporateAccountId, migrateAccountsToCorporateAccount } = params

    return GraphQLService.query(`
      mutation inviteExistingUser (
        $id: ID!
        $role: Int
        $corporateAccountId: ID
        $migrateAccountsToCorporateAccount: Boolean
      ) {
        updateUser (
          input: {
            id: $id
            role: $role
            corporateAccountId: $corporateAccountId
            migrateAccountsToCorporateAccount: $migrateAccountsToCorporateAccount
          }
        ) {
          user {
            id
            corporateAccountId
          }
          errors
        }
      }
    `, {
      id: userId,
      corporateAccountId,
      migrateAccountsToCorporateAccount
    })
  }

  public static async updateUserTestModeGQL (userId: number, testModeEnabled: boolean) {
    return GraphQLService.query(`
      mutation updateCurrentUserTestMode (
        $id: ID!
        $testModeEnabled: Boolean
      ) {
        updateUser (
          input: {
            id: $id
            user: {
              testModeEnabled: $testModeEnabled
            }
          }
        ) {
          user {
            id
          }
          errors
        }
      }
    `, {
      id: userId,
      testModeEnabled
    })
  }
}
