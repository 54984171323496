
  import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
  import validator from 'validator'
  import Inputmask from 'inputmask'
  import { EnviayaUserTS } from '@/models/EnviayaUserTS'
  // @ts-ignore
  import { countrySelectHelper } from '@/mixins/countrySelectHelper'
  import { TimeZoneSelectHelper } from '@/mixins/time-zone-select-helper'
  // @ts-ignore
  import Password from 'vue-password-strength-meter'
  import PhoneInput from '@/components/input/PhoneInput.vue'
  import parsePhoneNumber from 'libphonenumber-js'
  import { ISelectItem } from '@/models/SelectBoxItem'
  import { MONTHLY_SALES, SHIPMENTS_PER_MONTH_LIST } from '@/common/customer-info-data'
  import { isEmpty } from 'class-validator'
  import { WHO } from '@/common/user.roles'
  import { AuthModule } from '@/store/auth.module'

  @Component({
    components: {
      Password,
      PhoneInput
    },
    mixins: [ countrySelectHelper ]
  })

  export default class UserDetailsForm extends Mixins(TimeZoneSelectHelper) {
    @Prop(Object) public readonly user!: EnviayaUserTS
    @Prop(Boolean) public readonly passwordMissing!: boolean
    @Prop(Object) public readonly customerInfoAttributes!: { shipmentsPerMonth: string, estMonthlySalesVolume: number, estMonthlySalesVolumeCurrency: string }

    public password: string = ''
    public confirmPassword: string = ''
    public passwordPlaceholder: string = ''
    public confirmPasswordPlaceholder: string = ''
    public shipmentsPerMonthOptions: ISelectItem[] = []
    public monthlySalesOptions: ISelectItem[] = []

    public validation: any = {
      firstName: {
        valid: false,
        validated: false,
        invalidFeedback: ''
      },
      lastName: {
        valid: false,
        validated: false,
        invalidFeedback: ''
      },
      phone: {
        valid: false,
        validated: false,
        invalidFeedback: ''
      },
      password: {
        valid: false,
        validated: false,
        invalidFeedback: ''
      },
      confirm_password: {
        valid: false,
        validated: false,
        invalidFeedback: ''
      }
    }

    public customerInfoValidation: any = {
      shipmentsPerMonth: {
        valid: false,
        validated: false,
        invalidFeedback: ''
      },
      estMonthlySalesVolume: {
        valid: false,
        validated: false,
        invalidFeedback: ''
      }
    }

    public selectCountryData: any = [
      {
        id: 'de',
        text: 'German'
      },
      {
        id: 'es',
        text: 'Spanish'
      },
      {
        id: 'en',
        text: 'English'
      }
    ]

    public language: any = []

    public mounted () {
      // this.user.phoneCountry = this.user.country
      this.$nextTick(() => {
        Inputmask({ regex: '^[a-zA-Z\\u00C0-\\u00FF]+\\s[a-zA-Z\\u00C0-\\u00FF]+$', placeholder: '' }).mask(document.getElementById('firstName'))
        Inputmask({ regex: '^[a-zA-Z\\u00C0-\\u00FF]+\\s[a-zA-Z\\u00C0-\\u00FF]+$', placeholder: '' }).mask(document.getElementById('lastName'))
      })

      this.language.push(
        { text: this.$t('languages_data.es'), id: 'es' },
        { text: this.$t('languages_data.de'), id: 'de' },
        { text: this.$t('languages_data.en'), id: 'en' }
      )

      this.shipmentsPerMonthOptions = this.getShipmentsPerMonthData()
      this.monthlySalesOptions = this.getMonthlySalesData()
      this.initTimeZoneList(this.user.timezone)
    }

    public getShipmentsPerMonthData (): ISelectItem[] {
      let shipmentPerMonthList: ISelectItem[] = []
      SHIPMENTS_PER_MONTH_LIST.forEach((el) => {
        shipmentPerMonthList.push({
          text: this.$t(`customer_info.shipments_per_month.${el.text}`).toString(),
          value: el.value,
          selected: (this.customerInfoAttributes.shipmentsPerMonth === el.value)
        })
      })
      return shipmentPerMonthList
    }

    public getMonthlySalesData (): ISelectItem[] {
      let monthlySalesList: ISelectItem[] = []
      MONTHLY_SALES.forEach((el) => {
        monthlySalesList.push({
          text: this.$t(`customer_info.monthly_sales.${el.text}`).toString(),
          value: el.value,
          selected: (this.customerInfoAttributes.estMonthlySalesVolume === el.value)
        })
      })
      monthlySalesList.shift()
      return monthlySalesList
    }

    public validate (key: string) {
      if (key === 'firstName') {
        if (validator.isEmpty(this.user[key])) {
          this.validation[key].valid = false
          this.validation[key].invalidFeedback = this.$t('user_details_form.errorMessages.blank_name')
        } else if (/\d/.test(this.user[key])) {
          this.validation[key].valid = false
          this.validation[key].invalidFeedback = this.$t('user_details_form.errorMessages.invalid_name')
        } else {
          this.validation[key].valid = true
          this.validation[key].invalidFeedback = ''
        }
        this.validation[key].validated = true
      }

      if (key === 'lastName') {
        if (validator.isEmpty(this.user[key])) {
          this.validation[key].valid = false
          this.validation[key].invalidFeedback = this.$t('user_details_form.errorMessages.blank_last_name')
        } else if (/\d/.test(this.user[key])) {
          this.validation[key].valid = false
          this.validation[key].invalidFeedback = this.$t('user_details_form.errorMessages.invalid_last_name')
        } else {
          this.validation[key].valid = true
          this.validation[key].invalidFeedback = ''
        }
        this.validation[key].validated = true
      }

      if (key === 'phone') {
        const phoneNumber = parsePhoneNumber(this.user.phone, this.user.phoneCountry)
        if (!phoneNumber || !phoneNumber.isPossible()) {
          this.validation[key].valid = false
          this.validation[key].invalidFeedback = this.$t('user_details_form.errorMessages.invalid_phone_number')
        } else {
          this.validation[key].valid = true
          this.validation[key].invalidFeedback = ''
        }
        this.validation[key].validated = true
      }

      if (key === 'password') {
        if ((this.password.length === 0 || this.password.length < 6) && this.passwordMissing) {
          this.validation['password'].valid = false
          this.validation['password'].invalidFeedback = this.$t('user_settings.security.error_messages.password_too_short')
        } else {
          this.validation['password'].valid = true
          this.validation['password'].invalidFeedback = ''
        }
        this.validation['password'].validated = true
      }

      if (key === 'confirm_password') {
        if (validator.isEmpty(this.confirmPassword) && !validator.isEmpty(this.password) && this.passwordMissing) {
          this.validation['confirm_password'].valid = false
          this.validation['confirm_password'].invalidFeedback = this.$t('user_settings.security.error_messages.blank_confirmation_password')
        } else if (this.password !== this.confirmPassword && !validator.isEmpty(this.password) && this.passwordMissing) {
          this.validation['confirm_password'].valid = false
          this.validation['confirm_password'].invalidFeedback = this.$t('user_settings.security.error_messages.invalid_confirmation_password')
        } else {
          this.validation['confirm_password'].valid = true
          this.validation['confirm_password'].invalidFeedback = ''
        }
        this.validation['confirm_password'].validated = true
      }

      if (key === 'shipmentsPerMonth') {
        if (!this.customerInfoAttributes.shipmentsPerMonth && !WHO.isCorporateUser(AuthModule.currentUser)) {
          this.customerInfoValidation[key].valid = false
          this.customerInfoValidation[key].invalidFeedback = this.$t('user_details_form.errorMessages.blank')
        } else {
          this.customerInfoValidation[key].valid = true
          this.customerInfoValidation[key].invalidFeedback = ''
        }
        this.customerInfoValidation[key].validated = true
      }

      if (key === 'estMonthlySalesVolume') {
        if (isEmpty(this.customerInfoAttributes.estMonthlySalesVolume) && !WHO.isCorporateUser(AuthModule.currentUser)) {
          this.customerInfoValidation[key].valid = false
          this.customerInfoValidation[key].invalidFeedback = this.$t('user_details_form.errorMessages.blank')
        } else {
          this.customerInfoValidation[key].valid = true
          this.customerInfoValidation[key].invalidFeedback = ''
        }
        this.customerInfoValidation[key].validated = true
      }
    }

    public validateForm () {
      this.validate('firstName')
      this.validate('lastName')
      this.validate('phone')
      this.validate('password')
      this.validate('confirm_password')
    }

    public validateCustomerInfo () {
      this.validate('shipmentsPerMonth')
      this.validate('estMonthlySalesVolume')
    }

    public isValidForm () {
      this.validateForm()
      return Object.keys(this.validation).find(key => this.validation[key].valid === false) === undefined
    }

    public isValidCustomerInfo () {
      this.validateCustomerInfo()
      return Object.keys(this.customerInfoValidation).find(key => this.customerInfoValidation[key].valid === false) === undefined
    }

    public getFormData () {
      let passwordData = { /**/ }
      if (this.isValidForm() && this.passwordMissing) {
        Object.assign(passwordData, { password: this.password })
      }
      return { user: this.user, ...passwordData, customerInfoAttributes: {
        'id': this.user.id,
        'shipments_per_month': this.customerInfoAttributes.shipmentsPerMonth,
        'est_monthly_sales_volume': this.customerInfoAttributes.estMonthlySalesVolume,
        'est_monthly_sales_volume_currency': this.customerInfoAttributes.estMonthlySalesVolumeCurrency
      } }
    }

    public get startTyping () {
      return this.password.length > 0
    }

    @Watch('user', { immediate: false, deep: true })
    public onUserChanged () {
      this.validateForm()
    }
  }
