
  import { Component, Prop } from 'vue-property-decorator'
  import BasicComponent from '@/views/BasicComponent'
  import { AppModule } from '@/store/app.module'
  // @ts-ignore
  import serviceCodesLocale from '@/locales/service_codes.json'
  import { Carrier } from '@/models/carrier'
  import { CarriersApiService } from '@/common/api/carriers.api.service'
  import { CarrierService, SERVICE_TYPES } from '@/models/carrier-service'
  import { CarrierServicesApiService } from '@/common/api/carrier_services.api.service'

  @Component
  export default class ServiceCodesSelectBox extends BasicComponent {
    @Prop(String) public label!: string | 'Default label'
    @Prop(String) public placeholder!: string | ''
    @Prop(String) public classes!: string | ''
    @Prop({ default: 'serviceCode' }) public valueField!: string | 'serviceCode'
    @Prop(Number) public carrier!: number | undefined
    @Prop([String, Array]) public result!: string | string[] | undefined
    @Prop(Boolean) public disabled!: boolean | false
    @Prop(Boolean) public resetBtn!: boolean | false
    @Prop(Boolean) public search!: boolean | false
    @Prop(Boolean) public selectAll!: boolean | false
    @Prop(Boolean) public multiple!: boolean | false

    @Prop(Boolean) public customValidation!: boolean
    @Prop({ default: true }) public isValid!: boolean
    @Prop(String) public invalidFeedback!: string

    public carrierList: Carrier[] = []
    public serviceCodes: any[] = []

    public icons: any = {
      'international': require(`@/assets/img/svg/international.svg`),
      'domestic': require(`@/assets/img/svg/domestic.svg`)
    }

    public async mounted () {
      this.carrierList = await CarriersApiService.list()
      await this.fillServiceSelect()
      if (!Array.isArray(this.result)) {
        this.unwatchAccessors.push(this.$watch('result', this.onModelChanged))
        this.unwatchAccessors.push(this.$watch('carrier', this.onModelChanged))
      } else {
        this.unwatchAccessors.push(this.$watch('result.length', this.onModelChanged))
        this.unwatchAccessors.push(this.$watch('carrier', this.onModelChanged))
      }
    }

    public async onModelChanged () {
      await this.fillServiceSelect()
    }

    public async fillServiceSelect () {
      let selected: string[] | number[] = []
      let dbServiceCodes: CarrierService[] = await CarrierServicesApiService.getAllByCarrier(this.carrier)
      selected = this.getSelectedValue()

      this.serviceCodes = []
      let carrier: Carrier | undefined = this.carrierList.find((carrier) => { return carrier.id === Number(this.carrier) })
      let carrierName: string | undefined = (carrier) ? carrier.name.toLowerCase() : undefined
      if (carrier && carrierName) {
        this.serviceCodes.push({ text: carrier.name + ' - domestic', value: null, disabled: true, optgroup: true })
        dbServiceCodes.filter((service: CarrierService) => service.carrierId === carrier!.id && service.serviceTypeCode === SERVICE_TYPES.DOMESTIC).forEach((service: CarrierService) => {
          this.serviceCodes.push({
            text: `${service.localizedServiceName} (${service.serviceCode})`,
            // @ts-ignore
            value: service[this.valueField],
            icon: this.icons[service.serviceType],
            // @ts-ignore
            selected: selected.includes(this.getValue(service))
          })
        })
        this.serviceCodes.push({ text: carrier.name + ' - international', value: null, disabled: true, optgroup: true })
        dbServiceCodes.filter((service: CarrierService) => service.carrierId === carrier!.id && service.serviceTypeCode === SERVICE_TYPES.INTERNATIONAL).forEach((service: CarrierService) => {
          this.serviceCodes.push({
            text: `${service.localizedServiceName} (${service.serviceCode})`,
            // @ts-ignore
            value: service[this.valueField],
            icon: this.icons[service.serviceType],
            // @ts-ignore
            selected: selected.includes(this.getValue(service))
          })
        })
      } else {
        this.carrierList.forEach((carrier: Carrier) => {
          this.serviceCodes.push({ text: carrier.name + ' - domestic', value: null, disabled: true, optgroup: true })
          dbServiceCodes.filter((service: CarrierService) => service.carrierId === carrier.id && service.serviceTypeCode === SERVICE_TYPES.DOMESTIC).forEach((service: CarrierService) => {
            this.serviceCodes.push({
              text: `${service.localizedServiceName} (${service.serviceCode})`,
              // @ts-ignore
              value: service[this.valueField],
              icon: this.icons[service.serviceType],
              // @ts-ignore
              selected: selected.includes(this.getValue(service))
            })
          })
          this.serviceCodes.push({ text: carrier.name + ' - international', value: null, disabled: true, optgroup: true })
          dbServiceCodes.filter((service: CarrierService) => service.carrierId === carrier.id && service.serviceTypeCode === SERVICE_TYPES.INTERNATIONAL).forEach((service: CarrierService) => {
            this.serviceCodes.push({
              text: `${service.localizedServiceName} (${service.serviceCode})`,
              // @ts-ignore
              value: service[this.valueField],
              icon: this.icons[service.serviceType],
              // @ts-ignore
              selected: selected.includes(this.getValue(service))
            })
          })
        })
      }

      if (this.selectAll) {
        // @ts-ignore
        this.serviceCodes.unshift({ text: serviceCodesLocale[this.locale]['all_service_codes'], value: null, selected: !this.result })
      }
    }

    public getValue (service: CarrierService): string | number {
      if (this.valueField === 'serviceCode') {
        return service.serviceCode.toLocaleLowerCase()
      } else {
        return service.id
      }
    }

    public getSelectedValue () {
      if (!Array.isArray(this.result)) {
        if (this.valueField === 'serviceCode') {
          return (this.result) ? [this.result.toLocaleLowerCase()] : []
        } else {
          return (this.result) ? [this.result] : []
        }
      } else {
        if (this.valueField === 'serviceCode') {
          return (this.result) ? this.result.map((item) => item.toLocaleLowerCase()) : []
        } else {
          return (this.result) ? this.result.map((item) => item) : []
        }
      }
    }

    public get locale (): string {
      return AppModule.locale.toLowerCase()
    }

    public updateValue (value: string) {
      this.$emit('update:result', value)
    }

    public validate () {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs['select'].validate()
      })
    }
  }
