import JwtService from '@/common/jwt.service'
import { UsersApiService } from '@/common/api/users.api.service'
import { EnviayaUserTS } from '@/models/EnviayaUserTS'
import { plainToClass } from 'class-transformer'
import UserDetailsForm from '@/components/users/UserDetailsForm.vue'
import i18n from '@/i18n'
import { AuthModule } from '@/store/auth.module'
import { ValidationError } from '@/common/exceptions/validation-error'
import { UnhandledError } from '@/common/exceptions/unhandled-error'
import { WHO } from '@/common/user.roles'

const MissingProfileState = {
  install(Vue) {
    let container = {
      checkUserProfile (appUser, ability) {
        if (appUser !== null
          && (!appUser.firstName
            || !appUser.lastName
            || !appUser.phone
            || !appUser.countryCode
            || !appUser.timezone
            || !appUser.locale
            || appUser.passwordMissing
            || (!appUser.shipmentsPerMonth && !WHO.isCorporateUser(AuthModule.currentUser))
            || (appUser.estMonthlySalesVolume === null && !WHO.isCorporateUser(AuthModule.currentUser)))) {
          Vue.nextTick(async () => {
            let userResponse = await UsersApiService.get(appUser.id)
            let enviayaUser = plainToClass(EnviayaUserTS, userResponse.data)
            await this.showUserDetailsForm(appUser, enviayaUser, ability)
          })
        }
      },
      showUserDetailsForm (appUser, user, ability) {
        let ComponentClass = Vue.extend(UserDetailsForm)
        let instance = new ComponentClass({
          propsData: {
            user: user,
            passwordMissing: appUser.passwordMissing,
            customerInfoAttributes: {
              shipmentsPerMonth: appUser.shipmentsPerMonth,
              estMonthlySalesVolume: appUser.estMonthlySalesVolume,
              estMonthlySalesVolumeCurrency: appUser.estMonthlySalesVolumeCurrency
            }
          },
          i18n: i18n,
          store: Vue.$store
        })

        Vue.$modal.show({
          size: 'lg',
          type: 'info',
          close: false,
          headerText: i18n.t('user_details_form.title'),
          centered: true,
          scrollable: true,
          applyBtnText: i18n.t('user_details_form.save_button'),
          component: instance,
          applyAction: async () => {
            // @ts-ignore
            let userData = instance.getFormData()
            // @ts-ignore
            if (instance.isValidForm() && instance.isValidCustomerInfo()) {
              try {
                await UsersApiService.updateUser(userData.user.id.toString(), userData.user, userData.customerInfoAttributes)
                if (appUser.passwordMissing && userData.password && userData.password.length > 0) {
                  await UsersApiService.updatePassword(userData.user.id.toString(), { password: userData.password })
                }
                Vue.$modal.show({
                  type: 'success',
                  centered: true,
                  headerText: 'Success',
                  bodyText: i18n.t('user_details_form.messages.data_was_saved'),
                  applyAction: async () => {
                    AuthModule.tokenLogin().then(async ({ data }) => {
                      ability.update(data.user.abilities)
                      document.location.reload()
                    }).catch((_e) => { /**/ })
                  }
                })
              } catch (err) {
                switch (true) {
                  case err instanceof ValidationError:
                    Vue.$modal.show({
                      type: 'danger',
                      centered: true,
                      headerText: 'Error',
                      bodyText: Vue.options.filters.formatErrors({ ...err.errors })
                    })
                    return Promise.reject(err)
                  case err instanceof UnhandledError:
                    Vue.$modal.show({
                      type: 'danger',
                      centered: true,
                      headerText: 'Error',
                      bodyText: 'Error',
                    })
                    return Promise.reject(err)
                  default:
                    return Promise.reject(err)
                }
              }
            } else {
              return Promise.reject()
            }
          },
          lazy: false
        })
      }
    }

    Vue.prototype.$profileValidator = container

    Vue.$profileValidator = container
  }
};

export default MissingProfileState;
