import i18n from '@/i18n'
import { USER_STATUSES } from '@/common/user.settings'
import { AuthModule } from '@/store/auth.module'
import { WHO } from '@/common/user.roles'

export function useProfileUserStatusesByRole(selectedValue: number | undefined | null) {
  return Object.values(USER_STATUSES)
    .filter(
      (value) =>
        (Number.isInteger(value as number) &&
          value !== USER_STATUSES.USER_STATUS_EMAIL_CONFIRMATION_PENDING &&
          value !== USER_STATUSES.USER_STATUS_DISABLED_SYSTEM) ||
        (selectedValue === USER_STATUSES.USER_STATUS_EMAIL_CONFIRMATION_PENDING &&
          value === USER_STATUSES.USER_STATUS_EMAIL_CONFIRMATION_PENDING) ||
        (value === USER_STATUSES.USER_STATUS_EMAIL_CONFIRMATION_PENDING &&
          (AuthModule.currentUser.role > 90 || WHO.isWhiteLabelAdmin(AuthModule.currentUser))) ||
        (selectedValue === USER_STATUSES.USER_STATUS_DISABLED_SYSTEM &&
          value === USER_STATUSES.USER_STATUS_DISABLED_SYSTEM) ||
        (value === USER_STATUSES.USER_STATUS_DISABLED_SYSTEM && AuthModule.currentUser.role > 90)
    )
    .map((status) => {
      return {
        text: i18n.t(`user_statuses.status_${status}`) as string,
        value: status,
        selected: !!selectedValue && selectedValue.toString() === status.toString()
      }
    })
}

export function useUserStatusesByRole(selectedValue: number | undefined | null) {
  let statuses = []
  statuses.push({
    text: i18n.t(`user_statuses.status_${USER_STATUSES.USER_STATUS_ACTIVE}`) as string,
    value: USER_STATUSES.USER_STATUS_ACTIVE,
    selected: !!selectedValue && selectedValue.toString() === USER_STATUSES.USER_STATUS_ACTIVE.toString()
  })
  statuses.push({
    text: i18n.t(`user_statuses.status_${USER_STATUSES.USER_STATUS_DISABLED}`) as string,
    value: USER_STATUSES.USER_STATUS_DISABLED,
    selected: !!selectedValue && selectedValue.toString() === USER_STATUSES.USER_STATUS_DISABLED.toString()
  })
  if (WHO.isVendorAdmin(AuthModule.currentUser)) {
    statuses.push({
      text: i18n.t(`user_statuses.status_${USER_STATUSES.USER_STATUS_DELETED}`) as string,
      value: USER_STATUSES.USER_STATUS_DELETED,
      selected: !!selectedValue && selectedValue.toString() === USER_STATUSES.USER_STATUS_DELETED.toString()
    })
  }
  if(WHO.isCorporateAdministrator(AuthModule.currentUser) ||
    WHO.isWhiteLabelAdmin(AuthModule.currentUser)) { return statuses }
  if (AuthModule.currentUser.role >= 90) {
    statuses.push({
      text: i18n.t(`user_statuses.status_${USER_STATUSES.USER_STATUS_DELETED}`) as string,
      value: USER_STATUSES.USER_STATUS_DELETED,
      selected: !!selectedValue && selectedValue.toString() === USER_STATUSES.USER_STATUS_DELETED.toString()
    })
    statuses.push({
      text: i18n.t(`user_statuses.status_${USER_STATUSES.USER_STATUS_EMAIL_CONFIRMATION_PENDING}`) as string,
      value: USER_STATUSES.USER_STATUS_EMAIL_CONFIRMATION_PENDING,
      selected: !!selectedValue && selectedValue.toString() === USER_STATUSES.USER_STATUS_EMAIL_CONFIRMATION_PENDING.toString()
    })
    statuses.push({
      text: i18n.t(`user_statuses.status_${USER_STATUSES.USER_STATUS_DISABLED_SYSTEM}`) as string,
      value: USER_STATUSES.USER_STATUS_DISABLED_SYSTEM,
      selected: !!selectedValue && selectedValue.toString() === USER_STATUSES.USER_STATUS_DISABLED_SYSTEM.toString()
    })
  }
  return statuses
}
