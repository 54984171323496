import { Exclude, Expose } from 'class-transformer'
import { validate, ValidationError } from 'class-validator'

@Exclude()
export abstract class AbstractModel {
  public errors: Object

  constructor () {
    this.errors = {}
  }

  public get isValidModel (): boolean {
    return Object.keys(this.errors).length === 0
  }

  public async validateModel () {
    let result = await validate(this)
    this.errors = this.setError(result)
  }

  public setError (result: ValidationError[]): Object {
    let propBag = {}

    for (const error of result) {
      for (const key in error.constraints) {
        if (Object.prototype.hasOwnProperty.call(error.constraints, key)) {
          (propBag as any)[error.property] = error.constraints[key]
        }
      }
    }

    return propBag
  }

  @Expose({ name: 'id' })
  public id: number | null = null
}
