import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import { ShipmentReference } from '@/models/shipment-reference'
import {
  CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE,
  CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE,
  PM_PACKAGING_CATEGORY, PM_PRODUCT_CATEGORY
} from '@/models/shipment'
import { Packaging } from '@/models/packaging'
import { ParcelCommodity } from './parcel-commodity'

@Exclude()
export class Parcel extends AbstractModel {
  static get modelName () {
    return 'Parcel'
  }

  @Expose({ name: 'quantity' })
  public quantity: number | string | undefined | null = ''

  @Expose({ name: 'length' })
  public length: number | string | undefined | null = ''

  @Expose({ name: 'width' })
  public width: number | string | undefined | null = ''

  @Expose({ name: 'height' })
  public height: number | string | undefined | null = ''

  @Expose({ name: 'weight' })
  public weight: number | undefined | null = undefined

  @Expose({ name: 'volumetric_weight' })
  public volumetricWeight: number | string | undefined = ''

  @Expose({ name: 'content' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public content: string | null = ''

  @Expose({ name: 'value' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public value: number | string | null = ''

  @Expose({ name: 'value_currency' })
  @Transform(value => value, { toClassOnly: true })
  public valueCurrency: string | null = null

  @Expose({ name: 'dimension_unit' })
  @Transform(value => value === 'cm' ? 'm' : 'i', { toClassOnly: true })
  @Transform(value => value === 'm' ? 'cm' : 'in', { toPlainOnly: true })
  public measurementUnit: string = 'i'

  @Expose({ name: 'weight_unit' })
  @Transform(value => value === 'kg' ? 'm' : 'i', { toClassOnly: true })
  @Transform(value => value === 'm' ? 'kg' : 'lbs', { toPlainOnly: true })
  public weightUnit: string = 'i'

  @Expose({ name: 'shipment_references' })
  @Type(() => ShipmentReference)
  public shipmentReferences: ShipmentReference[] = []

  @Expose({ name: '_destroy' })
  @Transform((value) => (value) ? value : false)
  public destroy: boolean = false

  @Expose({ name: 'packaging_category' })
  @Transform(value => !value ? { parcel_id: undefined, key: PM_PACKAGING_CATEGORY, value: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.value, select_box_text: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.text } : value, { toClassOnly: true })
  public packagingCategory: { parcel_id: string | undefined, key: number, value: string, select_box_text: string } = { parcel_id: undefined, key: PM_PACKAGING_CATEGORY, value: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.value, select_box_text: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.text }

  @Expose({ name: 'product_category' })
  @Transform(value => !value ? { parcel_id: undefined, key: PM_PRODUCT_CATEGORY, value: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.value, select_box_text: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.text } : value, { toClassOnly: true })
  public productCategory: { parcel_id: string | undefined, key: number, value: string, select_box_text: string } = { parcel_id: undefined, key: PM_PRODUCT_CATEGORY, value: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.value, select_box_text: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.text }

  @Expose({ name: 'has_dangerous_materials' })
  public hasDangerousMaterials: boolean | undefined = false

  @Expose({ name: 'dangerous_material_product_category' })
  public dangerousMaterialProductCategory: string | undefined

  @Expose({ name: 'dangerous_material_packaging_category' })
  public dangerousMaterialPackagingCategory: string | undefined

  @Expose({ name: 'commodities' })
  @Type(() => ParcelCommodity)
  @Transform((value) => (value) ? value : [])
  public commodities: ParcelCommodity[] = []

  public get commodityTotals () {
    let totals = {
      weight: 0,
      value: 0
    }
    this.activeCommodities.map((commodity: ParcelCommodity) => totals.weight += (commodity.totalWeight || 0))
    this.activeCommodities.map((commodity: ParcelCommodity) => totals.value += (commodity.totalValue || 0))
    return totals
  }

  public get activeCommodities () {
    return this.commodities.filter((commodity) => !commodity.destroy)
  }

  public validated: boolean = false
  public fromTemplate: Packaging | null = null

  public get activeRefs () {
    return this.shipmentReferences.filter(item => !item.destroy)
  }

  public async isValid (skipCartaPorteValidation: boolean = false) {
    this.validated = true
    return !!(this.width && Number(this.width) > 0
      && this.height && Number(this.height) > 0
      && this.weight && Number(this.weight) > 0
      && this.quantity && Number(this.quantity) > 0
      && this.length && Number(this.length) > 0
      && (skipCartaPorteValidation || (this.content && this.content.length > 0))
      && this.referencesValid()
      && this.isCartaPorteValid(skipCartaPorteValidation)
      && await this.allCommoditiesValid(this)
    )
  }

  public async allCommoditiesValid (parcel: Parcel) {
    for (const commodity of parcel.activeCommodities) {
      await commodity.validateModel()
      commodity.wasValidated = true
      if (!commodity.isValidModel) return false
    }
    return true
  }

  public isCartaPorteValid (skipCartaPorteValidation: boolean = false): boolean {
    if (skipCartaPorteValidation) return true
    if (this.hasDangerousMaterials) {
      return (this.dangerousMaterialProductCategory ?? '').length > 0 && (this.dangerousMaterialPackagingCategory ?? '').length > 0
    } else {
      return (this.packagingCategory.value ?? '').length > 0 && (this.productCategory.value ?? '').length > 0 && (this.content ?? '').length > 0
    }
  }

  public referencesValid () {
    let isValid: boolean = true
    this.activeRefs.every((ref: ShipmentReference) => {
      ref.validated = true
      return isValid = !!(ref.reference && ref.reference.length)
    })
    return isValid
  }
}
