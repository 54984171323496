import { Expose } from 'class-transformer'

export class OrderCustomer {
  @Expose({ name: 'customer_id' })
  public customerId: number | undefined

  @Expose({ name: 'first_name' })
  public firstName: string = ''

  @Expose({ name: 'last_name' })
  public lastName: string = ''

  constructor (firstName: string, lastName: string) {
    this.firstName = firstName
    this.lastName = lastName
  }
}
