import i18n from '@/i18n'
import { SelectScopeOptions } from '@/constants/corporate-account'


export function useCorporateAccountScopes(selectedValue: string[] | string | undefined | null) {
  return Object.values(SelectScopeOptions).map((code) => {
    return {
      text: `${i18n.t(`corporate_account.index.options.filter.scope.${code.toLowerCase()}`)}`,
      value: code,
      selected:
        !!selectedValue &&
        ((Array.isArray(selectedValue) && selectedValue.map((val) => val.toLowerCase()).includes(code.toLowerCase())) ||
          !Array.isArray(selectedValue) && selectedValue.toLowerCase() === code.toLowerCase())
    }
  })
}
