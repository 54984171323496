import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'

// @ts-ignore
import RequestDiscount from './store/RequestDiscount'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0'
  },
  modules: {
    requestDiscount: RequestDiscount
  }
}

export default new Vuex.Store<RootState>(store)
