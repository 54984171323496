import Vue from 'vue'

// All commented code was generated by quasar
// This code was moved to quasar layout to not conflict with main application

// import './styles/quasar.scss'
import 'quasar/dist/quasar.ie.polyfills'
// import iconSet from 'quasar/icon-set/fontawesome-v6-pro.js'
import '@quasar/extras/material-icons/material-icons.css'
// import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import Quasar from 'quasar/src/vue-plugin.js';

Vue.use(Quasar, {
  config: {},
  plugins: {
  },
  // iconSet: iconSet
 })
